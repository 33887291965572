import React, { useRef } from "react";
import { useLayoutV2 } from "../../../../context/LayoutProvider";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  WIFormDropdownV2,
  WIFormInputTextV2,
} from "../../../../components_v2/common/form-fields";
import { Button } from "primereact/button";
import * as Yup from "yup";
import GlobalSettingsAPI from "../../../../services/global-contents/globalSettings";
import { parseJSONtoObj } from "../../../../utils/logic";
import WIFormArrayInputTextV2 from "../../../../components_v2/common/form-fields/wi-form-array-input";
import _ from "lodash";
import { GLOBAL_SETTING_TYPE_LIST, GLOBAL_SETTING_TYPES } from "../../../../utils/constants";

const GlobalSettingsForm = (props: any) => {
  const { globalSetting, callBack, onHide } = props;
  const { setLoadingProgress, setErrorProgress, setSuccessProgress } =
    useLayoutV2();
  const { t: errorTrans } = useTranslation("language", { keyPrefix: "errors" });
  const { t } = useTranslation("language", { keyPrefix: "global_settings" });
  const formikRef = useRef(null);
  const SEPERATOR_CHARACTER = ';';

  const onSuccess = () => {
    callBack();
    onHide();
  };

  const onSubmitglobalSettingForm = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans("txt_loading"));
        const updateRes = await GlobalSettingsAPI.updateGlobalSetting({
          ...values,
          setting_value: globalSetting.data_type === 'array' ? values.setting_value?.join(SEPERATOR_CHARACTER) : globalSetting.data_type === 'json' ? JSON.stringify(values.setting_value) : values.setting_value
        }, globalSetting?.id);
        if (updateRes?.status === 200 && updateRes?.data) {
          setSuccessProgress(t("txt_update_setting_success"));
          onSuccess();
        } else {
          throw new Error("txt_failed_update_setting");
        }
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  };

  const initialFormValues = () => {
    let initialValues: any = {
      setting_type: GLOBAL_SETTING_TYPES.WI_ADMIN_PORTAL,
      setting_value: ""
    };

    if (globalSetting) {
      initialValues.setting_type = parseJSONtoObj(globalSetting?.setting_type)|| initialValues.setting_type;
      initialValues.setting_value = globalSetting.data_type === 'array' ? globalSetting?.setting_value?.split(SEPERATOR_CHARACTER) : parseJSONtoObj(globalSetting?.setting_value) || initialValues.setting_value;
    }

    return initialValues;
  };

  const validationSchema = Yup.object().shape({
    setting_value: Yup.lazy((value) => {
      if (Array.isArray(value)) {
        return Yup.array()
          .of(Yup.string().nullable().required(errorTrans("txt_required")));
      }
  
      if (typeof value === "object") {
        return Yup.object().shape({
          de: Yup.string().nullable().required(errorTrans("txt_required")),
          en: Yup.string().nullable().required(errorTrans("txt_required")),
        });
      }
  
      return Yup.string().nullable().required(errorTrans("txt_required"));
    }),
  });
  

  return (
    <div className="globalSetting-form">
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues()}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="row-content">
              <div className="col-md-12 mb-24">
                <WIFormDropdownV2
                  name='setting_type'
                  title={t('txt_setting_type')}
                  isRequired={true}
                  optionLabel="label"
                  optionValue="code"
                  appendTo="self"
                  options={GLOBAL_SETTING_TYPE_LIST}
                />
              </div>
                {
                globalSetting.data_type === 'json' ?
                <>
                <div className="row mt-24 mb-24">
                  <div className="col-md-12">
                    <WIFormInputTextV2
                      name={`setting_value.de`}
                      title={t('txt_setting_value_de')}
                      isRequired={true}
                    />
                  </div>
                  
                </div>
                <div className="row mt-24 mb-24">
                  <div className="col-md-12">
                  <WIFormInputTextV2
                    name={`setting_value.en`}
                    title={t('txt_setting_value_en')}
                    isRequired={true}
                  />
                  </div>
                </div>
                </>
                 :
                 globalSetting.data_type === 'array' ? 
                 <WIFormArrayInputTextV2
                    name={`setting_value`}
                    title={t('txt_setting_value')}
                    isRequired={true}
                    onAdd={() => {
                      setFieldValue('setting_value', [...values?.setting_value, '']);
                    }}
                    onRemove={(index: number) => {
                      const updatedItems = [..._.get(values, 'setting_value')];
                      updatedItems.splice(index, 1);
                      setFieldValue('setting_value', updatedItems);
                    }}
                  />
                 :
                 <div className="row mt-24 mb-24">
                  <div className="col-md-12">
                    <WIFormInputTextV2
                      name={`setting_value`}
                      title={t('txt_setting_value')}
                      isRequired={true}
                    />
                  </div>
                </div>
                
                }
              <div className="row mt-24">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between gap-24">
                    <Button
                      className="wi-danger-button-v2 h48 flex-1"
                      type="submit"
                      label={`${t("txt_cancel")}`}
                      onClick={() => onHide()}
                    />
                    <Button
                      className="wi-primary-button-v2 h48 flex-1"
                      type="submit"
                      label={t("txt_update")}
                      onClick={() => onSubmitglobalSettingForm()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default GlobalSettingsForm;
