import * as React from 'react';
import { memo } from 'react';
import useAuth from '../../../../context/useAuth';
import { PERMISSIONS_V2 } from '../../../../utils/constants';

const CertificateFormComponent = (props: any) => {
  const { values, errors, touched, handleChange, handleBlur } = props;
  const { auth } = useAuth();
  const permissions = {
    canCreateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_CREATE),
    canUpdateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_UPDATE),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
  };

  return (
    <>
      <div className="row row-content">
        <div className="col-md-12">
          <div className="element-form">
            <label className="label">
              Certificate Description DE
              <span className="required-label"> *</span>
            </label>
            <textarea
              className={`${touched.certificate_description?.de && errors.certificate_description?.de ? 'has-error' : ''}`}
              name="certificate_description.de"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.certificate_description?.de}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
            />
            <div className={`${touched.certificate_description?.de && errors.certificate_description?.de ? 'error' : ''}`}>
              {touched.certificate_description?.de && errors.certificate_description?.de}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="element-form">
            <label className="label">
              Certificate Description EN
              <span className="required-label"> *</span>
            </label>
            <textarea
              className={`${touched.certificate_description?.en && errors.certificate_description?.en ? 'has-error' : ''}`}
              name="certificate_description.en"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.certificate_description?.en}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
            />
            <div className={`${touched.certificate_description?.en && errors.certificate_description?.en ? 'error' : ''}`}>
              {touched.certificate_description?.en && errors.certificate_description?.en}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CertificateFormComponent);
