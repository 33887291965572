import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { Tag } from 'primereact/tag';
import { FOOTERS, HEADERS, TypeLandCode } from '../../../utils/utils';
import { onNavigateToByURL } from '../../../utils/logic';
import './column-template.scss';
import { Link } from 'react-router-dom';
import WiTooltip from '../../../components_v2/common/wi-tooltip/wi-tooltip';

export const customMenuItemsDot = (item: any, options: any) => {
  const buildChild = (item: any, options: any) => (
    <>
      <span className={options.iconClassName}></span>
      <div className="p-menuitem-containers">
        <span className={`${options.labelClassName} headline`}>{item.label}</span>
        {item.description && (
          <>
            <span className={`${options.labelClassName} description`}>{item.description}</span>
          </>
        )}
      </div>
    </>
  );

  if (item.url) {
    return (
      <>
        <Link
          to={item.url}
          className={'p-menuitem-link wi-menuitem-3-dot' + (item.disabled ? ' p-disabled' : '')}
          role="menuitem"
          onClick={e => options.onClick(e)}
        >
          {buildChild(item, options)}
        </Link>
      </>
    );
  } else {
    const propList = item.externalUrl ? { href: item.externalUrl } : { onClick: (e: any) => options.onClick(e) };
    return (
      <>
        <a
          data-testid="menudotItem"
          {...propList}
          className={`p-menuitem-link wi-menuitem-3-dot ${item.dangerous ? 'color-red-warning' : ''} ${item.disabled ? ' p-disabled' : ''}`}
          role="menuitem"
        >
          {buildChild(item, options)}
        </a>
      </>
    );
  }
};

export const formatDateBodyTemplate =
  (formatDate: string, lang = 'de') =>
  (rowData: any, column: any) => {
    return rowData[column.field]
      ? moment(new Date(rowData[column.field]), 'MMM DD, YYYY HH:mm:ss')
          .locale(lang)
          .utc()
          .add(1, 'hour')
          .format(formatDate || 'MMM DD, YYYY HH:mm:ss')
      : '';
  };

export const formatDateByFormatBodyTemplate = (formatDate: string) => (rowData: any, column: any) => {
  return rowData[column.field] ? moment(new Date(rowData[column.field]), formatDate).utc().add(1, 'hour').format(formatDate) : '-';
};

export const formatNameBodyTemplate = (blockName: string) => (rowData: any, column: any) => {
  const enumBlock = blockName === 'footer' ? FOOTERS : HEADERS;
  return (
    <React.Fragment>
      <div className="row">
        <span className="image-text px-2">
          {_.isString(rowData[column.field]) ? rowData[column.field] : rowData.is_primary ? enumBlock.PRIMARY.value : enumBlock.SECONDARY.value}
        </span>
      </div>
    </React.Fragment>
  );
};

export const buildActionBtnTemplate = (rowData: any, listBtn: any, toast?: any, displayBtn: any = true, disable: any = false) => {
  return (
    <React.Fragment>
      {listBtn.length > 0 ? (
        displayBtn &&
        listBtn.map((item: any, index: number) => (
          <div key={index} className="btn-action">
            {item.btnFunc ? (
              <i className={`${item.className} ${disable ? 'disabled' : ''}`} onClick={e => item.btnFunc(e, rowData, toast)}></i>
            ) : (
              <i className={`${item.className}`}></i>
            )}
          </div>
        ))
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
export const buildDefaultBodyTemplate = (rowData: any, column: any) => rowData[column.field];

export const buildCountryBodyTemplate = (countries: any) => (rowData: any, column: any) => {
  if (rowData[column.field]) {
    const country: any = countries.find((countryItem: any) => countryItem.ISO2 === rowData[column.field]);
    return country?.name || rowData[column.field];
  }
};

export const buildTooltipManageTemplate = (
  title: string,
  label: string,
  tooltipLabel: string,
  url: string,
  isDisabled = false,
  isHideNavigateButton = false,
) => {
  return (
    <div className="title-content">
      {label ? (
        <>
          <label className="title-header">
            {title}:{` `}
          </label>
          <span> </span>
          <WiTooltip content={tooltipLabel} direction="right">
            <span className="me-3">{label}</span>
          </WiTooltip>
          {isHideNavigateButton ? null : (
            <i
              className={`${isDisabled ? 'has-disable-navigate' : ''} fa-solid fa-arrow-up-right-from-square`}
              onClick={() => onNavigateToByURL(url)}
            ></i>
          )}
        </>
      ) : (
        <label className="title-header">New</label>
      )}
    </div>
  );
};

export const buildCouponStatusColumnTemplate = (rowData: any, column: any) => {
  const status = _.isString(rowData[column.field]) ? rowData[column.field] : rowData[column.field] ? 'finished' : 'active';
  const value = status;

  try {
    return (
      <React.Fragment>
        <Tag className={`mr-2 wi-tag tag-status-${status.toLowerCase()}`} value={value} />
      </React.Fragment>
    );
  } catch (error) {
    return (
      <React.Fragment>
        <Tag className="mr-2" value={value} />
      </React.Fragment>
    );
  }
};

export const buildAreaRefLink = (rowData: any) => {
  return rowData.land_id ? (
    <Link className="table-ref-link-cell" to={`/areas/${rowData.land_id}`}>
      {rowData.land_code}
    </Link>
  ) : (
    <div>{rowData.land_code}</div>
  );
};

export const buildCampaignRefLink = (rowData: any) => {
  return rowData.campaign_id ? (
    <Link className="table-ref-link-cell" to={`/campaigns/${rowData.campaign_id}`}>
      {rowData.campaign_code}
    </Link>
  ) : (
    <div>{rowData.campaign_code}</div>
  );
};

export const formatSourceOptionTemplate = (option: any, available_size: any, key: any = TypeLandCode.campaign) => {
  return (
    <div>
      {key === TypeLandCode.region ? (
        <>
          <div className="t-bold">{option?.region?.name?.de}</div>
          <div style={{ fontWeight: 500 }}>
            {option?.name?.de || option?.name} - Available size [{(+(available_size || 0))?.toLocaleString('de-DE')} m<sup>2</sup>]
          </div>
        </>
      ) : (
        <></>
      )}
      {key === TypeLandCode.land ? (
        <>
          <div className="t-bold">
            {option?.name?.de || option?.name} - Available size [{(+(available_size || 0))?.toLocaleString('de-DE')} m<sup>2</sup>]
          </div>
          <div style={{ fontWeight: 500 }}>{option?.master_region?.name?.de}</div>
        </>
      ) : (
        <></>
      )}
      {key === TypeLandCode.campaign ? (
        <>
          <div className="t-bold">
            {option?.name?.de || option?.name} - Available size [{(+(available_size || 0))?.toLocaleString('de-DE')} m<sup>2</sup>]
          </div>
          <div style={{ fontWeight: 500 }}>
            {option?.master_region?.name?.de} / {option?.land?.name.de || option?.land?.name}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
