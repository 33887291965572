import { useEffect, useRef, useState } from 'react';
import React from 'react';
import './user-detail-form.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Menu } from 'primereact/menu';
import { LANGUAGE_LIST, USER_PARTIES, TENANT_TYPES, STATUS_CODE } from '../../../../utils/constants';
import { env } from '../../../../configs';
import { PartnerManagerAPI } from '../../../../services';
import crown from '../../../../assets/images/icons/crown.svg';
import building from '../../../../assets/images/icons/building.svg';
import { ReactComponent as ReplaceSvg } from '../../../../assets/images/icons/replace.svg';
import { ReactComponent as EditSvg } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as TrashSvg } from '../../../../assets/images/icons/trash.svg';
import PartnerItem from '../../../../components_v2/common/partner-item/partner-item';
import InsertMediaDialogComponent from '../../../../components/common/upload/wi-upload-media-dialog';
import { WIFormDropdownV2, WIFormInputTextV2 } from '../../../../components_v2/common/form-fields';
import WIFormMultiSelectV2 from '../../../../components_v2/common/form-fields/wi-form-multiselect';
import { UserManagerAPI } from '../../../../services/v2';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import _ from 'lodash';

const UserDetailForm = (props: any) => {
  const { setErrorProgress } = useLayoutV2();
  const { isDisabledForm, isDisabledFiledForm, userData, isEdit, formikRef, isProfileMode } = props;
  const { t } = useTranslation('language', { keyPrefix: 'system_user_detail' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: globalTrans } = useTranslation('language');
  const [partners, setPartners] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [isShowMediaDialog, setIsShowMediaDialog] = useState(false);
  const menu = useRef(null);

  const commonSelectedTemplate = (option: any) => {
    return <div>{option?.label ? globalTrans(option?.label) : ''} </div>;
  };

  const commonItemTemplate = (option: any) => {
    return <div>{globalTrans(option?.label)} </div>;
  };

  const commonSelectedItemsTemplate = (option: any) => {
    const selectedItem = roles.find((r: any) => r.uuid === option);
    if (selectedItem) {
      return <span className="wi-tag-role mr-8">{selectedItem?.label ? globalTrans(selectedItem?.label) : ''} </span>;
    }
    return <></>;
  };

  const fetchMasterData = async () => {
    try {
      const masterDataPromises = [PartnerManagerAPI.getAllPartners()];
      if (!isProfileMode) {
        masterDataPromises.push(UserManagerAPI.getRoles());
      }
      const [resPartner, resRoles] = await Promise.all(masterDataPromises);
      for (let res of [resPartner, resRoles]) {
        if (res && res.status !== 200) {
          if (_.isEqual(res, resPartner) || (_.isEqual(res, resRoles) && !isProfileMode)) {
            throw new Error('txt_unknown_load_data_fail');
          }
        }
      }
      setPartners(resPartner?.data?.records.filter((r: any) => r.status === STATUS_CODE.ACTIVE) || []);
      if (!isProfileMode) {
        setRoles(resRoles?.data?.result.map((r: any) => ({ ...r, label: `roles.txt_${r.name}` })) || []);
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  };

  useEffect(() => {
    fetchMasterData();
  }, []);

  const validationSchema = Yup.object().shape({
    roles: Yup.array().nullable().min(1, errorTrans('txt_required')).required(errorTrans('txt_required')),
    first_name: Yup.string()
      .transform((currentValue, originalValue) => {
        return !originalValue || originalValue.toString().trim() === '' ? null : currentValue;
      })
      .nullable()
      .required(errorTrans('txt_required')),
    last_name: Yup.string()
      .transform((currentValue, originalValue) => {
        return !originalValue || originalValue.toString().trim() === '' ? null : currentValue;
      })
      .nullable()
      .required(errorTrans('txt_required')),
    email: Yup.string().email(errorTrans('txt_email_format')).required(errorTrans('txt_required')),
    language: Yup.string().required(errorTrans('txt_required')),
    partner_id: Yup.string()
      .nullable()
      .when('party', {
        is: USER_PARTIES.PARTNER,
        then: Yup.string().required(errorTrans('txt_required')),
        otherwise: Yup.string().nullable(),
      }),
  });

  const initialFormValues = () => {
    let initialUser = {
      first_name: userData?.first_name || '',
      last_name: userData?.last_name || '',
      email: userData?.email || '',
      party: userData?.party || USER_PARTIES.WILDERNESS_INTERNATIONAL,
      roles: userData?.roles?.map((r: any) => r.uuid) || [],
      display_name: userData?.display_name || '',
      partner_id: userData?.party === USER_PARTIES.PARTNER ? userData?.tenant_id : '' || '',
      avatar: userData?.avatar || '',
      language: userData?.language_code || 'en',
    };

    return initialUser;
  };

  const itemsAction = (values: any, setFieldValue: any) => {
    return [
      {
        disabled: !isEdit || isDisabledFiledForm(),
        label: `${t('txt_change')}`,
        className: 'item-menu',
        icon: <ReplaceSvg className="icon-svg"></ReplaceSvg>,
        command: () => {
          setIsShowMediaDialog(true);
        },
      },
      {
        // @ts-ignore: Object is possibly 'null'.
        disabled: !isEdit || !values?.avatar || isDisabledFiledForm(),
        label: `${t('txt_delete')}`,
        className: 'item-menu special-item',
        icon: <TrashSvg className="icon-svg"></TrashSvg>,
        command: () => {
          setFieldValue('avatar', '', true);
        },
      },
    ];
  };

  const langugeOptionTemplate = (option: any) => {
    return (
      <div className="wi-language-item">
        <p className="wi-language-label m-0">{globalTrans(option?.label)}</p>
        <div className="wi-language-icon ml-12 d-flex">
          <img alt={option?.text} src={option?.icon} loading="lazy" className="m-0" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="user-form">
        <div className="user-form-container">
          <Formik
            enableReinitialize={true}
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({ values, handleSubmit, setFieldValue, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="user-column pt-24">
                        <div className={`user-avatar-with-role big m-10`}>
                          {values?.avatar ? (
                            <img className="avatar m-0" src={`${env.PUBLIC_UPLOAD_URL}/${values?.avatar}`} alt="logo user" loading="lazy" />
                          ) : (
                            <div className="user-avatar-default" style={{ background: userData?.avartar_color }}>
                              {values?.first_name?.charAt(0).toUpperCase()}
                              {values?.last_name?.charAt(0).toUpperCase()}
                            </div>
                          )}
                          {isEdit && (
                            <div
                              className="background-edit-user"
                              // @ts-ignore: Object is possibly 'null'.
                              onClick={event => menu.current.toggle(event)}
                            >
                              <EditSvg></EditSvg>
                            </div>
                          )}
                          <div className="user-role">
                            <img
                              className="m-0"
                              src={values?.party === USER_PARTIES.WILDERNESS_INTERNATIONAL ? crown : building}
                              alt="badge"
                              loading="lazy"
                            />
                          </div>
                          <Menu
                            model={itemsAction(values, setFieldValue)}
                            popup
                            ref={menu}
                            id="popup_menu"
                            className="menu-popup-v2 dropdown-panel-bottom-v2"
                          />
                        </div>
                        <InsertMediaDialogComponent
                          visible={isShowMediaDialog}
                          fileType="image"
                          handleFileValue={(selectedMedia: any) => {
                            setFieldValue('avatar', encodeURI(`${selectedMedia.fullPath}`), true);
                            setIsShowMediaDialog(false);
                          }}
                          setIsShowMediaDialog={setIsShowMediaDialog}
                          onHide={() => setIsShowMediaDialog(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-24 mb-24">
                    <div className="col-md-6">
                      <WIFormInputTextV2
                        name={`first_name`}
                        title={t('txt_first_name')}
                        isRequired={true}
                        maxLength={40}
                        disabled={isDisabledFiledForm() || !isEdit}
                      />
                    </div>
                    <div className="col-md-6">
                      <WIFormInputTextV2
                        name={`last_name`}
                        title={t('txt_last_name')}
                        isRequired={true}
                        maxLength={40}
                        disabled={!isEdit || isDisabledFiledForm()}
                      />
                    </div>
                  </div>
                  <div className="row mb-24">
                    <div className="col-md-6">
                      <WIFormInputTextV2
                        name={`display_name`}
                        title={t('txt_display_name')}
                        maxLength={16}
                        disabled={!isEdit || isDisabledFiledForm()}
                      />
                    </div>
                    <div className="col-md-6">
                      <WIFormDropdownV2
                        name="language"
                        title={t('txt_language')}
                        isRequired={true}
                        options={LANGUAGE_LIST}
                        optionLabel={`label`}
                        optionValue="code"
                        appendTo="self"
                        valueTemplate={commonSelectedTemplate}
                        itemTemplate={langugeOptionTemplate}
                        disabled={!isEdit || isDisabledFiledForm()}
                      />
                    </div>
                  </div>
                  <div className="row mb-24">
                    <div className="col-md-12">
                      <WIFormInputTextV2
                        name={`email`}
                        title={t('txt_email')}
                        isRequired={true}
                        maxLength={255}
                        // disabled={!isEdit || isDisabledForm()}
                        disabled={true}
                      />
                    </div>
                  </div>
                  {!isProfileMode && (
                    <>
                      <div className="row mb-24">
                        <div className="col-md-12">
                          <WIFormDropdownV2
                            name="party"
                            title={t('txt_tenant')}
                            isRequired={true}
                            options={TENANT_TYPES}
                            optionLabel={`label`}
                            optionValue="code"
                            appendTo="self"
                            valueTemplate={commonSelectedTemplate}
                            itemTemplate={commonItemTemplate}
                            disabled={!isEdit || isDisabledForm()}
                            onChange={(e: any) => {
                              setFieldValue('roles', [], false);
                              handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-24">
                        <div className="col-md-12">
                          <WIFormMultiSelectV2
                            name="roles"
                            title={t('txt_role')}
                            isRequired={true}
                            options={
                              roles.filter((r: any) => r.is_host_only === (values?.party === USER_PARTIES.WILDERNESS_INTERNATIONAL ? 1 : 0)) || []
                            }
                            optionLabel={`label`}
                            optionValue="uuid"
                            appendTo="self"
                            selectedItemTemplate={commonSelectedItemsTemplate}
                            itemTemplate={commonItemTemplate}
                            disabled={!isEdit || isDisabledForm()}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {values?.party === USER_PARTIES.PARTNER && !isProfileMode && (
                    <div className="row">
                      <div className="col-md-12">
                        <WIFormDropdownV2
                          name="partner_id"
                          title={t('txt_partner')}
                          isRequired={true}
                          options={partners || []}
                          optionLabel={`name`}
                          optionValue="uuid"
                          appendTo="self"
                          itemTemplate={(option: any) => <PartnerItem option={option}></PartnerItem>}
                          filter
                          filterBy="name"
                          disabled={!isEdit || isDisabledForm()}
                          filterPlaceholder={t('txt_search')}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UserDetailForm;
