import * as React from 'react';
import './wi-progress.scss';
import { ProgressBar } from 'primereact/progressbar';

export const WiProgress = (fieldName: string, fieldTotal: string, fieldCal: string) => (rowData: any, column: any) => {
  const total = parseInt(rowData[fieldTotal]);
  const calculated = fieldName === 'calculator' ? total - parseInt(rowData[fieldCal]) : rowData[fieldCal];
  const value = (100 * calculated) / total;

  const displayValueTemplate = (total: number, value: number) => () => {
    return (
      <React.Fragment>
        {(value || 0).toLocaleString('de')} / {(total || 0).toLocaleString('de')}
      </React.Fragment>
    );
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <ProgressBar value={value} displayValueTemplate={displayValueTemplate(total, calculated)}></ProgressBar>
      </div>
    </>
  );
};
