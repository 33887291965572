import * as React from 'react';
import './add-donation-form.scss';
import { Formik } from 'formik';
import { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { SelectButton } from 'primereact/selectbutton';
import { COMMON_STATUS, CurrencyList, ImportNewTypeList, TypeLandCode } from '../../../../../../utils/utils';
import { parseStringToFloat, validateNumber } from '../../../../../../utils/logic';
import WICheckBoxV2 from '../../../../../../components_v2/common/wi-checkbox';
import SearchDonorComponent from '../search-donor/search-donor';
import { DonationManagerAPI, DonorManagerAPI } from '../../../../../../services';
import { formatSourceOptionTemplate } from '../../../../../common/column-template-table/column-template';
import { useLayoutV2 } from '../../../../../../context/LayoutProvider';
import AddDonorFormComponent from '../../../../../../pages/donor-manager/components/add-donor-form/add-donor-form';
import { CURRENCY_CODES, DONATION_PAYMENT_METHOD_LIST, DONATION_STATUS, LANGUAGE_LIST } from '../../../../../../utils/constants';

const AddDonationFormComponent = (props: any) => {
  const { lands, campaigns, countries, isSuperAdmin, isReserve, onHide } = props;

  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'donation_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const [isShowSidebarDonor, setIsShowSidebarDonor] = useState(false);
  const [selectedDonor, setSelectedDonor] = useState<any>({ name: '', item: null });
  const [donors, setDonors] = useState([]);
  let timeoutInput: any = null;
  const { t: globalTrans } = useTranslation('language');

  const getDonors = async (text: string = '', item?: any) => {
    try {
      if (item) {
        setSelectedDonor({
          name: `${item.payment_first_name || ''} ${item.payment_last_name || ''} - ${item.payment_email}`,
          item: item,
        });
      }
      const res = await DonorManagerAPI.searchDonors(item ? `${item.payment_first_name || ''} ${item.payment_last_name || ''}` : text);
      if (res && res.status === 200) {
        const sortDonors: any = _.sortBy(res.data, 'joined_date').reverse();
        setDonors(res.data?.length > 0 ? sortDonors : []);
      }
    } catch (error) {
      console.log('error >>>', error);
    }
  };

  useEffect(() => {
    getDonors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (selectedDonor && selectedDonor.name) {
      setSelectedDonor(selectedDonor);
    }
  }, [selectedDonor]);

  const initialDonationValues = () => {
    return {
      donation_money: 0,
      payment_method: '',
      payment_language_code: 'de',
      payment_currency_code: 'eur',
      donation_type: TypeLandCode.region,
      land_code: '',
      payment_date: moment().toDate(),
      personalize_certificate_display_name: '',
      personalize_certificate_reason: '',
      is_add_donation: false,
      disabled_receipt: false,
      enable_receipt: true,
      area_value: 0,
      donor_id: '',
      land_id: '',
      campaign_id: '',
      area_selected: null,
      custom_area: 0,
      payment_company_name: '',
      send_mail: false,
    };
  };

  const donationSchema = Yup.object().shape({
    donation_money: Yup.number()
      .when('payment_currency_code', {
        is: (value: any) => value === CURRENCY_CODES.CAD,
        then: Yup.number().nullable().min(2, errorTrans('txt_integer_min_2')),
        otherwise: Yup.number().nullable().min(1, errorTrans('txt_integer_min_1')),
      })
      .required(errorTrans('txt_required')),
  });

  const donationFormSchema = Yup.object().shape({
    donation_money: donationSchema.fields.donation_money,
    payment_method: Yup.string().nullable().required(errorTrans('txt_required')),
    land_code: Yup.string().nullable().required(errorTrans('txt_required')),
    payment_date: Yup.string().nullable().required(errorTrans('txt_required')),
    donation_type: Yup.string().nullable().required(errorTrans('txt_required')),
    payment_currency_code: Yup.string().nullable().required(errorTrans('txt_required')),
    payment_language_code: Yup.string().nullable().required(errorTrans('txt_required')),
  });

  const formatOptionTemplate = formatSourceOptionTemplate;

  const selectedOptionTemplate = (option: any, values: any, available_size: any, props: any) => {
    if (available_size < values.custom_area) {
      values.donation_type !== TypeLandCode.campaign ? (values.land_id = '') : (values.campaign_id = '');
      option = null;
    }

    if (!option) {
      return <span>{props.placeholder}</span>;
    }

    if (values.donation_type === TypeLandCode.region) {
      return (
        <div>
          {option?.region?.name?.de || option?.region?.name} - {t('txt_available_size')} [{parseInt(available_size || '0')?.toLocaleString('de-DE')} m
          <sup>2</sup>]
        </div>
      );
    } else if (values.donation_type === TypeLandCode.land) {
      return (
        <div className="t-bold">
          {option?.name?.de || option?.name} - {t('txt_available_size')} [{(+(available_size || 0))?.toLocaleString('de-DE')} m<sup>2</sup>]
        </div>
      );
    } else {
      return (
        <div className="t-bold">
          {option?.name?.de || option?.name} - {t('txt_available_size')} [{(+(available_size || 0))?.toLocaleString('de-DE')} m<sup>2</sup>]
        </div>
      );
    }
  };

  const onAddDonation = async (isSendMail: boolean) => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, resetForm } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef?.current?.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && formikRef?.current?.dirty && formikRef?.current?.isValid && selectedDonor.item) {
        setLoadingProgress(errorTrans('txt_loading'));

        const valuesData = {
          ...values,
          send_mail: values.send_mail,
          disabled_receipt: !values.enable_receipt,
          payment_date: moment(values?.payment_date).format('DD.MM.YYYY'),
          donor_id: selectedDonor.name ? selectedDonor.item.id : '',
          payment_company_name: values.payment_company_name?.trim(),
          personalize_certificate_display_name: values.personalize_certificate_display_name?.trim(),
          personalize_certificate_reason: values.personalize_certificate_reason?.trim(),
          payment_received_date: moment(values?.payment_date).format('DD.MM.YYYY'),
          status: isReserve ? DONATION_STATUS.RESERVED : DONATION_STATUS.SUCCEEDED,
        };

        if (values.donation_type !== TypeLandCode.campaign) {
          valuesData.land_id = values.land_code;
          delete valuesData.campaign_id;
          valuesData.donation_type = TypeLandCode.land;
        } else {
          valuesData.campaign_id = values.land_code;
          valuesData.land_id = values.area_selected?.uuid;
        }

        delete valuesData.land_code;
        delete valuesData.area_value;
        delete valuesData.is_add_donation;
        delete valuesData.area_selected;
        delete valuesData.custom_area;
        delete valuesData.enable_receipt;

        const resAdd = await DonationManagerAPI.addDonationManual(valuesData);
        if (resAdd && resAdd.status === 200) {
          if (values.is_add_donation) {
            resetForm();
            setSelectedDonor({ name: '', item: null });
          } else {
            navigate(`/donations/${resAdd.data.data.id}`);
          }
          setSuccessProgress(t('txt_save_donation_success'));
        } else {
          throw new Error('txt_unknown_load_data_fail');
        }
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message || 'txt_unknown_load_data_fail'));
    }
  };

  const onBlurAmountInput = (values: any, setFieldValue: any) => {
    const { custom_area, land_code, area_value } = values;
    if (custom_area && land_code && custom_area > area_value) {
      setFieldValue('land_code', '', false);
    }
  };

  const commonItemTemplate = (option: any) => {
    return <div>{option?.name ? globalTrans(option?.name) : ''} </div>;
  };

  const commonLanguageTemplate = (option: any) => {
    return <div style={{ fontSize: '13px', flex: '1 1 auto' }}>{option?.label ? globalTrans(option?.label) : ''} </div>;
  };

  const onSelectedItemSearch = (item: any, setFieldValue: any) => {
    if (item) {
      setFieldValue('donor_id', item.id, false);
      setSelectedDonor({
        name: `${item?.payment_first_name || ''} ${item?.payment_last_name || ''} - ${item?.payment_email}`,
        item: item,
      });
    } else {
      setFieldValue('donor_id', '', false);
      setSelectedDonor({ name: '', item: null });
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, setFieldValue: any) => {
    event.preventDefault();

    // remove new line character if any
    const pastedData = event.clipboardData.getData('text').replace(/(\r\n|\n|\r)/gm, '');
    let validatedNumber = validateNumber(pastedData);

    if (validatedNumber) {
      validatedNumber = parseFloat(validatedNumber.replace(/\./g, '').replace(',', '.'));
    } else {
      // reset to previous value
      // @ts-ignore: Object is possibly 'null'.
      validatedNumber = formikRef?.current?.values?.donation_money;
    }

    // this is cheat code beacuse Formik not support detect when set value to previous
    setFieldValue('donation_money', Number.parseFloat(validatedNumber) + 0.001, true);

    setTimeout(() => {
      setFieldValue('donation_money', validatedNumber, true);
    }, 0);
  };

  return (
    <div className="add-form-donation">
      <div className="add-form-container">
        <Formik
          enableReinitialize={false}
          innerRef={formikRef}
          initialValues={initialDonationValues()}
          validationSchema={donationFormSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="row-content">
                <div className="row">
                  <div className="col-md-12 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">
                        {t('txt_donor')}
                        <span className="required-label"> *</span>
                      </label>
                      <SearchDonorComponent
                        name="donor_id"
                        id="donor_id"
                        setIsShowSidebar={setIsShowSidebarDonor}
                        donors={donors}
                        selectedDonor={selectedDonor}
                        setSelectedDonor={setSelectedDonor}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          if (!e.target.value) {
                            onSelectedItemSearch(null, setFieldValue);
                          }
                        }}
                        getDonors={getDonors}
                        onSelected={(item: any) => onSelectedItemSearch(item, setFieldValue)}
                      />
                      <div className={`${touched.donor_id && !selectedDonor.name ? 'error' : ''}`}>
                        {touched.donor_id && !selectedDonor.name ? errorTrans('txt_required') : ''}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">
                        {t('txt_donation_amount')}
                        <span className="required-label"> *</span>
                      </label>
                      <div
                        onPaste={(e: any) => {
                          handlePaste(e, setFieldValue);
                        }}
                      >
                        <InputNumber
                          className={`${touched.donation_money && errors.donation_money ? 'has-error' : ''}`}
                          name="donation_money"
                          onBlur={e => {
                            const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                            setFieldValue('donation_money', value, true);
                            if (values.land_code) {
                              // @ts-ignore: Object is possibly 'null'.
                              const amount_conversion = values?.area_selected['amount_area_conversion'][values.payment_currency_code];
                              // @ts-ignore: Object is possibly 'null'.
                              setFieldValue('custom_area', Math.floor(value * amount_conversion), true);
                            } else {
                              setFieldValue('custom_area', value ?? 0, true);
                            }
                            onBlurAmountInput(values, setFieldValue);
                            clearTimeout(timeoutInput);
                            timeoutInput = setTimeout(() => {
                              handleBlur(e);
                            }, 200);
                          }}
                          value={values.donation_money}
                          mode="decimal"
                          locale="de-DE"
                          placeholder="0.00"
                          minFractionDigits={0}
                          maxFractionDigits={2}
                        />
                      </div>
                      <div className={`${touched.donation_money && errors.donation_money ? 'error' : ''}`}>
                        {touched.donation_money && errors.donation_money}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">
                        {t('txt_label_payment_method')}
                        <span className="required-label"> *</span>
                      </label>
                      <Dropdown
                        id="payment_method"
                        name="payment_method"
                        className="dropdown-component-v2"
                        panelClassName="dropdown-panel-v2 dropdown-panel-bottom-v2"
                        value={values.payment_method}
                        onBlur={handleBlur}
                        options={DONATION_PAYMENT_METHOD_LIST}
                        valueTemplate={commonItemTemplate}
                        itemTemplate={commonItemTemplate}
                        onChange={(item: any) => {
                          setFieldValue('payment_method', item.value, true);
                        }}
                        optionLabel={'name'}
                        optionValue={'code'}
                        appendTo="self"
                        placeholder={t('txt_placeholder_payment_method')}
                        filter
                        filterBy="name,code"
                        filterPlaceholder={t('txt_search')}
                      />
                      <div className={`${touched.payment_method && errors.payment_method ? 'error' : ''}`}>
                        {touched.payment_method && errors.payment_method}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">
                        {t('txt_type')}
                        <span className="required-label"> *</span>
                      </label>
                      <SelectButton
                        id="donation_type"
                        name="donation_type"
                        value={values.donation_type}
                        className={`wi-selectbutton ${isSuperAdmin ? 'wi-selectbutton-three' : 'wi-selectbutton-two'}`}
                        options={ImportNewTypeList.filter((l: any) => isSuperAdmin || l.code !== TypeLandCode.land)}
                        onChange={(item: any) => {
                          if (item.value) {
                            setFieldValue('donation_type', item.value, true);
                          }
                        }}
                        optionLabel="name"
                        optionValue="code"
                      />
                      <div className={`${touched.donation_type && errors.donation_type ? 'error' : ''}`}>
                        {touched.donation_type && errors.donation_type}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">
                        {t('txt_label_payment_date')}
                        <span className="required-label"> *</span>
                      </label>
                      <div className="calendar-item">
                        <Calendar
                          id="basic"
                          name="payment_date"
                          className="wi-calendar-v2"
                          value={values.payment_date}
                          onBlur={handleBlur}
                          onChange={item => {
                            setFieldValue('payment_date', item.value, false);
                          }}
                          dateFormat="dd.mm.yy"
                          iconPos="right"
                          icon="fa-solid fa-calendar-days"
                          showIcon={true}
                          disabled={isReserve}
                          appendTo="self"
                        />
                      </div>
                      <div className={`${touched.payment_date && errors.payment_date ? 'error' : ''}`}>
                        {`${touched.payment_date && errors.payment_date ? errors.payment_date : ''}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">
                        {values.donation_type === TypeLandCode.region
                          ? t('txt_region')
                          : values.donation_type === TypeLandCode.campaign
                            ? t('txt_campaign')
                            : t('txt_area')}
                        <span className="required-label"> *</span>
                      </label>
                      <Dropdown
                        name="land_code"
                        id="land_code"
                        className="dropdown-component-v2"
                        panelClassName="dropdown-panel-v2 dropdown-panel-bottom-v2"
                        disabled={!values.donation_type}
                        value={values.land_code}
                        onBlur={handleBlur}
                        options={
                          values.donation_type === TypeLandCode.region
                            ? lands.filter((l: any) => !!l.region)
                            : (values.donation_type === TypeLandCode.land
                                ? lands.filter((l: any) => l.status === COMMON_STATUS.ACTIVE)
                                : campaigns) || []
                        }
                        onChange={(item: any) => {
                          const area_item_selected =
                            values.donation_type !== TypeLandCode.campaign
                              ? lands.find((land: any) => land.uuid === item.value)
                              : campaigns.find((campaign: any) => campaign.uuid === item.value);
                          setFieldValue('land_code', item.value, false);
                          setFieldValue(
                            'region_id',
                            values.donation_type !== TypeLandCode.campaign ? area_item_selected?.region_id : area_item_selected?.land?.region_id,
                            false,
                          );
                          setFieldValue(
                            'area_value',
                            values.donation_type !== TypeLandCode.campaign
                              ? area_item_selected?.available_size
                              : area_item_selected?.land?.available_size,
                            false,
                          );
                          setFieldValue(
                            'area_selected',
                            values.donation_type !== TypeLandCode.campaign ? area_item_selected : area_item_selected.land,
                            false,
                          );
                        }}
                        optionLabel={`name.de`}
                        optionDisabled={option =>
                          values.donation_type !== TypeLandCode.campaign
                            ? option.available_size < values.custom_area
                            : option?.land?.available_size < values.custom_area
                        }
                        itemTemplate={option =>
                          formatOptionTemplate(
                            option,
                            values.donation_type !== TypeLandCode.campaign ? option.available_size : option?.land?.available_size,
                            values.donation_type,
                          )
                        }
                        valueTemplate={(option, props) =>
                          selectedOptionTemplate(
                            option,
                            values,
                            values.donation_type !== TypeLandCode.campaign ? option?.available_size : option?.land?.available_size,
                            props,
                          )
                        }
                        optionValue={'uuid'}
                        appendTo="self"
                        placeholder={
                          values.donation_type !== TypeLandCode.campaign
                            ? values.donation_type !== TypeLandCode.region
                              ? t('txt_placeholder_area')
                              : t('txt_placeholder_region')
                            : t('txt_placeholder_campaign')
                        }
                        filter
                        filterBy="region.name.de,name,name.de,code"
                        filterPlaceholder={t('txt_search')}
                      />
                      <div className={`${touched.land_code && errors.land_code ? 'error' : ''}`}>
                        {`${touched.land_code && errors.land_code ? errors.land_code : ''}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">
                        {t('txt_language')}
                        <span className="required-label"> *</span>
                      </label>
                      <SelectButton
                        className="donation-checkbox wi-selectbutton wi-selectbutton-two"
                        id="payment_language_code"
                        name="payment_language_code"
                        options={LANGUAGE_LIST}
                        onChange={(item: any) => {
                          if (item.value) {
                            setFieldValue('payment_language_code', item.value, true);
                          }
                        }}
                        value={values.payment_language_code}
                        optionLabel="label"
                        optionValue="code"
                        itemTemplate={commonLanguageTemplate}
                      />
                      <div className={`${touched.payment_language_code && errors.payment_language_code ? 'error' : ''}`}>
                        {touched.payment_language_code && errors.payment_language_code}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">
                        {t('txt_currency')}
                        <span className="required-label"> *</span>
                      </label>
                      <SelectButton
                        id="payment_currency_code"
                        name="payment_currency_code"
                        value={values.payment_currency_code}
                        className="wi-selectbutton wi-selectbutton-three"
                        options={CurrencyList}
                        onChange={(item: any) => {
                          if (item.value) {
                            setFieldValue('payment_currency_code', item.value, true);
                            if (values.land_code) {
                              // @ts-ignore: Object is possibly 'null'.
                              const amount_conversion = values?.area_selected['amount_area_conversion'][item.value];
                              setFieldValue('custom_area', Math.floor(values.donation_money * amount_conversion), false);
                            } else {
                              setFieldValue('custom_area', values.donation_money, false);
                            }
                          }
                        }}
                        optionLabel="name"
                        optionValue="code"
                      />
                      <div className={`${touched.payment_currency_code && errors.payment_currency_code ? 'error' : ''}`}>
                        {touched.payment_currency_code && errors.payment_currency_code}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">{t('txt_organization')}</label>
                      <InputText
                        value={values.payment_company_name}
                        name="payment_company_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={80}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">{t('txt_certificate_name')}</label>
                      <InputText
                        value={values.personalize_certificate_display_name}
                        name="personalize_certificate_display_name"
                        onChange={item => {
                          setFieldValue('personalize_certificate_display_name', item.target.value, false);
                        }}
                        onBlur={handleBlur}
                        maxLength={80}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-24">
                    <div className="element-form">
                      <label className="label mb-8">{t('txt_certificate_occasion')}</label>
                      <InputText
                        value={values.personalize_certificate_reason}
                        name="personalize_certificate_reason"
                        onChange={item => {
                          setFieldValue('personalize_certificate_reason', item.target.value, false);
                        }}
                        onBlur={handleBlur}
                        maxLength={80}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="check-box-container col-md-12">
                    <WICheckBoxV2 className={`wi-checkbox-v2`} id={`enable_receipt`} name={`enable_receipt`} label={t('txt_receipt')} />
                  </div>
                </div>
                {!isReserve ? (
                  <>
                    <div className="row">
                      <div className="check-box-container col-md-12">
                        <WICheckBoxV2 className={`wi-checkbox-v2`} id={`send_mail`} name={`send_mail`} label={t('txt_send_mail')} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="check-box-container col-md-12">
                        <WICheckBoxV2
                          className={`wi-checkbox-v2`}
                          id={`is_add_donation`}
                          name={`is_add_donation`}
                          label={t('txt_add_another_donation')}
                        />
                      </div>
                    </div>{' '}
                  </>
                ) : (
                  <></>
                )}
                <div className="d-flex justify-content-end mt-4 mb-24 gap-24">
                  <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={t('txt_cancel')} onClick={() => onHide()}></Button>
                  <Button
                    className={`wi-primary-button-v2 h48 flex-1`}
                    type="submit"
                    label={t('txt_save')}
                    onClick={() => onAddDonation(false)}
                  ></Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <Sidebar
        visible={isShowSidebarDonor}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '700px' }}
        onHide={() => setIsShowSidebarDonor(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_add_donor')}</h6>
          </div>
          <AddDonorFormComponent
            countries={countries}
            fetchCallBack={(donorItem: any) => getDonors('', donorItem)}
            disableAdd={true}
            onHide={() => setIsShowSidebarDonor(false)}
          />
        </div>
      </Sidebar>
    </div>
  );
};

export default AddDonationFormComponent;
