import * as React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../../layout/layout';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { CampaignManagerAPI } from '../../services';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import _ from 'lodash';
import { Sidebar } from 'primereact/sidebar';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import WiStatus from '../../components_v2/common/wi-status/wi-status';
import { COMMON_CURRENCY, COUPON_STATUS_LIST, PERMISSIONS_V2, STATUS_CODE, STATUS_LIST, COUPON_TYPES } from '../../utils/constants';
import CreateCouponForm from './components/create-coupon-form';
import DownloadReceiptForm from './components/download-receipt-form';
import { Link, useSearchParams } from 'react-router-dom';
import CampaignCouponManagerAPI from '../../services/campaignCouponManager';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';
import CouponFilterForm from './components/coupon-filter-form';
import { useLayoutV2 } from '../../context/LayoutProvider';
import { formatDateTimeV2, formatDateV2, formatIntergerV2 } from '../../utils/logic';
import useAuth from '../../context/useAuth';
import moment from 'moment';

export type FilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    campaign_id: string[];
    coupon_status: string[];
    currency_code: string[];
  };
  order: [];
};

const CouponManager = () => {
  const [coupons, setCoupons] = useState({
    records: [] as any,
    total: 0,
  });

  const { t } = useTranslation('language', { keyPrefix: 'coupons_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

  const { auth } = useAuth();
  const permissions = {
    canCreateCoupon: auth?.permissions?.includes(PERMISSIONS_V2.COUPON_CREATE) || auth?.permissions?.includes(PERMISSIONS_V2.COUPON_CREATE_TENANT),
    canUpdateCoupon: auth?.permissions?.includes(PERMISSIONS_V2.COUPON_UPDATE) || auth?.permissions?.includes(PERMISSIONS_V2.COUPON_UPDATE_TENANT),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const { getLanguage } = useLayoutV2();

  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);

  const { setErrorProgress, setLoadingProgress, setSuccessProgress } = useLayoutV2();
  const [coupon, setCoupon] = useState<any>(null);
  const [rows, setRows] = useState(noRows);
  const [page, setPage] = useState(pageNumber);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  const [isDownload, setIsDownLoad] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const sortField = searchParams?.get('sortField');
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const [first, setFirst] = useState(pageNumber * noRows);
  const [search, setSearch] = useState<any>(null);
  const filterCampaign = searchParams?.get('filterCampaign') ? searchParams?.get('filterCampaign')?.split(',') || [] : [];
  const filterStatus = (searchParams?.get('filterStatus') || COUPON_STATUS_LIST.map((c: any) => c.code)?.join(',')).split(',');
  const fitlerCurrency = (searchParams?.get('fitlerCurrency') || COMMON_CURRENCY.map((c: any) => c.code)?.join(',')).split(',');

  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });

  const SEARCH_FIELDS = ['coupon'];
  const searchField = searchParams?.get('search') || '';
  const getObjectSearchValue = (value: string) => {
    const search_result: any[] = [];
    SEARCH_FIELDS.forEach((key: string) => {
      const obj = {
        [key]: value,
      };
      search_result.push(obj);
    });

    return search_result;
  };

  const getObjectWithValues = (where_obj: any) => {
    const tempt_obj: any = {};
    for (const [key, value] of Object.entries(where_obj)) {
      if ((Array.isArray(value) || typeof value === 'string') && value.length > 0) {
        tempt_obj[key] = value;
      }
    }
    return tempt_obj;
  };

  const [filterValues, setFilterValues] = useState<FilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getObjectSearchValue(searchField) : [],
      campaign_id: filterCampaign,
      coupon_status: filterStatus,
      currency_code: fitlerCurrency,
    },
    order: [],
  });

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchCouponsData = async () => {
    setIsLoading(true);
    const filter = buildFilter();
    try {
      const [couponRes, campaignsRes] = await Promise.all([CampaignCouponManagerAPI.getCoupons(filter), CampaignManagerAPI.getAllCampaigns()]);

      [couponRes, campaignsRes].forEach(res => {
        if (!res || res.status !== 200) {
          throw new Error('txt_unknown_load_data_fail');
        }
      });

      handleCampaignsResponse(campaignsRes);
      handleCouponsResponse(couponRes);
    } catch (error: any) {
      console.error('Error fetching data:', error);
      setErrorProgress(errorTrans(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const buildFilter = () => {
    const where_conditions = filterValues.where;
    return {
      pageNo: page.toString(),
      range: rows.toString(),
      where: {
        ...where_conditions,
        search_name: filterValues.search,
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === -1 ? 'DESC' : 'ASC'],
    };
  };

  const handleCampaignsResponse = (campaignsRes: any) => {
    if (campaignsRes?.status === 200 && campaignsRes.data.records?.length > 0) {
      setCampaigns(
        _.sortBy(
          campaignsRes.data.records.filter((item: any) => item.status === STATUS_CODE.ACTIVE),
          item => item.name?.de?.toLowerCase(),
        ),
      );
    }
  };

  const handleCouponsResponse = (couponRes: any) => {
    if (couponRes?.status === 200) {
      setCoupons(couponRes.data);
    }
  };

  useEffect(() => {
    fetchCouponsData();
  }, []);

  useEffect(() => {
    if (typeof page === 'number') {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });

      setSearchParams(
        {
          pageNumber: (page + 1).toString(),
          noRows: rows.toString(),
        },
        { replace: true },
      );
    }
  }, [page, rows]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      filterCampaign: filterValues.where.campaign_id?.join(',') || '',
      filterStatus: filterValues.where.coupon_status?.join(',') || '',
      fitlerCurrency: filterValues.where.currency_code?.join(',') || '',
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
    };

    setSearchParams(
      _.omitBy(searchParams, p => !p),
      { replace: true },
    );
    fetchCouponsData();
  }, [filterValues, sortConfig]);

  const onHideSideBar = () => {
    setIsShowSidebar(false);
    setIsDownLoad(false);
    if (coupon) {
      setCoupon(null);
    }
  };

  const toggleCouponStatus = async (coupon: any, status: any) => {
    if (status === coupon.coupon_status) {
      return;
    }

    setLoadingProgress(errorTrans('txt_loading'));
    try {
      if (status === STATUS_CODE.ACTIVE) {
        let activeRes = await CampaignCouponManagerAPI.activeCoupon(coupon.uuid);
        if (activeRes?.status === 200 && activeRes?.data) {
          setSuccessProgress(t('txt_update_coupon_success'));
          fetchCouponsData();
        }
      } else {
        let deactiveRes = await CampaignCouponManagerAPI.deactiveCoupon(coupon.uuid);
        if (deactiveRes?.status === 200 && deactiveRes?.data) {
          setSuccessProgress(t('txt_update_coupon_success'));
          fetchCouponsData();
        }
      }
    } catch (e) {
      setIsLoading(false);
      setErrorProgress(errorTrans('txt_update_coupon_failed'));
    }
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-edit',
        hidden: !permissions.canCreateCoupon,
        command: (e: any) => {
          setCoupon(rowData);
          setIsShowSidebar(true);
        },
      },
      {
        label: t('txt_download_receipt'),
        icon: 'wi-download',
        command: (e: any) => {
          setCoupon(rowData);
          setIsDownLoad(true);
          setIsShowSidebar(true);
        },
      },
      {
        label: t('txt_active'),
        icon: 'wi-active',
        hidden: !permissions.canUpdateCoupon || rowData?.coupon_status === STATUS_CODE.ACTIVE,
        command: (e: any) => {
          toggleCouponStatus(rowData, STATUS_CODE.ACTIVE);
        },
      },
      {
        label: t('txt_inactive'),
        icon: 'wi-inactive',
        hidden: !permissions.canUpdateCoupon || rowData?.coupon_status === STATUS_CODE.INACTIVE,
        command: (e: any) => {
          toggleCouponStatus(rowData, STATUS_CODE.INACTIVE);
        },
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const buildStatusTemplate = (rowData: any, column: any) => {
    const statusItem = STATUS_LIST.find((item: any) => item.code === rowData[column.field]);
    return <WiStatus item={statusItem}></WiStatus>;
  };

  const tableColumns = [
    { field: 'coupon', header: t('txt_coupon'), width: '15%', align: 'left' },
    {
      field: 'campaign_id',
      header: t('txt_campaign'),
      width: '25%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const campaign = campaigns.find((c: any) => c.uuid === rowData.campaign_id);
        return (
          <Link className="table-ref-link-cell" to={`/campaigns/${rowData.campaign_id}`}>
            {campaign?.name?.de}
          </Link>
        );
      },
    },
    {
      field: 'coupon_type',
      header: t('txt_type'),
      width: '10%',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        const couponType = COUPON_TYPES[0];
        if (!couponType) return rowData.couponType;
        return couponType.name;
      },
    },
    {
      field: 'coupon_status',
      header: `${t('txt_status')}`,
      width: '15%',
      align: 'center',
      bodyTemplate: buildStatusTemplate,
    },
    {
      field: 'start_date',
      header: t('txt_start_date'),
      width: '10%',
      align: 'center',
      bodyTemplate: (rowData: any) => formatDateV2(rowData.start_date, getLanguage()),
    },
    {
      field: 'end_date',
      header: t('txt_end_date'),
      width: '10%',
      align: 'center',
      bodyTemplate: (rowData: any) => moment.utc(rowData.end_date).add(-1, 'hour').locale(getLanguage()).format('DD MMM YYYY'),
    },
    {
      field: 'coupon_limit',
      header: t('txt_limit'),
      width: '10%',
      align: 'right',
      bodyTemplate: (rowData: any) => formatIntergerV2(rowData?.coupon_limit, getLanguage()),
    },
    {
      field: 'currency_code',
      header: t('txt_currency'),
      width: '80px',
      align: 'left',
      bodyTemplate: (rowData: any) => rowData?.currency_code?.toUpperCase(),
    },
    {
      field: 'redemption_limit',
      header: t('txt_redemption_limit'),
      width: '170px',
      align: 'right',
      bodyTemplate: (rowData: any) => {
        const lang = getLanguage();
        return `${formatIntergerV2(rowData?.used_redemption, lang)} / ${formatIntergerV2(rowData?.redemption_limit, lang)}`;
      },
    },
    {
      field: 'receipt_status',
      header: t('txt_receipt_status'),
      width: '8%',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        return rowData.receipt_status === 'issued' ? (
          <Button className={`no-mouse-events wi-primary-button-icon-v2`}>
            <span className="fa-solid fa-check" />
          </Button>
        ) : (
          <></>
        );
      },
    },
    {
      field: 'created_at',
      header: `${t('txt_created')}`,
      width: '170px',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.created_at ? formatDateTimeV2(rowData.created_at, getLanguage()) : '-'}</div>;
      },
      align: 'center',
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const onSearchFunc = (searchText: string) => {
    const where_conditions = filterValues.where;
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions['or'] = searchText ? or_conditions : [];
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    p.search = '';
    setFilterValues(p);
  };

  const onFilterChange = (values: any, fields: any) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    fields.forEach((field: any) => {
      const data = values?.get(field);
      where_conditions[field] = data;
    });
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  return (
    <Layout title={t('txt_title')}>
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={(value: any) => onSearchFunc(search)}
            />
            <div className="filter-btn">
              <div className="filter-btn-content p-8 ml-16" onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg></FilterSvg>
              </div>
            </div>
          </div>
          <Button
            className="wi-primary-button-v2"
            label={t('txt_btn_add')}
            hidden={!permissions.canCreateCoupon}
            onClick={(e: any) => {
              setCoupon(null);
              setIsShowSidebar(true);
            }}
          >
            <PlusSvg className="icon-svg" />
          </Button>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={coupons.records}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={coupons.total} onPageChange={onBasicPageChange} />
      </div>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_filter')}</h6>
          </div>
          <CouponFilterForm
            fetchCallBack={() => setIsShowSidebarFilter(false)}
            filterValues={filterValues}
            onRemove={onFilterRemove}
            setSearch={setSearch}
            onSearchFunc={onSearchFunc}
            onHide={() => setIsShowSidebarFilter(false)}
            fields={['campaign_id', 'coupon_status', 'currency_code']}
            campaigns={campaigns}
            onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        onHide={() => onHideSideBar()}
      >
        <div className="sidebar-content">
          {isDownload ? (
            <>
              <div className="headline mt-24 mb-24">
                <h6 className="m-0">{t('txt_download_receipt')}</h6>
              </div>
              <DownloadReceiptForm onHide={() => onHideSideBar()} coupon={coupon} fetchCallBack={(couponItem: any) => fetchCouponsData()} />
            </>
          ) : (
            <>
              <div className="headline mt-24 mb-24">
                <h6 className="m-0">{coupon ? t('txt_update_coupon') : t('txt_add_new_coupon')}</h6>
              </div>
              <CreateCouponForm
                campaigns={campaigns}
                onHide={() => onHideSideBar()}
                coupon={coupon}
                coupons={coupons.records}
                fetchCallBack={(couponItem: any) => fetchCouponsData()}
              />
            </>
          )}
        </div>
      </Sidebar>
    </Layout>
  );
};

export default CouponManager;
