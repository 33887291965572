import * as React from 'react';
import './wi-menubar.scss';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import WIMenu from '../menu/wi-menu';
import WhiteAdminLogo from '../../assets/images/logo/white-eagle.svg';
import MenuButton from '../common/menu-button/menu-button';

const WIMenubar = (props: any) => {
  const { sidebar, setSidebar, menuItems, mode, className } = props;
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/');
  };

  const showSidebar = () => {
    localStorage.setItem('is_sidebar_expaned', sidebar.toString());
    setSidebar(!sidebar);
  };

  const actionCreateList = () => {
    return [
      {
        label: 'Area',
        description: 'Create new area',
        icon: 'fa-solid fa-map',
        url: '/areas/create',
      },
      {
        label: 'Campaign',
        description: 'Create new campaign',
        icon: 'fa-solid fa-bullhorn',
        url: '/campaigns/create',
      },
      {
        label: 'Partner',
        description: 'Create new partner',
        icon: 'fa-solid fa-handshake',
        url: '/partners/create',
      },
      {
        label: 'Code Set',
        description: 'Create new code set',
        icon: 'fa-solid fa-tags',
        url: '/codes/create',
      },
      {
        label: 'CMS Page',
        description: 'Create new cms page',
        icon: 'fa-solid fa-newspaper',
        url: '/cms-pages/create',
      },
    ];
  };

  return (
    <>
      {mode === 'horizontal' && (
        <div className={`navbar-horizontal-wilderness ${className}`}>
          <div className="p-menubar-start">
            <Link className="wilderness-logo" to="/">
              <img alt="logo" className="wilderness-logo-img" src={WhiteAdminLogo} onClick={() => navigateToHome()} />
              <div className="wi-logo-text ml-1">
                Wilderness <br /> International
              </div>
            </Link>

            <div className="wi-global-search-container">
              {/* <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText placeholder="Search" />
                        </span> */}
            </div>
            <div>
              <MenuButton label="Create" items={actionCreateList()}></MenuButton>
            </div>
            <div className="wi-help-container">
              <a className="help-link" href="https://help.wilderness-international.org/" target="_blank">
                <i className="fa-solid fa-circle-question mr-1"></i>
                <span>Help </span>
              </a>
            </div>
            {/* <div className="wi-user-info-container">
                        <Button label={'AD'} security="info" className="p-button-rounded"/>
                    </div> */}
          </div>
          <WIMenu items={menuItems} mode="horizontal" />
        </div>
      )}
    </>
  );
};

export default WIMenubar;
