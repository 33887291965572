import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Layout from '../layout/layout';
import { ProgressSpinner } from 'primereact/progressspinner';
import { LANGUAGE_MODE_OPTIONS, PAGE_CODE, PAGE_STATUS, SCREEN_MODE_OPTIONS } from './utils';
import CMsPageManagerAPI from '../../services/cmsPageManager';
import _ from 'lodash';
import './page-details.scss';
import CMSPageInfoFormComponent from './components/cms-page-info/cms-page-info-form';
import { Toast } from 'primereact/toast';
import { showNotification } from '../../utils/logic';
import { Formik } from 'formik';
import {
  buildUrls,
  emptySpecialDataOfSource,
  filterParentPages,
  getContentById,
  loadPageMasterData,
  onInitPageValues,
  processPageInfo,
  removeDisabledFromBlocks,
  stringifyJSON,
  validationPageSchema,
} from './builder-page.common';
import { Button } from 'primereact/button';
import { ScreenModePanel } from './components/builder-header/builder-header';
import { ContentComponent } from './components/content-builder';
import PageHolder from '../../assets/images/page_holder.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import WithPreventNavigation from '../common/preventNavigation';
import WIBlockDialog from './components/block-modal/block-modal';
import { useBeforeUnload } from '../../hooks/useBeforeUnload';
import { v4 as uuidv4 } from 'uuid';
import useAuth from '../../context/useAuth';
import { PERMISSIONS_V2 } from '../../utils/constants';

const PageDetails = (props: any) => {
  const { blockPopupVisible, setBlockPopupVisible, shouldBlock, setShouldBlock, setConfirmedNavigation, confirmNavigation } = props;

  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const sourceId = searchParams?.get('sourceId') || '';
  const pageTypeId = useSearchParams()[0]?.get('type');
  const navigate = useNavigate();
  const toast = useRef(null);
  const formikRef = useRef(null);
  const [pagePreview, setPagePreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingIFrame, setIsLoadingIFrame] = useState(false);
  const [selectedPage, setSelectedPage] = useState<any>();
  const [isShowCreate, setIsShowCreate] = useState(true);
  const [pageTypes, setPageTypes] = useState<any>([]);
  const [headers, setHeaders] = useState<any>([]);
  const [footers, setFooters] = useState<any>([]);
  const [parentPages, setParentPages] = useState<any>([]);
  const [listURLs, setListURLs] = useState<any>([]);
  const [langCode, setLangCode] = useState(LANGUAGE_MODE_OPTIONS.find(c => c.isSelected));
  const [screenMode, setScreenMode] = useState(SCREEN_MODE_OPTIONS.find(c => c.isSelected));
  const { height, width } = useWindowDimensions();
  const MIN = 0.3;
  const MAX = +(width / 2 / 1260).toFixed(2);
  const [zoomLevel, setZoomLevel] = useState(MAX);
  const BLOCK_IDS = {
    SOCIAL_SHARING: '16f5569c-199d-4111-819b-47000139906c',
    DYNAMIC_NEWS_PRESS: '2ccf5741-2a5d-4705-abe2-898135670a54',
  };

  const { auth } = useAuth();

  const permissions = {
    canCreateCMSPage: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_CREATE),
    canUpdateCMSPage: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_UPDATE),
  };

  const defaultBlocksForNewsPress = [
    {
      uuid: BLOCK_IDS.SOCIAL_SHARING,
      order: 0,
    },
    {
      uuid: BLOCK_IDS.DYNAMIC_NEWS_PRESS,
      order: 1,
    },
  ];

  useBeforeUnload((event: any) => {
    if (shouldBlock) {
      event.preventDefault();
    }
  });

  const callCreatePageAPI = async (data: any) => {
    try {
      const res = await CMsPageManagerAPI.createPage(data);
      if (res && res.status === 200) {
        showNotification('success', 'Generate Page successfully', toast);
        return res.data.result.id;
      }
    } catch (error) {
      console.log(error);
    }

    showNotification('error', 'Failed to generate ', toast);
  };

  const callUpdatePageAPI = async (uuid: string, data: any) => {
    try {
      const res = await CMsPageManagerAPI.updatePage(uuid, data);

      if (res && res.status === 200) {
        showNotification('success', 'Update Page successfully', toast);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCMSPageInfoById = async (id: any, loading?: boolean) => {
    const resPageDetail = await CMsPageManagerAPI.getCMSPageDetail(id || '');
    if (resPageDetail && resPageDetail.status === 200) {
      setSelectedPage(resPageDetail.data.record);
      if (resPageDetail.data?.record) {
        setIsShowCreate(false);
      }
    }
  };

  const getCMSPagePreview = async (uuid: string) => {
    setIsLoadingIFrame(true);
    try {
      const lang = langCode?.code || 'de';
      const res = await CMsPageManagerAPI.getCmsPagePreview(uuid, lang);
      if (res && res.status === 200) {
        if (res.data && res.data.htmlTemplate) {
          setPagePreview(res.data.htmlTemplate);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoadingIFrame(false);
  };

  const getSourceCMSPageInfoById = async (id: any) => {
    if (id) {
      setIsLoading(true);
      const resPageDetail = await CMsPageManagerAPI.getCMSPageDetail(id || '');
      if (resPageDetail && resPageDetail.status === 200) {
        const data = emptySpecialDataOfSource(resPageDetail.data.record);
        setSelectedPage(data);
      }
      setIsLoading(false);
    }
  };

  const onPressCreatePage = async (isPublished = false) => {
    //@ts-ignore: Object is possibly 'null'.
    if (formikRef && ((isPublished && selectedPage.status === 'draft') || formikRef.current.dirty) && formikRef.current.isValid) {
      setIsLoading(true);

      //@ts-ignore: Object is possibly 'null'.
      let pageData = processPageInfo(formikRef.current.values, selectedPage, isPublished);
      let pageId = selectedPage ? selectedPage.uuid : '';

      if (isShowCreate) {
        if (sourceId) {
          const resContent = await getContentById(sourceId);
          pageData.content = stringifyJSON(removeDisabledFromBlocks(resContent));
        }

        const selectedItem = pageTypes.find((item: any) => item.uuid === pageData.type_id);
        if (!sourceId && selectedItem && (selectedItem.code === 'press' || selectedItem.code === 'news')) {
          pageData.content = stringifyJSON(removeDisabledFromBlocks(await setDefaultBlocksForNewsPress()));
        }

        pageId = await callCreatePageAPI(pageData);
        navigate(`/cms-pages/${pageId}/content`);
      } else {
        await callUpdatePageAPI(selectedPage.uuid, pageData);
      }
      await fetchDataAfterUpdating(pageId);

      setIsLoading(false);
    }
  };

  const setDefaultBlocksForNewsPress = async () => {
    const defaultBlocks = (
      await Promise.all([
        CMsPageManagerAPI.getCTABlocksById(BLOCK_IDS.SOCIAL_SHARING),
        CMsPageManagerAPI.getCTABlocksById(BLOCK_IDS.DYNAMIC_NEWS_PRESS),
      ])
    ).map(b => b?.data?.record);

    let contents: any = [];
    for (let i = 0; i < defaultBlocks.length; i++) {
      let expectedBlock = {
        uuid: uuidv4(),
        order: i,
        is_visible: true,
        name: defaultBlocks[i]?.name,
        cta_block_id: defaultBlocks[i]?.uuid,
        parameters: defaultBlocks[i]?.parameters,
      };
      contents.push(expectedBlock);
    }

    return contents;
  };

  const fetchCMSParentPages = async (excludedTypes: any = null) => {
    const res = await CMsPageManagerAPI.getAllPages();
    if (res && res.status === 200 && res.data) {
      const items = res.data.records;
      let pageParent: any = [];
      let urls: any = [];
      if (items && items.length > 0) {
        let typeFunc = (f: any) =>
          (f.status.toLocaleLowerCase() === PAGE_STATUS[1].code && !excludedTypes.includes(f.type)) || f.type === PAGE_CODE.Master;
        pageParent = items
          .filter((f: any) => f.uuid !== id && typeFunc(f))
          .map((i: any) => {
            return { id: i.uuid, name: i.name, page_id: i.page_id, url: i.url, title: i.title, special_type: i.special_type };
          });
        urls = items
          .filter((p: any) => p.uuid !== id)
          .map((i: any) => {
            return { id: i.uuid, name: i.name, page_id: i.page_id, url: i.url, title: i.title, special_type: i.special_type };
          }); //.map((i: any) => { return { link: i.url } });
      }
      setParentPages(buildUrls(pageParent));
      setListURLs(buildUrls(urls));
    }
  };

  const fetchDataAfterUpdating = async (id: any) => {
    await Promise.all([getCMSPageInfoById(id, true)]);
  };

  const fetchData = async () => {
    setIsLoading(true);
    fetchCMSParentPages([PAGE_CODE.News, PAGE_CODE.Press]);
    const { pageTypes, footers, headers, htmlTemplate } = await loadPageMasterData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (id !== undefined) {
      getCMSPageInfoById(id);
    } else if (sourceId && sourceId.trim()) {
      getSourceCMSPageInfoById(sourceId);
    }

    setFooters(footers);
    setHeaders(headers);
    setPageTypes(pageTypes);
    setTimeout(() => setIsLoading(false), 1000);
    setTimeout(() => setShouldBlock(false), 100);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (parentPages.length > 0 && selectedPage && !isShowCreate) {
      const pages = filterParentPages(parentPages, selectedPage.uuid);
      setParentPages(pages);
    }
  }, [selectedPage]);

  useEffect(() => {
    if (id !== undefined) {
      getCMSPagePreview(id);
    }
  }, [langCode, selectedPage]);

  const onEditContent = async (dirty = false) => {
    setShouldBlock(false);
    if (dirty) {
      await onPressCreatePage();
    }
    navigate(`/cms-pages/${id}/content`);
  };

  const onZoomChange = (cal: string) => {
    let zoom = zoomLevel;
    if (cal === 'in') {
      zoom = Math.min(Number((zoom + 0.1).toFixed(2)), MAX);
    } else {
      zoom = Math.max(Number((zoom - 0.1).toFixed(2)), MIN);
    }
    setZoomLevel(zoom);
  };

  const saveChanges = async () => {
    setBlockPopupVisible(false);
    await onPressCreatePage();
    setConfirmedNavigation(true);
  };

  const onChangeForm = () => {
    setShouldBlock(true);
  };

  const onSaveDetail = (isPublish?: boolean) => {
    setShouldBlock(false);
    onPressCreatePage(isPublish);
  };

  const getIconByType = (name: string) => {
    const mapper: any = {
      'General Blocks': 'fa-circle-info',
      Action: 'fa-pager',
      'Landing Page': 'fa-pager',
      Press: 'fa-newspaper',
      Blog: 'fa-pager',
      News: 'fa-newspaper',
      Project: 'fa-diagram-project',
      Partner: 'fa-handshake',
      Expedition: 'fa-pager',
      Region: 'fa-pager',
      'Wilderness Run': 'fa-person-running',
    };

    return mapper[name];
  };

  return (
    <>
      <Layout>
        {isLoading ? (
          <div className="loading-component">
            <ProgressSpinner />
          </div>
        ) : (
          <></>
        )}
        <Toast ref={toast} />

        {headers && (
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={onInitPageValues({
              selectedPage,
              isShowCreate: sourceId && selectedPage ? false : isShowCreate,
              headers,
              footers,
              pageTypes,
              defaultPageTypeId: pageTypeId,
            })}
            validationSchema={validationPageSchema}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({ isValid, dirty, values }: any) => (
              <React.Fragment>
                <div className="page-details-header">
                  <div className="page-details-header-title">
                    <span>Page Details</span>
                    {!isShowCreate && selectedPage && (
                      <span className={`page-status page-status-${selectedPage.status}`}> {selectedPage.status}</span>
                    )}
                    <div className="page-type">
                      {!isShowCreate && values.first_published_at && (
                        <span style={{ marginRight: '10px' }}>
                          First published on <b>{values.first_published_at}</b>
                        </span>
                      )}
                      {!isShowCreate && values.last_published_at && (
                        <span>
                          {/* {pageTypes.find((t: any) => t.uuid === values.type_id)?.name} */}
                          Last published on <b>{values.last_published_at}</b>
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="btn-submit-container">
                      {!isShowCreate && (
                        <Button
                          disabled={!isValid}
                          label={dirty ? 'Save & Design' : `Design`}
                          className="p-button-rounded btn-submit btn-submit-page"
                          type="submit"
                          icon="pi pi-chevron-right"
                          iconPos="right"
                          hidden={!permissions.canUpdateCMSPage}
                          onClick={() => onEditContent(dirty)}
                        />
                      )}
                      <Button
                        loading={isLoading}
                        disabled={!(isValid && dirty)}
                        hidden={!permissions.canCreateCMSPage}
                        label={`${isShowCreate ? 'Create' : 'Save Draft'}`}
                        className="p-button-rounded btn-submit btn-submit-page"
                        type="submit"
                        icon="pi pi-chevron-right"
                        iconPos="right"
                        onClick={() => onSaveDetail()}
                      />
                      {!isShowCreate && (
                        <Button
                          loading={isLoading}
                          disabled={!isValid || (!dirty && values.status === 'published')}
                          className="p-button-rounded btn-submit btn-submit-page btn-publish"
                          type="submit"
                          hidden={!permissions.canUpdateCMSPage}
                          label={`Publish`}
                          icon="pi pi-chevron-right"
                          iconPos="right"
                          onClick={() => onSaveDetail(true)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={`row d-flex flex-column flex-lg-row page-details`}>
                  <div className={`col-12 col-lg-4 ${isShowCreate ? 'page-form-details' : 'custom-page-form-details'}`}>
                    <CMSPageInfoFormComponent
                      isDisabled={!isShowCreate && selectedPage && !!selectedPage.parent_id}
                      isShowCreate={isShowCreate}
                      masterData={{ pageTypes, headers, footers, parentPages, listURLs }}
                      toast={toast}
                      form={formikRef}
                      onChangeForm={onChangeForm}
                    />
                  </div>
                  {!isShowCreate && (
                    <div className="col-12 col-lg-8 page-preview">
                      <div className="d-flex flex-row align-items-start justify-content-between flex-wrap preview-action-panel">
                        {!isShowCreate && (
                          <>
                            <ScreenModePanel onChange={setScreenMode} />
                            <div className="zoom-level-container"></div>
                            <div className="lang-mode text-right">
                              <i
                                title="Zoom In"
                                className={`mode-item zoom-btn fa-solid fa-magnifying-glass-plus ${zoomLevel >= MAX && 'btn-disabled'}`}
                                onClick={() => onZoomChange('in')}
                              ></i>
                              <i
                                title="Zoom Out"
                                className={`mode-item zoom-btn fa-solid fa-magnifying-glass-minus ${zoomLevel <= MIN && 'btn-disabled'}`}
                                onClick={() => onZoomChange('out')}
                              ></i>
                              {/* <LanguageModePanel onChange={setLangCode} /> */}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="page-preview-content">
                        {isShowCreate && <img style={{ height: 'calc(100vh - 190px)' }} src={PageHolder} alt="" />}
                        {!isShowCreate && isLoadingIFrame && (
                          <div className="loading-iframe-component">
                            <ProgressSpinner />
                          </div>
                        )}
                        {!isShowCreate && !isLoadingIFrame && (
                          <ContentComponent className={`wrapper preview-screen preview-${screenMode?.cssClassName}`}>
                            {
                              <iframe
                                style={{
                                  width: '100%',
                                  height: `${100 / zoomLevel}%`,
                                  transform: `scale(${zoomLevel})`,
                                  transformOrigin: 'top center',
                                }}
                                srcDoc={pagePreview}
                              ></iframe>
                            }
                          </ContentComponent>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </Formik>
        )}

        <WIBlockDialog
          visible={blockPopupVisible}
          onStay={() => setBlockPopupVisible(false)}
          onLeave={confirmNavigation}
          onSaveChanges={saveChanges}
          message={
            <React.Fragment>
              <div className="mb-3">
                You have modified this item.
                <br />
                You can save your changes, discard your changes, or cancel to continue editing.
              </div>
            </React.Fragment>
          }
        ></WIBlockDialog>
      </Layout>
    </>
  );
};

export default WithPreventNavigation(PageDetails);
