import * as React from 'react';
import './create-person-form.scss';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CMsPageManagerAPI, ContactManagerAPI } from '../../../../services';
import useAuth from '../../../../context/useAuth';
import { PERMISSIONS_V2 } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { WIFormInputTextV2, WIFormImageSelectorV2 } from '../../../../components_v2/common/form-fields';
import ActionFormComponent from './action-form';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import { AxiosResponse } from 'axios';
import {
  callCreateAndPublishPageAPI,
  callDeletePageAPI,
  callUpdatePageAPI,
  fetchAllBlocks,
  fetchCMSFooters,
  fetchCMSHeaders,
  getMasterURL,
} from '../../../../components/pages/builder-page.common';
import { generatePersonPageData } from '../../people-business';
import { LanguageItem } from '../../../../types/commonType';
import { InputSwitch } from 'primereact/inputswitch';
import { RichTextEditorV2 } from '../../../../components_v2/common/rich-text-editor-v2/rich-text-editor-v2';
import { env } from '../../../../configs';

interface ContactType {
  name: string;
  phone: string;
  email: string;
  cover_picture: string;
  profile_picture: string;
  description: LanguageItem;
  position: LanguageItem;
  headline: LanguageItem;
  generate_page: boolean;
  mobile_cover_picture: string;
  link: LanguageItem;
}

const CreatePeopleFormComponent = (props: any) => {
  const { personPageType, contact, parentPages, cmsPersonPage, cmsPersonPages, cmsMasterPages, data } = props;
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { auth } = useAuth();
  const { t } = useTranslation('language', { keyPrefix: 'people_manager' });
  const [isEdit, setIsEdit] = useState(false);
  const { setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const [isLoading, setIsLoading] = useState(false);
  const [allBlocks, setAllBlocks] = useState<any>([]);
  const [headers, setHeaders] = useState<any>([]);
  const [footers, setFooters] = useState<any>([]);
  const [personPageURL, setPersonPageURL] = useState<any>();

  const isDisabledFiledForm = () => {
    return data?.isShowCreate ? false : !isEdit;
  };

  const menuItems = [
    {
      label: t('txt_edit'),
      icon: 'wi-edit',
      command: () => setIsEdit(true),
    },
    {
      label: t('txt_open_cms_page'),
      icon: 'wi-open',
      hidden: !(data?.person && cmsPersonPage && cmsPersonPage.uuid),
      externalUrl: data?.person && cmsPersonPage && cmsPersonPage.uuid ? env.CMS_URL + personPageURL + cmsPersonPage.url : '',
    },
  ];

  const permissions = {
    canUpdatePeople: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_UPDATE),
  };

  useEffect(() => {
    fetchDataToCreatePersonPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPersonPageURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, cmsPersonPage]);

  const updateContact = async (parameters: any) => {
    return await ContactManagerAPI.updateContact(parameters);
  };

  const generateContact = async (parameters: any) => {
    parameters.mobile_cover_picture = parameters.cover_picture;
    return await ContactManagerAPI.createContact(parameters);
  };

  const requestContactData = async () => {
    // @ts-ignore: Object is possibly 'null'.
    const formikValues = formikRef?.current.values;

    // @ts-ignore: Object is possibly 'null'.
    if (contact) {
      // @ts-ignore: Object is possibly 'null'.
      const contactObj = contact;

      let request = { ...contactObj, ...formikValues };
      request.mobile_cover_picture = formikValues.cover_picture;
      return updateContact({
        ...request,
      });
    }

    return generateContact({
      ...formikValues,
    });
  };

  const handleRequestContact = (resGenerateContact: AxiosResponse<any, any>) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateContact;
    let method = response.config.method;

    if (response && response.status === 200 && (method === 'post' || method === 'put')) {
      let mesage = method === 'post' ? 'txt_generate_person_success' : 'txt_update_person_success';
      setSuccessProgress(t(mesage));
      return response.data.result;
    } else {
      setErrorProgress(errorTrans('txt_generate_person_failed'));
    }
    return false;
  };

  const onSubmitDataForm = async () => {
    try {
      setIsLoading(true);
      const resGenerateContact = await requestContactData();
      let personResponse = handleRequestContact(resGenerateContact);
      await handleRequestPersonPage(personResponse);
      if (personResponse) {
        navigate(`/global-contents/people/${resGenerateContact?.data.result.id}`);
        window.location.reload();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setErrorProgress(errorTrans('txt_generate_person_failed'));
    }
  };

  const handleRequestPersonPage = async (personResponse: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const formikValues = formikRef?.current.values;
    let resultHandlePersonPage = false;
    if (!personResponse) {
      return resultHandlePersonPage;
    }

    //const cmsPage = findCmsPage(cmsPersonPages, contact);
    if (formikValues.generate_page !== true) {
      if (contact && cmsPersonPage && cmsPersonPage.uuid) {
        await callDeletePageAPI(
          cmsPersonPage.uuid,
          (result: any) => {
            resultHandlePersonPage = true;
            setSuccessProgress(t('txt_delete_person_page_success'));
          },
          () => setErrorProgress(errorTrans('txt_delete_person_page_failed')),
        );
      }
      return resultHandlePersonPage;
    }

    let pageData = await generatePersonPageData(
      formikValues,
      parentPages,
      cmsPersonPage,
      personPageType,
      allBlocks,
      footers,
      headers,
      personResponse.id,
      true,
    );

    if (contact && cmsPersonPage && cmsPersonPage.uuid) {
      delete pageData['url'];
      let updateContentRes = await CMsPageManagerAPI.update(cmsPersonPage.uuid, pageData.content);
      await callUpdatePageAPI(
        cmsPersonPage.uuid,
        pageData,
        async () => {
          resultHandlePersonPage = true;
          setSuccessProgress(t('txt_update_person_page_success'));
        },
        () => setErrorProgress(errorTrans('txt_update_person_page_failed')),
      );
    } else {
      correctUrlPersonPage(pageData);
      await callCreateAndPublishPageAPI(
        pageData,
        (result: any) => {
          resultHandlePersonPage = true;
          setSuccessProgress(errorTrans('txt_generate_person_page_success'));
        },
        () => setErrorProgress(errorTrans('txt_generate_person_page_failed')),
      );
    }

    return resultHandlePersonPage;
  };

  const correctUrlPersonPage = (pageData: any) => {
    const duplicateUrls = cmsPersonPages.filter((f: any) => f.url.includes(pageData.url));
    if (duplicateUrls.length > 0) {
      let personUrl = pageData.url + '-';
      let expectedUrl = '';
      for (let index = 1; index < 1000; index++) {
        let urlExisted = duplicateUrls.find((f: any) => f.url.includes(personUrl + index));
        if (!urlExisted) {
          expectedUrl = personUrl + index;
          break;
        }
      }

      if (expectedUrl !== '') {
        pageData.url = expectedUrl;
      }
    }
  };

  const fetchDataToCreatePersonPage = async () => {
    let [footers, headers, allBlocks] = await Promise.all([fetchCMSFooters(), fetchCMSHeaders(), fetchAllBlocks()]);

    setAllBlocks(allBlocks);
    setFooters(footers);
    setHeaders(headers);
    fetchPersonPageURL();
  };

  const fetchPersonPageURL = () => {
    if (cmsPersonPage && cmsMasterPages) {
      const parentPersonURL = getMasterURL(cmsPersonPage?.page_id, cmsMasterPages);
      setPersonPageURL(parentPersonURL);
    }
  };

  const defaultCoverPicture = () => {
    return 'header/hero-header-landingpages/Blick-auf-den-Regenwald-von-oben-Peru-hero.jpg';
  };

  const initialProplesValues = (): ContactType => {
    return {
      name: contact && contact.name ? `${contact.name}` : '',
      phone: contact && contact.phone ? `${contact.phone}` : '',
      email: contact && contact.email ? `${contact.email}` : '',
      profile_picture: contact ? `${contact.profile_picture ?? ''}` : '',
      cover_picture: contact ? `${contact.cover_picture ? contact.cover_picture : defaultCoverPicture()}` : defaultCoverPicture(),
      description: contact && contact.description ? { de: contact.description.de, en: contact.description.en } : { de: '', en: '' },
      position: contact && contact.position ? { de: contact.position.de, en: contact.position.en } : { de: '', en: '' },
      generate_page: contact ? contact.generate_page : true,
      mobile_cover_picture: contact ? `${contact.cover_picture ? contact.cover_picture : defaultCoverPicture()}` : defaultCoverPicture(),
      link: contact && contact.link ? { de: contact.link.de, en: contact.link.en } : { de: '', en: '' },
      headline: contact && contact.headline ? { de: contact.headline.de, en: contact.headline.en } : { de: '', en: '' },
    };
  };

  const peoplesFormSchema = Yup.object().shape({
    name: Yup.string().max(100, errorTrans('txt_required_lenght_100')).required(errorTrans('txt_required')),

    profile_url: Yup.object().shape({
      de: Yup.string().max(255, errorTrans('txt_required_lenght_255')).required(errorTrans('txt_required')),
      en: Yup.string().max(255, errorTrans('txt_required_lenght_255')).required(errorTrans('txt_required')),
    }),
  });

  const actionBack = () => {
    setIsEdit(false);
    // @ts-ignore: Object is possibly 'null'.
    formikRef?.current?.resetForm();
  };

  return (
    <>
      <ActionFormComponent
        isEdit={isEdit}
        cmsPersonPage={cmsPersonPage}
        personPageURL={personPageURL}
        data={data}
        onSubmit={() => onSubmitDataForm()}
        actionBack={actionBack}
        menuItems={menuItems}
      />
      <div className="people-form">
        <div className="people-form-container">
          <div className="people-form-content">
            <Formik
              enableReinitialize={true}
              innerRef={formikRef}
              initialValues={initialProplesValues()}
              validationSchema={peoplesFormSchema}
              onSubmit={(values, { setSubmitting }) => {}}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row-content">
                    <div className="row mt-24 mb-24">
                      <div className="col-md-5">
                        <div className="element-form profile_picture_upload">
                          <WIFormImageSelectorV2
                            isRequired={true}
                            title={t('txt_profile_picture')}
                            name="profile_picture"
                            disabled={isDisabledFiledForm() || !permissions.canUpdatePeople}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-6">
                        <WIFormInputTextV2
                          name={`name`}
                          title={t('txt_name')}
                          isRequired={true}
                          maxLength={100}
                          disabled={isDisabledFiledForm() || !permissions.canUpdatePeople}
                        />
                      </div>
                      <div className="col-md-6">
                        <WIFormInputTextV2
                          name={`phone`}
                          title={t('txt_phone')}
                          onChange={(item: any) => {
                            setFieldValue('phone', item.target.value, true);
                          }}
                          onBlur={handleBlur}
                          maxLength={100}
                          disabled={isDisabledFiledForm() || !permissions.canUpdatePeople}
                        />
                      </div>
                    </div>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-12">
                        <WIFormInputTextV2
                          name={`email`}
                          title={t('txt_email')}
                          onChange={(item: any) => {
                            setFieldValue('email', item.target.value, true);
                          }}
                          onBlur={handleBlur}
                          maxLength={100}
                          disabled={isDisabledFiledForm() || !permissions.canUpdatePeople}
                        />
                      </div>
                    </div>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label pb-16"> {t('txt_position')} </label>
                          <RichTextEditorV2
                            name="position.de"
                            isMultipleLine={false}
                            disabled={isDisabledFiledForm() || !permissions.canUpdatePeople}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label pb-16"> {t('txt_description')} </label>
                        <RichTextEditorV2
                          name="description.de"
                          header="description"
                          isMultipleLine={true}
                          disabled={isDisabledFiledForm() || !permissions.canUpdatePeople}
                        />
                      </div>
                    </div>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label pb-16"> {t('txt_generate_page')} </label>
                          <InputSwitch
                            className="wi-form-toggle-button-partner"
                            checked={values.generate_page}
                            onChange={(e: any) => setFieldValue('generate_page', e.value)}
                            onBlur={handleBlur}
                            disabled={isDisabledFiledForm() || !permissions.canUpdatePeople}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-12">
                        <div className="element-form">
                          <WIFormImageSelectorV2
                            isRequired={true}
                            title={t('txt_header_picture')}
                            name="cover_picture"
                            className="img-frame-16-9"
                            disabled={isDisabledFiledForm() || !permissions.canUpdatePeople}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePeopleFormComponent;
