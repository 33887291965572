import * as React from 'react';
import './change-donor-form.scss';
import { Formik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { DonationManagerAPI, DonorManagerAPI } from '../../../../../../services';
import SearchDonorComponent from '../../../add-donation/components/search-donor/search-donor';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../../../../../context/LayoutProvider';

const ChangeDonorForm = (props: any) => {
  const { donors, setDonors, onHide, donationID, donation, fetchDonationDetail, selectedDonor, setSelectedDonor } = props;

  const formikRef = useRef(null);
  const { t } = useTranslation('language', { keyPrefix: 'donation_detail' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();

  const initialDonorValues = () => {
    return {
      donor_id: donation?.donor_id || '',
    };
  };

  const donorFormSchema = Yup.object().shape({
    donor_id: Yup.string().nullable().required('This field is required.'),
  });

  const onChangeDonor = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef?.current?.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && formikRef?.current?.dirty && formikRef?.current?.isValid) {
        setLoadingProgress(errorTrans('txt_loading'));

        const donationData = { ...values };
        const resAdd = await DonationManagerAPI.updateDonor(donationID, donationData);
        if (resAdd && resAdd.status === 200) {
          onHide();
          fetchDonationDetail();
          setSuccessProgress(t('txt_change_donor_success'));
        } else {
          throw new Error('txt_change_donor_failed');
        }
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message || 'txt_change_donor_failed'));
    }
  };

  const getDonors = async (text: string = '', item?: any) => {
    try {
      if (item) {
        setSelectedDonor({
          name: `${item.payment_first_name || ''} ${item.payment_last_name || ''} - ${item.payment_email}`,
          item: item,
        });
      }
      const res = await DonorManagerAPI.searchDonors(item ? `${item.payment_first_name || ''} ${item.payment_last_name || ''}` : text);
      if (res && res.status === 200) {
        const sortDonors: any = _.sortBy(res.data, 'joined_date').reverse();
        setDonors(res.data?.length > 0 ? sortDonors : []);
      }
    } catch (error) {
      console.log('error >>>', error);
    }
  };

  const onSelectedItemSearch = (item: any, setFieldValue: any) => {
    if (item) {
      setFieldValue('donor_id', item.id, false);
      setSelectedDonor({
        name: `${item?.payment_first_name || ''} ${item?.payment_last_name || ''} - ${item?.payment_email}`,
        item: item,
      });
    } else {
      setFieldValue('donor_id', '', false);
      setSelectedDonor({
        name: '',
        item: null,
      });
    }
  };

  return (
    <div className="change-donor-form">
      <div className="form-container">
        <Formik
          enableReinitialize={false}
          innerRef={formikRef}
          initialValues={initialDonorValues()}
          validationSchema={donorFormSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="row-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form">
                      <label className="label">
                        Donor
                        <span className="required-label"> *</span>
                      </label>
                      <SearchDonorComponent
                        name="donor_id"
                        id="donor_id"
                        donors={donors}
                        hiddenAdd={true}
                        selectedDonor={selectedDonor}
                        setSelectedDonor={setSelectedDonor}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          if (!e.target.value) {
                            onSelectedItemSearch(null, setFieldValue);
                          }
                        }}
                        getDonors={getDonors}
                        onSelected={(item: any) => onSelectedItemSearch(item, setFieldValue)}
                      />
                      <div className={`${touched.donor_id && !selectedDonor.name ? 'error' : ''}`}>
                        {touched.donor_id && !selectedDonor.name ? 'This field is required.' : ''}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-start btn-donor gap-24">
                      <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={'Cancel'} onClick={() => onHide()} />
                      <Button className="wi-primary-button-v2 h48 flex-1" type="submit" label="Save" onClick={() => onChangeDonor()} />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangeDonorForm;
