import { useState, useRef } from 'react';
import React from 'react';
import DonationExamplesManagerAPI from '../../../../services/global-contents/donationExamplesManager';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import SearchURLGlobal from '../../../../components_v2/common/search-url-global/search-url-global';
import { RichTextEditorV2 } from '../../../../components_v2/common/rich-text-editor-v2/rich-text-editor-v2';

const DonationExampleForm = (props: any) => {
  const { onHide, donationExample, fetchDonationExamplesData, globalContent } = props;

  const formikRef = useRef(null);
  const { t } = useTranslation('language', {
    keyPrefix: 'donation_example_manager',
  });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();

  const onSuccess = () => {
    fetchDonationExamplesData();
    onHide();
  };

  const initialFormValues = () => {
    let initialValues = {
      url: '#',
      description: { de: '' },
      example: { de: '' },
    };

    if (donationExample) {
      initialValues.url = donationExample?.url || initialValues.url;
      initialValues.description.de = donationExample?.description?.de || initialValues.description.de;
      initialValues.example.de = donationExample?.example?.de || initialValues.example.de;
    }

    return initialValues;
  };

  const validationSchema = Yup.object().shape({
    description: Yup.object().shape({
      de: Yup.string().required(errorTrans('txt_required')),
    }),
    example: Yup.object().shape({
      de: Yup.string().required(errorTrans('txt_required')),
    }),
  });

  const onSubmitExampleForm = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));

        if (donationExample) {
          donationExample.example.de = values.example.de;
          donationExample.description.de = values.description.de;
          donationExample.url = values.url;

          const updateRes = await DonationExamplesManagerAPI.updateDonationExamples(donationExample);
          if (updateRes?.status === 200 && updateRes?.data) {
            setSuccessProgress(t('txt_update_example_success'));
            onSuccess();
          } else {
            throw new Error('txt_failed_update_example');
          }
        } else {
          const newData = {
            ...values,
            description: { de: values.description.de },
            example: { de: values.example.de },
            url: values.url,
          };

          let createRes = await DonationExamplesManagerAPI.generateDonationExamples(newData);
          if (createRes?.status === 200 && createRes?.data) {
            setSuccessProgress(t('txt_generate_example_success'));
            onSuccess();
          } else {
            throw new Error('txt_failed_generate_example');
          }
        }
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  };

  return (
    <div className="donation-example-form">
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues()}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="row-content">
              <div className="row mb-24">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label pb-16">
                      {' '}
                      {t('txt_title')} <span className="required-label ml-4"> *</span>
                    </label>
                    <RichTextEditorV2 name="example.de" isMultipleLine={false} isRequired={true} />
                  </div>
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-12">
                  <div className="element-form">
                    <SearchURLGlobal
                      label={t('txt_url')}
                      search_content={globalContent?.search_content?.internal_urls}
                      values={values.url}
                      searchFields={['name']}
                      isRequired={true}
                      onChangeValue={(valueURL: any) => {
                        setFieldValue(`url`, valueURL, true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label pb-16">
                      {' '}
                      {t('txt_description')} <span className="required-label ml-4"> *</span>
                    </label>
                    <RichTextEditorV2 name="description.de" isMultipleLine={true} isRequired={true} />
                  </div>
                </div>
              </div>

              <div className="row mt-24">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between gap-24">
                    <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                    <Button
                      className="wi-primary-button-v2 h48 flex-1"
                      type="submit"
                      label={donationExample ? t('txt_update') : t('txt_create')}
                      onClick={() => onSubmitExampleForm()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default DonationExampleForm;
