import * as React from 'react';
import { Paginator } from 'primereact/paginator';
import './wi-paginator.scss';
import { useTranslation } from 'react-i18next';

const WIPaginator = (props: any) => {
  const { first, rows, totalRecords, onPageChange, rowsPerPageOptions, currentPageReportTemplate, template } = props;
  const { t } = useTranslation('language');

  return (
    <Paginator
      className="wi-paginator-container-v2 mt-8"
      first={first}
      rows={rows}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
      dropdownAppendTo="self"
      rowsPerPageOptions={rowsPerPageOptions || [25, 50, 100, 500]}
      currentPageReportTemplate={currentPageReportTemplate || t('txt_pagination_report')}
      template={template || 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'}
    />
  );
};

export default WIPaginator;
