import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import React from 'react';
import { Toast } from 'primereact/toast';
import './download-teams-runners-dialog.scss';
import { WIButton } from '../../../common';

const DownloadTeamsRunnersDialog = (props: any) => {
  const { visible, onHide, onSubmit, isLoading, labelFileTeams, setLabelFileTeams } = props;
  const [value, setValue] = useState<any>();
  const toast = useRef(null);

  const handleOnChangedValue = (evt: any) => {
    const file = evt.target.files[0];
    if (file && file.type === 'text/csv') {
      const label = evt.target.value.split('\\');
      setLabelFileTeams(label[label.length - 1]);
      if (file) {
        const read = new FileReader();
        read.onload = function (e: any) {
          const contents = e.target.result;
          setValue(window.btoa(unescape(encodeURIComponent(contents))));
        };
        read.readAsText(file);
      } else {
        alert('Failed to load file');
      }
    } else {
      alert('Only import files csv');
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={`Import Teams and Runner`} visible={visible} style={{ width: '350px' }} modal className="wi-dialog" onHide={onHide}>
        <div className="dialog-teams-and-runners-container">
          <div className="row">
            <div className="col-md-12 teams-and-runners-container upload-file">
              <label className="label-upload-csv" htmlFor="upload">
                Choose CSV
              </label>
              <input className="input-upload" type="file" name="upload" id="upload" accept=".csv" onChange={handleOnChangedValue} />
            </div>
            {labelFileTeams ? (
              <label className="name-file">{labelFileTeams}</label>
            ) : (
              <div className="description">
                <a target="_blank" href={`${process.env.PUBLIC_URL}/data/Import_teams_and_runners.csv`} rel="noreferrer">
                  Download sample
                </a>
              </div>
            )}
          </div>

          <div className="btn-submit-container">
            <WIButton
              className="add-wir"
              primary={true}
              type="submit"
              label="Submit"
              icon={'pi-angle-right'}
              onClick={() => {
                if (value) {
                  onSubmit(value);
                  setValue('');
                  setLabelFileTeams(null);
                }
              }}
              disable={isLoading}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DownloadTeamsRunnersDialog;
