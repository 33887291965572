import { ErrorMessage, Field, useField } from 'formik';
import React from 'react';
import { useState } from 'react';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import { Dropdown } from 'primereact/dropdown';
import './wi-selectfield.scss';

export function SelectField(props: any) {
  const { name, options, selectFieldOptions, schema } = props;

  const CustomDropdownComponent = (props: any) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = field;
    const { setValue } = helpers;

    return (
      <Dropdown
        className="wi-form-select"
        panelClassName="wi-form-select"
        optionLabel={schema.optionLabel}
        optionValue={schema.optionValue}
        value={value}
        options={selectFieldOptions[schema.sourceName]}
        appendTo="self"
        onChange={(e: any) => setValue(e.value)}
        filter
        filterBy={schema.optionLabel}
      />
    );
  };

  return (
    <WIFormElement {...props}>
      <Field key={name} id={name} name={name} as={CustomDropdownComponent} />
      <ErrorMessage name={name} render={(msg: any) => <div style={{ color: 'red' }}>{msg}</div>} />
    </WIFormElement>
  );
}
