import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { WIFormDropdownV2, WIFormInputNumberV2, WIFormInputTextV2, WIFormSelectButtonV2 } from '../../../components_v2/common/form-fields';
import { useLayoutV2 } from '../../../context/LayoutProvider';
import { CurrencyList } from '../../../utils/utils';
import CampaignCouponManagerAPI from '../../../services/campaignCouponManager';
import { STATUS_CODE, COUPON_TYPES } from '../../../utils/constants';

const COUPON_DEFAULT_VALUE = {
  uuid: uuidv4(),
  coupon: '',
  campaign_id: '',
  coupon_type: COUPON_TYPES[0].code,
  receipt_status: '',
  coupon_status: STATUS_CODE.ACTIVE,
  start_date: moment().isValid() ? moment().toDate() : new Date(),
  end_date: moment().isValid() ? moment().toDate() : new Date(),
  currency_code: CurrencyList[0].code,
  budget_total: 0,
  coupon_limit: 0,
  redemption_limit: 0,
};

const CreateCouponForm = (props: any) => {
  const { onHide, coupon, campaigns, coupons, fetchCallBack } = props;
  const formikRef = useRef<any>(null);
  const { t } = useTranslation('language', { keyPrefix: 'coupons_manager' });
  const { t: globalTrans } = useTranslation('language');
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { turnOffProgress, setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();

  const formatCouponData = (formData: any) => {
    return {
      ...formData,
      start_date: moment.utc(moment(formData.start_date).format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').add(1, 'hour').toISOString(),
      end_date: moment.utc(moment(formData.end_date).format('DD.MM.YYYY'), 'DD.MM.YYYY').endOf('day').add(1, 'hour').toISOString(),
      coupon_limit: Number(formData.coupon_limit),
      redemption_limit: Number(formData.redemption_limit),
    };
  };

  const validateCouponData = (couponData: any) => {
    if (checkDuplicateCoupon(couponData.coupon, couponData.uuid)) {
      throw new Error('txt_duplicate_coupon');
    }
    if (moment(couponData.end_date).isBefore(moment(couponData.start_date))) {
      throw new Error('txt_error_date');
    }
  };

  const onSuccess = () => {
    fetchCallBack();
    onHide();
  };

  const onSubmitAddCoupon = async () => {
    try {
      const { values, dirty, isValid } = formikRef?.current;
      formikRef.current.validateForm();

      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));
        let couponData = formatCouponData(values);
        validateCouponData(couponData);

        if (!coupon) {
          let createRes = await CampaignCouponManagerAPI.createCoupon(couponData);
          if (createRes?.status === 200 && createRes?.data) {
            setSuccessProgress(t('txt_create_coupon_success'));
            onSuccess();
          } else {
            throw new Error('txt_create_coupon_fail');
          }
        }
      }
    } catch (e: any) {
      setErrorProgress(errorTrans(e?.message || 'txt_create_coupon_fail'));
    }
  };

  const onSubmitUpdateCoupon = async () => {
    try {
      const { values, dirty, isValid } = formikRef?.current;
      formikRef?.current?.validateForm();

      if (formikRef && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));
        let couponData = formatCouponData(values);
        validateCouponData(couponData);

        let updateRes = await CampaignCouponManagerAPI.updateCoupon(coupon.uuid, couponData);
        if (updateRes?.status === 200 && updateRes?.data) {
          setSuccessProgress(t('txt_update_coupon_success'));
          onSuccess();
        } else {
          throw new Error('txt_update_coupon_failed');
        }
      }
    } catch (e: any) {
      setErrorProgress(errorTrans(e?.message) || 'txt_update_coupon_failed');
    }
  };

  const checkDuplicateCoupon = (coupon: string, uuid: string = '') => {
    return Array.isArray(coupons) && coupons.some((c: any) => c.coupon.toLowerCase().trim() === coupon.toLowerCase().trim() && c.uuid !== uuid);
  };

  const validationSchema = Yup.object().shape({
    coupon: Yup.string().required(errorTrans('txt_required')),
    campaign_id: Yup.string().required(errorTrans('txt_required')),
    currency_code: Yup.string().required(errorTrans('txt_required')),
    coupon_status: Yup.string().required(errorTrans('txt_required')),
    coupon_limit: coupon ? Yup.number().notRequired() : Yup.number().required(errorTrans('txt_required')).min(1, errorTrans('txt_value_min')),
    redemption_limit: coupon ? Yup.number().notRequired() : Yup.number().required(errorTrans('txt_required')).min(1, errorTrans('txt_value_min')),
    start_date: Yup.date().required(errorTrans('txt_required')),
    end_date: Yup.date().required(errorTrans('txt_required')).min(Yup.ref('start_date'), errorTrans('txt_error_date')),
  });

  const validationSchemaWhenUpdate = Yup.object().shape({
    end_date: Yup.date().required(errorTrans('txt_required')).min(Yup.ref('start_date'), errorTrans('txt_error_date')),
  });

  const initialFormValues = () => {
    let initialCoupon = COUPON_DEFAULT_VALUE;
    if (coupon) {
      initialCoupon = _.pick(coupon, [
        'uuid',
        'coupon',
        'campaign_id',
        'coupon_type',
        'receipt_status',
        'coupon_status',
        'currency_code',
        'budget_total',
        'coupon_limit',
        'start_date',
        'end_date',
        'redemption_limit',
      ]);
      initialCoupon.start_date = moment(coupon.start_date).toDate();
      initialCoupon.end_date = moment(moment.utc(coupon.end_date).add(-1, 'hour').format('DD.MM.YYYY'), 'DD.MM.YYYY').toDate();
    }

    return initialCoupon;
  };

  return (
    <div className="create-coupon-form">
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues()}
        validationSchema={coupon ? validationSchemaWhenUpdate : validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          //console.log("values >>>", values);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <div className="row-content">
              <div className="row mb-24">
                <div className="col-md-12">
                  <WIFormInputTextV2
                    title={t('txt_coupon')}
                    name="coupon"
                    isRequired={true}
                    maxLength={20}
                    disabled={coupon}
                    hideErrorWhenLoading={true}
                    onChange={(e: any) => {
                      const text = e.target.value;
                      const sanitizedText = text.replace(/[^a-zA-Z0-9-]/g, '');
                      if (sanitizedText.length <= 20) {
                        setFieldValue('coupon', sanitizedText.toUpperCase());
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row mb-24">
                <div className="col-md-12">
                  <WIFormDropdownV2
                    name="campaign_id"
                    title={t('txt_campaign')}
                    isRequired={true}
                    optionLabel="name.de"
                    optionValue="uuid"
                    appendTo="self"
                    disabled={coupon}
                    hideErrorWhenLoading={true}
                    options={campaigns}
                    filter
                    filterBy="name.de"
                    placeholder={t('txt_choose_campaign')}
                    filterPlaceholder={t('txt_search')}
                  />
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-6 currency-selectbutton">
                  <WIFormSelectButtonV2
                    title={t('txt_currency')}
                    name="currency_code"
                    isRequired={true}
                    disabled={coupon}
                    options={CurrencyList}
                    optionLabel="name"
                    optionValue="code"
                    className="wi-selectbutton-three"
                  />
                </div>
                <div className="col-md-6">
                  <WIFormDropdownV2
                    title={t('txt_coupon_type')}
                    name="coupon_type"
                    isRequired={true}
                    disabled={coupon}
                    options={COUPON_TYPES}
                    style={{ width: 'auto' }}
                    optionLabel="name"
                    optionValue="code"
                    appendTo="self"
                  />
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-6">
                  <WIFormInputNumberV2
                    title={t('txt_limit')}
                    name="coupon_limit"
                    disabled={coupon}
                    useGrouping={true}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                    hideErrorWhenLoading={true}
                    isRequired={true}
                  />
                </div>
                <div className="col-md-6">
                  <WIFormInputNumberV2
                    title={t('txt_redemption_limit')}
                    name="redemption_limit"
                    disabled={coupon}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                    useGrouping={true}
                    hideErrorWhenLoading={true}
                    isRequired={true}
                  />
                </div>
              </div>

              <div className="row mb-24">
                <div className="col-md-6">
                  <div className="element-form">
                    <label className="label mb-12">
                      {t('txt_start_date')} <span className="required-label ml-4">*</span>
                    </label>
                    <div className="calendar-item">
                      <Calendar
                        id="basic"
                        name="start_date"
                        className="wi-calendar-v2"
                        value={values.start_date}
                        disabled={coupon}
                        onBlur={handleBlur}
                        dateFormat="dd.mm.yy"
                        iconPos="right"
                        icon="fa-solid fa-calendar-days"
                        showIcon={true}
                        onChange={item => {
                          setFieldValue('start_date', item.value, false);
                        }}
                        appendTo="self"
                      />
                    </div>
                    <div className={`${_.get(errors, 'start_date') && _.get(touched, 'start_date') ? 'error' : ''}`}>
                      {`${(_.get(touched, 'start_date') && _.get(errors, 'start_date')) || ''}`}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="element-form">
                    <label className="label mb-12">
                      {t('txt_end_date')} <span className="required-label ml-4">*</span>
                    </label>
                    <div className="calendar-item">
                      <Calendar
                        id="basic"
                        name="end_date"
                        className="wi-calendar-v2"
                        value={values.end_date}
                        onBlur={handleBlur}
                        dateFormat="dd.mm.yy"
                        iconPos="right"
                        icon="fa-solid fa-calendar-days"
                        showIcon={true}
                        onChange={item => {
                          setFieldValue('end_date', item.value, false);
                        }}
                        appendTo="self"
                      />
                    </div>
                    <div className={`${_.get(errors, 'end_date') && _.get(touched, 'end_date') ? 'error' : ''}`}>
                      {`${(_.get(touched, 'end_date') && _.get(errors, 'end_date')) || ''}`}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between gap-24">
                    <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                    <Button
                      className="wi-primary-button-v2 h48 flex-1"
                      type="submit"
                      label={`${t(coupon ? 'txt_save' : 'txt_btn_add')}`}
                      onClick={() => {
                        if (coupon) {
                          onSubmitUpdateCoupon();
                        } else {
                          onSubmitAddCoupon();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateCouponForm;
