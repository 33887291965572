import * as React from 'react';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LogoLogo from '../../../assets/images/login-logo.svg';
import './accept-term.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { UserManagerAPI } from '../../../services/v2';
import { ProgressSpinner } from 'primereact/progressspinner';
import { decodeBase64 } from '../../../utils/logic';
import useAuth from '../../../context/useAuth';
import { env } from '../../../configs';
import { HOMEPAGE_URL } from '../../../utils/constants';

const AcceptTermPage = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [bgLoginIndex] = useState(Math.floor(Math.random() * 12));
  const { t } = useTranslation('language', { keyPrefix: 'login_process' });
  const { auth } = useAuth();

  const onUpdateUser = async () => {
    try {
      setIsLoading(true);
      let updateUser = await UserManagerAPI.acceptTermConditions(auth?.tenant_id);
      if (updateUser && updateUser?.status === 200) {
        const redirectUrl = searchParams?.get('state') ? decodeBase64(searchParams?.get('state') || '') : '';
        navigate(redirectUrl || HOMEPAGE_URL);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  return (
    <div className="term-container">
      {isLoading ? (
        <div className="loading-component-v2">
          <ProgressSpinner className="progress-spinner-v2" />
        </div>
      ) : (
        <></>
      )}
      <img src={`${env.PUBLIC_IMAGE_URL}/bg_login${bgLoginIndex}.jpg`} alt="" className="background-cover" />
      <div className="background-backdrop"></div>
      <div className="term-form-wrapper">
        <img src={LogoLogo} alt="" className="term-logo" />
        <div className="term-form p-48">
          <h2 className="term-title mb-24">{t('txt_term_title')}</h2>
          <div className="term-content">
            <div className="term-content-container">
              <div className="dear term-text pb-28">
                {t('txt_dear')} {auth?.first_name} {t('txt_from')} <span className="strong-text"> {auth?.partner_name}</span>,
              </div>
              <div className="term-policy" dangerouslySetInnerHTML={{ __html: t('txt_term_context') }}></div>

              <div className="btn-term">
                <Button
                  className="wi-primary-button-v2 dots-button mt-24 h48"
                  type={'submit'}
                  label={t('txt_accept')}
                  disabled={isLoading}
                  onClick={() => onUpdateUser()}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptTermPage;
