const SEARCH_FIELDS = ['name', 'quantity'];

const getObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if (key && key !== 'dates') {
      if ((Array.isArray(value) || typeof value === 'string') && value.length > 0) {
        tempt_obj[key] = value;
      }
    } else {
      if (Array.isArray(value) && value.length === 2) {
        tempt_obj['from'] = value[0];
        tempt_obj['to'] = value[1];
      }
    }
  }
  return tempt_obj;
};

// ============================= GLOBAL FUNCTIONALITY =============================
export const getCouponObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if (key && key !== 'dates') {
      if ((Array.isArray(value) || typeof value === 'string') && value.length > 0) {
        tempt_obj[key] = value;
      }
    } else {
      if (Array.isArray(value) && value.length === 2) {
        tempt_obj['from'] = value[0];
        tempt_obj['to'] = value[1];
      }
    }
  }
  return tempt_obj;
};

export const getCouponObjectSearchValue = (value: string) => {
  const search_result: any[] = [];
  SEARCH_FIELDS.forEach((key: string) => {
    const obj = {
      [key]: value,
    };
    search_result.push(obj);
  });

  return search_result;
};

export const getCouponPayloadConditions = (where_obj: any, sort_config: any) => {
  const { sortField, sortOrder } = sort_config;
  const where_conditions = getObjectWithValues(where_obj);
  const mapper: any = {
    serial: [['serial']],
    name: [['name']],
    partner_name: [['partner_id']],
    land_name: [['land_id']],
    quantity: [['quantity']],
    area: [['area']],
    expire_date: [['expire_date']],
    // status: [["status"]],
    // progress: [["progress"]],
  };
  const orders = mapper[sortField];
  orders.forEach((r: any) => r.push(sortOrder === -1 ? 'DESC' : 'ASC'));
  const order = [...orders];
  return {
    where: where_conditions,
    order,
  };
};
