const SEARCH_FIELDS = ['name', 'code'];

export const getObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if (key && key !== 'dates') {
      if ((Array.isArray(value) || typeof value === 'string') && value.length > 0) {
        tempt_obj[key] = value;
      }
    }
  }
  return tempt_obj;
};

// ============================= GLOBAL FUNCTIONALITY =============================
export const getGoalObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if (key && key !== 'dates') {
      if ((Array.isArray(value) || typeof value === 'string') && value.length > 0) {
        tempt_obj[key] = value;
      }
    }
  }
  return tempt_obj;
};

export const getGoalObjectSearchValue = (value: string) => {
  const search_result: any[] = [];
  SEARCH_FIELDS.forEach((key: string) => {
    const obj = {
      [key]: value,
    };
    search_result.push(obj);
  });

  return search_result;
};

export const getGoalPayloadConditions = (filterValues: any, sort_config: any) => {
  const { sortField, sortOrder } = sort_config;
  const where_conditions = getObjectWithValues(filterValues.where);
  const mapper: any = {
    name: [['name']],
    land_id: [['land_id']],
    campaign_id: [['campaign_id']],
    code: [['code']],
    partner_id: [['partner_id']],
    donation_goal: [['donation_goal']],
    total_area: [['total_area']],
    created_at: [['created_at']],
  };
  const orders = mapper[sortField];
  orders.forEach((r: any) => r.push(sortOrder === -1 ? 'DESC' : 'ASC'));
  return {
    where: {
      ...where_conditions,
      search_name: filterValues.search,
    },
    order: orders[0],
  };
};
