import * as React from 'react';
import flag from '../../../assets/images/flags/flag_placeholder.png';

export const UndoRedoPanel = (props: any) => {
  const { reactQuillRef } = props;

  const undo = () => {
    if (reactQuillRef.current && reactQuillRef.current?.getQuill()) {
      let myEditor = reactQuillRef.current?.getQuill();
      return myEditor.history.undo();
    }
  };

  const redo = () => {
    if (reactQuillRef.current && reactQuillRef.current?.getQuill()) {
      let myEditor = reactQuillRef.current?.getQuill();
      return myEditor.history.redo();
    }
  };

  const clearFormatOfSelection = () => {
    const quillEditor = reactQuillRef.current?.getQuill();
    const range = quillEditor.getSelection();
    if (range.length === 0) {
      let [leaf, offset] = quillEditor.getLeaf(range.index);
      quillEditor.removeFormat(range.index - offset, range.index + leaf?.domNode?.length);
    } else {
      quillEditor.removeFormat(range.index, range.length);
    }
  };

  const showSource = () => {};

  return (
    <>
      <span className="ql-formats">
        <button className="wi-ql-undo" aria-label="Undo" onClick={undo} type="button">
          <svg width="24" height="24" focusable="false">
            <path
              d="M6.4 8H12c3.7 0 6.2 2 6.8 5.1.6 2.7-.4 5.6-2.3 6.8a1 1 0 0 1-1-1.8c1.1-.6 1.8-2.7 1.4-4.6-.5-2.1-2.1-3.5-4.9-3.5H6.4l3.3 3.3a1 1 0 1 1-1.4 1.4l-5-5a1 1 0 0 1 0-1.4l5-5a1 1 0 0 1 1.4 1.4L6.4 8Z"
              fill-rule="nonzero"
            ></path>
          </svg>
        </button>
        <button className="wi-ql-undo" aria-label="Redo" onClick={redo} type="button">
          <svg width="24" height="24" focusable="false">
            <path
              d="M17.6 10H12c-2.8 0-4.4 1.4-4.9 3.5-.4 2 .3 4 1.4 4.6a1 1 0 1 1-1 1.8c-2-1.2-2.9-4.1-2.3-6.8.6-3 3-5.1 6.8-5.1h5.6l-3.3-3.3a1 1 0 1 1 1.4-1.4l5 5a1 1 0 0 1 0 1.4l-5 5a1 1 0 0 1-1.4-1.4l3.3-3.3Z"
              fill-rule="nonzero"
            ></path>
          </svg>
        </button>

        {/* <button className="ql-clear-format" aria-label="Clear Format" onClick={clearFormatOfSelection}>
                <svg viewBox="0 0 576 512">
                    <path className="ql-fill ql-stroke" d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z" />
                </svg>
            </button> */}
        {/* <button className="ql-show-source" aria-label="Show Source" onClick={clearFormatOfSelection}>
                <svg viewBox="0 0 576 512">
                    <path className="ql-fill ql-stroke" d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z" />
                </svg>
            </button> */}
      </span>
    </>
  );
};

export function HeaderEditor(props: any) {
  const { lang } = props;

  return (
    <>
      {lang && <img src={flag} className={`flag flag-${lang || 'de'}`} style={{ marginBottom: '5px' }} width={30} />}
      <UndoRedoPanel {...props}></UndoRedoPanel>
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-script" value="sub"></button>
        <button className="ql-script" value="super"></button>
      </span>
      <span className="ql-formats">
        <select className="ql-color" aria-label="Color Picker">
          <option value="#004432"></option>
          <option value="#47B27A"></option>
          <option value="#E52322"></option>
          <option value="#E6CE00"></option>
          <option value="#477EC0"></option>
          <option value="#8ACBC1"></option>
          <option value="#94C24A"></option>
          <option value="#13735F"></option>
          <option value="#861135"></option>
          <option value="#333333"></option>
          <option value="#4D4D4D"></option>
          <option value="#737373"></option>
          <option value="#ABABAB"></option>
          <option value="#DCDCDC"></option>
          <option value="#F7F7F7"></option>
          <option value="#FFFFFF"></option>
          <option value="#000000"></option>
        </select>
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-list" value="ordered" aria-label="Ordered List"></button>
        <button type="button" className="ql-list" value="bullet" aria-label="Unordered List"></button>
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-link" aria-label="Insert Link"></button>
      </span>
    </>
  );
}

export const PlainClipboard = {
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true,
  },
  // clipboard: {
  //     matchers: [
  //         [
  //             Node.ELEMENT_NODE, (node: any, delta: any) => {
  //                 delta.ops = delta.ops.map((op: any) => ({ insert: op.insert }));
  //                 return delta;
  //             }
  //         ]
  //     ]
  // }
};

export const ColorsEditor = [
  '004432',
  'Sherwood Green',
  '47B27A',
  'Mint',
  'E52322',
  'Lava Red',
  'E6CE00',
  'Citrine',
  '477EC0',
  'Tufts Blue',
  '8ACBC1',
  'Cyan Opaque',
  '94C24A',
  'Green Peas',
  '13735F',
  'Teal Green',
  '861135',
  'Merlot',
  '333333',
  'Dune',
  '4D4D4D',
  'Liver',
  '737373',
  'Smokey Grey',
  'ABABAB',
  'Silver Chalice',
  'DCDCDC',
  'Gainsboro',
  'F7F7F7',
  'Desert Storm',
  'FFFFFF',
  'White',
  '000000',
  'Black',
];

export const ToolbarsSingleLine = 'undo redo removeContentButton| copy |' + 'subscript superscript | code removeformat';

export const ToolbarsSingleLineWithColor = 'undo redo removeContentButton| copy |' + 'forecolor | ' + 'subscript superscript | code removeformat';

export const ToolbarsMultiLine =
  'undo redo removeContentButton| copy | ' +
  'bold italic underline forecolor | ' +
  'numlist bullist | ' +
  'subscript superscript | ' +
  'link code | removeformat ';

export const Plugins = ['lists', 'code', 'paste', 'link', 'textcolor colorpicker'];

export const ContentStyleForMultiLine = `
    body {
        font-family: Lato, Helvetica, Arial, sans-serif;
        font-size: 14px;
    }
    body p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    html:has(.mce-content-readonly) {
        background-color: #f2f2f2;
    }`;

export const ContentStyleForSingleLine = `
    body {
        font-family: Lato, Helvetica, Arial, sans-serif;
        font-size: 14px;
        overflow-y: hidden!important;
    }
    html:has(.mce-content-readonly) {
        background-color: #f2f2f2;
    }`;
