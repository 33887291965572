import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import moment from 'moment';
import './donation-manager.scss';
import _ from 'lodash';
import { Sidebar } from 'primereact/sidebar';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useLayoutV2 } from '../../context/LayoutProvider';
import useAuth from '../../context/useAuth';
import { CampaignManagerAPI, DonationManagerAPI, LandManagerAPI, RegionManagerAPI } from '../../services';
import CountryService from '../../services/country-service';
import { onDownloadMultipleReceipt, onDownloadReceipt } from '../../utils/donation.util';
import {
  getObjectSearchValue,
  getObjectWithValues,
  getPayloadConditions,
  NO_ACTION_LIST,
  disabledEditAction,
  isValidDonorInfo,
} from './donation-manager.util';
import { DONATION_RHYTHM_LIST, DONATION_STATUS_LIST, DONATION_TYPE_LIST, LAND_TYPES, PERMISSIONS_V2 } from '../../utils/constants';
import Layout from '../../layout/layout';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import WiStatus from '../../components_v2/common/wi-status/wi-status';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import SnackBarComponent from './components/snack-bar/snack-bar';
import CertificateDonationDialogComponent from './components/generate-certificate-donation-dialog/generate-certificate-donation-dialog';
import DonationFilterComponent from './components/donation-filter/donation-filter-component';
import GenerateReceiptDonationDialogComponent from './components/generate-receipt-donation-dialog/generate-receipt-donation-dialog';
import ExportDonationDialogComponent from './components/export-donation-dialog/export-donation-dialog';
import AddDonationFormComponent from './components/add-donation/components/add-donation-form/add-donation-form';
import UpdateDonationFormComponent from './components/update-donation-form/update-donation-form';
import { formatDateTimeV2, formatDecimalV2 } from '../../utils/logic';

export type DonationFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    status: string[];
    // land_id: string[],
    region_id: string[];
    campaign_id: string[];
    payment_currency_code: string[];
    payment_method: string[];
    dates: string[];
    donation_type: string[];
    rhythm: number[];
    subsequent: number[];
  };
  order: [];
};
const SuperAdminKey = 'nature';

const DonationManager = (props: any) => {
  const { auth } = useAuth();
  const { t } = useTranslation('language', { keyPrefix: 'donation_manager' });
  const { t: globalTrans } = useTranslation('language');
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress, getLanguage } = useLayoutV2();
  const permissions = {
    canCreateDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_CREATE),
    canUpdateDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_UPDATE),
    canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
    canViewCampaign: auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_VIEW),
    canCreateDonationWithArea: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_X),
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const isSuperAdmin = searchParams?.get('superadmin') === SuperAdminKey;
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const sortField = searchParams?.get('sortField') || 'donation_serial';
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const filterStatus = (searchParams?.get('filterStatus') || DONATION_STATUS_LIST.map((c: any) => c.code).join(',')).split(',');
  const filterRegions = searchParams?.get('filterRegions') ? searchParams?.get('filterRegions')?.split(',') || [] : [];
  // const filterLands = searchParams?.get('filterLands') ? searchParams?.get('filterLands')?.split(',') || [] : [];
  const filterCampaigns = searchParams?.get('filterCampaigns') ? searchParams?.get('filterCampaigns')?.split(',') || [] : [];
  const filterCurrencies = searchParams?.get('filterCurrencies') ? searchParams?.get('filterCurrencies')?.split(',') || [] : [];
  const filterMethods = searchParams?.get('filterMethods') ? searchParams?.get('filterMethods')?.split(',') || [] : [];
  const filterRhythms = searchParams?.get('filterRhythms')
    ? searchParams
        ?.get('filterRhythms')
        ?.split(',')
        .map(r => +r) || []
    : [];
  const filterSubsequent = searchParams?.get('filterSubsequent')
    ? searchParams
        ?.get('filterSubsequent')
        ?.split(',')
        .map(c => +c) || [0, 1]
    : [0, 1];
  const filterTypes = (
    searchParams?.get('filterTypes') ||
    DONATION_TYPE_LIST.filter(c => c.code !== 'code')
      .map(c => c.code)
      .join(',')
  ).split(',');
  const filterDates = searchParams?.get('filterDates') ? searchParams?.get('filterDates')?.split(',') || [] : [];
  const searchField = searchParams?.get('search') || '';

  const statusTypeAll = 'all';
  const [isShowCreateCertificateDialog, setIsShowCreateCertificateDialog] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDonations, setSelectedDonations] = useState([]);
  const [donations, setDonations] = useState({
    data: [],
    totalPage: 0,
  });
  const [donation, setDonation] = useState<any>(null);
  const [page, setPage] = useState(pageNumber);
  const [first, setFirst] = useState(pageNumber * noRows);
  const [rows, setRows] = useState(noRows);
  const [statusDonations, setStatusDonations] = useState<any[]>([]);
  const [selectedStatusDonation, setSelectedStatusDonation] = useState<any>(filterStatus.length !== 1 ? statusTypeAll : filterStatus[0]);
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const [filterValues, setFilterValues] = useState<DonationFilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getObjectSearchValue(searchField) : [],
      status: filterStatus,
      // land_id: filterLands,
      region_id: filterRegions,
      campaign_id: filterCampaigns,
      payment_currency_code: filterCurrencies,
      payment_method: filterMethods,
      rhythm: filterRhythms,
      subsequent: filterSubsequent,
      donation_type: filterTypes,
      dates: filterDates,
    },
    order: [],
  });
  const [isMultipleDownload, setIsMultipleDownload] = useState(false);
  const [isShowReceiptDialog, setIsShowReceiptDialog] = useState(false);
  const [isShowExportDonationDialog, setIsShowExportDonationDialog] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [lands, setLands] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [countries, setCountries] = useState([]);
  const [isReserve, setIsReserve] = useState(false);

  const navigate = useNavigate();

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
    setSelectedDonations([]);
  };

  const setupDonationStatus = async () => {
    const statusList = _.cloneDeep(DONATION_STATUS_LIST);
    statusList.unshift({ code: 'all', label: 'All', name: { de: 'All', en: 'All' } });
    // setSelectedStatusDonation(statusList && statusList.length > 0 ? statusList[0].code : '')
    setStatusDonations(statusList);
  };

  const fetchDonationsData = async () => {
    setIsLoading(true);

    const payload = getPayloadConditions(filterValues.where, sortConfig);
    try {
      const res = await DonationManagerAPI.getDonationsBySearch({ pageNo: page, range: rows, ...payload });
      if (res && res.status === 200) {
        setIsLoading(false);
        setDonations({
          data: res.data.records
            ? res.data.records.map((item: any) => ({
                ...item,
                full_name: item.payment_company_name || (item.payment_first_name || '') + ' ' + (item.payment_last_name || ''),
              }))
            : [],
          totalPage: res.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setDonations({
        data: [],
        totalPage: 0,
      });
    }
  };

  const fetchAreasData = async () => {
    setIsLoading(true);
    const [regionRes, landsRes, campaignsRes, countriesRes] = await Promise.all([
      RegionManagerAPI.getAllRegions(),
      LandManagerAPI.getAllLands(),
      CampaignManagerAPI.getAllCampaigns(),
      CountryService.getCountries(),
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (regionRes && regionRes.status === 200 && regionRes.data.records?.length > 0) {
      setRegions(_.sortBy(regionRes.data.records, item => item.name?.de?.toLowerCase()));
    }
    // @ts-ignore: Object is possibly 'null'.
    if (landsRes && landsRes.status === 200 && landsRes.data.records?.length > 0) {
      const landList = landsRes.data.records.filter((l: any) => l.land_type !== LAND_TYPES.CampaignOnly);
      const regionList = regionRes?.data?.records;
      landList.forEach((item: any) => {
        item.region = regionList?.find((r: any) => r.default_land_id === item.uuid);
        item.master_region = regionList?.find((r: any) => r.uuid === item.region_id);
      });
      setLands(_.sortBy(landList, item => item.name?.de?.toLowerCase()));
    }
    // @ts-ignore: Object is possibly 'null'.
    if (campaignsRes && campaignsRes.status === 200 && campaignsRes.data.records?.length > 0) {
      const campaignList = campaignsRes?.data?.records;
      campaignList.forEach((item: any) => {
        item.master_region = regions?.find((r: any) => r.uuid === item.land?.region_id);
      });
      setCampaigns(_.sortBy(campaignList, item => item.name?.de?.toLowerCase()));
    }

    if (countriesRes && countriesRes.status === 200) {
      if (countriesRes.data.data && countriesRes.data.data.length > 0) {
        setCountries(countriesRes.data.data);
      }
    }
  };

  useEffect(() => {
    fetchAreasData();
    setupDonationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === 'number') {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });
      setSearchParams(
        {
          pageNumber: (page + 1).toString(),
          noRows: rows.toString(),
        },
        { replace: true },
      );

      setSelectedDonations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  useEffect(() => {
    if (typeof selectedStatusDonation === 'string') {
      let p = _.cloneDeep({ ...filterValues });
      if (selectedStatusDonation === statusTypeAll) {
        // p.where["status"] = [...DONATION_STATUS_LIST.map((c) => c.code)];
      } else {
        p.where['status'] = [selectedStatusDonation];
      }
      setFilterValues(p);
      setSelectedDonations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusDonation]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterStatus: filterValues.where.status.join(',') || '',
      filterRegions: filterValues.where.region_id?.join(',') || '',
      // filterLands: filterValues.where.land_id?.join(',') || '',
      filterCampaigns: filterValues.where.campaign_id?.join(',') || '',
      filterCurrencies: filterValues.where.payment_currency_code?.join(',') || '',
      filterMethods: filterValues.where.payment_method?.join(',') || '',
      filterRhythms: filterValues.where.rhythm?.join(',') || '',
      filterSubsequent: filterValues.where.subsequent?.join(',') || '0,1',
      filterTypes: filterValues.where.donation_type?.join(',') || '',
      filterDates: filterValues.where.dates?.join(',') || '',
      superadmin: isSuperAdmin ? SuperAdminKey : '',
    };
    setSearchParams(
      _.omitBy(searchParams, p => !p),
      { replace: true },
    );
    fetchDonationsData();
    setSelectedDonations([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  const onFilterChange = (values: any, fields: any[]) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    fields.forEach((field: any) => {
      const data = values?.get(field);
      if (field !== 'dates') {
        where_conditions[field] = data;
      } else {
        if (data) {
          const dates = data?.map((c: any) => (_.isString(c) ? c : moment(c).format('DD/MM/YYYY')));
          where_conditions[field] = dates;
          where_conditions.from = dates[0];
          where_conditions.to = dates[1];
        }
      }
    });

    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    if (selectedStatusDonation === statusTypeAll) {
      // p.where["status"] = [...DONATION_STATUS_LIST.filter(c => c.code !== 'reserved').map((c) => c.code)];
      p.where['status'] = [...DONATION_STATUS_LIST.map((c: any) => c.code)];
    } else {
      p.where['status'] = [selectedStatusDonation];
    }
    p.where['donation_type'] = [...DONATION_TYPE_LIST.map(c => c.code)];
    p.where['subsequent'] = [1];
    p.search = '';
    setFilterValues(p);
  };

  const onEditDonation = async (e: any, rowData: any) => {
    setIsLoading(true);
    if (rowData) {
      setDonation(rowData);
      setIsLoading(false);
      setIsShowUpdate(true);
    }
  };

  const onGenerateCertificateDialog = (e: any, rowData: any) => {
    setIsMultipleDownload(false);
    setDonation(rowData);
    setIsShowCreateCertificateDialog(true);
  };

  const handleDisableIcon = (rowData: any) => {
    const isChildDonation = rowData.is_first_time === false;
    const isNoAction = NO_ACTION_LIST.includes(rowData?.status?.toLowerCase());
    return isNoAction || isChildDonation;
  };

  const handleDisableOldData = (rowData: any) => {
    if (rowData?.old_donation_id) {
      const paymentDate = new Date(rowData.payment_date);
      const year = paymentDate.getFullYear();
      const dateValid = [2021, 2022, 2023];
      return !dateValid.includes(year);
    }

    return false;
  };

  const handleChangeStatus = (status: any) => {
    setPage(0);
    setFirst(0);
    setSelectedStatusDonation(status.code);

    if (status.code === statusTypeAll) {
      let p = _.cloneDeep({ ...filterValues });
      // p.where["status"] = [...DONATION_STATUS_LIST.filter(c => c.code !== 'reserved').map((c) => c.code)];
      p.where['status'] = [...DONATION_STATUS_LIST.map((c: any) => c.code)];
      setFilterValues(p);
    }
  };

  const copyLinkDonation = (rowData: any) => {
    setLoadingProgress(errorTrans('txt_loading'));
    navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}/${rowData.uuid}`).then(() => {
      setSuccessProgress(t('txt_copy_link_donation_success'));
    });
  };

  const formatAmountBodyTemplate = (rowData: any) => {
    return (
      <div>
        <b style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>
          {rowData ? formatDecimalV2(rowData.donation_money, getLanguage()) : '-'}
        </b>
        <span>{rowData.payment_currency_code?.toUpperCase()}</span>
      </div>
    );
  };

  const formatSerialBodyTemplate = (rowData: any) => {
    return (
      <Link to={`/donations/${rowData.uuid}`} className="table-ref-link-cell">
        {rowData.donation_serial || `00-${rowData.serial}`}
      </Link>
    );
  };

  const tableColumns = [
    {
      field: 'donation_serial',
      header: t('txt_serial'),
      width: '110px',
      align: 'left',
      bodyTemplate: formatSerialBodyTemplate,
    },
    {
      field: 'donation_money',
      header: t('txt_donation_amount'),
      width: '120px',
      align: 'right',
      bodyTemplate: formatAmountBodyTemplate,
    },
    {
      field: 'status',
      header: '',
      width: '170px',
      align: 'center',
      disabledSort: true,
      bodyTemplate: (rowData: any) => {
        const statusItem = DONATION_STATUS_LIST.find((item: any) => item.code === rowData.status);
        return <WiStatus item={statusItem}></WiStatus>;
      },
    },
    {
      field: 'rhythm',
      header: t('txt_rhythm'),
      width: '130px',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const rhythm = globalTrans(DONATION_RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name || 'rhythms.txt_once');
        if (rhythm && +rowData.rhythm > 0 && rowData.is_first_time) {
          return (
            <span>
              {rhythm} <i className="fa-solid fa-clock-rotate-left rhythm-icon" title="First time donation"></i>
            </span>
          );
        }

        if (rhythm && +rowData.rhythm > 0 && !rowData.is_first_time) {
          return (
            <span>
              {rhythm} <i className="fa-solid fa-arrows-rotate rhythm-icon" title="Subsequent donation"></i>
            </span>
          );
        }

        return rhythm;
      },
    },
    {
      field: 'region.name.de',
      header: t('txt_region'),
      width: '200px',
      align: 'left',
      disabledSort: true,
      bodyTemplate: (rowData: any) => {
        const name = rowData.region?.name?.de;
        if (permissions.canViewRegion) {
          return (
            <Link to={`/regions/${rowData.region_id}`} className="table-ref-link-cell">
              {name}
            </Link>
          );
        }
        return name;
      },
    },
    {
      field: 'campaign.name.de',
      header: t('txt_campaign'),
      width: '200px',
      align: 'left',
      disabledSort: true,
      bodyTemplate: (rowData: any) => {
        const name = rowData.campaign?.name?.de;
        if (permissions.canViewCampaign) {
          return (
            <Link to={`/campaigns/${rowData.campaign_id}`} className="table-ref-link-cell">
              {name}
            </Link>
          );
        }

        return name;
      },
    },
    {
      field: 'full_name',
      header: t('txt_name'),
      width: 'calc(100% - 1430px)',
      align: 'left',
      minWidth: '150px',
    },
    {
      field: 'payment_email',
      header: t('txt_email'),
      width: '270px',
      align: 'left',
      wordBreak: 'break-word',
      maxWidth: '270px',
    },
    {
      field: 'payment_date',
      header: t('txt_payment_date'),
      width: '170px',
      align: 'left',
      bodyTemplate: (rowData: any) => formatDateTimeV2(rowData.payment_date, getLanguage()),
    },
    {
      field: 'payment_method',
      header: t('txt_payment_method'),
      width: '140px',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return <div style={{ textTransform: 'capitalize' }}>{rowData.payment_method}</div>;
      },
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
          wordBreak: col.wordBreak || 'normal',
          maxWidth: col.maxWidth || 'none',
          minWidth: col.minWidth || 'none',
        }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
        sortable={!col.disabledSort}
      />
    );
  });

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions['or'] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  const handleActionAdd = () => {
    setIsShowSidebar(true);
    setIsReserve(false);
  };

  const handleActionReserve = () => {
    setIsShowSidebar(true);
    setIsReserve(true);
  };

  return (
    <Layout title={t('txt_title')} className="donation-manager">
      <DonationFilterComponent
        lang={'de'}
        onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
        items={statusDonations}
        selectedItem={selectedStatusDonation}
        onStatusChange={handleChangeStatus}
        isStatusAll={selectedStatusDonation === statusTypeAll}
        filterValues={filterValues}
        fields={[
          'region_id',
          'campaign_id',
          'payment_currency_code',
          'status',
          'payment_method',
          'donation_type',
          'rhythm',
          'subsequent',
          'dates',
          'matchFundingItems',
          'coupons',
        ]}
        onRemove={onFilterRemove}
        onSearchFunc={onSearchFunc}
        onAddDonation={handleActionAdd}
        onReserveDonation={handleActionReserve}
        area={lands}
        regions={regions}
        campaigns={campaigns}
      />
      <div className="p-table-v2 ml-24 mr-24">
        <DataTable
          loading={isLoading}
          value={donations.data}
          scrollable
          selectionPageOnly
          emptyMessage={t('txt_no_records')}
          isDataSelectable={event => {
            const data = event.data;
            return !handleDisableIcon(data);
          }}
          selectionMode="checkbox"
          selection={selectedDonations}
          onSelectionChange={e => {
            if (e.value && e.value.length === 0) {
              setIsMultipleDownload(false);
            }
            setSelectedDonations(e.value);
          }}
          dataKey="uuid"
          scrollHeight="calc(100vh - 276px)"
          scrollDirection="both"
          rowClassName={data => (handleDisableIcon(data) ? 'disabled-donation-row' : 'selectable-donation-row')}
          lazy
          onSort={(e: any) => {
            setSortConfig({ sortField: e.sortField, sortOrder: e.sortOrder });
            setSelectedDonations([]);
          }}
          sortField={sortConfig.sortField}
          sortOrder={sortConfig.sortOrder}
        >
          <Column selectionMode="multiple" headerStyle={{ width: '50px' }}></Column>
          {dynamicColumns}
          <Column
            frozen
            alignFrozen="right"
            style={{ width: '64px' }}
            body={rowData => {
              const defaultActions = [
                {
                  label: t('txt_open'),
                  icon: 'wi-open',
                  url: `/donations/${rowData.uuid}`,
                },
                {
                  label: t('txt_copy_donation_link'),
                  icon: 'wi-copy',
                  command: (e: any) => copyLinkDonation(rowData),
                },
              ];
              if (+rowData.rhythm > 0 && !rowData.is_first_time) {
                return (
                  <MenuDot
                    items={[
                      ...defaultActions,
                      {
                        label: t('txt_open_parent_donation'),
                        icon: 'wi-open',
                        url: `/donations/${rowData.parent_donation_id}`,
                      },
                    ]}
                  ></MenuDot>
                );
              }

              return (
                <MenuDot
                  items={[
                    ...defaultActions,
                    {
                      label: t('txt_quick_edit'),
                      icon: 'wi-edit',
                      disabled: disabledEditAction(rowData) || handleDisableIcon(rowData),
                      hidden: !permissions.canUpdateDonation,
                      command: (e: any) => onEditDonation(e, rowData),
                      // description: 'Quickly edit email and personalize the certificate'
                    },
                    {
                      label: t('txt_download_certificate'),
                      icon: 'wi-download',
                      disabled: handleDisableIcon(rowData),
                      command: (e: any) => onGenerateCertificateDialog(e, rowData),
                      // description: 'Download certificate as PDF'
                    },
                    {
                      label: t('txt_download_receipt'),
                      icon: 'wi-download',
                      disabled:
                        rowData?.disabled_receipt || handleDisableIcon(rowData) || !isValidDonorInfo(rowData) || handleDisableOldData(rowData),
                      command: (e: any) => onDownloadReceipt(setSuccessProgress, setErrorProgress, rowData, e),
                      // description: 'Download receipt as PDF'
                    },
                  ]}
                ></MenuDot>
              );
            }}
          ></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={donations.totalPage} onPageChange={onBasicPageChange} />
      </div>
      <Sidebar
        visible={isShowUpdate}
        closeOnEscape={false}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '700px' }}
        onHide={() => setIsShowUpdate(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_quick_edit')}</h6>
          </div>
          <UpdateDonationFormComponent donation={donation} onHide={() => setIsShowUpdate(false)} fetchDonationsData={fetchDonationsData} />
        </div>
      </Sidebar>

      <GenerateReceiptDonationDialogComponent
        visible={isShowReceiptDialog}
        onHide={() => setIsShowReceiptDialog(false)}
        selectedDonations={selectedDonations}
      />
      <ExportDonationDialogComponent
        visible={isShowExportDonationDialog}
        onHide={() => setIsShowExportDonationDialog(false)}
        selectedDonations={selectedDonations}
      />
      <SnackBarComponent
        selectedDonations={selectedDonations}
        visible={selectedDonations && selectedDonations.length > 0}
        clearDonations={() => setSelectedDonations([])}
        downloadCertificate={() => {
          setIsMultipleDownload(true);
          setIsShowCreateCertificateDialog(true);
        }}
        downloadReceipt={() => {
          setLoadingProgress(errorTrans('txt_loading'));
          const ids = selectedDonations.map((donation: any) => donation.uuid);
          onDownloadMultipleReceipt(ids, 'de')
            .then(() => {
              setSuccessProgress(errorTrans('txt_generate_success'));
            })
            .catch((err: any) => {
              setErrorProgress(errorTrans(err.message));
            });
        }}
        exportDonation={() => setIsShowExportDonationDialog(true)}
      />
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '625px' }}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{isReserve ? t('txt_resevse_donation') : t('txt_add_donation')}</h6>
          </div>
          <AddDonationFormComponent
            lands={lands}
            campaigns={campaigns}
            setIsLoading={setIsLoading}
            setIsShowSidebar={setIsShowSidebar}
            countries={countries}
            isSuperAdmin={permissions.canCreateDonationWithArea}
            isReserve={isReserve}
            onHide={() => setIsShowSidebar(false)}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowCreateCertificateDialog}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowCreateCertificateDialog(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_download_certificate')}</h6>
          </div>
          <CertificateDonationDialogComponent
            donation={donation}
            selectedDonations={selectedDonations}
            isMultipleDownload={isMultipleDownload}
            onHide={() => setIsShowCreateCertificateDialog(false)}
          />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default DonationManager;
