import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { Column, ColumnAlignType } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Layout from '../layout/layout';
import './coupon-detail.scss';
import { generateMapUrlForBatchCode, showNotification } from '../../utils/logic';
import CouponDetailAPI from '../../services/couponDetail';
import moment from 'moment';
import WIConfirmDialog from '../common/delete-confirm-dialog/delete-confirm-dialog';
import { buildCouponStatusColumnTemplate, formatDateBodyTemplate } from '../common/column-template-table/column-template';
import MenuDot from './components/menu-dot/menu-dot';
import { WIPaginator } from '../common';
import _ from 'lodash';
import CouponDetailFilterComponent from './coupon-detail-filter/coupon-detail-filter-component';
import MenuButton from './components/menu-button';
import { env } from '../../configs';
import { getObjectWithValues, getObjectSearchValue, getCodePayloadConditions, createMarker, createMap } from './coupon-detail-util';
import maplibregl, { Map } from 'maplibre-gl';
import DownloadReceiptDialogComponent from './components/download-receipt-dialog';
import ImportCSVDialogComponent from './components/import-csv/import-csv-dialog';
import ImportReferenceCodesDialogComponent from './components/import-reference-codes/import-reference-codes-dialog';
import EditBatchDialogComponent from './components/edit-batch/edit-batch-dialog';
import DownloadCertificateDialogComponent from './components/download-certificate/download-certificate-dialog';
import useAuth from '../../context/useAuth';
import { CODE_STATUS_LIST, PERMISSIONS_V2 } from '../../utils/constants';
export type CouponDetailFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    status: string[];
    dates: string[];
  };
  order: [];
};

const CouponDetailPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const sortField = searchParams?.get('sortField') || 'code';
  const sortOrder = +(searchParams?.get('sortOrder') || 1);
  const filterStatus = (searchParams?.get('filterStatus') || CODE_STATUS_LIST.map(c => c.code).join(',')).split(',');
  const filterDates = searchParams?.get('filterDates') ? searchParams?.get('filterDates')?.split(',') || [] : [];
  const searchField = searchParams?.get('search') || '';

  const statusTypeAll = 'all';
  const [selectedStatusCoupon, setSelectedStatusCoupon] = useState<any>(filterStatus.length !== 1 ? statusTypeAll : filterStatus[0]);
  const [language] = useState('de');
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const toast = useRef(null);
  let { id } = useParams();
  const [couponData, setCouponData] = useState<any>(undefined);
  const [coupons, setCoupons] = useState({
    data: [],
    totalPage: 0,
  });
  const mapContainer = useRef<any>(null);
  const [geoMap, setGeoMap] = useState<any>(null);
  const [selectedCodes, setSelectedCodes] = useState<any>([]);
  const [selectedCode, setSelectedCode] = useState<any>();
  const [isShowActive, setIsShowActive] = useState(false);
  const [isHandleActive, setIsHandleActive] = useState(false);
  const [isShowDeactive, setIsShowDeactive] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [geoCoordinate, setGeoCoordinate] = useState<any>();
  const [isShowDownloadReceiptDialog, setIsShowDownloadReceiptDialog] = useState(false);
  const [isShowDownloadCertificateDialog, setIsShowDownloadCertificateDialog] = useState(false);
  const [isShowImportCSVDialog, setIsShowImportCSVDialog] = useState(false);
  const [isShowImportReferenceCodesDialog, setIsShowImportReferenceCodesDialog] = useState(false);
  const [isShowEditBatchDialog, setIsShowEditBatchDialog] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMap, setLoadingMap] = useState(false);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [filterValues, setFilterValues] = useState<CouponDetailFilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getObjectSearchValue(searchField) : [],
      status: filterStatus,
      dates: filterDates,
    },
    order: [],
  });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const { auth } = useAuth();

  const permissions = {
    canUpdateCode: auth?.permissions?.includes(PERMISSIONS_V2.CODE_UPDATE),
    canViewPartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_VIEW),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
    canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
    canViewDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_VIEW),
    canViewCampaign: auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_VIEW),
  };

  const fetchCouponDataHeader = async () => {
    setLoading(true);
    const resCouponDetail = await CouponDetailAPI.getCouponDetail(id || '');
    if (resCouponDetail && resCouponDetail.status === 200) {
      setCouponData(resCouponDetail.data);
      setLoading(false);
    }
  };

  const getCouponData = async () => {
    setLoading(true);
    const payload = getCodePayloadConditions(filterValues.where, sortConfig);
    const resCodes = await CouponDetailAPI.queryBatchCodes(id || '', {
      pageNo: page,
      range: rows,
      ...payload,
    });

    if (resCodes && resCodes.status === 200) {
      setCoupons({
        data: resCodes.data.records,
        totalPage: resCodes.data.total,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCouponDataHeader();
    getCouponData();
  }, []);

  useEffect(() => {
    if (couponData && couponData.geos && coupons && coupons.data) {
      setLoadingMap(true);
      const serials = coupons.data.map((c: any) => c.donation?.serial);
      const geoList = serials && serials.length > 0 ? couponData.geos.filter((g: any) => serials.includes(g.donation_serial)) : [];
      if (couponData.is_prepaid) {
        renderMap(couponData.geos.filter((g: any) => serials.includes(g.donation_serial)));
      } else {
        renderMap(couponData.geos.filter((g: any, i: number) => i < 500 || serials.includes(g.donation_serial)));
      }
    }
  }, [couponData, coupons]);

  const renderMap = (geos: any) => {
    if (!geos || !mapContainer?.current) {
      return;
    }

    const firstGeo = null || geos[0];
    const initialState = {
      lng: firstGeo?.longitude || -69.50186126019634,
      lat: firstGeo?.latitude || -12.890086055192254,
      zoom: 1,
    };

    const map = new Map({
      container: mapContainer?.current,
      center: [initialState.lng, initialState.lat],
      zoom: initialState.zoom,
      style: { ...createMap() },
      // style: mapStyle,
    });

    map.addControl(new maplibregl.NavigationControl({}), 'top-right');
    createMarker(map, geos);

    map.flyTo({
      center: [initialState.lng, initialState.lat],
      zoom: 20,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });

    setGeoMap(map);
    setLoadingMap(false);
  };

  useEffect(() => {
    const searchParams = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterStatus: filterValues.where.status?.join(',') || '',
      filterDates: filterValues.where.dates?.join(',') || '',
    };
    setSearchParams(_.omitBy(searchParams, p => !p));
    getCouponData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  useEffect(() => {
    if (typeof page === 'number') {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });
      setSearchParams({
        pageNumber: (page + 1).toString(),
        noRows: rows.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  useEffect(() => {
    if (couponData?.id) {
      getGeoCoordinate(couponData?.id);
    }
  }, [couponData]);

  const buildCodeBodyTemplate = (couponData: any) => (rowData: any) => {
    const code = `${(couponData?.partner && couponData?.partner.code ? couponData?.partner.code : 'WID') + '-' + rowData.code}`;
    const urlName = rowData?.status === 'Active' ? 'redeem' : 'message';
    return (
      <a target="_blank" href={`${env.DONATION_URL}/${urlName}?code=${code}`} className="btn-serial" rel="noreferrer">
        {code}
      </a>
    );
  };

  const buildReferenceBodyTemplate = (couponData: any) => (rowData: any) => {
    return (
      <a target="_blank" href={`${env.DONATION_URL}/message?code=${rowData.reference_code}`} className="btn-serial" rel="noreferrer">
        {rowData.reference_code}
      </a>
    );
  };

  const formatSerialBodyTemplate = (rowData: any) => {
    return permissions.canViewDonation && rowData.donation?.id ? (
      <a target="_blank" href={`/donations/${rowData.donation?.id}`} className="btn-serial" rel="noreferrer">
        {rowData.donation?.serial}
      </a>
    ) : (
      ''
    );
  };

  const navigateToDetail = (rowData: any, isOpenInNewTab = false) => {
    if (!isOpenInNewTab) {
      navigate(`/donations/${rowData.donation?.id}`);
      return;
    }

    window.open(`/donations/${rowData.donation?.id}`, '_blank');
  };

  const tableColumns = [
    {
      field: 'code',
      header: 'Code',
      minWidth: '20%',
      align: 'left',
      bodyTemPlate: buildCodeBodyTemplate(couponData),
    },
    {
      field: 'reference_code',
      header: 'Reference',
      minWidth: '20%',
      align: 'left',
      disabledSort: false,
      bodyTemPlate: buildReferenceBodyTemplate(couponData),
    },
    {
      field: 'text_2fa',
      header: '2FA',
      width: 'calc(20% - 110px)',
      minWidth: '200px',
      align: 'left',
      disabledSort: false,
    },
    {
      field: 'status',
      header: 'Status',
      minWidth: '10%',
      align: 'left',
      bodyTemPlate: buildCouponStatusColumnTemplate,
      disabledSort: true,
    },
    {
      field: 'donation.serial',
      header: 'Associated Donation',
      minWidth: '15%',
      align: 'left',
      bodyTemPlate: formatSerialBodyTemplate,
      disabledSort: true,
    },
    {
      field: 'updated_at',
      header: 'Updated Date',
      minWidth: '15%',
      align: 'left',
      bodyTemPlate: formatDateBodyTemplate('DD.MM.YYYY HH:mm'),
      disabledSort: false,
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width, minWidth: col.minWidth }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const onUpdateConfirm = async () => {
    if (id) {
      let res: any;
      let codes = [];
      codes = selectedCode ? [selectedCode] : selectedCodes;

      if (codes) {
        if (isHandleActive) {
          res = await CouponDetailAPI.activeBatchCodes(id || '', {
            is_all: isAll,
            selected_ids: codes.map((item: any) => item.id.toString()),
          });
        } else {
          res = await CouponDetailAPI.deactiveBatchCodes(id || '', {
            is_all: isAll,
            selected_ids: codes.map((item: any) => item.id.toString()),
          });
        }

        if (res && res.status === 200) {
          showNotification('success', 'Update status successfully', toast);
          await getCouponData();
        } else {
          showNotification('error', 'Update status failed', toast);
        }
      }

      setSelectedCodes([]);
      setSelectedCode(null);
      setIsShowActive(false);
      setIsShowDeactive(false);
      setIsShowDialog(false);
      setIsHandleActive(false);
      setIsAll(false);
    }
  };

  const mapGeoData = (data: any) => {
    let ojbData: any = {};
    ojbData.title = data?.campaign?.name?.de || data?.land?.region_name?.de;
    ojbData.subtitle = data?.campaign?.name.de ? 'im ' + data?.land?.region_name?.de : '';
    ojbData.area = Math.floor(+data.area * +data.no_valid_coupons);
    ojbData.name = data?.name;
    ojbData.lat = data.latitude;
    ojbData.lng = data.longitude;
    ojbData.date = data.created_at;
    ojbData.nw_geo_coordinate = data.nw_geo_coordinate;
    return ojbData;
  };

  const getGeoCoordinate = async (batchId: any) => {
    const res = await CouponDetailAPI.getGeoByBatchId(batchId);
    if (res && res.status === 200) {
      let geoData = mapGeoData({ ...couponData, ...res?.data });
      setGeoCoordinate(geoData);
    }
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const customMenuItemsDot = (item: any, options: any, requireSelectedValue = false, isRedStyle = false) => {
    return (
      <>
        <a
          className={
            requireSelectedValue && selectedCodes && selectedCodes.length === 0
              ? 'p-menuitem-link coupon-menuitem-3-dot code-menuitem-disable'
              : 'p-menuitem-link coupon-menuitem-3-dot'
          }
          role="menuitem"
          onClick={e => options.onClick(e)}
        >
          <span className={options.iconClassName}></span>
          <div className={`p-menuitem-containers ${isRedStyle ? 'p-menuitem-containers-red-style' : ''}`}>
            <span className={`${options.labelClassName} headline`}>{item.label}</span>
            {item.description && (
              <>
                <span className={`${options.labelClassName} description`}>{item.description}</span>
              </>
            )}
          </div>
        </a>
      </>
    );
  };

  const handleActivateSelected = async (rowData: any, is_all = false) => {
    if (rowData) {
      setSelectedCode(rowData);
    }

    setIsHandleActive(true);
    if (is_all) {
      setIsShowActive(true);
      setIsAll(true);
    } else {
      setIsShowDialog(true);
    }
  };

  const handleDeactivateSelected = async (rowData: any, is_all: any) => {
    if (rowData) {
      setSelectedCode(rowData);
    }

    setIsHandleActive(false);
    if (is_all) {
      setIsShowDeactive(true);
      setIsAll(true);
    } else {
      setIsShowDialog(true);
    }
  };

  const handleDownloadReceipt = () => {
    setIsShowDownloadReceiptDialog(true);
  };

  const handleDownloadCertificate = () => {
    setIsShowDownloadCertificateDialog(true);
  };

  const handleImportCSV = () => {
    setIsShowImportCSVDialog(true);
  };

  const handleImportReferenceCodes = () => {
    setIsShowImportReferenceCodesDialog(true);
  };

  const handleEditBatch = () => {
    setIsShowEditBatchDialog(true);
  };

  const buildActionMenu = (data: any) => {
    return [
      {
        label: 'Edit',
        description: 'Change certificate information and receipt owner',
        icon: 'pi pi-user-edit edit-icon ',
        command: (e: any) => handleEditBatch(),
        hidden: !permissions.canUpdateCode,
        template: (item: any, options: any) => customMenuItemsDot(item, options),
      },
      {
        label: 'Activate selected',
        description: 'Activate inactive selected codes',
        icon: 'fa-solid fa-toggle-on',
        hidden: !permissions.canUpdateCode,
        command: (e: any) => handleActivateSelected(null, false),
        template: (item: any, options: any) => customMenuItemsDot(item, options, true),
      },
      {
        label: 'Activate all',
        description: 'Activate all inactive and unredeemed codes',
        hidden: !permissions.canUpdateCode,
        icon: 'fa-solid fa-toggle-on',
        command: (e: any) => handleActivateSelected(null, true),
        template: (item: any, options: any) => customMenuItemsDot(item, options),
      },
      {
        label: 'Deactivate selected',
        description: 'Deactivate active selected codes',
        icon: 'fa-solid fa-toggle-off',
        hidden: !permissions.canUpdateCode,
        command: (e: any) => handleDeactivateSelected(null, false),
        template: (item: any, options: any) => customMenuItemsDot(item, options, true),
      },
      {
        label: 'Deactivate all',
        description: 'Deactivate all active selected codes',
        icon: 'fa-solid fa-toggle-off',
        hidden: !permissions.canUpdateCode,
        command: (e: any) => handleDeactivateSelected(null, true),
        template: (item: any, options: any) => customMenuItemsDot(item, options),
      },
      {
        label: 'Free ungiven codes',
        description: 'Set reserved coordinates free, invalidate the codes and remove associated donations',
        icon: 'fa-solid fa-eraser',
        hidden: !permissions.canUpdateCode,
        disabled: !data || data.is_prepaid || data.no_valid_coupons !== data.quantity,
        command: (e: any) => {
          handleImportCSV();
        },
        template: (item: any, options: any) => customMenuItemsDot(item, options, false, true),
      },
      // {
      //   label: "Download Receipt",
      //   description: "Download receipt for partner",
      //   icon: "fa-solid fa-file-invoice",
      //   //disabled: data && data.receipt_type !== 'partner',
      //   command: (e: any) => handleDownloadReceipt(),
      //   template: (item: any, options: any) => customMenuItemsDot(item, options),
      // },
      {
        label: 'Download Certificate',
        description: 'Download certificate for partner',
        icon: 'fa-solid fa-medal',
        command: (e: any) => handleDownloadCertificate(),
        template: (item: any, options: any) => customMenuItemsDot(item, options),
      },
      {
        label: 'Import references & 2FA',
        description: 'Update/Add references & 2FA for existing codes using CSV file',
        icon: 'fa-solid fa-file-import',
        hidden: !permissions.canUpdateCode,
        command: (e: any) => {
          handleImportReferenceCodes();
        },
        template: (item: any, options: any) => customMenuItemsDot(item, options, false, false),
      },
    ].filter(f => !f.disabled);
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: 'Open donation',
        description: 'Open the associated donation in the new tab',
        icon: 'fa-solid fa-arrow-up-right-from-square',
        command: (e: any) => navigateToDetail(rowData, true),
        hidden: !permissions.canViewDonation,
        template: (item: any, options: any) => customMenuItemsDot(item, options),
      },
      {
        label: 'Activate',
        description: 'Activate an inactive code in order to redeem',
        icon: 'fa-solid fa-toggle-on',
        command: (e: any) => handleActivateSelected(rowData, false),
        hidden: !permissions.canUpdateCode,
        template: (item: any, options: any) => customMenuItemsDot(item, options),
      },
      {
        label: 'Deactivate',
        description: 'Deactivate an active code in order to disable it from being redeemed',
        icon: 'fa-solid fa-toggle-off',
        command: (e: any) => handleDeactivateSelected(rowData, false),
        hidden: !permissions.canUpdateCode,
        template: (item: any, options: any) => customMenuItemsDot(item, options),
      },
    ];
    return <MenuDot className="coupon-detail-menu-dot" items={[...defaultActions]}></MenuDot>;
  };

  const onFilterChange = (values: any) => {
    const { field, value } = values;
    const where_conditions = getObjectWithValues(filterValues.where);
    if (field !== 'dates') {
      where_conditions[field] = value;
    } else {
      const dates = value.map((c: any) => moment(c).format('DD/MM/YYYY'));
      where_conditions[field] = dates;
      where_conditions.from = dates[0];
      where_conditions.to = dates[1];
    }
    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    if (selectedStatusCoupon === statusTypeAll) {
      p.where['status'] = [...CODE_STATUS_LIST.map(c => c.code)];
    } else {
      p.where['status'] = [selectedStatusCoupon];
    }
    p.search = '';
    setFilterValues(p);
  };

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions['or'] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  const displayCouponValueTemplate = (total: number, value: number) => () => {
    return (
      <React.Fragment>
        <>
          <span className="label-value">{`${value ? value?.toLocaleString('de') : 0}/${total ? total?.toLocaleString('de') : 0}`}</span>
        </>
      </React.Fragment>
    );
  };

  const navigateToMap = (data: any) => {
    if (data) {
      return `${env.CMS_URL || 'http://wild.appbisweb.com'}/map?id=${generateMapUrlForBatchCode(data)}`;
    }
    return '#';
  };

  return (
    <Layout>
      <div className="coupon-detail-content">
        <WIConfirmDialog
          visible={isShowActive}
          onHide={() => setIsShowActive(false)}
          onConfirm={onUpdateConfirm}
          hideLabel="Cancel"
          confirmLabel="Yes, do it"
          className="info-dialog"
          message={
            <Fragment>
              <span style={{ fontSize: '18px', fontWeight: '700' }}>
                Are you sure you want to <b style={{ color: '#13735f' }}>active</b> all codes?
              </span>
              <br />
              <span style={{ fontSize: '13px' }}>
                <b style={{ color: '#13735f' }}>Active</b> codes to allow donors can redeem codes.
              </span>
              <br />
              <span style={{ fontSize: '13px' }}>
                System will change all codes that status is <b style={{ color: '#646464' }}>Inactive</b> to <b style={{ color: '#13735f' }}>Active</b>
                .
              </span>
            </Fragment>
          }
          classIcon="fa-solid fa-info mr-3 dialog-icon"
        />
        <WIConfirmDialog
          visible={isShowDeactive}
          onHide={() => setIsShowDeactive(false)}
          onConfirm={onUpdateConfirm}
          hideLabel="Cancel"
          confirmLabel="Yes, do it"
          className="info-dialog"
          message={
            <Fragment>
              <span style={{ fontSize: '18px', fontWeight: '700' }}>
                Are you sure you want to <b style={{ color: '#EF4444' }}>deactive</b> all codes?
              </span>
              <br />
              <span style={{ fontSize: '13px' }}>
                <b style={{ color: '#EF4444' }}>Deactive</b> codes to block donors redeem codes.
              </span>
              <br />
              <span style={{ fontSize: '13px' }}>
                System will change all codes that status is <b style={{ color: '#13735f' }}>Active</b> to <b style={{ color: '#646464' }}>Inactive</b>
                .
              </span>
            </Fragment>
          }
          classIcon="fa-solid fa-info mr-3 dialog-icon"
        />
        <WIConfirmDialog
          visible={isShowDialog}
          onHide={() => setIsShowDialog(false)}
          onConfirm={() => onUpdateConfirm()}
          hideLabel="Cancel"
          confirmLabel="Yes, do it"
          className="info-dialog"
          message={
            <React.Fragment>
              Are you sure <b>update status</b> of selected codes to{' '}
              <b style={{ color: isHandleActive ? '#13735f' : '#646464' }}>{isHandleActive ? 'Active' : 'Inactive'}</b>?
              <br />
              <span style={{ fontSize: '13px' }}>
                System will change all codes that status is{' '}
                {isHandleActive ? (
                  <>
                    <b style={{ color: '#646464' }}>Inactive</b> to <b style={{ color: '#13735f' }}>Active</b>
                  </>
                ) : (
                  <>
                    <b style={{ color: '#13735f' }}>Active</b> to <b style={{ color: '#646464' }}>Inactive</b>
                  </>
                )}
                .
              </span>
            </React.Fragment>
          }
          classIcon="fa-solid fa-info"
        />
        <DownloadReceiptDialogComponent
          visible={isShowDownloadReceiptDialog}
          onHide={() => setIsShowDownloadReceiptDialog(false)}
          id={id}
          donation_money={couponData?.donation_money}
          quantity={couponData?.no_valid_coupons}
          currency_unit={couponData?.currency_unit}
        />
        <DownloadCertificateDialogComponent
          visible={isShowDownloadCertificateDialog}
          onHide={() => setIsShowDownloadCertificateDialog(false)}
          id={id}
          partner={couponData?.partner}
          occasion={couponData?.certificate_occasion}
          date={couponData?.created_at}
          land={couponData?.land}
          area={couponData?.area}
          currencyUnit={couponData?.currency_unit}
          quantity={couponData?.no_valid_coupons}
          geoCoordinate={geoCoordinate?.nw_geo_coordinate}
          name={couponData?.name}
          description={couponData?.certificate_description}
          certificate_logo={couponData?.certificate_logo}
        />
        <ImportCSVDialogComponent
          visible={isShowImportCSVDialog}
          onHide={() => setIsShowImportCSVDialog(false)}
          id={id}
          donation_money={couponData?.donation_money}
          quantity={couponData?.quantity}
          currency_unit={couponData?.currency_unit}
          getCouponData={getCouponData}
          fetchCouponDataHeader={fetchCouponDataHeader}
        />
        <ImportReferenceCodesDialogComponent
          visible={isShowImportReferenceCodesDialog}
          onHide={() => setIsShowImportReferenceCodesDialog(false)}
          id={id}
          getCouponData={getCouponData}
          fetchCouponDataHeader={fetchCouponDataHeader}
        />
        <EditBatchDialogComponent
          visible={isShowEditBatchDialog}
          onHide={() => setIsShowEditBatchDialog(false)}
          id={id}
          name={couponData?.name}
          certificate_occasion={couponData?.certificate_occasion}
          certificate_description={couponData?.certificate_description}
          description_2fa={couponData?.description_2fa}
          receipt_type={couponData?.receipt_type}
          fetchCouponDataHeader={fetchCouponDataHeader}
          isShow2FA={coupons.data[0] && coupons.data[0]['text_2fa'] && coupons.data[0]['text_2fa'] !== ''}
        />
        <div className="header-containers">
          {
            <div className="header-info">
              <div className="title-container">
                {' '}
                <h3>{couponData?.name}</h3>
              </div>
              <div className="content-container">
                <div className="header-info-section header-info-section-partner">
                  <p className="info">Partner</p>
                  {permissions.canViewPartner ? (
                    <a className="green-color" rel="" target="_blank" href={`/partners/${couponData?.partner?.uuid}`}>
                      {couponData?.partner?.name}
                    </a>
                  ) : (
                    couponData?.partner?.name
                  )}
                </div>
                {!!couponData?.campaign ? (
                  <div className="header-info-section header-info-section-area">
                    <p className="info">Campaign</p>
                    {permissions.canViewCampaign ? (
                      <a className="green-color" target="_blank" href={`/campaigns/${couponData?.campaign?.uuid}`}>
                        {couponData?.campaign?.name?.de ?? couponData?.campaign?.name}
                      </a>
                    ) : (
                      couponData?.campaign?.name
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div className="header-info-section header-info-section-area">
                  <p className="info">Area</p>
                  {permissions.canViewArea ? (
                    <a className="green-color" target="_blank" href={`/areas/${couponData?.land?.uuid}`}>
                      {couponData?.land?.name?.de ?? couponData?.land?.name}
                    </a>
                  ) : (
                    couponData?.land?.name
                  )}
                </div>
                <div className="header-info-section header-info-section-area">
                  <p className="info">Region</p>
                  {permissions.canViewRegion ? (
                    <a className="green-color" target="_blank" href={`/regions/${couponData?.land?.region_id}`}>
                      {couponData?.land?.region_name?.de ?? couponData?.land?.region_name}
                    </a>
                  ) : (
                    couponData?.land?.region_name
                  )}
                </div>
                <div className="header-info-section">
                  <p className="info">Quantity</p>
                  <p className="green-color">{(+couponData?.no_valid_coupons).toLocaleString('de')}</p>
                </div>
                <div className="header-info-section">
                  <p className="info">
                    Value (m<sup>2</sup>)
                  </p>
                  <p className="green-color">{(+couponData?.area).toLocaleString('de')}</p>
                </div>
                <div className="header-info-section">
                  <p className="info">Currency</p>
                  <p className="green-color">{couponData?.currency_unit.toUpperCase()}</p>
                </div>
                <div className="header-info-section header-info-section-date">
                  <p className="info">Geo-coordinate</p>
                  <a className="green-color" href={navigateToMap(geoCoordinate)} target="_blank">
                    {geoCoordinate?.nw_geo_coordinate || ''}
                  </a>
                </div>
                <div className="header-info-section header-info-section-status">
                  <p className="info">Pre-payment</p>
                  <p className="green-color">{couponData?.is_prepaid ? 'Yes' : 'No'}</p>
                </div>
                <div className="header-info-section">
                  <p className="info">Receipt Owner</p>
                  <p className="green-color">
                    {couponData?.receipt_type ? couponData?.receipt_type.charAt(0).toUpperCase() + couponData?.receipt_type.slice(1) : ''}
                  </p>
                </div>
              </div>
              <div className="progress-bar">
                <ProgressBar
                  value={(couponData?.no_used_coupons / couponData?.no_valid_coupons) * 100}
                  displayValueTemplate={displayCouponValueTemplate(couponData?.no_valid_coupons, couponData?.no_used_coupons)}
                ></ProgressBar>
              </div>
            </div>
          }

          {/* <div className="show-map-container">
            <div className="btn-show-map">
              <Button
                  type="button"
                  icon={isShowMap ? 'fa-solid fa-circle-minus' : 'fa-solid fa-circle-plus'}
                  label={isShowMap ? 'Hide map' : 'Show map'}
                  iconPos="right"
                  className="show-map-button"
                  onClick={() => setIsShowMap(!isShowMap)}
              />
            </div>
            <AnimateHeight
                duration={300}
                height={isShowMap ? `auto` : 0}
            >
              {loadingMap ? (
                <div className="loading-component">
                  <ProgressSpinner />
                </div>
                  ) : (
                    <></>
                  )}
                {
                  <div className="map-container" ref={mapContainer}></div>
                }
            </AnimateHeight>
          </div> */}

          <div className="coupon-header-panel" style={{ display: 'flex' }}>
            <CouponDetailFilterComponent
              lang={language}
              filterValues={filterValues}
              fields={['status', 'dates']}
              onChange={onFilterChange}
              onRemove={onFilterRemove}
              onSearchFunc={onSearchFunc}
            />
            <MenuButton items={[...buildActionMenu(couponData)]}></MenuButton>
          </div>
        </div>
        <div className="content-table">
          <div className="table-code">
            <DataTable
              loading={loading}
              value={coupons.data}
              scrollable
              dataKey="code"
              // scrollHeight="calc(100vh - 180px)"
              scrollDirection="both"
              lazy
              selectionMode="checkbox"
              selection={selectedCodes}
              onSelectionChange={e => {
                setSelectedCodes(e.value);
              }}
              onSort={(e: any) => {
                setSortConfig({
                  sortField: e.sortField,
                  sortOrder: e.sortOrder,
                });
              }}
              sortField={sortConfig.sortField}
              sortOrder={sortConfig.sortOrder}
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} align="center"></Column>
              {dynamicColumns}
              <Column frozen alignFrozen="right" style={{ width: '50px' }} body={buildMenu}></Column>
            </DataTable>
            <WIPaginator first={first} rows={rows} totalRecords={coupons.totalPage} onPageChange={onBasicPageChange} />
          </div>
        </div>
        <Toast ref={toast} />
      </div>
    </Layout>
  );
};

export default CouponDetailPage;
