import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { SelectButton } from 'primereact/selectbutton';
import './wi-searchTextfield.scss';
import * as _ from 'lodash';
import { getFormElement } from '../../components';

export function SearchTextField(props: any) {
  const urlOptions = [
    { name: 'Internal', value: 0 },
    { name: 'External', value: 1 },
  ];
  const defaultURL = '#';
  const lang = 'de';

  const { name, placeholder, values, disabled, maxLength, schema, search_content } = props;
  const { handleChange, handleBlur, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const [sources, setSources] = useState(search_content ? search_content[schema?.sourceConfiguration?.type] : []);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [originalURL, setOriginalURL] = useState<any>();
  const [urlOptionValue, setUrlOptionValue] = useState<any>();
  const [isInternalURL, setIsInternalURL] = useState(true);

  useEffect(() => {
    let originalURL = _.get(props.values, name);
    setOriginalURL(originalURL);

    let urlOptionValue =
      originalURL && !originalURL.page_id && (originalURL.de !== defaultURL || originalURL.en !== defaultURL)
        ? urlOptions[1].value
        : urlOptions[0].value;
    setUrlOptionValue(urlOptionValue);
    onChangeURLOption(urlOptionValue, originalURL);
  }, []);

  useEffect(() => {
    if (_.get(props.values, 'link.option')) {
      setUrlOptionValue(_.get(props.values, 'link.option'));
      setIsInternalURL(false);
    }
  }, [values]);

  const onChangeURLOption = (urlOptionValue: any, originalURL: any) => {
    setIsInternalURL(urlOptionValue === urlOptions[0].value);
    if (urlOptionValue === urlOptions[0].value) {
      if (selectedValue) {
        updateFieldValue(selectedValue);
      } else {
        setSelectedValue(originalURL?.page_id);
        updateFieldValue(originalURL?.page_id);
      }
      return;
    }

    let externalOriginalURL = !originalURL?.page_id ? originalURL : createEmptyURL();
    let url = { ...externalOriginalURL, page_id: '' };
    updateFormValueByName(name, url);
  };

  const updateFieldValue = (page_id: any) => {
    let source = sources?.find((f: any) => f.id === page_id);
    let url: any = source ? { ...source.url, page_id: source.id } : { ...createEmptyURL(), page_id: defaultURL };
    updateFormValueByName(name, url);
  };

  const updateFormValueByName = (name: any, urlObj: any) => {
    let url = { ...urlObj, global_content_id: urlObj.page_id };
    setFieldValue(name, url, false);
    setFieldTouched(name, true, false);
  };

  const createEmptyURL = () => {
    let url = _.reduce(
      schema.supportMultiLanguages.map((l: any) => l),
      function (pre: any, cur: any) {
        pre[cur] = defaultURL;
        return pre;
      },
      {},
    );
    return { ...url, page_id: '' };
  };

  const customItemTemplate = (option: any) => {
    let label = schema.sourceConfiguration.labelField || 'id';
    return (
      <div>
        <div>{_.get(option, label)}</div>
        <div className="page-item">
          /
          {option.nodes
            ?.map((n: any) => n.url)
            .filter((u: string) => !!u)
            .join('/')}
        </div>
      </div>
    );
  };

  const customValueTemplate = (option: any) => {
    let label = schema.sourceConfiguration.labelField || 'id';
    return isInternalURL ? <div className="global-item-label">{_.get(option, label)}</div> : <></>;
  };

  return (
    <div className="form-element">
      <div className="form-element-title">{schema.label}</div>
      <div style={{ marginLeft: -10 }}>
        <div className="apply-btn" style={{ display: 'flex', background: 'transparent' }}>
          <div className="select-group">
            {sources ? (
              <>
                <div style={{ marginRight: '10px' }}>
                  <SelectButton
                    hidden={disabled}
                    className="wi-form-select-button"
                    optionLabel="name"
                    value={urlOptionValue}
                    options={urlOptions}
                    onChange={(e: any) => {
                      setUrlOptionValue(e.value);
                      onChangeURLOption(e.value, originalURL);
                    }}
                    unselectable={false}
                  />
                </div>
                {
                  <Dropdown
                    disabled={!isInternalURL}
                    hidden={disabled}
                    value={selectedValue}
                    itemTemplate={customItemTemplate}
                    valueTemplate={customValueTemplate}
                    options={sources}
                    showClear={!!selectedValue && selectedValue !== defaultURL}
                    dropdownIcon={isInternalURL ? 'pi pi-chevron-down' : ''}
                    onChange={(item: any) => {
                      setSelectedValue(item?.value);
                      updateFieldValue(item?.value);
                    }}
                    optionLabel={schema.sourceConfiguration.labelField || 'id'}
                    optionValue="id"
                    filter
                    filterBy={schema.sourceConfiguration.searchFields?.join(',')}
                    filterPlaceholder={'Search'}
                  />
                }
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div style={{ margin: 0, padding: 0 }} key={`${name}-${lang}`} className="row form-row form-multilang">
          {getFormElement(
            `${name}.${lang}`,
            { ...schema, type: 'textField', label: '' },
            { ...props, parentKey: null, isGlobalContentRef: disabled || isInternalURL },
            lang,
          )}
        </div>
      </div>
    </div>
  );
}
