import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import './wi-videoUrlfield.scss';
import * as _ from 'lodash';
import flag from '../../../../assets/images/flags/flag_placeholder.png';

export function VideoUrlField(props: any) {
  const { name, placeholder, values, disabled, maxLength } = props;
  const { setFieldValue, handleBlur, errors, touched } = useFormikContext();
  const { relative } = props.schema;
  const { lang } = props;

  const getIDByUrl = (url: string): string => {
    const regex = new RegExp(
      '(http:|https:|)\\/\\/(player.|www.)?(vimeo\\.com|youtu(be\\.com|\\.be|be\\.googleapis\\.com|be\\-nocookie\\.com))\\/(video\\/|embed\\/|watch\\?\\S*v=|v\\/)?(\\&\\S+)?',
      'g',
    );
    return url.replace(regex, '');
  };

  const handlerChange = (e: any) => {
    const value = e.target.value;
    setFieldValue(name, value, false);
    if (relative && relative.name) {
      const id = getIDByUrl(value);
      if (typeof relative.value[lang] === 'string') {
        setFieldValue(`${relative.name}.${lang}`, id, false);
      } else if (typeof relative.value === 'string') {
        setFieldValue(`${relative.name}`, id, false);
      }
    }
  };

  const renderInput = () => {
    return (
      <input
        className={`${_.get(errors, name) && _.get(touched, name) ? 'has-error' : ''}`}
        name={name}
        id={name}
        value={_.get(values, name)}
        onChange={handlerChange}
        onBlur={handleBlur}
        placeholder={placeholder || ''}
        disabled={disabled}
        maxLength={maxLength}
      />
    );
  };

  return (
    <WIFormElement {...props}>
      {lang ? (
        <div className="p-inputgroup p-inputgroup-container">
          <span className="p-inputgroup-addon">
            {lang && <img src={flag} alt={lang || 'de'} className={`flag flag-${lang || 'de'}`} width={30} />}
          </span>
          {renderInput()}
        </div>
      ) : (
        renderInput()
      )}

      <ErrorMessage name={name} render={(msg: any) => <div className="error">{msg}</div>} />
    </WIFormElement>
  );
}
