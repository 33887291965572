import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import './wi-textfield.scss';
import * as _ from 'lodash';
import flag from '../../../../assets/images/flags/flag_placeholder.png';

export function TextField(props: any) {
  const { name, placeholder, values, disabled, maxLength } = props;
  const { handleChange, handleBlur, errors, touched } = useFormikContext();

  const renderInput = () => {
    return (
      <input
        className={`${_.get(errors, name) && _.get(touched, name) ? 'has-error' : ''}`}
        name={name}
        id={name}
        value={_.get(values, name)}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder || ''}
        disabled={disabled}
        maxLength={maxLength}
      />
    );
  };
  return (
    <WIFormElement {...props}>
      {props.lang ? (
        <div className="p-inputgroup p-inputgroup-container">
          <span className="p-inputgroup-addon">{props.lang && <img src={flag} className={`flag flag-${props.lang || 'de'}`} width={30} />}</span>
          {renderInput()}
        </div>
      ) : (
        renderInput()
      )}

      <ErrorMessage name={name} render={(msg: any) => <div className="error">{msg}</div>} />
    </WIFormElement>
  );
}
