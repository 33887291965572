import { AxiosClient } from './httpClient';

/**
 * A type alias of "Coupon Receipt Info"
 */
type CouponReceiptInfo = {
  name: string;
  country: string;
  city: string;
  address: string;
  postal_code: number;
  donation_date: string;
  receipt_date: string;
};

export default class CampaignCouponManagerAPI {
  static getCoupons(data: any) {
    return AxiosClient.post(`/coupons/search`, data);
  }

  static createCoupon(data: any) {
    return AxiosClient.post(`/coupons`, data);
  }

  static updateCoupon(id: any, data: any) {
    return AxiosClient.put(`/coupons/${id}`, data);
  }

  static activeCoupon(id: any) {
    return AxiosClient.put(`/coupons/${id}/activate`);
  }
  static deactiveCoupon(id: any) {
    return AxiosClient.put(`/coupons/${id}/deactivate`);
  }

  /**
   * Download Coupon Receipt
   * @param couponId: Coupon ID (Campaign Coupon)
   * @param receiptInfo: Receipt Info inputted by user
   * @returns A promise of generating Coupon Receipt
   */
  static downloadCouponReceipt(couponId: string, receiptInfo: CouponReceiptInfo) {
    return AxiosClient.post(`/coupons/${couponId}/receipt`, receiptInfo);
  }
}
