import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import * as React from 'react';
import { useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { FAQsManagerAPI } from '../../../../services';
import DynamicForm from '../../../dynamic-form/dynamic-form';
import { FootersBlock } from '../utils';
import { showNotification } from '../../../../utils/logic';
import './footers-dialog-component.scss';
import { FOOTERS } from '../../../../utils/utils';

const FootersDialogComponent = (props: any) => {
  const { footer, fetchFootersData, onHide, globalContent } = props;
  const toast = useRef(null);
  const block = { ...FootersBlock };

  const generateFooters = async (parameters: any) => {
    return await FAQsManagerAPI.generateFooters(parameters);
  };

  const updateFooters = async (parameters: any) => {
    return await FAQsManagerAPI.updateFooters(parameters);
  };

  const requestFootersData = async (value: any) => {
    // @ts-ignore: Object is possibly 'null'.
    //const formikValues = formikRef?.current.values;

    if (footer) {
      // @ts-ignore: Object is possibly 'null'.
      const obj = footer;
      let request = { ...obj, parameters: JSON.stringify(value) };
      return updateFooters({
        ...request,
      });
    }
    return generateFooters({
      ...value,
    });
  };

  const handleRequestFooters = (resGenerateFooters: AxiosResponse<any, any>) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateFooters;
    let method = response.config.method;
    if (response && response.status === 200 && (method === 'post' || method === 'put')) {
      let mesage = method === 'post' ? 'Generate footers successfully' : 'Update footers successfully';
      showNotification('success', mesage, toast);
      onHide();
      fetchFootersData();
    } else {
      showNotification('error', 'Failed to generate footers', toast);
    }
  };

  const onSubmitDataForm = async (value: any) => {
    try {
      const resGenerateFooters = await requestFootersData(value);
      handleRequestFooters(resGenerateFooters);
    } catch (error) {
      showNotification('error', 'Failed to generate footers', toast);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="footer-dynamic">
        <h3>{footer?.name?.de || 'Footer'}</h3>
        <DynamicForm
          formSchema={footer ? JSON.parse(footer.configuration) : block?.configuration}
          data={footer ? JSON.parse(footer.parameters) : block?.parameters}
          submitButtonConfig={{
            title: footer?.uuid ? 'Update' : 'Create',
            icon: 'pi pi-chevron-right',
            iconPos: 'right',
          }}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
          globalContent={globalContent}
        ></DynamicForm>
      </div>
    </>
  );
};

export default FootersDialogComponent;
