import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import React from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './generate-receipt-donation-dialog.scss';
import { onDownloadMultipleReceipt } from '../../../../utils/donation.util';
import { LANGUAGE_CODES } from '../../../../utils/constants';

const GenerateReceiptDonationDialogComponent = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { visible, onHide, selectedDonations } = props;

  const toast = useRef(null);
  const [languageCode, setLanguageCode] = useState(LANGUAGE_CODES.GERMAN);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Download ${selectedDonations ? selectedDonations.length : ''}  Receipt${selectedDonations && selectedDonations.length > 1 ? 's' : ''}`}
        visible={visible}
        style={{ width: '400px' }}
        modal
        className="wi-dialog"
        onHide={onHide}
      >
        <div className="dialog-receipt-container">
          <div className="row"></div>

          <div className="btn-submit-container">
            <Button
              type="submit"
              label={'Download'}
              icon="pi pi-download"
              iconPos="right"
              loading={isLoading}
              className="p-button-rounded btn-submit wi-main-button"
              onClick={e => {
                setIsLoading(true);
                const ids = selectedDonations.map((donation: any) => donation.uuid);
                onDownloadMultipleReceipt(ids, languageCode).then(() => {
                  setIsLoading(false);
                });
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default GenerateReceiptDonationDialogComponent;
