/**
 * API Manager for "Donation Coupon(s)"
 */

import { AxiosClient } from './httpClient';

export default class DonationCouponManagerAPI {
  static getCouponsByDonationId(donationId: string) {
    return AxiosClient.get(`/donation-coupons/donation/${donationId}`);
  }
}
