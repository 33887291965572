/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './sidebar-menu.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import left from '../../assets/images/icons/chevrons-left.svg';
import { buildMenuItems, HOMEPAGE_URL, PARTNER_TYPES, PERMISSIONS_V2, USER_PARTIES, isProduction, USER_TYPES } from '../../utils/constants';
import useAuth from '../../context/useAuth';
import { getUserType } from '../../utils/logic';

const MenuChildrenTemplate = (props: any) => {
  const { children, t, isExpand, onCollapseMenu } = props;
  const path_url = window ? window.location.pathname : '';

  const isMatched = (item_menu: any) => {
    return path_url === item_menu.url || (item_menu.url !== '/' && path_url.includes(item_menu.url));
  };

  return (
    <>
      <ul className={`dropdown-menuitem ${isExpand ? 'show' : 'hidden'} m-0 pl-12 pr-12 `}>
        <div className="dropdown-menuitem-content">
          {children
            .filter((m: any) => !m.hidden)
            .map((item: any, index: number) => (
              <div key={`dropdown-menuitem-${index}`} className={`p-menuitem ${isMatched(item) && 'active-menu'}`} style={{ minWidth: '240px' }}>
                <Link to={item.url} className="p-menuitem-link" role="menuitem">
                  <div className="icon-content">{item.icon}</div>
                  <div className="p-menuitem-text ml-12">{t(item.label)}</div>
                </Link>
              </div>
            ))}
        </div>
        {isExpand && (
          <div className="collapse-content">
            <div className="collapse-menu" onClick={() => onCollapseMenu(false)}>
              <img src={left} alt="menu-icon" className="collapse-icon p-10 m-0" />
            </div>
          </div>
        )}
      </ul>
    </>
  );
};

const MenuItem = (props: any) => {
  const { item_menu, index, t } = props;
  const [isExpand, setIsExpand] = useState(false);
  const path_url = window ? window.location.pathname : '';
  const isMatched = path_url === item_menu.url || (item_menu.url !== '/' && path_url.includes(item_menu.url));

  const onCollapseMenu = (isCollapse: boolean) => {
    setIsExpand((prev: any) => isCollapse);
  };

  return (
    <div className={`p-menuitem ${item_menu?.className} ${isMatched && 'active-menu'}`} key={index} onClick={() => onCollapseMenu(!isExpand)}>
      {item_menu.items ? (
        <>
          <a className="p-menuitem-link" role="menuitem" aria-haspopup="menu" aria-expanded={isExpand ? 'true' : 'false'}>
            <div className="icon-content">{item_menu.icon}</div>
            <div className="p-menuitem-text ml-12">{t(item_menu.label)}</div>
          </a>
          <MenuChildrenTemplate children={item_menu.items} t={t} isExpand={isExpand} onCollapseMenu={onCollapseMenu} />
        </>
      ) : (
        <Link to={item_menu.url} className="p-menuitem-link" role="menuitem">
          <div className="icon-content">{item_menu.icon}</div>
          <div className="p-menuitem-text ml-12">{t(item_menu.label)}</div>
        </Link>
      )}
    </div>
  );
};

const Menubar = (props: any) => {
  const { auth } = useAuth();
  const isWIEmployee = getUserType(auth) === USER_TYPES.WIEmployee ? true : false;
  const permissions = {
    canViewDashboard: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.STATISTIC_VIEW),
    canViewUsers: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.USER_VIEW),
    canViewDonations: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.DONATION_VIEW),
    canViewDonors: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.DONOR_VIEW),
    canViewSubscriptions: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.SUBSCRIPTION_VIEW),
    canViewRegions: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
    canViewAreas: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
    canViewPartners: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_VIEW),
    canViewCampaigns: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_VIEW),
    canViewCodes: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.CODE_VIEW),
    canViewCMSPages: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_VIEW) && auth?.partner?.type !== PARTNER_TYPES.FUNDRAISER,
    canViewMedia: auth?.permissions?.includes(PERMISSIONS_V2.MEDIA_VIEW),
    canViewGoals: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.GOAL_VIEW),
    canViewCoupons: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.COUPON_VIEW),
    canViewUtilities: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.UTILITY_VIEW),
    canViewGlobalContents: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_VIEW),
    canViewGlobalBlocks: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_BLOCK_VIEW),
    canViewMatchFundings: isWIEmployee && auth?.permissions?.includes(PERMISSIONS_V2.MATCH_FUNDING_VIEW),
  };

  const { t } = useTranslation('language', { keyPrefix: 'side_bar_menu' });
  const menuItems = buildMenuItems(permissions, auth);

  return (
    <div className="sidebar-menu">
      <div className={`sidebar-menu-content`}>
        <div className="sidebar-menu-container pt-40 pb-40">
          <div className="header-menu mb-32">
            <a className="logo" href={HOMEPAGE_URL}>
              <div className={`logo-menu m-0 ${isProduction() ? 'logo-menu-prod' : 'logo-menu-none-prod'}`}></div>
            </a>
          </div>

          <div className="content-menu pl-24 pr-24">
            {menuItems
              .filter((m: any) => !m.hidden)
              .map((item: any, index: number) => (
                <MenuItem key={index} item_menu={item} index={index} t={t} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menubar;
