import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import React from 'react';
import './wilderness-run-details.scss';
import { MenuDot, WIPaginator, WISearchField } from '../common';
import MenuButton from '../common/menu-button/menu-button';
import Layout from '../layout/layout';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { LandManagerAPI, PartnerManagerAPI, WildernessRunsManagerAPI } from '../../services';
import WITagWIR from './components/tag-wir/tag-wir';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import _ from 'lodash';
import AddWildernessFormComponent from '../wilderness-run-manager/components/add-wilderness-run-manager/add-wilderness-run-form';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';
import { COMMON_STATUS } from '../../utils/utils';
import DownloadTeamsRunnersDialog from './components/download-teams-runners-dialog/download-teams-runners-dialog';
import moment from 'moment';
import DownloadDonorDialog from './components/download-donor-dialog/download-donor-dialog';
import { generateFileDownload, showNotification } from '../../utils/logic';
import ImportDonorDetail from './components/import-donor-details/import-donor-details';
import { env } from '../../configs';
import ImportTeamsRunnersDetail from './components/import-teams-runners-details/import-teams-runners-details';
import ConfirmPasswordDialog from '../common/confirm-password-dialog/confirm-password-dialog';
import { PASSWORD_SENDMAIL } from '../../utils/constants';

const WildernessRunDetails = (props: any) => {
  const wildernessRunID = window.location.pathname ? window.location.pathname.split('/')[2] : '';
  const [wildernessData, setWildernessData] = useState<any>();
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const toast = useRef(null);
  const [partners, setPartners] = useState<any[]>([]);
  const [lands, setLands] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [teams, setTeams] = useState({
    data: [],
    totalPage: 5,
  });
  const [isShowDownloadTeams, setIsShowDownloadTeams] = useState(false);
  const [isShowImportDonor, setIsShowImportDonor] = useState(false);
  const [paginator, setPaginator] = useState({
    page: 0,
    first: 0,
    rows: 10,
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: 'name',
    sortOrder: -1,
  });
  const [valueSearch, setValueSearch] = useState('');
  const [donorImport, setDonorImport] = useState<any>(null);
  const [teamsRunners, setTeamsRunners] = useState<any>(null);
  const [labelFileDonor, setLabelFileDonor] = useState(null);
  const [labelFileTeams, setLabelFileTeams] = useState(null);
  const [isShowPasswordDialog, setIsShowPasswordDialog] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState(false);
  const [sendMail, setSendMail] = useState({
    donor: false,
    team: false,
  });

  const fetchAreasData = async () => {
    const [landsRes, partnersRes] = await Promise.all([LandManagerAPI.getAllLands(), PartnerManagerAPI.getAllPartners()]);

    // @ts-ignore: Object is possibly 'null'.
    if (landsRes && landsRes.status === 200 && landsRes.data.records?.length > 0) {
      const lands = landsRes.data.records?.filter((f: any) => f.status === COMMON_STATUS.ACTIVE);
      setLands(_.sortBy(lands, item => item.name?.de?.toLowerCase()));
    }

    if (partnersRes.status === 200 && partnersRes.data.records.length > 0) {
      setPartners(_.sortBy(partnersRes.data.records, item => item.name?.de?.toLowerCase()));
    }
  };

  const fetchWildernessRunDetails = async () => {
    setIsLoading(true);
    try {
      const res = await WildernessRunsManagerAPI.getWildernessRunDetails(wildernessRunID);
      if (res && res.status === 200) {
        setWildernessData(res.data.record || null);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchTeamsData = async () => {
    setIsLoading(true);
    const valuesFilter = {
      pageNo: paginator.page,
      range: paginator.rows,
      where: {
        search_name: valueSearch,
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === 1 ? 'DESC' : 'ASC'],
    };
    try {
      const resTeams = await WildernessRunsManagerAPI.getTeams(wildernessRunID, valuesFilter);
      if (resTeams && resTeams.status === 200) {
        setTeams({
          data: resTeams.data.records,
          totalPage: resTeams.data.total || 0,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setTeams({
        data: [],
        totalPage: 0,
      });
      setIsLoading(false);
    }
  };

  const onImportDonors = async (value: string) => {
    setIsLoading(true);
    try {
      const res = await WildernessRunsManagerAPI.importSponsors(wildernessRunID, value);
      if (res && res.status === 200) {
        const errorRecordsFormat = res.data.invalidRecords.map((item: any, index: number) => {
          return {
            key: `${index}`,
            data: {
              name: item.no,
              message: '',
            },
            children: (_.isArray(item.errors) ? item.errors : [])?.map((errorItem: any, indexError: number) => {
              return {
                key: `${index}-${indexError}`,
                data: {
                  name: errorItem.instancePath,
                  message: errorItem.message,
                },
              };
            }),
          };
        });
        setDonorImport({
          validRecords: res.data.validRecords,
          invalidRecords: errorRecordsFormat,
        });
        fetchWildernessRunDetails();
        fetchTeamsData();
        showNotification('success', 'Import donors successfully.', toast);
      } else {
        showNotification('error', 'Import donors failed.', toast);
      }
      setIsShowImportDonor(false);
      setIsLoading(false);
    } catch (error) {
      setIsShowImportDonor(false);
      setIsLoading(false);
    }
  };

  const onImportTeamsRunners = async (value: string) => {
    setIsLoading(true);
    try {
      const res = await WildernessRunsManagerAPI.importTeamsRunners(wildernessRunID, value);
      if (res && res.status === 200) {
        const errorRecordsFormat = res.data.invalidRecords.map((item: any, index: number) => {
          return {
            key: `${index}`,
            data: {
              name: item.no,
              message: '',
            },
            children: (_.isArray(item.errors) ? item.errors : [])?.map((errorItem: any, indexError: number) => {
              return {
                key: `${index}-${indexError}`,
                data: {
                  name: errorItem.instancePath,
                  message: errorItem.message,
                },
              };
            }),
          };
        });
        setTeamsRunners({
          validRecords: res.data.validRecords,
          invalidRecords: errorRecordsFormat,
        });
        fetchWildernessRunDetails();
        fetchTeamsData();
        showNotification('success', 'Import teams and runners successfully.', toast);
      } else {
        showNotification('error', 'Import teams and runners failed.', toast);
      }
      setIsShowDownloadTeams(false);
      setIsLoading(false);
    } catch (error) {
      setIsShowDownloadTeams(false);
      setIsLoading(false);
    }
  };

  const downloadTeamCertificate = async (data: any) => {
    setIsLoading(true);
    try {
      const res = await WildernessRunsManagerAPI.downloadTeamCertificate(wildernessRunID, data.id);
      if (res && res.status === 200) {
        const default_file_name = data?.name
          ?.trim()
          .toLowerCase()
          .replace(/(^|\s)\S/g, (l: any) => l.toUpperCase())
          .replace(/ /g, '-');
        generateFileDownload(`Wildnislauf_Team_Urkunde_${default_file_name}.pdf`, res.data.content);
        showNotification('success', 'Download certificate successfully.', toast);
      } else {
        showNotification('error', 'Download certificate failed.', toast);
      }
      setIsLoading(false);
    } catch (error) {
      showNotification('error', 'Download certificate failed.', toast);
      setIsLoading(false);
    }
  };

  const downloadAllTeamCertificates = async () => {
    setIsLoading(true);
    try {
      const res = await WildernessRunsManagerAPI.downloadAllTeamCertificate(wildernessRunID);
      if (res && res.status === 200) {
        const default_file_name = wildernessData?.name
          ?.trim()
          .toLowerCase()
          .replace(/(^|\s)\S/g, (l: any) => l.toUpperCase())
          .replace(/ /g, '-');
        generateFileDownload(`Wildnislauf_Laeufer_Urkunden_${default_file_name}.zip`, res.data.base64Zip);
        showNotification('success', 'Download all team certificates successfully.', toast);
      } else {
        showNotification('error', 'Download certificates failed.', toast);
      }
      setIsLoading(false);
    } catch (error) {
      showNotification('error', 'Download certificates failed.', toast);
      setIsLoading(false);
    }
  };

  const sendDonorCertificates = async () => {
    if (password === PASSWORD_SENDMAIL) {
      setErrorPassword(false);
      setIsLoading(true);
      try {
        const resSend = await WildernessRunsManagerAPI.sendDonorCertificates(wildernessRunID);
        if (resSend && resSend.status === 200) {
          showNotification('success', 'Sent mail successfully', toast);
        }
        setIsLoading(false);
      } catch (error) {
        showNotification('error', 'Failed to send mail', toast);
        setIsLoading(false);
        return;
      }
      onHideConfirmDialog();
    } else {
      setErrorPassword(true);
    }
  };

  const sendAllTeamCertificates = async () => {
    if (password === PASSWORD_SENDMAIL) {
      setErrorPassword(false);
      setIsLoading(true);
      try {
        const resSend = await WildernessRunsManagerAPI.sendAllTeamCertificates(wildernessRunID);
        if (resSend && resSend.status === 200) {
          fetchWildernessRunDetails();
          showNotification('success', 'Sent mail successfully', toast);
        }
        setIsLoading(false);
      } catch (error) {
        showNotification('error', 'Failed to send mail', toast);
        setIsLoading(false);
        return;
      }
      onHideConfirmDialog();
    } else {
      setErrorPassword(true);
    }
  };

  const onHideConfirmDialog = () => {
    setPassword('');
    setIsShowPasswordDialog(false);
    setErrorPassword(false);
    setSendMail({
      team: false,
      donor: false,
    });
  };

  useEffect(() => {
    if (typeof paginator.page === 'number') {
      fetchTeamsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator, sortConfig]);

  useLayoutEffect(() => {
    fetchWildernessRunDetails();
    fetchTeamsData();
    fetchAreasData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
  };

  const onSearchFunc = () => {
    fetchTeamsData();
  };

  const actionItems: any = [
    {
      label: 'Edit Wilderness Run',
      icon: 'fa-solid fa-pen',
      description: 'Import Runners and Teams',
      command: () => setIsShowSidebar(true),
    },
    {
      label: 'Import Teams And Runners',
      icon: 'fa-solid fa-file-import',
      description: 'Import Teams and Runners using CSV file',
      command: () => setIsShowDownloadTeams(true),
    },
    {
      label: 'Import Donors',
      icon: 'fa-solid fa-file-import',
      description: 'Import Donors and their donations using CSV file',
      command: () => setIsShowImportDonor(true),
    },
    {
      label: 'Download All Team Certificates',
      icon: 'fa-solid fa-download',
      description: 'Download all team certificates as zipped PDFs',
      command: () => downloadAllTeamCertificates(),
    },
    {
      label: 'Send All Team Certificates',
      icon: 'fa-solid fa-paper-plane',
      description: 'Send all team certificates to all team leaders via emails',
      // disabled: (wildernessData?.teams_certificate_sent_at),
      command: () => {
        setIsShowPasswordDialog(true);
        setSendMail({
          team: true,
          donor: false,
        });
      },
    },
    {
      label: 'Send Donor Certificates',
      icon: 'fa-solid fa-paper-plane',
      description: 'Send the certificate to all Donors via emails',
      command: () => {
        setIsShowPasswordDialog(true);
        setSendMail({
          team: false,
          donor: true,
        });
      },
    },
  ];

  const buildActionMenu = () => {
    return actionItems;
  };

  const formatAmountBodyTemplate = (rowData: any, field: string) => {
    return (
      <div>
        <span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>
          {rowData[field] || rowData[field] === 0 ? parseFloat(rowData[field]?.toString())?.toLocaleString('de-DE') : '-'}
        </span>
      </div>
    );
  };

  const formDate = (value: any) => {
    if (value) {
      const date = moment(new Date(value), 'DD.MM.YYYY HH:mm:ss').utc().add(1, 'hour').format('DD.MM.YYYY HH:mm:ss');
      return date.replace('01:00:00', '00:00:00');
    }
    return '-';
  };

  const tableColumns = [
    {
      field: 'name',
      header: 'Name',
      width: 'calc(25% - 50px)',
      align: 'left',
      bodyTemPlate: (rowData: any, column: any) => {
        return (
          <Link onClick={e => e.stopPropagation()} className="table-ref-link-cell" to={`/wilderness-runs/${wildernessRunID}/teams/${rowData.id}`}>
            {rowData.name}
          </Link>
        );
      },
    },
    {
      field: 'total_runners',
      header: 'Runners',
      width: '15%',
      align: 'left',
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'total_runners'),
    },
    {
      field: 'total_sponsors',
      header: 'Sponsors',
      width: '15%',
      align: 'left',
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'total_sponsors'),
    },
    {
      field: 'total_archived_units',
      header: 'Achieved Units',
      width: '15%',
      align: 'left',
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'total_archived_units'),
    },
    {
      field: 'expected_paid_money',
      header: 'EA (€)',
      width: '15%',
      align: 'left',
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'expected_paid_money'),
    },
    {
      field: 'actual_paid_money',
      header: 'APA (€)',
      width: '15%',
      align: 'left',
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'actual_paid_money'),
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: 'Open',
        description: 'Open And Edit Team Details',
        icon: 'fa-solid fa-arrow-up-right-from-square',
        url: `/wilderness-runs/${wildernessRunID}/teams/${rowData.id}`,
      },
      {
        label: 'Download Team Certificate',
        description: 'Download Runner certificates as PDF',
        icon: 'fa-solid fa-download',
        command: () => downloadTeamCertificate(rowData),
      },
      // {
      //     label: "Send Team Certificate",
      //     description: "Send Team certificate to the team leader via email",
      //     icon: "fa-solid fa-paper-plane",
      //     disabled: (!rowData.actual_paid_money)
      // },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout>
      <Toast ref={toast} />
      {isLoading && (
        <div className="loading-component">
          <ProgressSpinner />
        </div>
      )}
      <div className="wir-detail-page">
        <div className="wir-detail-container">
          <div className="wir-detail-header">
            <div className="text">
              <p className="name"> {wildernessData?.name || '-'} </p>
              <div className="status-amount">
                {' '}
                <WITagWIR value={wildernessData?.status || 'new'}></WITagWIR>
              </div>
            </div>
            <div className="actions">
              <MenuButton label="Actions" items={buildActionMenu()}></MenuButton>
            </div>
          </div>
          {donorImport || teamsRunners ? (
            donorImport ? (
              <ImportDonorDetail data={donorImport} onHide={() => setDonorImport(null)} />
            ) : (
              <ImportTeamsRunnersDetail data={teamsRunners} onHide={() => setTeamsRunners(null)} />
            )
          ) : (
            <>
              <div className="partner-info">
                <div className="partner-info-container">
                  <div className="headline">
                    <label className="label">Partner Information</label>
                    <div className="partner-content">
                      <p className="text"> {wildernessData?.partner_name || '-'} </p>
                      <div className="info">
                        <p className="name"> {wildernessData?.contact_person_name || '-'} </p>
                        <p className="email"> {wildernessData?.contact_person_email || '-'} </p>
                      </div>
                    </div>
                  </div>
                  {wildernessData && wildernessData?.logo && (
                    <div className="logo-wi-run">
                      <img src={`${env.PUBLIC_UPLOAD_URL}/${wildernessData?.logo}`} alt="" className="logo-wi-run-img" />
                    </div>
                  )}
                </div>
              </div>
              <div className="wir-info">
                <div className="wir-info-container">
                  <div className="headline">
                    <label className="label">Wilderness Run Information</label>
                  </div>
                  <div className="content">
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Teams</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {wildernessData?.total_teams ? parseFloat(wildernessData?.total_teams?.toString())?.toLocaleString('de-DE') : '-'}
                        </p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Runners</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {wildernessData?.total_runners ? parseFloat(wildernessData?.total_runners?.toString())?.toLocaleString('de-DE') : '-'}
                        </p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Sponsors</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {wildernessData?.total_sponsors ? parseFloat(wildernessData?.total_sponsors?.toString())?.toLocaleString('de-DE') : '-'}
                        </p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Achieved </label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {wildernessData?.total_archived_units
                            ? `${parseFloat(wildernessData?.total_archived_units?.toString())?.toLocaleString('de')} units - ${parseFloat(((wildernessData?.total_archived_units * wildernessData?.unit_length) / 1000)?.toString())?.toLocaleString('de-DE')} km`
                            : '-'}
                        </p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Unit length</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {wildernessData?.unit_length ? parseFloat(wildernessData?.unit_length?.toString())?.toLocaleString('de') : '-'} m
                        </p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Area</label>
                      </div>
                      <div className="col-md-9">
                        {wildernessData?.land_id ? (
                          <p className="text">
                            <Link to={`/areas/${wildernessData?.land_id}` || '#'} target="_blank">
                              {wildernessData?.land_name?.de || ''}{' '}
                            </Link>
                          </p>
                        ) : (
                          <p className="text">-</p>
                        )}
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Expected amount (EA)</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {wildernessData?.expected_paid_money
                            ? `${parseFloat(wildernessData?.expected_paid_money?.toString())?.toLocaleString('de')} €`
                            : '-'}
                        </p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Actual paid amount (APA)</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {wildernessData?.actual_paid_money
                            ? `${parseFloat(wildernessData?.actual_paid_money?.toString())?.toLocaleString('de')} €`
                            : '-'}{' '}
                        </p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Location</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">{wildernessData?.partner_name || '-'}</p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Certificate occasion</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">{wildernessData?.certificate_occasion || '-'}</p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">Date</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {wildernessData?.run_date
                            ? moment(new Date(wildernessData?.run_date), 'DD.MM.YYYY').utc().add(1, 'hour').format('DD.MM.YYYY')
                            : '-'}
                        </p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">WIR certificate sent</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">{wildernessData?.wir_certificate_sent_at ? formDate(wildernessData?.wir_certificate_sent_at) : '-'}</p>
                      </div>
                    </div>
                    <div className="row content-row">
                      <div className="col-md-3">
                        <label className="label">All Team certificates sent</label>
                      </div>
                      <div className="col-md-9">
                        <p className="text">
                          {' '}
                          {wildernessData?.teams_certificate_sent_at ? formDate(wildernessData?.teams_certificate_sent_at) : '-'}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="teams-info">
                <div className="teams-info-container">
                  <div className="headline">
                    <label className="label">Teams</label>
                    <div className="wir-header-panel">
                      <WISearchField
                        icon={'pi pi-search'}
                        placeholder="Search for anything"
                        setSearch={(value: any) => setValueSearch(value.global.value)}
                        enterSearch={() => onSearchFunc()}
                        globalValue={valueSearch}
                      />
                    </div>
                  </div>
                  <div className="teams-table">
                    <DataTable
                      value={teams.data}
                      scrollable
                      dataKey="id"
                      scrollHeight="calc(100vh - 166px)"
                      scrollDirection="both"
                      lazy
                      onSort={(e: any) => {
                        setSortConfig({
                          sortField: e.sortField,
                          sortOrder: e.sortOrder,
                        });
                      }}
                      sortField={sortConfig.sortField}
                      sortOrder={sortConfig.sortOrder}
                    >
                      {dynamicColumns}
                      <Column frozen alignFrozen="right" style={{ width: '50px' }} body={buildMenu}></Column>
                    </DataTable>
                    <WIPaginator first={paginator.first} rows={paginator.rows} totalRecords={teams.totalPage} onPageChange={onBasicPageChange} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <DownloadTeamsRunnersDialog
          visible={isShowDownloadTeams}
          onHide={() => {
            setLabelFileTeams(null);
            setIsShowDownloadTeams(false);
          }}
          onSubmit={(value: string) => onImportTeamsRunners(value)}
          isLoading={isLoading}
          labelFileTeams={labelFileTeams}
          setLabelFileTeams={setLabelFileTeams}
        />
        <DownloadDonorDialog
          visible={isShowImportDonor}
          onHide={() => {
            setLabelFileDonor(null);
            setIsShowImportDonor(false);
          }}
          onSubmit={(value: string) => onImportDonors(value)}
          isLoading={isLoading}
          labelFileDonor={labelFileDonor}
          setLabelFileDonor={setLabelFileDonor}
        />
        <Sidebar
          visible={isShowSidebar}
          position="right"
          className="p-sidebar-md add-donor-sibar-right"
          style={{ width: '30em' }}
          onHide={() => setIsShowSidebar(false)}
        >
          <div className="add-donor-sidebar-content">
            <div className="headline">
              <h6>Update Wilderness Run</h6>
            </div>
            <AddWildernessFormComponent
              lands={lands}
              fetchCallBack={() => fetchWildernessRunDetails()}
              toast={toast}
              onHide={() => setIsShowSidebar(false)}
              wildernessRunData={wildernessData}
              partners={partners}
            />
          </div>
        </Sidebar>
        <ConfirmPasswordDialog
          visible={isShowPasswordDialog}
          onHide={() => onHideConfirmDialog()}
          onConfirm={() => {
            if (sendMail.donor) {
              sendDonorCertificates();
            } else {
              sendAllTeamCertificates();
            }
          }}
          password={password}
          onSetPassword={(value: any) => setPassword(value)}
          errorPassword={errorPassword}
        />
      </div>
    </Layout>
  );
};

export default WildernessRunDetails;
