import * as React from 'react';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import './menu-dot.scss';

const MenuDot = (props: any) => {
  const { items } = props;
  const refMenu = useRef<any>(null);

  return (
    <div className="dot-action" onClick={(event: any) => refMenu?.current?.toggle(event)}>
      <Menu className="dot-action-menu" style={{ width: '14rem' }} model={items || []} popup ref={refMenu} />
      <div className="fa-solid fa-ellipsis" />
    </div>
  );
};

export default MenuDot;
