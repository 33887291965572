import * as React from 'react';
import { useEffect, useRef, useState, Fragment } from 'react';
import './recurring-yearly-manager.scss';
import Layout from '../layout/layout';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { DonationManagerAPI } from '../../services';
import { Toast } from 'primereact/toast';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { showNotification } from '../../utils/logic';
import { WIConfirmDialog } from '../common';
import moment from 'moment';

const RecurringYearlyManager = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const sortField = searchParams?.get('sortField') || 'serial';
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const searchField = searchParams?.get('search') || '';
  const [isShowSendMailDialog, setIsShowSendMailDialog] = useState(false);
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecords] = useState({
    data: [],
    totalPage: 0,
  });
  const [paginator, setPaginator] = useState({
    page: 0,
    first: 0,
    rows: 20,
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const [search, setSearch] = useState<any>('');
  const navigate = useNavigate();

  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
  };

  const sendMail = async () => {
    try {
      setIsLoading(true);
      const res = await DonationManagerAPI.sendMailYearlyDonations(moment().year() - 1);
      showNotification('success', 'Triggered mails successfully', toast);
      setIsLoading(false);
    } catch (err) {
      console.log('ERROR', err);
      setIsLoading(false);
    }
  };

  /**
   * Book Geo for last year big-brother donations
   *
   * NOTE:
   * a big-brother donation is the oldest donation (based on payment date) in a group of
   * donations having the same parent donation
   */
  const bookGeoForLastYearBigBrotherDonations = async () => {
    try {
      setIsLoading(true);
      const res = await DonationManagerAPI.bookGeoForLastYearBigBrotherDonations();
      showNotification('success', 'Booking Geo Successfully Triggered!', toast);
      setIsLoading(false);
    } catch (err) {
      console.log('ERROR', err);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const res = await DonationManagerAPI.getYearlyDonations(moment().year() - 1);

      if (res && res.status === 200) {
        setRecords({
          data: res.data,
          totalPage: 1,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setRecords({
        data: [],
        totalPage: 0,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatAmountBodyTemplate = (rowData: any, column: string) => {
    return (
      <div>
        <b style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>
          {rowData ? parseFloat(rowData[column]?.toString())?.toLocaleString('de-DE') : '-'}
        </b>{' '}
        <span>{rowData.payment_currency_code?.toUpperCase()}</span>
      </div>
    );
  };

  const formatNumberBodyTemplate = (rowData: any, column: string) => {
    return (
      <div>
        <span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>
          {rowData ? parseFloat(rowData[column]?.toString())?.toLocaleString('de-DE') : '-'}
        </span>
      </div>
    );
  };

  const tableColumns = [
    {
      field: 'serial',
      header: 'Serial',
      width: '100px',
      align: 'left',
      disabledSort: true,
      bodyTemplate: (rowData: any) => {
        return <Link to={`/donations/${rowData.test_parent_donation_id}`} className="btn-serial">{`${rowData.donation?.id}`}</Link>;
      },
    },
    {
      field: 'child_serial',
      header: 'Earliest Child Serial',
      width: '100px',
      align: 'left',
      disabledSort: true,
      bodyTemplate: (rowData: any) => {
        return <Link to={`/donations/${rowData.test_earliest_child_id}`} className="btn-serial">{`00-${rowData.test_earliest_child_serial}`}</Link>;
      },
    },
    {
      field: 'area.geocoordinates',
      header: 'Earliest Child GEO Coordinate',
      width: '200px',
      align: 'left',
      disabledSort: true,
      bodyTemplate: (rowData: any) => {
        return (
          <a href={rowData?.area?.map} className="btn-serial">
            {rowData?.area?.geocoordinates}
          </a>
        );
      },
    },
    {
      field: 'test_email',
      header: 'Email',
      width: '200px',
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'fullname',
      header: 'Fullname',
      width: '140px',
      bodyTemplate: (rowData: any) => (rowData.person?.firstname || '') + ' ' + (rowData.person?.lastname || ''),
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'donation.area',
      header: 'Region/Campaign',
      width: '200px',
      align: 'left',
      disabledSort: true,
      bodyTemplate: (rowData: any) => {
        if (rowData.test_campaign_id) {
          return (
            <Link to={`/campaigns/${rowData.test_campaign_id}`} className="btn-serial">
              {rowData.donation?.area}
            </Link>
          );
        }
        return <Link to={`/regions/${rowData.test_region_id}`} className="btn-serial">{`${rowData.donation?.area}`}</Link>;
      },
    },
    {
      field: 'donation.amount',
      header: 'Collection Amount',
      width: '100px',
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'donation.rhythm',
      header: 'Rhythm',
      width: '100px',
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'donation.date',
      header: 'Date',
      width: '100px',
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'donation.method',
      header: 'Method',
      width: '130px',
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'subscription.year_amount',
      header: 'Year Amount',
      width: '120px',
      align: 'right',
      disabledSort: true,
    },
    {
      field: 'donation.symbol',
      header: '',
      width: '60px',
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'subscription.total_amount',
      header: 'Total Amount',
      width: '120px',
      align: 'right',
      disabledSort: true,
    },
    {
      field: 'donation.symbol',
      header: '',
      width: '60px',
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'subscription.year_forest',
      header: 'Year Area (m²)',
      width: '120px',
      align: 'right',
      disabledSort: true,
    },
    {
      field: 'subscription.total_forest',
      header: 'Total Area (m²)',
      width: '120px',
      align: 'right',
      disabledSort: true,
    },
    {
      field: 'subscription.year_co2',
      header: 'Year CO2 (kg)',
      width: '120px',
      align: 'right',
      disabledSort: true,
    },
    {
      field: 'subscription.total_co2',
      header: 'Total CO2 (kg)',
      width: '120px',
      align: 'right',
      disabledSort: true,
    },
    {
      field: 'portal.token',
      header: 'Portal Link',
      width: '100px',
      align: 'left',
      disabledSort: true,
      bodyTemplate: (rowData: any) => {
        return (
          <a href={rowData?.portal?.token} className="btn-serial">
            Link
          </a>
        );
      },
    },
  ];

  const dynamicColumns = tableColumns.map((col: any, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
          minWidth: col.minWidth,
        }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="yearly-manager">
        <div className="manager-container">
          <div className="headline">
            <h2>Yearly Report</h2>
          </div>
          <div className="header-containers">
            <div className="yearly-header-panel">
              {/* <WISearchField
                icon={"pi pi-search"}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value.global.value)}
                // enterSearch={() => onSearchFunc(search)}
                globalValue={search}
              /> */}
            </div>
            <div className="btn-yearly-panel">
              <Button
                type="button"
                label={'Book Geo'}
                className="generate-button"
                onClick={() => {
                  // setIsShowSidebar(true);
                  console.log('Book Geo');
                  bookGeoForLastYearBigBrotherDonations();
                }}
              />
            </div>
            <div className="btn-yearly-panel">
              <Button
                type="button"
                label={'Send Mails'}
                className="generate-button"
                onClick={() => {
                  // setIsShowSidebar(true);
                  setIsShowSendMailDialog(true);
                }}
              />
            </div>
          </div>
          <div className="table-yearly-report">
            <DataTable
              loading={isLoading}
              value={record.data}
              scrollable
              dataKey="parent_donation_id"
              scrollHeight="calc(100vh - 295px)"
              scrollDirection="both"
              selectionMode="checkbox"
              paginator
              rows={100}
              rowsPerPageOptions={[5, 25, 50, 100, 200]}
            >
              <Column selectionMode="multiple" style={{ width: '50px' }}></Column>
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: '50px' }}
                // body={buildMenu}
              ></Column>
            </DataTable>
            {/* <WIPaginator
              first={paginator.first}
              rows={paginator.rows}
              totalRecords={certificates.totalPage}
              onPageChange={onBasicPageChange}
            /> */}
          </div>
        </div>
        <WIConfirmDialog
          visible={isShowSendMailDialog}
          onHide={() => setIsShowSendMailDialog(false)}
          onConfirm={() => {
            setIsShowSendMailDialog(false);
            sendMail();
          }}
          confirmLabel="Ok, do it"
          hideLabel="No, end it"
          message={
            <Fragment>
              <span style={{ fontSize: '18px', fontWeight: '700' }}>Are you sure you want to trigger annual mails?</span>
            </Fragment>
          }
          classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
        />
      </div>
    </Layout>
  );
};

export default RecurringYearlyManager;
