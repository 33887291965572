import maplibregl from 'maplibre-gl';

const SEARCH_FIELDS = ['code', 'reference_code', 'text_2fa'];
export const getObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if (key && key !== 'dates') {
      if ((Array.isArray(value) || typeof value === 'string') && value.length > 0) {
        tempt_obj[key] = value;
      }
    } else {
      if (Array.isArray(value) && value.length === 2) {
        tempt_obj['from'] = value[0];
        tempt_obj['to'] = value[1];
      }
    }
  }
  return tempt_obj;
};

export const getObjectSearchValue = (value: string) => {
  const search_result: any[] = [];
  SEARCH_FIELDS.forEach((key: string) => {
    const obj = {
      [key]: value,
    };
    search_result.push(obj);
  });

  return search_result;
};

export const getCodePayloadConditions = (where_obj: any, sort_config: any) => {
  const { sortField, sortOrder } = sort_config;
  const where_conditions = getObjectWithValues(where_obj);
  const mapper: any = {
    code: [['code']],
    reference_code: [['reference_code']],
    text_2fa: [['text_2fa']],
    updated_at: [['updated_at']],
  };
  const orders = mapper[sortField];
  orders.forEach((r: any) => r.push(sortOrder === -1 ? 'DESC' : 'ASC'));
  const order = [...orders];
  return {
    where: where_conditions,
    order,
  };
};

const createIcon = () => {
  //  var el = document.createElement('div');
  //   el.style.width = 50 + 'px';
  // el.style.height = 50 + 'px';
  // // el.style.background = '#ffffff';
  // el.style.padding = '5px';
  // el.style.borderRadius = '10px';
  // el.innerHTML = `<i class="fa-solid fa-tree" style="color: #13735f; font-size: 50px"></i>`
};

export const createMarker = (map: any, geos: any) => {
  const markers = geos
    ? geos.map((g: any) => ({
        serial: g.donation_serial,
        nw_geo_coordinate: g.nw_geo_coordinate,
        geo: [g.longitude, g.latitude],
      }))
    : [];

  markers.forEach((m: any) => {
    const popup = new maplibregl.Popup({ closeButton: false }).setHTML(`
      <div>
        <div style="color: #13735f; font-weight: 700; font-size: 16px">${m.serial ? 'Booked by ' + m.serial : 'Reserved'}</div>
        <div style="color: #13735f; font-weight: 700">${m.nw_geo_coordinate || ''}</div>
      </div>`);

    new maplibregl.Marker({ color: m.serial ? '#13735f' : '#477EC0' }).setLngLat(m.geo).addTo(map).setPopup(popup);
  });
};

export const createMap = (): any => {
  const myAPIKey = '6A0NaZGb67Lg2NfLOVfc';
  const fenceKey = '6f41673a-96e5-4e5e-a726-47b805b572e9';
  let [mistyForestSrc, secrectForestSrc] = 'fe677931-9070-43aa-87c8-8b105708c224;29a110a3-5916-4bad-bfc5-4984410b57d3'.split(';');
  const mapStyle = `https://api.maptiler.com/maps/satellite-v2/style.json?key=${myAPIKey}`;

  return {
    version: 8,
    sources: {
      satellite: {
        url: `https://api.maptiler.com/tiles/satellite-v2/tiles.json?key=${myAPIKey}`,
        type: 'raster',
        preload: 16,
      },
      // "maptiler_planet": {
      //   "url": `https://api.maptiler.com/tiles/v3/tiles.json?key=${myAPIKey}`,
      //   "type": "vector"
      // },
      'raster-tiles': {
        type: 'raster',
        url: `https://api.maptiler.com/tiles/${secrectForestSrc}/tiles.json?key=${myAPIKey}`,
      },
      'raster-tiles-misty': {
        type: 'raster',
        url: `https://api.maptiler.com/tiles/${mistyForestSrc}/tiles.json?key=${myAPIKey}`,
      },
    },
    layers: [
      {
        id: 'satellite',
        type: 'raster',
        source: 'satellite',
        minzoom: 0,
        layout: { visibility: 'visible' },
        paint: { 'raster-opacity': 1 },
        filter: ['all'],
      },
      {
        id: 'simple-tiles',
        type: 'raster',
        source: 'raster-tiles',
        minzoom: 0,
        maxzoom: 22,
      },
      {
        id: 'simple-tiles-misty',
        type: 'raster',
        source: 'raster-tiles-misty',
        minzoom: 0,
        maxzoom: 22,
      },
    ],
  };
};
