import * as React from 'react';
import { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import './wi-search-field.scss';

const WISearchField = (props: any) => {
  const { icon, placeholder, className, setSearch, enterSearch, globalValue = '' } = props;
  const [search] = useState(() => {
    return { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
  });
  const [globalFilterValue, setGlobalFilterValue] = useState(globalValue);

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters2 = { ...search };
    _filters2['global'].value = value;
    if (setSearch) {
      setSearch(_filters2);
    }
    setGlobalFilterValue(value);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      enterSearch();
    }
  };

  useEffect(() => {
    if (!globalValue) {
      setGlobalFilterValue('');
    }
  }, [globalValue]);

  return (
    <div className={className ? className : 'wi-search-container'}>
      <span className="p-input-icon-left">
        {icon ? <i className={icon} /> : null}
        <InputText type={'search'} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={placeholder} onKeyDown={_handleKeyDown} />
      </span>
    </div>
  );
};

export default WISearchField;
