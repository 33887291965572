import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LandManagerAPI, RegionManagerAPI } from '../../../services';
import CreateLandFormComponent from './create-land-form/create-land-form';
import { useLayoutV2 } from '../../../context/LayoutProvider';
import { useTranslation } from 'react-i18next';
import Layout from '../../../layout/layout';

export const CreateLandManager = () => {
  let { id } = useParams();
  const [isShowCreate, setIsShowCreate] = useState<boolean>(true);
  const [selectedLand, setSelectedLand] = useState<any>({});
  const [regions, setRegions] = useState([]);
  const { setIsContentLoading } = useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'area_details' });

  const fetchLandData = async (uuid: string) => {
    try {
      setIsContentLoading(true);
      const res = await LandManagerAPI.getLand(uuid);
      if (res && res.status === 200) {
        const dataLand = res.data.record || {};
        setIsShowCreate(false);
        handleRegionName(dataLand);
        setSelectedLand(dataLand);
        setIsContentLoading(false);
        return dataLand;
      }
      setIsContentLoading(false);
      return [];
    } catch (error) {
      setIsContentLoading(false);
      setIsShowCreate(true);
      setSelectedLand(null);
    }
  };

  const fetchRegionsData = async () => {
    const res = await RegionManagerAPI.getAllRegions();
    if (res && res.status === 200) {
      setRegions(
        res.data.records
          ? res.data.records.map((f: any) => ({
              id: f.uuid,
              name: f.name.de,
            }))
          : [],
      );
    }
  };

  const handleRegionName = (land: any) => {
    if (land && regions) {
      const selectedRegion: any = regions.find((r: any) => r.id === land.region_id);
      land.region_name = selectedRegion ? selectedRegion.name : null;
    }
  };

  useEffect(() => {
    (async () => {
      await fetchRegionsData();
      if (id) {
        await fetchLandData(id);
        handleRegionName(selectedLand);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout title={t('txt_area_details')} customLayout={true} customCopyRight={true}>
      <CreateLandFormComponent
        data={{
          regions,
          isShowCreate,
          selectedLand,
        }}
        fetchLandData={fetchLandData}
      />
    </Layout>
  );
};

export default CreateLandManager;
