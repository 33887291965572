import _ from 'lodash';
import React, { useRef } from 'react';
import { MediaV2ManagerAPI } from '../../../../services';
import { PNG_MAZ_SIZE, canBeThumbnail, getFileType, getThumbnailFullpath, uploadPhysicalFileToS3 } from '../../../../utils/mediaUtils';
import { createEmptyMediaV2 } from '../../../../types/mediaType';
import './upload-media-dialog.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import WIUploadMultipleField from '../../../../components/common/upload/wi-upload-multiple-field-v2';

const VALIDATION_SCHEMA = Yup.object().shape({
  mediaFiles: Yup.array().min(1, 'txt_array_min').max(10, 'txt_no_files_max').required('txt_required'),
});

export const UploadMediaDialogComponent = (props: any) => {
  const { fullPath, selectedFolder, refresh, onHide } = props;
  const formikRef = useRef<any>(null);
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'media_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

  // Operator Upload Images
  const onSubmitDataForm = async (value: any) => {
    try {
      setLoadingProgress(errorTrans('txt_uploading'));
      /* Stop here if there is no file */
      if (!formikRef?.current?.isValid) {
        throw new Error(formikRef?.current?.errors?.mediaFiles);
      }
      /* Create a list of file metadata objects */
      let newMedia = createEmptyMediaV2();
      const fileInfoDataList = value?.mediaFiles?.map((mediaFile: any) => {
        const metaInfo = _.cloneDeep(newMedia.metadata);
        const finalMetadata = { ...metaInfo, ...mediaFile.metadata };
        newMedia.name = mediaFile.name;
        newMedia.fullPath = fullPath ? `${fullPath}/${mediaFile.name}` : mediaFile.name;
        newMedia.alternativeText = mediaFile.metadata.alternative_text;
        newMedia.title = mediaFile.metadata.title;
        newMedia.description = mediaFile.metadata.description;
        newMedia.type = getFileType(mediaFile.extension);
        newMedia.size = mediaFile.size;
        newMedia.folderId = selectedFolder?.current?.uuid;
        newMedia.metadata = finalMetadata;
        let fileInfo: any = {
          name: newMedia.name,
          originalName: newMedia.name,
          description: JSON.stringify(newMedia.description),
          folderId: newMedia.folderId,
          fullPath: newMedia.fullPath,
          alternativeText: JSON.stringify(newMedia.alternativeText),
          metadata: JSON.stringify(newMedia.metadata),
          size: newMedia.size,
          type: newMedia.type,
          title: JSON.stringify(newMedia.title),
        };
        // Create a link between this "fileInfo" and "media file"
        const relationalKey = Math.random();
        mediaFile.relationalKey = relationalKey;
        fileInfo.relationalKey = relationalKey;
        return fileInfo;
      });
      /* Stop here if there is no file */
      if (!fileInfoDataList || fileInfoDataList.length < 1) {
        throw new Error('txt_upload_no_files');
      }
      /* Save the list of file metadata objects to the app in order to get the adjusted file names (if duplicate) */
      for (let i = 0; i < fileInfoDataList.length; i++) {
        const fileCreatingResponse = await MediaV2ManagerAPI.createFile(fileInfoDataList[i]);
        if (fileCreatingResponse?.status === 200 && fileCreatingResponse.data?.file) {
          fileInfoDataList[i].uuid = fileCreatingResponse.data.file.uuid;
          fileInfoDataList[i].name = fileCreatingResponse.data.file.name;
          fileInfoDataList[i].fullPath = fullPath ? `media/${fullPath}/${fileInfoDataList[i].name}` : `media/${fileInfoDataList[i].name}`;
          fileInfoDataList[i].hasError = false;
          if (canBeThumbnail(fileInfoDataList[i].fullPath)) {
            fileInfoDataList[i].thumbnailFullPath = getThumbnailFullpath(fileInfoDataList[i].fullPath);
          }
        } else {
          fileInfoDataList[i].hasError = true;
        }
      }
      /* If there is no any valid file for being uploaded to S3 storage -> stop here */
      const hasValidFile: boolean = fileInfoDataList.some((f: any) => f.hasError === false);
      if (!hasValidFile) {
        throw new Error('txt_upload_files_fail');
      }
      /* Get S3 Uploading Presigned URLs for files */
      const imageObjectKeys: string[] = fileInfoDataList.filter((item: any) => item.fullPath !== null).map((item: any) => item.fullPath);
      const thumbnailObjectKeys: string[] = fileInfoDataList
        .filter((item: any) => item.thumbnailFullPath !== null)
        .map((item: any) => item.thumbnailFullPath);
      const presignedUrlsResponse = await MediaV2ManagerAPI.getMultipleFileUploadingPresignedUrls({
        object_keys: [...imageObjectKeys, ...thumbnailObjectKeys],
      });
      if (!presignedUrlsResponse || presignedUrlsResponse.status !== 200 || presignedUrlsResponse.data.length < 1) {
        throw new Error('txt_upload_files_fail');
      }
      /* Define a Task (Promise) of uploading the image to S3 storage */
      const uploadImageToS3 = async (s3PresignedUrl: string, fileInfo: any, mediaFile: any) => {
        try {
          const newSize = await uploadPhysicalFileToS3(s3PresignedUrl, mediaFile.url, mediaFile.type, mediaFile.size > PNG_MAZ_SIZE);
          if (newSize !== fileInfo.size) {
            // Just update new Size, so don't wait for invalidating image's CDN cache
            MediaV2ManagerAPI.updateFile({ ...fileInfo, size: newSize, fullPath: fullPath ? `${fullPath}/${fileInfo.name}` : fileInfo.name }, false);
          }
          return { fileInfo, isSuccessful: true };
        } catch (ex) {
          console.log(`Cannot upload media file of '${fileInfo.fullPath}'`);
          return { fileInfo, isSuccessful: false };
        }
      };
      /* Define another Task (Promise) of uploading that image's thumbnail (if any) to S3 storage */
      const uploadThumbnailToS3 = async (s3PresignedUrl: string, fileInfo: any, mediaFile: any) => {
        try {
          await uploadPhysicalFileToS3(s3PresignedUrl, mediaFile.thumbnailBase64, 'image/webp');
        } catch (ex) {
          console.log(`Cannot upload thumbnail of '${fileInfo.thumbnailFullPath}'`);
        }
        return null;
      };
      /* Define a S3 Uploading Task List */
      const s3UploadingTasks: any[] = [];
      /* Add uploading tasks to the list */
      presignedUrlsResponse.data.forEach((presignedUrlObject: any) => {
        // Add a task of uploading an image to the list
        const fileInfo = fileInfoDataList.find((f: any) => f.fullPath === presignedUrlObject.object_key);
        if (!fileInfo) return null;
        const mediaFile = value?.mediaFiles?.find((mediaFile: any) => mediaFile.relationalKey === fileInfo.relationalKey);
        if (!mediaFile) return null;
        s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.presigned_url, fileInfo, mediaFile));
        // Add a task of uploading its thumbnail to the list (if any)
        if (fileInfo.thumbnailFullPath) {
          const thumbnailPresignedUrlResponse = presignedUrlsResponse.data.find(
            (presignedUrlObject: any) => presignedUrlObject.object_key === fileInfo.thumbnailFullPath,
          );
          if (thumbnailPresignedUrlResponse) {
            s3UploadingTasks.push(uploadThumbnailToS3(thumbnailPresignedUrlResponse.presigned_url, fileInfo, mediaFile));
          }
        }
      });
      /* Execute the Uploading Task List */
      let noSuccess = 0;
      let noFiles = 0;
      if (s3UploadingTasks.length > 0) {
        const s3UploadingResponse = await Promise.all(s3UploadingTasks);
        const results = s3UploadingResponse.filter((response: any) => !!response);
        if (results && results.length > 0) {
          noFiles = results.length;
          noSuccess = results.filter((r: any) => r.isSuccessful)?.length;
          results.forEach((result: any) => {
            if (result.isSuccessful) {
              // showNotification("success", `${result.fileInfo.name} file uploaded successfully`, toast);
            } else {
              // showNotification("error", `Failed to upload ${result.fileInfo.name} file`, toast);
              console.log(`Failed to upload ${result.fileInfo.name} file`);
            }
          });
          refresh();
        }
      }

      setSuccessProgress(t('txt_upload_media_files', { noSuccess, noFiles }));
      onHide();
    } catch (e: any) {
      setErrorProgress(errorTrans(e?.message || 'txt_create_donor_fail'));
    }
  };

  const initialFormValues = () => {
    return {
      mediaFiles: [],
    };
  };

  return (
    <div className="upload-media-container">
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues()}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({ values, handleSubmit }) => (
          <form className="d-flex flex-column flex-1" onSubmit={handleSubmit}>
            <div className="d-flex flex-column flex-1">
              <WIUploadMultipleField type="file" id={'mediaFiles'} name={'mediaFiles'} required={true} hideLabel={true} />
            </div>
            <div className="d-flex justify-content-between mb-32 gap-24">
              <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
              <Button
                className="wi-primary-button-v2 h48 flex-1"
                type="submit"
                label={`${t('txt_upload')}`}
                onClick={() => onSubmitDataForm(values)}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
