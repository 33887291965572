import { Dialog } from 'primereact/dialog';
import React from 'react';
import { IContent } from '../../interfaces/IContent';
import { ContentBuilderComponentView } from '../content-builder';

const FloatingLayers = (props: any) => {
  const { visible, dashboard, setDashboard, selectedComponent, setSelectedComponent, header, footer } = props;

  return (
    <Dialog
      style={{ width: '250px', maxHeight: '700px' }}
      visible={visible}
      modal={false}
      closable={false}
      onHide={() => {}}
      header={<i className="fa-solid fa-layer-group"></i>}
      headerClassName="layers-header"
      contentClassName="layers-content"
      position="bottom-right"
    >
      <div className="tree-view">
        {dashboard.map((component: IContent, index: number) => {
          return (
            <ContentBuilderComponentView
              className="content-builder-component-view"
              key={`cb_${index}`}
              id={`cb_${index}`}
              dashboard={dashboard}
              setDashboard={(value: any) => setDashboard(value)}
              selectedComponent={selectedComponent}
              setSelectedComponent={setSelectedComponent}
              footer={footer}
              header={header}
            />
          );
        })}
      </div>
    </Dialog>
  );
};

export default FloatingLayers;
