import Layout from '../../layout/layout';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import CreateCouponFormComponent from './create-coupon-form/create-coupon-form';
import { LandManagerAPI, PartnerManagerAPI, RegionManagerAPI, CampaignManagerAPI } from '../../../services';

export const CreateCouponManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [lands, setLands] = useState([]);
  const [regions, setRegions] = useState([]);
  const [partners, setPartners] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const ACTIVE_STATUS = 'active';

  const fetchRegionsData = async () => {
    const resRegions = await RegionManagerAPI.getAllRegions();
    if (resRegions && resRegions.status === 200) {
      setRegions(resRegions.data.records || []);
    }
  };

  const fetchLandsData = async () => {
    const resLands = await LandManagerAPI.getAllLands();
    if (resLands && resLands.status === 200) {
      setLands(resLands.data.records.filter((r: any) => r.status === ACTIVE_STATUS) || []);
    }
  };

  const fetchPartnerData = async () => {
    const resPartner = await PartnerManagerAPI.getAllPartners();
    if (resPartner && resPartner.status === 200) {
      setPartners(resPartner.data.records.filter((r: any) => r.status === ACTIVE_STATUS) || []);
    }
  };

  const fetchCampaignsData = async () => {
    const res = await CampaignManagerAPI.getAllCampaigns();
    if (res && res.status === 200) {
      const campaigns = res.data.records.filter((item: any) => item.status === ACTIVE_STATUS && item.land?.status === ACTIVE_STATUS);
      setCampaigns(campaigns || []);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([fetchLandsData(), fetchPartnerData(), fetchRegionsData(), fetchCampaignsData()]);
    } catch (error: any) {
      console.log('fetchData(): ' + error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout customLayout={true} customCopyRight={true}>
      {isLoading ? (
        <div className="loading-component">
          <ProgressSpinner />
        </div>
      ) : (
        <></>
      )}
      <Toast ref={toast} />
      <div className="d-flex flex-column w-100 text-left">
        <CreateCouponFormComponent
          data={{
            lands,
            partners,
            regions,
            campaigns,
          }}
        />
      </div>
    </Layout>
  );
};

export default CreateCouponManager;
