import moment from 'moment';
import { Card } from 'primereact/card';
import React from 'react';
import { useState } from 'react';
import './card-item.scss';
import { formatImageFileURL, getOriginalFileName, getThumbnailFullpath, IconConfig } from '../../../../utils/mediaUtils';
import { env } from '../../../../configs';
import { ReactComponent as CheckCircleSvg } from '../../../../assets/images/icons/check-circle.svg';

const CardItem = (props: any) => {
  const { mediaFile, onEditMedia, isInsertMediaPopup, onSetSelectedItem, handleDoubleClick, selectedMedia } = props;
  const [language] = useState('de');
  const fileUrl =
    mediaFile && mediaFile.type === 'Image'
      ? encodeURI(`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(mediaFile.fullPath))}`)
      : encodeURI(IconConfig[mediaFile?.file_extension]);

  const createShortName = (name: any) => {
    const maxLength = 32;
    if (name && name.length > maxLength) {
      return name.slice(0, maxLength - 18) + '...' + name.slice(name.length - 10, name.length);
    }
    return name;
  };

  return mediaFile.type ? (
    <div
      className={`media-card-item ${mediaFile?.uuid === selectedMedia?.uuid ? 'card-item-highlight' : ''}`}
      onClick={e => {
        if (isInsertMediaPopup) {
          onSetSelectedItem(mediaFile);
        } else {
          onEditMedia(true, e, mediaFile);
        }
      }}
      onDoubleClick={e => {
        if (isInsertMediaPopup) {
          handleDoubleClick(e);
        }
      }}
    >
      <Card className={`card-item card-item-${mediaFile?.type?.toLowerCase()}`}>
        <div className="card-image-wrapper">
          <img
            src={
              mediaFile.type === 'Image'
                ? `${fileUrl}?v=${mediaFile?.updatedAt ? moment(mediaFile.updatedAt).unix() : moment(mediaFile.createdAt).unix()}`
                : `${fileUrl}`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = encodeURI(`${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(mediaFile.fullPath)}`);
            }}
            alt={mediaFile?.alternativeText ? mediaFile?.alternativeText[language?.toLowerCase()] : ''}
            title={mediaFile?.title ? mediaFile?.title[language?.toLowerCase()] : ''}
            className={`card-image card-img-w-${
              mediaFile?.type === 'Image' ? '100' : '50'
            } card-img-bg-${mediaFile?.file_extension} ${mediaFile?.metadata?.dimensions && mediaFile?.metadata?.dimensions?.width < 200 && mediaFile?.metadata?.dimensions?.height < 200 ? 'card-img-non-cover' : ''}`}
            loading="lazy"
          />
        </div>
        <span>{createShortName(getOriginalFileName(mediaFile.name)) || 'Untitle'}</span>
        <div className="card-image-backdrop">
          <CheckCircleSvg className="icon-svg big"></CheckCircleSvg>
        </div>
      </Card>
    </div>
  ) : (
    <div className="dummy-block"></div>
  );
};

export default CardItem;
