import * as React from 'react';
import { useState, Fragment } from 'react';
import { Dialog } from 'primereact/dialog';
import './dialog-text-html.scss';
import { Button as PButton } from 'primereact/button';
import { WIConfirmDialog } from '../../../../common';
import { formatHTMLCode } from '../../../../../utils/logic';
import { replaceParameter } from '../../../../pages/utils';

const DialogTextHtml = (props: any) => {
  const { onHide, visible, onSaveHtml, htmlTemplete, areaValue, setAreaValue, setIsShowDialog } = props;
  let { headHtml } = props;

  const [isShowCancel, setIsShowCancel] = useState(false);
  const attributeHTML: any = {
    style: ``,
    script: ``,
  };

  Object.keys(attributeHTML).forEach((f: string) => {
    headHtml = replaceParameter(headHtml, `{{parameters.${f}}}`, attributeHTML[f]);
  });

  const templateResutl = `
    ${headHtml}
    <body>
      ${areaValue}
    </body>
  `;
  const onConfirm = () => {
    onSaveHtml();
    setIsShowDialog(false);
  };

  const confirmCancelHtml = () => {
    onHide();
    setIsShowCancel(!isShowCancel);
  };

  const footerTemplate = (
    <React.Fragment>
      <PButton label="Cancel" icon="pi pi-times" className="p-button-text button-hide" onClick={() => setIsShowCancel(true)} />
      <PButton label="Apply" icon="pi pi-check" className="p-button-text button-confirm" onClick={onConfirm} />
    </React.Fragment>
  );

  return (
    <div className="dialog_html">
      <Dialog visible={visible} className="dialog-text-html" modal onHide={onHide} contentClassName="content-text" footer={footerTemplate}>
        <div className="dialog-html">
          <label htmlFor="html-code"> HTML Code </label>
          <textarea
            id="html-code"
            name="html-code"
            rows={50}
            cols={70}
            onChange={e => setAreaValue(e.target.value)}
            defaultValue={formatHTMLCode(htmlTemplete)}
          />
        </div>
        <div className="dialog-html dialog-review">
          <label htmlFor="html-preview"> HTML Preview </label>
          <iframe
            className="preview-html"
            id="html-preview"
            style={{ width: '100%', height: `100%`, transformOrigin: 'top' }}
            srcDoc={templateResutl}
          />
        </div>
      </Dialog>
      <WIConfirmDialog
        visible={isShowCancel}
        onHide={() => setIsShowCancel(!isShowCancel)}
        onConfirm={confirmCancelHtml}
        message={<Fragment>Are you sure want to cancel?</Fragment>}
        classIcon="pi pi-times mr-3 dialog-icon"
      />
    </div>
  );
};

export default DialogTextHtml;
