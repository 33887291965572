import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import './wi-menu.scss';
import { Link, useNavigate } from 'react-router-dom';

const MenuChildrenTemplate = (props: any) => {
  const { children } = props;
  const navigate = useNavigate();

  const navigateURL = (item: any) => {
    if (item.outsideURL) {
      window.open(item.outsideURL, '_blank', 'noopener,noreferrer');
    } else {
      navigate(item.url);
    }
  };

  return (
    <ul className={`dropdown-menuitem ${props.isExpand ? 'show' : 'hidden'}`}>
      {children.map((item: any, index: number) => (
        <li key={`dropdown-menuitem-${index}`} className="menu-items items-dropdown">
          <Link to={item.url} className="p-menuitem-link" role="menuitem">
            <span className={`p-menuitem-icon ${item.icon}`}></span>
            <span className="p-menuitem-text">{item.label}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

const MenuItemsTemplate = (props: any) => {
  const [isExpand, setIsExpand] = useState(false);
  const navigate = useNavigate();

  const navigateURL = (item: any) => {
    if (item.outsideURL) {
      window.open(item.outsideURL, '_blank', 'noopener,noreferrer');
    } else {
      navigate(item.url);
    }
  };

  return (
    <li
      style={{ position: 'relative' }}
      className={`p-menuitem ${props.className}`}
      onMouseEnter={() => setIsExpand((prev: any) => !prev)}
      onMouseLeave={() => setIsExpand((prev: any) => !prev)}
    >
      {props.items ? (
        <>
          <a className="p-menuitem-link" role="menuitem" aria-haspopup="menu" aria-expanded={isExpand ? 'true' : 'false'}>
            <span className={`p-menuitem-icon ${props.icon}`}></span>
            <span className="p-menuitem-text">{props.label}</span>
          </a>
          <MenuChildrenTemplate children={props.items} isExpand={isExpand} />
        </>
      ) : (
        <Link to={props.url} className="p-menuitem-link" role="menuitem">
          <span className={`p-menuitem-icon ${props.icon}`}></span>
          <span className="p-menuitem-text">{props.label}</span>
        </Link>
      )}
    </li>
  );
};

const WIMenu = (props: any) => {
  const { items, mode } = props;

  items.forEach((m: any) => {
    const url = window.location.pathname;
    const isMatched = url === m.url || (m.url !== '/' && url.includes(m.url));
    m.className = isMatched ? `wi-menu-item wi-menu-item-active` : 'wi-menu-item';
  });

  return (
    <>
      <div className={`wi-menu p-menu p-component ${mode}`}>
        <ul className="p-menu-list p-reset">
          {items.map((item: any, index: number) => {
            return <MenuItemsTemplate key={`${index}`} {...item} mode={mode} />;
          })}
        </ul>
      </div>
    </>
  );
};

export default WIMenu;
