import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import './wi-icon-dropdown.scss';
import * as _ from 'lodash';
import { WIFormElement } from '../wi-form-element/wi-form-element';

const CMS_HEADER_ICONS = [
  { label: 'News', value: '&#xe93a;' },
  { label: 'Globe', value: '&#xe940;' },
  { label: 'Campaign', value: '&#xe941;' },
  { label: 'About Us', value: '&#xe93c;' },
  { label: 'Info', value: '&#xe939;' },
  { label: 'Project', value: '&#xe93b;' },
  { label: 'German Flag', value: '&#xe93e;' },
  { label: 'Canadian Flag', value: '&#xe93f;' },
  { label: 'Peruvian Flag', value: '&#xe93d;' },
  { label: 'Chevron Right', value: '&#xe903;' },
  { label: 'Youtube', value: '&#xe90d;' },
  { label: 'Facebook', value: '&#xe905;' },
  { label: 'LinkedIn', value: '&#xe907;' },
  { label: 'Twitter', value: '&#xe936;' },
  { label: 'Tiktok', value: '&#xe937;' },
  { label: 'Instagram', value: '&#xe906;' },
];

export function WIIconDropdown(props: any) {
  const { name, options } = props;
  const { handleBlur, setFieldValue, values } = useFormikContext();

  return (
    <WIFormElement {...props}>
      <Dropdown
        id={name}
        name={name}
        options={options || CMS_HEADER_ICONS}
        onBlur={handleBlur}
        onChange={e => setFieldValue(name, e.value)}
        optionLabel="label"
        optionValue="value"
        className="wi-form-dropdown"
        value={(_.get(values, name) || '').trim()}
      />
      <ErrorMessage name={name} render={(msg: any) => <div style={{ color: 'red' }}>{msg}</div>} />
    </WIFormElement>
  );
}
