import React, { Fragment, useEffect, useState } from 'react';
import { Column, ColumnAlignType } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { ReactComponent as WarningSvg } from '../../assets/images/icons/warning-symbol.svg';
import { Sidebar } from 'primereact/sidebar';
import QuoteFormComponent from './components/quote-form';
import { useLayoutV2 } from '../../context/LayoutProvider';
import useAuth from '../../context/useAuth';
import { PERMISSIONS_V2 } from '../../utils/constants';
import { QuoteManagerAPI } from '../../services';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIConfirmDialog from '../../components_v2/common/delete-confirm-dialog/delete-confirm-dialog';
import { formatDateV2, formatMultiLangField } from '../../utils/logic';

const QuoteManager = () => {
  const { t } = useTranslation('language', { keyPrefix: 'quote_manager' });
  const { getLanguage, setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

  const [isLoading, setIsLoading] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [quotes, setQuotes] = useState<any[]>();
  const [quote, setQuote] = useState<any>();
  const [search, setSearch] = useState<any>(null);
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  const { auth } = useAuth();

  const permissions = {
    canCreateQuote: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_CREATE),
    canUpdateQuote: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_UPDATE),
  };

  const fetchData = async () => {
    setIsLoading(true);
    const result = await QuoteManagerAPI.getAll(false);
    // @ts-ignore: Object is possibly 'null'.
    if (result.status === 200 && result.data.records) {
      setQuotes(result.data.records);
    } else {
      setQuotes([]);
    }
    setIsLoading(false);
  };

  const onCreateQuote = (isCreating: boolean, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setQuote(null);
    setIsShowSidebar(isCreating);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateQuote = async (e: any, rowData: any) => {
    const res = await QuoteManagerAPI.getQuote(rowData.uuid);
    if (res && res.status === 200) {
      setQuote(res.data.record);
      setIsShowSidebar(true);
    }
  };

  const onDeleteQuote = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setQuote(rowData);
  };

  const callDeleteQuoteAPI = async () => {
    try {
      let res = await QuoteManagerAPI.deleteQuote(quote?.uuid);
      if (res && res.status === 200 && res.data.result.isValid) {
        setQuote(null);
        setIsShowDeleteDialog(false);
        fetchData();
        setSuccessProgress(t('txt_delete_quote_success'));
        return;
      } else {
        console.log(res);
      }
    } catch (error) {
      setErrorProgress(errorTrans('txt_failed_delete_quote'));
    }
  };

  const tableColumns = [
    {
      field: 'owner_name',
      header: t('txt_owner_name'),
      width: 'calc(22% - 64px)',
      align: 'left',
    },
    {
      field: 'quote',
      header: t('txt_quote'),
      width: '52%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const data = formatMultiLangField(rowData.quote, 'de');
        return <div className="wi-html-content" dangerouslySetInnerHTML={{ __html: data }} />;
      },
    },
    {
      field: 'created',
      header: t('txt_created_at'),
      width: '13%',
      align: 'left',
      bodyTemplate: (rowData: any) => formatDateV2(rowData.created, getLanguage()),
    },
    {
      field: 'changed',
      header: t('txt_updated_at'),
      width: '13%',
      align: 'left',
      bodyTemplate: (rowData: any) => formatDateV2(rowData.changed, getLanguage()),
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        className={col.field}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-edit',
        hidden: !permissions.canUpdateQuote,
        command: (e: any) => {
          onUpdateQuote(e, rowData);
        },
      },
      {
        label: t('txt_delete'),
        icon: 'wi-delete-folder',
        dangerous: true,
        hidden: !permissions.canUpdateQuote,
        command: (e: any) => {
          onDeleteQuote(e, rowData);
        },
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout title={t('txt_title')}>
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField icon={'pi pi-search'} placeholder={t('txt_search')} setSearch={(value: any) => setSearch(value)} />
          </div>
          <Button
            className="wi-primary-button-v2"
            label={t('txt_create')}
            hidden={!permissions.canCreateQuote}
            onClick={(e: any) => onCreateQuote(true, e)}
          >
            <PlusSvg className="icon-svg" />
          </Button>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={quotes}
          filters={search}
          globalFilterFields={['owner_name', 'quote.de', 'quote.en']}
          emptyMessage="No quotes found."
          scrollable
          scrollHeight="600px"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
      </div>
      <Sidebar
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        visible={isShowSidebar}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{quote ? t('txt_update_quote') : t('txt_new_quote')}</h6>
          </div>
          <QuoteFormComponent quote={quote} fetchQuotesData={fetchData} onHide={() => setIsShowSidebar(false)} />
        </div>
      </Sidebar>
      <WIConfirmDialog
        visible={isShowDeleteDialog}
        onHide={() => setIsShowDeleteDialog(false)}
        onConfirm={callDeleteQuoteAPI}
        maskClassName="top-mask-dialog"
        confirmLabel={t('txt_confirm')}
        hideLabel={t('txt_cancel')}
        message={
          <Fragment>
            <div style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center', marginBottom: '16px' }}>{t('txt_are_you_sure')}</div>
            <div style={{ fontSize: '13px', textAlign: 'center' }}>
              <div
                dangerouslySetInnerHTML={{ __html: t('txt_confirm_description', { permanently: `<strong>${t('txt_permanently')}</strong>` }) }}
              ></div>
              <div dangerouslySetInnerHTML={{ __html: t('txt_can_not_action', { cant: `<strong>${t('txt_cant_not')}</strong>` }) }}></div>
              <br />
              <b>{t('txt_note')}</b>: <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: t('txt_note_delete', {
                    quote: `<strong>${t('txt_person')}</strong>`,
                    unexpected: `<strong>${t('txt_unexpected_issue')}</strong>`,
                  }),
                }}
              ></div>
            </div>
          </Fragment>
        }
        icon={<WarningSvg className="icon-svg"></WarningSvg>}
      />
    </Layout>
  );
};

export default QuoteManager;
