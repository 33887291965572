import * as React from 'react';
import { Paginator } from 'primereact/paginator';
import './wi-paginator.scss';

const WIPaginator = (props: any) => {
  const { first, rows, totalRecords, onPageChange, rowsPerPageOptions, currentPageReportTemplate, template } = props;

  return (
    <Paginator
      className="wi-paginator-container"
      first={first}
      rows={rows}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
      rowsPerPageOptions={rowsPerPageOptions || [5, 10, 20, 50, 100, 1000]}
      currentPageReportTemplate={currentPageReportTemplate || 'Showing {first} to {last} of {totalRecords}'}
      template={template || 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'}
    />
  );
};

export default WIPaginator;
