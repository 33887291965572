let umlautMap = {
  '\u00dc': 'UE',
  '\u00c4': 'AE',
  '\u00d6': 'OE',
  '\u00fc': 'ue',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00df': 'ss',
};

const replaceUmlaute = (str: string) => {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a: any) => {
      var big = umlautMap[a.slice(0, 1) as keyof typeof umlautMap];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'), (a: any) => umlautMap[a as keyof typeof umlautMap]);
};

const removeAccents = (str: any) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export function generateUrl(url: any) {
  url = url?.trim().replaceAll(' ', '-').toLowerCase();
  return removeAccents(replaceUmlaute(url))
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/-{2,}/g, '-');
}
