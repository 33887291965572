import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { Formik } from 'formik';
import { useRef } from 'react';
import { WiTextEditor1Line } from '../../../../dynamic-form/commons';
import SearchURLGlobal from '../../../../../components_v2/common/search-url-global/search-url-global';
import { InputSwitch } from 'primereact/inputswitch';
import { WIButton } from '../../../../common';

const HeaderButtonConfigDialog = (props: any) => {
  const { data, visible, setVisible, globalContent, setData } = props;
  const formikRef = useRef(null);

  const onHide = () => setVisible(false);

  const onInitValues = (data: any) => {
    return data;
  };

  const saveData = (values: any) => {
    onHide();
    setData(values);
  };

  return (
    <>
      <Dialog header="Button Configuration" visible={visible || false} onHide={onHide} style={{ width: '800px', maxHeight: '70vh' }}>
        <div className={`header-item-editor-container`}>
          <Formik
            enableReinitialize={true}
            innerRef={formikRef}
            initialValues={onInitValues(data)}
            // validationSchema={validationLandSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log('[Header Button] Save changes...');
              saveData(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, dirty, isValid }) => (
              <div>
                <div className="col-12">
                  <div className="row row-content">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Title</label>
                        <WiTextEditor1Line name="urlName.de" values={values} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="element-form">
                        <SearchURLGlobal
                          label={'Link'}
                          search_content={globalContent}
                          values={values.buttonUrl}
                          searchFields={['name']}
                          // isRequired={true}
                          onChangeValue={(valueURL: any) => {
                            setFieldValue(`buttonUrl`, valueURL, true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Open in new tab?</label>
                        <InputSwitch name={'blank_mode'} checked={values.blank_mode} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-action-footer">
                  <WIButton type="submit" className="btn-save-header-item" primary={true} label="Apply" onClick={handleSubmit}></WIButton>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
};

export default HeaderButtonConfigDialog;
