import * as React from 'react';
import { useState, useEffect, useRef, Fragment } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Column, ColumnAlignType } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { formatDateTimeV2 } from '../../utils/logic';
import { WIConfirmDialog } from '../../components/common';
import { CMsPageManagerAPI } from '../../services';
import { removeEmpty } from '../../utils/utils';
import { fetchPageTypes } from '../../components/pages/builder-page.common';
import { PAGE_CODE } from '../../components/pages/utils';
import { TabMenu } from 'primereact/tabmenu';
import _ from 'lodash';
import moment from 'moment';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';
import { ReactComponent as LockOpenSvg } from '../../assets/images/icons/lock-open.svg';
import { ReactComponent as LockCloseSvg } from '../../assets/images/icons/lock-close.svg';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import { CMS_STATUS_LIST, PERMISSIONS_V2 } from '../../utils/constants';
import WiStatus from '../../components_v2/common/wi-status/wi-status';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../context/LayoutProvider';
import useAuth from '../../context/useAuth';

const PageManager = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const uuidTypeAll = '00000000-0000-0000-0000-000000000000';
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const filterTypes = searchParams?.get('type') || uuidTypeAll;
  const [isLoading, setIsLoading] = useState(false);
  const [cmsPages, setCmsPages] = useState({
    data: [],
    totalPage: 0,
  });
  const [cmsPage, setCmsPage] = useState<any>(null);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(pageNumber);
  const [rows, setRows] = useState(noRows);
  const [pageTypes, setPageTypes] = useState<any[]>([]);
  const [selectedPageType, setSelectedPageType] = useState<any>(filterTypes);
  const [search, setSearch] = useState<any>(null);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const { t } = useTranslation('language', { keyPrefix: 'cms_page_manager' });
  const { t: globalTrans } = useTranslation('language');
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress, getLanguage } = useLayoutV2();

  const { auth } = useAuth();

  const permissions = {
    canCreateCMSPage:
      auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_CREATE) || auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_CREATE_TENANT),
    canUpdateCMSPage:
      auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_UPDATE) || auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_UPDATE_TENANT),
  };

  const setupCMSPageTypes = async (pageTypes: any[]) => {
    const types = pageTypes.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
    types.unshift({ uuid: uuidTypeAll, code: 'all', name: { de: 'All', en: 'All' } });
    // setSelectedPageType(types && types.length > 0 ? types[0].uuid : '');
    setPageTypes(types);
  };

  const fetchData = async () => {
    let types = await fetchPageTypes();
    setupCMSPageTypes(types);
    // await fetchCmsPagesData(types && types.length > 0 ? types[0].uuid : '');
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === 'number' && selectedPageType && pageTypes.length > 0) {
      fetchCmsPagesData(selectedPageType, search);
    }

    setSearchParams(
      {
        pageNumber: page.toString(),
        noRows: rows.toString(),
        type: selectedPageType || filterTypes || uuidTypeAll,
      },
      { replace: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows, selectedPageType, pageTypes]);

  const fetchCmsPagesData = async (page_type_id: string, valueSearch?: string) => {
    try {
      let excludedCodes = [PAGE_CODE.News, PAGE_CODE.Press];
      let pageTypeIds = pageTypes.filter(f => !excludedCodes.includes(f.code)).map(m => m.uuid);
      let querySearch: any = {
        conditions: removeEmpty({
          typeIds: page_type_id !== uuidTypeAll ? [page_type_id] : pageTypeIds,
          searchText: valueSearch || '',
        }),
      };

      let res = await CMsPageManagerAPI.searchAllPagesWithPagination(querySearch, { pageNo: page, range: rows });
      if (res && res.status === 200) {
        setIsLoading(false);
        setCmsPages({
          data: res.data.records,
          totalPage: res.data.total,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setCmsPages({
        data: [],
        totalPage: 0,
      });
    }
  };

  const callDeleteAPI = async () => {
    try {
      setIsLoading(true);
      setLoadingProgress(errorTrans('txt_loading'));
      setIsShowDeleteDialog(false);
      let res = await CMsPageManagerAPI.deletePage(cmsPage?.uuid);
      if (res && res.status === 200 && res.data.result.isValid) {
        setCmsPage(null);
        setSuccessProgress(t('txt_delete_page_success'));
        fetchCmsPagesData(selectedPageType);
        return;
      }

      throw new Error('txt_delete_cms_page_fail');
    } catch (error: any) {
      setErrorProgress(errorTrans(error?.message || 'txt_delete_cms_page_fail'));
    } finally {
      setIsLoading(false);
    }
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const onDelete = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setCmsPage(rowData);
  };

  const onSearch = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchCmsPagesData(selectedPageType, search);
  };

  const tableColumns = [
    {
      field: 'name',
      header: t('txt_name'),
      width: 'calc(100% - 924px + 150px)',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return (
          <div className="cell-info">
            <div className="cell-main-info pb-12">{rowData.name}</div>
            <div className="cell-sub-info">/{rowData.url}</div>
          </div>
        );
      },
    },
    {
      field: 'status',
      header: t('txt_status'),
      width: '200px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        const statusItem = CMS_STATUS_LIST.find((item: any) => item.code === rowData.status);
        return <WiStatus item={statusItem}></WiStatus>;
      },
    },
    // {
    //     field: "occupation",
    //     header: t('txt_occupation'),
    //     width: "150px",
    //     align: "center",
    //     bodyTemplate: (rowData: any) => {
    //         return (
    //             <Button type={"button"}
    //                 className={`no-mouse-events ${rowData.occupation ? "wi-danger-button-icon-v2" : "wi-primary-button-icon-v2"}`}>
    //                 {
    //                     !rowData.occupation
    //                         ? <LockOpenSvg className="icon-svg"></LockOpenSvg>
    //                         : <LockCloseSvg className="icon-svg"></LockCloseSvg>
    //                 }
    //             </Button>
    //         )
    //     }
    // },
    {
      field: 'type',
      header: t('txt_type'),
      width: '170px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        return globalTrans(`cms_types.txt_type_${rowData.type}`);
      },
    },
    {
      field: 'updated_at',
      header: `${t('txt_updated')}`,
      width: '170px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.updated_at ? formatDateTimeV2(rowData.updated_at, getLanguage()) : '-'}</div>;
      },
    },
    {
      field: 'created_at',
      header: `${t('txt_created')}`,
      width: '170px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.created_at ? formatDateTimeV2(rowData.created_at, getLanguage()) : '-'}</div>;
      },
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const handleChangeStatus = (status: any) => {
    setPage(0);
    setFirst(0);
    setSelectedPageType(status.uuid);
  };

  const callUpdatePageAPI = async (uuid: string, data: any) => {
    try {
      const res = await CMsPageManagerAPI.updatePage(uuid, data);
      if (res && res.status === 200) {
        setSuccessProgress(t('txt_publish_cms_page_success'));
        return;
      }

      throw new Error('txt_publish_cms_page_fail');
    } catch (error: any) {
      setErrorProgress(errorTrans(error?.message || 'txt_publish_cms_page_fail'));
    }
  };

  const callUnpublishedPageAPI = async (pageId: any) => {
    try {
      const res = await CMsPageManagerAPI.unpublishedPage(pageId);

      if (res && res.status === 200) {
        setSuccessProgress(t('txt_unpublish_cms_page_success'));
        return;
      }

      throw new Error('txt_unpublish_cms_page_fail');
    } catch (error: any) {
      setErrorProgress(errorTrans(error?.message || 'txt_unpublish_cms_page_fail'));
    }
  };

  const onPublishPage = async (rowData: any) => {
    try {
      if (!rowData) {
        return;
      }
      setLoadingProgress(errorTrans('txt_loading'));
      const pageData: any = { status: 'published' };
      pageData['custom_config'] = _.cloneDeep(rowData.custom_config) || {};
      pageData['custom_config']['published_info'] = pageData['custom_config']['published_info'] || [];
      pageData['custom_config']['published_info'].push({ time: moment().toISOString() });

      await callUpdatePageAPI(rowData.uuid, pageData);
      fetchCmsPagesData(selectedPageType);
    } catch (error: any) {
      setErrorProgress(errorTrans(error?.message || 'txt_publish_cms_page_fail'));
    }
  };

  const onUnpublishedPage = async (rowData: any) => {
    try {
      if (!rowData) {
        return;
      }

      setLoadingProgress(errorTrans('txt_loading'));
      await callUnpublishedPageAPI(rowData.uuid);
      fetchCmsPagesData(selectedPageType);
    } catch (e: any) {
      setErrorProgress(errorTrans(e?.message || 'txt_unpublish_cms_page_fail'));
    }
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit_metadata'),
        icon: 'wi-edit',
        url: `/cms-pages/${rowData.uuid}`,
        hidden: !permissions.canUpdateCMSPage,
      },
      {
        label: t('txt_design_content'),
        icon: 'wi-edit',
        url: `/cms-pages/${rowData.uuid}/content`,
        hidden: !permissions.canUpdateCMSPage,
      },
      {
        label: t('txt_publish_page'),
        icon: 'wi-online-circle',
        command: (e: any) => onPublishPage(rowData),
        hidden: !permissions.canUpdateCMSPage,
      },
      {
        label: t('txt_unpublish_page'),
        icon: 'wi-offline-circle',
        className: `${(!rowData?.custom_config?.published_info || !(rowData?.custom_config?.published_info?.length > 0)) && 'menu-item-hidden'}`,
        command: (e: any) => onUnpublishedPage(rowData),
        hidden: !permissions.canUpdateCMSPage,
      },
      {
        label: t('txt_duplicate_page'),
        icon: 'wi-copy',
        url: `/cms-pages/create?sourceId=${rowData.uuid || ''}`,
        hidden: !permissions.canCreateCMSPage,
      },
      {
        label: t('txt_delete'),
        icon: 'wi-trash',
        hidden: !permissions.canUpdateCMSPage,
        dangerous: true,
        disabled: !(!rowData.special_type && !rowData.parent_id),
        command: (e: any) => onDelete(e, rowData),
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout title={t('txt_title')} className="cms-pages-manager">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearch}
            />
            {/* <div className="filter-btn">
                            <div
                                className="filter-btn-content p-8 ml-16"
                            // onClick={() => setIsShowSidebarFilter(true)}
                            >
                                <FilterSvg ></FilterSvg>
                            </div>
                        </div> */}
          </div>
          <Link className="wi-primary-button-v2" to="/cms-pages/create" hidden={!permissions.canCreateCMSPage}>
            <div className="wi-btn-label">
              {t('txt_btn_add')} <PlusSvg className="icon-svg"></PlusSvg>
            </div>
          </Link>
        </div>
      </div>
      <div className="ml-24 mr-24 mt-12 mb-0">
        <div className="page-type-item">
          <TabMenu
            className="wi-tabmenu-v2"
            model={pageTypes.map((p, i) => ({ uuid: p.uuid, key: p.uuid, label: globalTrans(`cms_types.txt_type_${p.code}`) }))}
            activeIndex={pageTypes.findIndex(p => p.uuid === selectedPageType)}
            onTabChange={(e: any) => handleChangeStatus(e.value)}
          />
        </div>
      </div>
      <div className="p-table-v2 ml-24 mr-24">
        <DataTable
          loading={isLoading || pageTypes.length === 0}
          value={cmsPages.data}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          scrollDirection="both"
          cellSelection
          scrollHeight="calc(100vh - 276px)"
          // selectionMode="single"
          // onSelectionChange={(e) => onSelectedCell(e.value)}
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={cmsPages.totalPage} onPageChange={onBasicPageChange} />
      </div>
      <WIConfirmDialog
        visible={isShowDeleteDialog}
        onHide={() => setIsShowDeleteDialog(false)}
        onConfirm={callDeleteAPI}
        message={
          <Fragment>
            <span style={{ fontSize: '18px', fontWeight: '700' }}>Are you sure you want to delete this page?</span>
            <br />
            <span style={{ fontSize: '13px' }}>
              All of your below data will be deleted <b>permanently</b>:
              <ul style={{ marginBottom: '5px' }}>
                <li>
                  This page <b>{cmsPage?.name}</b>
                </li>
                <li>
                  All <b>child pages</b> related to this page
                </li>
              </ul>
              You <b>cannot undo</b> this action.
            </span>
          </Fragment>
        }
        classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
      />
    </Layout>
  );
};

export default PageManager;
