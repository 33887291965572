import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import './wilderness-run-manager.scss';
import Layout from '../layout/layout';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { LandManagerAPI, PartnerManagerAPI } from '../../services';
import { Toast } from 'primereact/toast';
import { useSearchParams, Link } from 'react-router-dom';
import { MenuDot, WIPaginator } from '../common';
import { formatDateByFormatBodyTemplate } from '../common/column-template-table/column-template';
import _ from 'lodash';
import {
  getWildernessRunsObjectSearchValue,
  getWildernessRunsObjectWithValues,
  getWildernessRunsPayloadConditions,
  getObjectWithValues,
} from './wilderness-run-manager-util';
import { Sidebar } from 'primereact/sidebar';
import AddWildernessFormComponent from './components/add-wilderness-run-manager/add-wilderness-run-form';
import { COMMON_STATUS, WIR_STATUS, WIR_STATUS_LIST } from '../../utils/utils';
import WildernessRunsFilterComponent from './components/wilderness-runs-filter/wir-run-filter-component';
import moment from 'moment';
import { WildernessRunsManagerAPI } from '../../services';
import WITagWIR from '../wilderness-run-details/components/tag-wir/tag-wir';
import { generateFileDownload, showNotification } from '../../utils/logic';

export type FilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    status: string[];
    land_id: string[];
    partner_id: string[];
    dates: string[];
  };
  order: [];
};

const WildernessRunManager = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const sortField = searchParams?.get('sortField') || 'run_date';
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const filterPartner = searchParams?.get('partner_id') ? searchParams?.get('partner_id')?.split(',') || [] : [];
  const filterLands = searchParams?.get('filterLands') ? searchParams?.get('filterLands')?.split(',') || [] : [];
  const filterStatus = (searchParams?.get('filterStatus') || WIR_STATUS_LIST.map(c => c.code).join(',')).split(',');
  const filterDates = searchParams?.get('filterDates') ? searchParams?.get('filterDates')?.split(',') || [] : [];
  const searchField = searchParams?.get('search') || '';

  const statusTypeAll = 'all';
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [wildernessRuns, setWildernessRuns] = useState({
    data: [],
    totalPage: 5,
  });
  const [paginator, setPaginator] = useState({
    page: 0,
    first: 0,
    rows: 20,
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const [filterValues, setFilterValues] = useState<FilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getWildernessRunsObjectSearchValue(searchField) : [],
      status: filterStatus,
      land_id: filterLands,
      partner_id: filterPartner,
      dates: filterDates,
    },
    order: [],
  });
  const [wildernessRun, setWildernessRun] = useState<any>(null);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [lands, setLands] = useState<any>([]);
  const [statusWIR, setStatusWIR] = useState<any[]>([]);
  const [selectedStatusWIR, setSelectedStatusWIR] = useState<any>(filterStatus.length !== 1 ? statusTypeAll : filterStatus[0]);
  const [partners, setPartners] = useState<any[]>([]);

  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
  };

  const setupWildernessRunsStatus = async () => {
    const statusList = _.cloneDeep(WIR_STATUS_LIST);
    statusList.unshift({ code: 'all', name: { de: 'All', en: 'All' } });
    setStatusWIR(statusList);
  };

  const fetchWIRData = async () => {
    setIsLoading(true);
    const payload = getWildernessRunsPayloadConditions(filterValues, sortConfig);

    try {
      const resWildernessRuns = await WildernessRunsManagerAPI.getWildernessRuns({
        pageNo: paginator.page,
        range: paginator.rows,
        ...payload,
      });
      if (resWildernessRuns && resWildernessRuns.status === 200) {
        setWildernessRuns({
          data: resWildernessRuns.data.records,
          totalPage: resWildernessRuns.data.total || 0,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setWildernessRuns({
        data: [],
        totalPage: 0,
      });
      setIsLoading(false);
    }
  };

  const fetchAreasData = async () => {
    setIsLoading(true);
    const [landsRes, partnersRes] = await Promise.all([LandManagerAPI.getAllLands(), PartnerManagerAPI.getAllPartners()]);

    // @ts-ignore: Object is possibly 'null'.
    if (landsRes && landsRes.status === 200 && landsRes.data.records?.length > 0) {
      const lands = landsRes.data.records?.filter((f: any) => f.status === COMMON_STATUS.ACTIVE);
      setLands(_.sortBy(lands, item => item.name?.de?.toLowerCase()));
    }

    if (partnersRes.status === 200 && partnersRes.data.records.length > 0) {
      setPartners(_.sortBy(partnersRes.data.records, item => item.name?.de?.toLowerCase()));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAreasData();
    setupWildernessRunsStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof paginator.page === 'number') {
      setFilterValues({
        ...filterValues,
        pageNo: paginator.page,
        range: paginator.rows,
      });
      setSearchParams({
        pageNumber: (paginator.page + 1).toString(),
        noRows: paginator.rows.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (paginator.page + 1).toString(),
      noRows: paginator.rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterStatus: filterValues.where.status.join(',') || '',
      filterLands: filterValues.where.land_id?.join(',') || '',
      partner_id: filterValues.where.partner_id?.join(',') || '',
      filterDates: filterValues.where.dates?.join(',') || '',
    };
    setSearchParams(_.omitBy(searchParams, p => !p));
    fetchWIRData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getWildernessRunsObjectWithValues(filterValues.where);
    const or_conditions = getWildernessRunsObjectSearchValue(searchText);
    where_conditions['or'] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (paginator.page !== 0) {
      setPaginator({
        ...paginator,
        page: 0,
        first: 0,
      });
    }
  };

  const onFilterChange = (values: any) => {
    const { field, value } = values;
    const where_conditions = getObjectWithValues(filterValues.where);
    if (field !== 'dates') {
      where_conditions[field] = value;
    } else {
      const dates = value.map((c: any) => moment(c).format('DD/MM/YYYY'));
      where_conditions[field] = dates;
      where_conditions.from_run_date = dates[0];
      where_conditions.to_run_date = dates[1];
    }
    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    if (selectedStatusWIR === statusTypeAll) {
      p.where['status'] = [...WIR_STATUS_LIST.map(c => c.code)];
    } else {
      p.where['status'] = [selectedStatusWIR];
    }
    p.search = '';
    setFilterValues(p);
  };

  const handleChangeStatus = (status: any) => {
    setPaginator({
      ...paginator,
      page: 0,
      first: 0,
    });
    setSelectedStatusWIR(status.code);

    if (status.code === statusTypeAll) {
      let p = _.cloneDeep({ ...filterValues });
      p.where['status'] = [...WIR_STATUS_LIST.map(c => c.code)];
      setFilterValues(p);
    }
  };

  const formatAmountBodyTemplate = (rowData: any, column: string) => {
    return (
      <div>
        <span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>
          {rowData && rowData[column] ? parseFloat(rowData[column]?.toString())?.toLocaleString('de') : '-'}
        </span>{' '}
      </div>
    );
  };

  const onDownloadPDFCertificate = async (rowData: any) => {
    setIsLoading(true);
    try {
      const res = await WildernessRunsManagerAPI.downloadWIRCertificate(rowData.id);
      if (res && res.status === 200) {
        const wir_name = rowData.name.trim().replace(/ /g, '-');
        const partner_name = rowData.partner_name.trim().replace(/ /g, '-');
        generateFileDownload(`Wildnislauf_Urkunde_${wir_name}_${partner_name}.pdf`, res.data.content);
        showNotification('success', 'Download certificate successfully.', toast);
      } else {
        showNotification('error', 'Download certificate failed.', toast);
      }
      setIsLoading(false);
    } catch (error) {
      showNotification('error', 'Download certificate failed.', toast);
      setIsLoading(false);
    }
  };

  const onSendWIRCertificate = async (rowData: any, e: any) => {
    try {
      setIsLoading(true);
      const resWildernessRuns = await WildernessRunsManagerAPI.sendWIRCertificate(rowData.id);
      if (resWildernessRuns && resWildernessRuns.status === 200) {
        showNotification('success', 'Sent mail successfully', toast);
      }
      setIsLoading(false);
    } catch (error) {
      showNotification('error', 'Failed to send mail', toast);
      setIsLoading(false);
    }
  };

  const onMarkStatusWIR = async (rowData: any, valueStatus: string) => {
    try {
      setIsLoading(true);
      const resWildernessRuns = await WildernessRunsManagerAPI.updateWildernessRun(rowData.id, { ...rowData, status: valueStatus });
      if (resWildernessRuns && resWildernessRuns.status === 200) {
        showNotification('success', 'Marked successfully', toast);
        fetchWIRData();
      }
      setIsLoading(false);
    } catch (error) {
      showNotification('error', 'Failed to mark', toast);
      setIsLoading(false);
    }
  };

  const onHideSideBar = () => {
    setIsShowSidebar(false);
    if (wildernessRun) {
      setWildernessRun(null);
    }
  };

  const tableColumns = [
    {
      field: 'name',
      header: 'Name',
      width: 'calc(92% - 1100px)',
      minWidth: '230px',
      align: 'left',
      bodyTemPlate: (rowData: any, column: any) => {
        return (
          <Link onClick={e => e.stopPropagation()} className="table-ref-link-cell" to={`/wilderness-runs/${rowData.id}`}>
            {rowData.name}
          </Link>
        );
      },
    },
    {
      field: 'partner_id',
      header: 'Partner',
      width: '250px',
      bodyTemPlate: (rowData: any, column: any) => {
        return rowData.partner_id ? (
          <Link onClick={e => e.stopPropagation()} className="table-ref-link-cell" to={`/partners/${rowData.partner_id}`}>
            {rowData.partner_name}
          </Link>
        ) : (
          <span>{rowData.partner_name}</span>
        );
      },
      align: 'left',
    },
    {
      field: 'land_id',
      header: 'Area',
      width: '230px',
      bodyTemPlate: (rowData: any, column: any) => {
        return rowData.land_id ? (
          <Link onClick={e => e.stopPropagation()} className="table-ref-link-cell" to={`/areas/${rowData.land_id}`}>
            {rowData.land_name}
          </Link>
        ) : (
          <span>{rowData.land_name}</span>
        );
      },
      align: 'left',
    },
    {
      field: 'status',
      header: 'Status',
      width: '150px',
      bodyTemPlate: (rowData: any) => {
        return <WITagWIR value={rowData?.status || 'new'}></WITagWIR>;
      },
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'expected_paid_money',
      header: 'Expected Amount (€)',
      width: '190px',
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'expected_paid_money'),
      align: 'left',
    },
    {
      field: 'actual_paid_money',
      header: 'Actual Paid Amount (€)',
      width: '210px',
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'actual_paid_money'),
      align: 'left',
    },
    {
      field: 'run_date',
      header: 'Date',
      width: '150px',
      bodyTemPlate: formatDateByFormatBodyTemplate('MMM DD, YYYY'),
      align: 'left',
      disabledSort: false,
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
          minWidth: col.minWidth,
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: 'Open',
        description: 'Open WIR Details',
        icon: 'fa-solid fa-arrow-up-right-from-square',
        url: `/wilderness-runs/${rowData.id}`,
      },
      {
        label: 'Download WIR Certificate',
        description: 'Download WIR certificate as PDF',
        icon: 'fa-solid fa-download',
        command: (e: any) => onDownloadPDFCertificate(rowData),
        disabled: !rowData.partner_id,
      },
      {
        label: 'Send WIR Certificate',
        description: 'Send WIR certificate to contact person',
        icon: 'fa-solid fa-paper-plane',
        command: (e: any) => onSendWIRCertificate(rowData, e),
        disabled: !rowData.partner_id,
      },
      {
        label: 'Mark As New',
        description: 'Mark Wilderness Run as up-coming',
        icon: 'fa-solid fa-bolt-lightning',
        disabled: rowData.status === WIR_STATUS.NEW || rowData.actual_paid_money,
        command: (e: any) => onMarkStatusWIR(rowData, WIR_STATUS.NEW),
      },
      {
        label: 'Mark As Active',
        description: 'Mark Wilderness Run as on-going',
        icon: 'fa-solid fa-toggle-on',
        disabled: rowData.status === WIR_STATUS.ACTIVE,
        command: (e: any) => onMarkStatusWIR(rowData, WIR_STATUS.ACTIVE),
      },
      {
        label: 'Mark As Completed',
        description: 'Mark Wilderness Run as done',
        icon: 'fa-solid fa-circle-check',
        disabled: rowData.status === WIR_STATUS.COMPLETED,
        command: (e: any) => onMarkStatusWIR(rowData, WIR_STATUS.COMPLETED),
      },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const handleActionAdd = () => {
    setIsShowSidebar(true);
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="wilderness-runs-manager">
        <div className="manager-container">
          <div className="header-containers">
            <div className="wilderness-runs-header-panel">
              <WildernessRunsFilterComponent
                lang={'de'}
                items={statusWIR}
                selectedItem={selectedStatusWIR}
                onStatusChange={handleChangeStatus}
                isStatusAll={selectedStatusWIR === statusTypeAll}
                filterValues={filterValues}
                fields={['partner_id', 'land_id', 'status', 'dates']}
                onChange={onFilterChange}
                onRemove={onFilterRemove}
                onSearchFunc={onSearchFunc}
                onAddDonation={handleActionAdd}
                area={lands}
                partners={partners}
              />
            </div>
          </div>
          <div className="table-wilderness-runs">
            <DataTable
              loading={isLoading}
              value={wildernessRuns.data}
              scrollable
              dataKey="id"
              scrollHeight="calc(100vh - 208px)"
              scrollDirection="both"
              lazy
              onSort={(e: any) => {
                setSortConfig({
                  sortField: e.sortField,
                  sortOrder: e.sortOrder,
                });
              }}
              sortField={sortConfig.sortField}
              sortOrder={sortConfig.sortOrder}
            >
              {dynamicColumns}
              <Column frozen alignFrozen="right" style={{ width: '50px' }} body={buildMenu}></Column>
            </DataTable>
            <WIPaginator first={paginator.first} rows={paginator.rows} totalRecords={wildernessRuns.totalPage} onPageChange={onBasicPageChange} />
          </div>
        </div>
        <Sidebar
          visible={isShowSidebar}
          position="right"
          className="p-sidebar-md add-donor-sibar-right"
          style={{ width: '30em' }}
          onHide={() => onHideSideBar()}
        >
          <div className="add-donor-sidebar-content">
            <div className="headline">
              <h6>{wildernessRun ? 'Update a Wilderness Run' : 'Add a new Wilderness Run'} </h6>
            </div>
            <AddWildernessFormComponent
              lands={lands}
              fetchCallBack={() => fetchWIRData()}
              toast={toast}
              onHide={() => onHideSideBar()}
              wildernessRunData={wildernessRun}
              partners={partners}
            />
          </div>
        </Sidebar>
      </div>
    </Layout>
  );
};

export default WildernessRunManager;
