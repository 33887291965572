import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import React from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './download-teams-runners-dialog.scss';
import { WIButton, WICSVUploadField } from '../../../common';

const DownloadTeamsRunnersDialog = (props: any) => {
  const { visible, onHide } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [labelFile, setLabelFile] = useState(null);
  const [value, setValue] = useState<any>();
  const toast = useRef(null);

  const handleOnChangedValue = (evt: any) => {
    const file = evt.target.files[0];
    if (file && file.type === 'text/csv') {
      const label = evt.target.value.split('\\');
      setLabelFile(label[label.length - 1]);
      if (file) {
        const read = new FileReader();
        read.onload = function (e: any) {
          const contents = e.target.result;
          setValue(window.btoa(unescape(encodeURIComponent(contents))));
        };
        read.readAsText(file);
      } else {
        alert('Failed to load file');
      }
    } else {
      alert('Only import files csv');
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={`Import Teams and Runner`} visible={visible} style={{ width: '350px' }} modal className="wi-dialog" onHide={onHide}>
        <div className="dialog-teams-and-runners-container">
          <div className="row">
            <div className="col-md-12 teams-and-runners-container upload-file">
              <label className="label-upload-csv" htmlFor="upload">
                Choose CSV
              </label>
              <input className="input-upload" type="file" name="upload" id="upload" accept=".csv" onChange={handleOnChangedValue} />
              {labelFile ? <label className="name-file">{labelFile}</label> : null}
            </div>
          </div>

          <div className="btn-submit-container">
            <WIButton className="add-wir" primary={true} type="submit" label="Submit" icon={'pi-angle-right'} />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DownloadTeamsRunnersDialog;
