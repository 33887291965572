import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { Column, ColumnAlignType } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { removeEmpty, FIELDS_SEARCH } from '../../utils/utils';
import DonationExamplesManagerAPI from '../../services/global-contents/donationExamplesManager';
import { Button } from 'primereact/button';
import useAuth from '../../context/useAuth';
import { PERMISSIONS_V2 } from '../../utils/constants';
import Layout from '../../layout/layout';
import { useLayoutV2 } from '../../context/LayoutProvider';
import { useTranslation } from 'react-i18next';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIConfirmDialog from '../../components_v2/common/delete-confirm-dialog/delete-confirm-dialog';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { ReactComponent as WarningSvg } from '../../assets/images/icons/warning-symbol.svg';
import { Sidebar } from 'primereact/sidebar';
import DonationExampleForm from './components/donation-example-form/donation-example-form';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import { fetchCMSAllPages, getInternalURLPages } from '../../components/pages/builder-page.common';
import { formatDateV2, formatMultiLangField } from '../../utils/logic';

const DonationExamplesManager = (props: any) => {
  const { getLanguage, setErrorProgress, setSuccessProgress } = useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'donation_example_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [donationExamples, setDonationExamples] = useState({
    data: [],
    totalPage: 0,
  });
  const [page, setPage] = useState(0);
  const [donationExample, setDonationExample] = useState<any>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [search, setSearch] = useState<any>(null);
  const [globalContent, setGlobalContent] = useState<any>({
    search_content: [],
  });

  const { auth } = useAuth();

  const permissions = {
    canCreateDonationExample: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_CREATE),
    canUpdateDonationExample: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_UPDATE),
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchDonationExamplesData = async (valueSearch?: string) => {
    setIsLoading(true);
    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.DONATIONEXAMPLES : undefined,
    });

    try {
      const res = await DonationExamplesManagerAPI.getDonationExamples(data);
      if (res && res.status === 200) {
        setIsLoading(false);
        setDonationExamples({
          data: res.data.records || [],
          totalPage: res.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setDonationExamples({
        data: [],
        totalPage: 0,
      });
    }
  };

  const fetchGlobalContent = async () => {
    let allPages = await fetchCMSAllPages();
    let internal_urls = getInternalURLPages(allPages);
    let search_content = { internal_urls: internal_urls };
    let newGlobalContent = { ...globalContent, search_content };
    setGlobalContent(newGlobalContent);
  };

  const fetchData = async () => {
    setIsLoading(true);
    await Promise.all([fetchDonationExamplesData(), fetchGlobalContent()]);
    setIsLoading(false);
  };

  const onSearchHistory = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchDonationExamplesData(search);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === 'number') {
      fetchDonationExamplesData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const onCreateDonationExample = (isCreating: boolean, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDonationExample(null);
    setIsShowSidebar(true);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setDonationExample(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteDonationExamples = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setDonationExample(rowData);
  };

  const onDeleteConfirm = async () => {
    const response = await DonationExamplesManagerAPI.deleteDonationExample(donationExample.uuid);
    if (response && response.status === 200 && response.data.result.isValid) {
      onShowDeleteDialog(false);
      fetchDonationExamplesData(search);
      setSuccessProgress(t('txt_delete_example_success'));
    } else {
      setErrorProgress(errorTrans('txt_failed_delete_example'));
    }
  };

  const onUpdateDonationExamples = async (e: any, rowData: any) => {
    setIsLoading(true);
    const res = await DonationExamplesManagerAPI.getDonationExample(rowData.uuid);
    if (res && res.status === 200) {
      setDonationExample(res.data.record);
      setIsLoading(false);
      setIsShowSidebar(true);
    }
  };

  const tableColumns = [
    {
      field: 'example',
      header: t('txt_title'),
      width: 'calc(40% - 64px)',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const data = formatMultiLangField(rowData?.example, 'de');
        return <div className="wi-html-content" dangerouslySetInnerHTML={{ __html: data }} />;
      },
    },
    {
      field: 'url',
      header: t('txt_url'),
      width: '34%',
      align: 'left',
      bodyTemplate: (rowData: any) => formatMultiLangField(rowData?.url, 'de'),
    },
    {
      field: 'created_at',
      header: t('txt_created_at'),
      width: '13%',
      align: 'left',
      bodyTemplate: (rowData: any) => formatDateV2(rowData.created_at, getLanguage()),
    },
    {
      field: 'updated_at',
      header: t('txt_updated_at'),
      width: '13%',
      align: 'left',
      bodyTemplate: (rowData: any) => formatDateV2(rowData.updated_at, getLanguage()),
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-edit',
        hidden: !permissions.canUpdateDonationExample,
        command: (e: any) => {
          onUpdateDonationExamples(e, rowData);
        },
      },
      {
        label: t('txt_delete'),
        icon: 'wi-delete-folder',
        dangerous: true,
        hidden: !permissions.canUpdateDonationExample,
        command: (e: any) => {
          onDeleteDonationExamples(e, rowData);
        },
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout title={t('txt_title')}>
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearchHistory}
            />
          </div>
          <Button
            className="wi-primary-button-v2"
            label={t('txt_create')}
            hidden={!permissions.canCreateDonationExample}
            onClick={(e: any) => onCreateDonationExample(true, e)}
          >
            <PlusSvg className="icon-svg" />
          </Button>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={donationExamples.data}
          filters={search}
          scrollable
          scrollHeight="calc(100vh - 225px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={donationExamples.totalPage} onPageChange={onBasicPageChange} />
      </div>
      <Sidebar
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '700px' }}
        visible={isShowSidebar}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{donationExample ? t('txt_update_example') : t('txt_new_example')}</h6>
          </div>
          <DonationExampleForm
            fetchDonationExamplesData={fetchDonationExamplesData}
            onHide={() => setIsShowSidebar(false)}
            donationExample={donationExample}
            globalContent={globalContent}
          />
        </div>
      </Sidebar>
      <WIConfirmDialog
        visible={isShowDeleteDialog}
        onHide={() => onShowDeleteDialog(false)}
        onConfirm={onDeleteConfirm}
        maskClassName="top-mask-dialog"
        confirmLabel={t('txt_confirm')}
        hideLabel={t('txt_cancel')}
        message={
          <Fragment>
            <div style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center', marginBottom: '16px' }}>{t('txt_are_you_sure')}</div>
            <div style={{ fontSize: '13px', textAlign: 'center' }}>
              <div
                dangerouslySetInnerHTML={{ __html: t('txt_confirm_description', { permanently: `<strong>${t('txt_permanently')}</strong>` }) }}
              ></div>
              <div dangerouslySetInnerHTML={{ __html: t('txt_can_not_action', { cant: `<strong>${t('txt_cant_not')}</strong>` }) }}></div>
              <br />
              <b>{t('txt_note')}</b>: <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: t('txt_note_delete', {
                    quote: `<strong>${t('txt_example')}</strong>`,
                    unexpected: `<strong>${t('txt_unexpected_issue')}</strong>`,
                  }),
                }}
              ></div>
            </div>
          </Fragment>
        }
        icon={<WarningSvg className="icon-svg"></WarningSvg>}
      />
    </Layout>
  );
};

export default DonationExamplesManager;
