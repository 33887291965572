import React, { useRef } from 'react';
import { AxiosResponse } from 'axios';
import { MediaFolderManagerAPI } from '../../../../services';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import CircleInfoSvg from '../../../../assets/images/icons/info-circle.svg';
import * as Yup from 'yup';

const CreateDirectoryForm = (props: any) => {
  const { fetchMediaFolder, onHide, data, isAllNodes } = props;
  const formikRef = useRef<any>(null);
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'media_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

  const onSubmitDataForm = async (value: any) => {
    try {
      if (value && value.name) {
        setLoadingProgress(errorTrans('txt_loading'));
        const counter = 0;
        let folders = data.selectedNode?.node?.children || data.allNodes[0]?.children;
        if (isAllNodes) {
          folders = data.allNodes;
        }
        const folderName = verifyFolderName(folders, value.name, counter);
        value.name = folderName;
        const resGenerateDirectory = await requestDirectoryData(value);
        handleRequestDirectory(resGenerateDirectory);
      }
    } catch (error: any) {
      console.log('Error:', error);
      setErrorProgress(errorTrans(error.message || 'txt_create_folder_fail'));
    }
  };

  const requestDirectoryData = async (value: any) => {
    if (data.directory) {
      const directoryObj = data.directory;
      let request = { ...directoryObj, ...value };
      return updateDirectory({ ...request });
    }

    return generateDirectoryV2({ ...value });
  };

  const handleRequestDirectory = (response: AxiosResponse<any, any>) => {
    let method = response?.config?.method;
    if (response && response.status === 200 && (method === 'post' || method === 'put')) {
      setSuccessProgress(t('txt_save_folder_success'));
      fetchMediaFolder();
      onHide();
    } else {
      throw new Error('txt_create_folder_fail');
    }
  };

  const generateDirectoryV2 = async (parameters: any) => {
    parameters['parentId'] = null;
    if (data.selectedNode && data.selectedNode.node) {
      parameters['parentId'] = data.selectedNode.node?.uuid;
    }
    parameters['createdUser'] = 'admin';
    return await MediaFolderManagerAPI.createMediaFolderV2(parameters);
  };

  const updateDirectory = async (parameters: any) => {
    // Only update Name
    data.directory.name = parameters.name;
    data.directory.parentId = parameters.parentId;

    return await MediaFolderManagerAPI.updateMediaFolderV2(data.directory);
  };

  const verifyFolderName = (nodes: any, folderName: string, counter: any): string => {
    if (counter > nodes?.length) return `${folderName} (${counter})`;

    let name = folderName;
    const isDuplicated = checkDuplicateFolder(nodes, folderName, counter);

    if (isDuplicated) {
      counter = counter + 1;
      name = verifyFolderName(nodes, folderName, counter);
    } else if (!isDuplicated && counter > 0) {
      name = `${folderName} (${counter})`;
    }

    return name;
  };

  const checkDuplicateFolder = (nodes: any, newNode: any, subfix: any) => {
    const checkName = subfix > 0 ? `${newNode} (${subfix})` : newNode;
    return _.some(nodes, (item: any) => item.label === checkName);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('txt_required')
      .max(50, 'Max length is 50 letters')
      .matches(/^[^\\\\/?*"><:|]*$/, 'txt_file_name_format'),
  });

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{ name: data.directory?.name, parentId: data.directory?.parent_folder_id }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          //console.log("values >>>", values);
        }}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, handleChange }: any) => (
          <form onSubmit={handleSubmit}>
            <div className="row-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="element-form mb-24">
                    <label className="label mb-8">
                      {t('txt_name')} <span className="required-label ml-4">*</span>
                      <div className="icon-info ml-8">
                        <img src={CircleInfoSvg} alt="icon info" loading="lazy" className="m-0" />
                      </div>
                    </label>
                    <InputText type="text" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name || ''} maxLength={50} />
                    <div className={`${touched.name && errors.name ? 'error' : ''}`}>{errors.name ? errorTrans(errors?.name) : ''}</div>
                  </div>
                </div>
              </div>
              <div className="row mt-12">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between gap-24">
                    <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                    <Button
                      className="wi-primary-button-v2 h48 flex-1"
                      type="submit"
                      label={`${t('txt_edit')}`}
                      onClick={() => onSubmitDataForm(values)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateDirectoryForm;
