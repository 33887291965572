import { AxiosClient } from './httpClient';

export default class CampaignManagerAPI {
  static getAllCampaigns() {
    return AxiosClient.get(`/campaigns`);
  }

  static getCampaign(id: string) {
    return AxiosClient.get(`/campaigns/${id}`);
  }

  static getCampaigns(data: any) {
    return AxiosClient.get(`/campaigns/pagination`, { ...data });
  }

  static searchCampaigns(data: any) {
    return AxiosClient.post(`/campaigns/search`, { ...data });
  }

  static createCampaign(data: any) {
    return AxiosClient.post(`/campaigns`, data);
  }

  static UpdateCampaign(id: string, data: any) {
    return AxiosClient.put(`/campaigns/${id}`, data);
  }

  static DeleteCampaign(id: string) {
    return AxiosClient.delete(`/campaigns/${id}`);
  }
}
