import { Toast } from 'primereact/toast';
import * as React from 'react';
import { useRef } from 'react';
import { AxiosResponse } from 'axios';
import { CMsPageManagerAPI } from '../../../../services';
import DynamicForm from '../../../dynamic-form/dynamic-form';
import { showNotification } from '../../../../utils/logic';
import './global-block-component.scss';

const GlobalBlockDialogComponent = (props: any) => {
  const { globalBlock, fetchGlobalBlockData, onHide } = props;
  const toast = useRef(null);

  const updateGlobalBlock = async (parameters: any) => {
    return await CMsPageManagerAPI.updateCTABlocks(parameters);
  };

  const requestUpdateData = async (value: any) => {
    if (globalBlock) {
      const obj = globalBlock;
      let request = { ...obj, parameters: { ...value, is_global_ref: true } };
      return updateGlobalBlock({
        ...request,
      });
    }
  };

  const handleRequestGlobalBlock = (resGenerateGlobalBlocks: AxiosResponse<any, any>) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateGlobalBlocks;
    if (response && response.status === 200) {
      showNotification('success', 'Update global block successfully', toast);
      onHide();
      fetchGlobalBlockData();
    } else {
      showNotification('error', 'Failed to generate global block', toast);
    }
  };

  const onSubmitDataForm = async (value: any) => {
    try {
      const resRequestUpdateData = await requestUpdateData(value);
      // @ts-ignore: Object is possibly 'null'.
      handleRequestGlobalBlock(resRequestUpdateData);
    } catch (error) {
      showNotification('error', 'Failed to generate global block', toast);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="globalBlock-dynamic">
        <h3>{globalBlock?.name || 'Global Block'}</h3>
        <DynamicForm
          formSchema={globalBlock ? globalBlock.configuration : {}}
          data={globalBlock ? globalBlock.parameters : {}}
          submitButtonConfig={{
            title: globalBlock?.uuid ? 'Update' : 'Create',
            icon: 'pi pi-chevron-right',
            iconPos: 'right',
          }}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
        ></DynamicForm>
      </div>
    </>
  );
};

export default GlobalBlockDialogComponent;
