import { AxiosClient, cacheAge } from '../httpClient';

export default class FAQsManagerAPI {
  static getAllFAQs(enableCache: boolean = true) {
    return enableCache ? AxiosClient.get(`/faqs`, cacheAge) : AxiosClient.get(`/faqs`);
  }

  static paginateFAQs(data: any) {
    return AxiosClient.get(`/faqs/pagination`, data);
  }

  static getFAQ(uuid: string) {
    return AxiosClient.get(`/faqs/${uuid}`);
  }

  static generateFAQs(data: any) {
    return AxiosClient.post(`/faqs`, data);
  }

  static updateFAQs(data: any) {
    return AxiosClient.put(`/faqs/${data.uuid}`, data);
  }

  static deleteFAQ(uuid: string) {
    return AxiosClient.delete(`/faqs/${uuid}`);
  }

  static getHeaders() {
    return AxiosClient.get(`/headers`);
  }

  static getHeader(uuid: string) {
    return AxiosClient.get(`/headers/${uuid}`);
  }

  static generateHeaders(data: any) {
    return AxiosClient.post(`/headers`, data);
  }

  static updateHeaders(data: any) {
    return AxiosClient.put(`/headers/${data.uuid}`, data);
  }

  static getFooters() {
    return AxiosClient.get(`/footers`);
  }

  static getFooter(uuid: string) {
    return AxiosClient.get(`/footers/${uuid}`);
  }

  static generateFooters(data: any) {
    return AxiosClient.post(`/footers`, data);
  }

  static updateFooters(data: any) {
    return AxiosClient.put(`/footers/${data.uuid}`, data);
  }

  static getAllArticles() {
    return AxiosClient.get(`/list-articles`, cacheAge);
  }
}
