import { AxiosClient, cacheAge } from '../httpClient'

export default class GlobalSettingsAPI {
  static deleteGlobalSetting(id: string) {
    return AxiosClient.delete(`/global-settings/${id}`);
  }

  static createGlobalSetting(data: any) {
    return AxiosClient.post(`/global-settings`, data);
  }

  static updateGlobalSetting(data: any, id: any) {
    return AxiosClient.put(`/global-settings/${id}`, data);
  }

  static getAll() {
    return AxiosClient.get(`/global-settings`);
  }

  static getGlobalSetting(uuid: string) {
    return AxiosClient.get(`/global-settings/${uuid}`);
  }
}