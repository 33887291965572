import { useField } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import './wi-upload-field-v2.scss';
import './wi-upload-multiple-field-v2.scss';
import {
  formatFileSize,
  getBase64,
  getCompressedImage,
  getCompressedThumbnail,
  getFileExtension,
  getImageDimensions,
  IconConfig,
  IMAGE_EXTENSIONS,
  isFileOverSize,
  isFileTypeNotAllowed,
  parseImageMetadata,
} from '../../../utils/mediaUtils';
import { ReactComponent as ImageSvg } from '../../../assets/images/icons/upload-cloud.svg';
import { ReactComponent as TrashSvg } from '../../../assets/images/icons/trash.svg';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useLayoutV2 } from '../../../context/LayoutProvider';

const MAX_COUNT = 10;
const TYPES_SUPPORTED = '.jpg, .jpeg, .png, .svg, .doc, .docx, .xls, .xlsx, .pdf, .txt, .zip, .rar, .7z, .avi, .mov, .mp4';

const WIUploadMultipleField = (props: any) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched } = helpers;
  const { error, touched } = meta;
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const { t } = useTranslation('language', { keyPrefix: 'upload_media_process' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setErrorProgress } = useLayoutV2();

  const onSelectedFiles = (e: any) => {
    e.preventDefault();
    setTouched(true);
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    if (areValidatedFiles(chosenFiles)) {
      handleUploadFiles(chosenFiles);
    } else {
      e.target.value = null;
    }
  };

  const areValidatedFiles = (chosenFiles: any) => {
    const foundFileOverSizeLimit = chosenFiles.some(isFileOverSize);
    if (foundFileOverSizeLimit) {
      setErrorProgress(errorTrans('txt_file_size_exceeded'));
      return false;
    }

    const hasFileIsNotAllowed = chosenFiles.some(isFileTypeNotAllowed);
    if (hasFileIsNotAllowed) {
      setErrorProgress(errorTrans('txt_file_type_not_supported'));
      return false;
    }

    return true;
  };

  const handleUploadFiles = async (files: any) => {
    const imagesArray = Array.from(files).map(async (uFile: any) => {
      let f = uFile;
      const fileExtension = getFileExtension(f.name).toLowerCase();
      const isImage = IMAGE_EXTENSIONS.some(item => item === fileExtension) && fileExtension !== 'svg' && fileExtension !== 'png';
      let thumbnailBase64 = null;
      let fileMetaInfo;
      if (IMAGE_EXTENSIONS.some(item => item === fileExtension)) {
        fileMetaInfo = await parseImageMetadata(f);
      }
      if (isImage) {
        f = await getCompressedImage(uFile);
        const thumbnailFile = await getCompressedThumbnail(f);
        thumbnailBase64 = await getBase64(thumbnailFile);
      }

      let fileBase64: any = await getBase64(f);
      if (IMAGE_EXTENSIONS.some(item => item === fileExtension)) {
        // const description = { de: "", en: "" };
        const dimensions = await getImageDimensions(fileBase64);
        fileMetaInfo = { ...fileMetaInfo, dimensions };
      }
      const fileIcon = IconConfig[fileExtension] || '';

      return {
        url: fileBase64,
        icon: fileIcon,
        name: f.name,
        size: f.size,
        type: f.type,
        extension: fileExtension,
        metadata: fileMetaInfo || {},
        thumbnailBase64: thumbnailBase64,
      };
    });

    await Promise.all(imagesArray).then((results: any) => {
      const updatedList = [...selectedFiles, ...results];
      setSelectedFiles(updatedList);
      setValue(updatedList, true);
    });
  };

  const deleteFileHandler = (file: any) => {
    const updatedList = selectedFiles.filter((e: any) => e !== file);
    setSelectedFiles(updatedList);
    setValue(updatedList, true);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setTouched(true);
    const chosenFiles = Array.prototype.slice.call(e.dataTransfer.files);

    setDropzoneActive(false);
    if (areValidatedFiles(chosenFiles)) {
      handleUploadFiles(chosenFiles);
    } else {
      e.dataTransfer.value = null;
    }
  };

  return (
    <>
      <div
        onDragOver={e => {
          setDropzoneActive(true);
          e.preventDefault();
        }}
        onDragLeave={e => {
          setDropzoneActive(false);
          e.preventDefault();
        }}
        onDrop={e => handleDrop(e)}
        className={`dropzone-v2 ${props.className || ''} ${dropzoneActive ? 'active' : ''} ${error && touched && (selectedFiles?.length === 0 || selectedFiles?.length > MAX_COUNT) ? 'upload-error' : ''}`.trim()}
      >
        <label className={`label-upload-field-v2`}>
          <div className="upload-placeholder">
            <div className="d-flex justify-content-center">
              <div className="upload-placeholder-icon">
                <ImageSvg></ImageSvg>
              </div>
            </div>
            {selectedFiles.length > MAX_COUNT ? (
              <div className="error">
                {t('txt_max_files_error', { max: MAX_COUNT })}
                <br />
                {t('txt_max_files_sub_error', { number: selectedFiles.length - MAX_COUNT })}
              </div>
            ) : (
              <>
                <div className="mb-12">{t('txt_upload_from_computer')}</div>
                <div className="subtitle">
                  {t('txt_file_max_size')} - {t('txt_max_files')}
                </div>
              </>
            )}
            <div className="media_type_wrapper d-flex flex-row align-items-center justify-content-center mt-16">
              {['jpg', 'png', 'svg', 'zip', 'txt', 'pdf', 'mp4'].map(t => {
                const type = t?.trim()?.toUpperCase();
                return (
                  <div key={type} className="circle-text">
                    {type}
                  </div>
                );
              })}
            </div>
          </div>
          <input
            className="input-upload"
            type={props.type}
            name={field.name}
            multiple={true}
            accept={TYPES_SUPPORTED}
            onChange={(e: any) => onSelectedFiles(e)}
          />
        </label>
      </div>
      <div className="preview-file-container-v2 mt-24">
        {selectedFiles &&
          selectedFiles.map((file: any, i: any) => {
            return (
              <div key={i} className="file-review-v2">
                <div key={file} className="file-review-item">
                  <img alt="Media Preview" className={`file-review-img file-review-img-bg-${file.extension} mr-16`} src={file.icon || file.url} />
                  <div className="file-review-item-info">
                    <p className="file-review-item-info-name m-0">{file.name}</p>
                    <p className="file-review-item-info-size m-0">
                      {formatFileSize(file.size)}
                      <span className="file-extension ml-16">{file.extension}</span>
                    </p>
                    <Button className="wi-danger-button-icon-v2" type={'button'} onClick={() => deleteFileHandler(file)}>
                      <TrashSvg className="icon-svg"></TrashSvg>
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default WIUploadMultipleField;
