import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { showNotification } from '../../../utils/logic';
import Layout from '../../layout/layout';
import CreateCampaignFormComponent from './create-campaign-form/create-campaign-form';
import { CampaignManagerAPI, LandManagerAPI, PartnerManagerAPI, RegionManagerAPI } from '../../../services';
import { COMMON_STATUS } from '../../../utils/utils';

export const CreateCampaignManager = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<any>();
  const [lands, setLands] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [partners, setPartners] = useState<any>([]);
  const [regionLogo, setRegionLogo] = useState('');

  const callCreateCampaignAPI = async (data: any) => {
    try {
      let res = await CampaignManagerAPI.createCampaign(data);
      if (res && res.status === 200) {
        if (res.data?.result?.error?.code) {
          showNotification('error', 'The code is already existed.', toast);
        } else {
          showNotification('success', 'Generate Campaign successfully', toast);
          return res.data?.result;
        }
        return;
      }
    } catch (error) {
      console.log('catch error', error);
    }

    showNotification('error', 'Failed to generate Campaign', toast);
  };

  const callUpdateCampaignAPI = async (uuid: string, data: any) => {
    try {
      let res = await CampaignManagerAPI.UpdateCampaign(uuid, data);
      if (res && res.status === 200) {
        showNotification('success', 'Update Campaign successfully', toast);
        return;
      }
    } catch (error) {
      console.log('catch error', error);
    }

    showNotification('error', 'Failed to update Campaign', toast);
  };

  const updateChangedImages = (original: any, data: any) => {
    let request = { ...original, ...data };
    if (!(original.campaign_logo !== data.campaign_logo)) {
      delete request['campaign_logo'];
      delete request['campaign_logo_extension'];
    }
    if (!(original.certificate_animal_image !== data.certificate_animal_image)) {
      delete request['certificate_animal_image'];
      delete request['certificate_animal_image_extension'];
    }
    if (!(original.certificate_tree_image !== data.certificate_tree_image)) {
      delete request['certificate_tree_image'];
      delete request['certificate_tree_image_extension'];
    }
    if (!(original.certificate_drone_image !== data.certificate_drone_image)) {
      delete request['certificate_drone_image'];
      delete request['certificate_drone_image_extension'];
    }
    if (!(original.certificate_qr_code !== data.certificate_qr_code)) {
      delete request['certificate_qr_code'];
      delete request['certificate_qr_code_extension'];
    }

    return request;
  };

  const onSubmit = async (landData: any) => {
    setIsLoading(true);
    if (!(id && selectedCampaign)) {
      const uuid = await callCreateCampaignAPI(landData);
      if (uuid) {
        navigate(`/campaigns/${uuid}`);
      }
      setIsLoading(false);
    } else {
      let newCampaign = updateChangedImages(selectedCampaign, landData);
      await callUpdateCampaignAPI(selectedCampaign.uuid, newCampaign);
      const newDataUpdate = await fetchCampaignData(selectedCampaign.uuid);
      setSelectedCampaign(newDataUpdate);
      setIsLoading(false);
    }
  };

  const fetchCampaignData = async (uuid: string) => {
    setIsLoading(true);
    try {
      const res = await CampaignManagerAPI.getCampaign(uuid);
      if (res && res.status === 200) {
        const dataCampaing = res.data.record || {};
        setIsLoading(false);
        setSelectedCampaign(dataCampaing);
        setRegionLogo(dataCampaing && dataCampaing.land ? dataCampaing.region_logo : '');
        return dataCampaing;
      }
      return [];
    } catch (error) {
      setIsLoading(false);
      setSelectedCampaign(null);
    }
  };

  const fetchLandsData = async () => {
    const res = await LandManagerAPI.getAllLands();
    if (res && res.status === 200) {
      const lands = res.data.records;
      setLands(lands);
    }
  };

  const fetchRegionsData = async () => {
    const res = await RegionManagerAPI.getAllRegions();
    if (res && res.status === 200) {
      const regions = res.data.records;
      setRegions(regions);
    }
  };

  const fetchPartnersData = async () => {
    const res = await PartnerManagerAPI.getAllPartners(false);
    if (res && res.status === 200) {
      const activedPartners = res.data.records?.filter((f: any) => f.status === COMMON_STATUS.ACTIVE);
      activedPartners.unshift({ uuid: '', name: 'None' });
      setPartners(activedPartners);
    }
  };

  useEffect(() => {
    fetchLandsData();
    fetchRegionsData();
    fetchPartnersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (id) {
      fetchCampaignData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout customLayout={true} customCopyRight={true}>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="loading-component">
          <ProgressSpinner />
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex flex-column w-100 text-left">
        <CreateCampaignFormComponent
          data={{
            selectedCampaign,
            regionLogo,
            partners,
            lands,
            regions,
          }}
          setRegionLogo={setRegionLogo}
          onSubmit={onSubmit}
        />
      </div>
    </Layout>
  );
};

export default CreateCampaignManager;
