import React from 'react';
import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import './wi-text-html.scss';
import { ErrorMessage, useFormikContext } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import { formatHTMLCode } from '../../../../utils/logic';
import DialogTextHtml from './component/dialog-text-html';
import { CMsPageManagerAPI } from '../../../../services';

export function WITextHTML(props: any) {
  const { name, values } = props;
  const { handleBlur, errors, touched, setFieldValue } = useFormikContext();
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [headHtml, setHeadHtml] = useState('');
  const currentValue = _.get(values, name);
  const [areaValue, setAreaValue] = useState(currentValue);

  const getHeadHtml = async () => {
    try {
      const res = await CMsPageManagerAPI.getHeadHtml();
      if (res && res.status === 200) {
        setHeadHtml(res.data.htmlTemplate);
      }
      return '';
    } catch (error) {
      console.log('Fetch error', error);
      return '';
    }
  };
  useEffect(() => {
    getHeadHtml();
  }, []);

  const onShowDialog = () => {
    setIsShowDialog(true);
  };

  const onHide = () => {
    setIsShowDialog(!isShowDialog);
    setAreaValue(currentValue);
  };

  const formatedValue = (value: any) => {
    return formatHTMLCode(value);
  };

  const onSaveHtml = () => {
    setFieldValue(name, areaValue, false);
  };

  return (
    <>
      <WIFormElement {...props}>
        <div className="block-html" onClick={onShowDialog}>
          <InputTextarea
            style={{ height: '500px' }}
            className={`wi-form-textarea ${_.get(errors, name) && _.get(touched, name) ? 'has-error' : ''}`}
            id={name}
            name={name}
            value={formatedValue(_.get(values, name))}
            onBlur={handleBlur}
          />
          <ErrorMessage name={name} render={(msg: any) => <div className="error">{msg}</div>} />
        </div>
      </WIFormElement>
      <DialogTextHtml
        visible={isShowDialog}
        onHide={onHide}
        onSaveHtml={onSaveHtml}
        htmlTemplete={_.get(values, name)}
        setAreaValue={setAreaValue}
        setIsShowDialog={setIsShowDialog}
        areaValue={areaValue}
        headHtml={headHtml}
      />
    </>
  );
}
