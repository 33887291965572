import { useRef, useState } from 'react';
import React from 'react';
import './update-team-form.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { showNotification } from '../../../../utils/logic';
import { WIButton } from '../../../common';
import { InputText } from 'primereact/inputtext';
import { WildernessRunsManagerAPI } from '../../../../services';

const UpdateTeamForm = (props: any) => {
  const { onHide, fetchCallBack, toast, teamData, wildernessRunID } = props;

  const formikRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitFormTeam = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setIsLoading(true);
        let valuesData = {
          ...values,
        };

        let updateRes = await WildernessRunsManagerAPI.updateTeam(wildernessRunID, teamData.id, valuesData);
        if (updateRes?.status === 200 && updateRes?.data) {
          showNotification('success', 'Update team successfully.', toast);
          onHide();

          fetchCallBack(updateRes.data);
          return;
        }
      }
    } catch {
      showNotification('error', 'Check team failed.', toast);
      setIsLoading(false);
    }
  };

  const initialFormValues = () => {
    let initialTeam = {
      name: '',
      leader_name: '',
      leader_email: '',
    };

    if (teamData) {
      initialTeam.name = teamData.name || '';
      initialTeam.leader_name = teamData.leader_name || '';
      initialTeam.leader_email = teamData.leader_email || '';
    }

    return initialTeam;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required('This field is required.'),
    leader_name: Yup.string().nullable().required('This field is required.'),
    leader_email: Yup.string().email('Email is invalid format').required('This field is required'),
  });

  return (
    <>
      <div className="update-team-form">
        <div className="update-team-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({ values, errors, touched, dirty, isValid, handleBlur, handleSubmit, setFieldValue, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          Team name
                          <span className="required-label"> *</span>
                        </label>
                        <InputText
                          className={`${touched.name && errors.name ? 'has-error' : ''}`}
                          value={values.name}
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={50}
                        />
                        <div className={`${touched.name && errors.name ? 'error' : ''}`}>{`${touched.name && errors.name ? errors.name : ''}`}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          Team leader name <span className="required-label"> *</span>
                        </label>
                        <InputText
                          className={`${touched.leader_name && errors.leader_name ? 'has-error' : ''}`}
                          value={values.leader_name}
                          name="leader_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={80}
                        />
                        <div className={`${touched.leader_name && errors.leader_name ? 'error' : ''}`}>
                          {touched.leader_name && errors.leader_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          Team leader email <span className="required-label"> *</span>
                        </label>
                        <InputText
                          className={`${touched.leader_email && errors.leader_email ? 'has-error' : ''}`}
                          value={values.leader_email}
                          name="leader_email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={255}
                        />
                        <div className={`${touched.leader_email && errors.leader_email ? 'error' : ''}`}>
                          {touched.leader_email && errors.leader_email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-submit">
                    <WIButton
                      className="update-team"
                      primary={true}
                      type="submit"
                      label="Update"
                      icon={'pi-angle-right'}
                      onClick={() => onSubmitFormTeam()}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdateTeamForm;
