import { AxiosClient } from '../httpClient';

export default class UserManagerAPI {
  static getUsersBySearch(data: any) {
    return AxiosClient.post(`/user-service/users/search`, data);
  }

  static getUser(uuid: any, isIpId?: boolean) {
    if (isIpId) {
      return AxiosClient.get(`/user-service/users/${uuid}?isIpId=true`);
    }
    return AxiosClient.get(`/user-service/users/${uuid}`);
  }

  static createUser(data: any) {
    return AxiosClient.post(`/user-service/users`, data);
  }

  static updateUser(data: any, uuid: any) {
    return AxiosClient.put(`/user-service/users/${uuid}`, data);
  }

  static acceptTermConditions(tenantId: string) {
    return AxiosClient.put(`/tenant-service/tenants/${tenantId}/term-condition`, {});
  }

  static enabledUser(data: any, uuid: string) {
    return AxiosClient.put(`/user-service/users/${uuid}/enabled`, data);
  }

  static disabledUser(data: any, uuid: string) {
    return AxiosClient.put(`/user-service/users/${uuid}/disabled`, data);
  }

  static deleteUser(uuid: any) {
    return AxiosClient.delete(`/user-service/users/${uuid}`);
  }

  static getAllPermissions() {
    return AxiosClient.get(`/permission-service/permissions`);
  }

  static canAccessPage(pageAliasName: string) {
    return AxiosClient.get(`/auth-alias-service/aliases/guard${pageAliasName}`);
  }

  static getRoles() {
    return AxiosClient.get(`/role-service/roles`);
  }
}
