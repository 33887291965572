import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button as PButton } from 'primereact/button';
import './confirm-password-dialog.scss';
import { Password } from 'primereact/password';

const ConfirmPasswordDialog = (props: any) => {
  const { visible, onConfirm, onHide, password, onSetPassword, errorPassword } = props;

  const buildFooterTemplate = () => (
    <React.Fragment>
      <PButton label="No" icon="pi pi-times" className="p-button-text button-hide" onClick={onHide} />
      <PButton label="Confirm" icon="pi pi-check" className="p-button-text button-confirm" onClick={onConfirm} />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        modal
        header="Confirmation Password"
        className="confirm-password-dialog"
        visible={visible}
        style={{ width: '550px' }}
        footer={buildFooterTemplate}
        onHide={onHide}
      >
        <div className="confirmation-password">
          <div className="pass_text">
            <div className="password_filed">
              <label htmlFor="pass_word" className="block">
                Password
              </label>
              <Password id="pass_word" className="block" toggleMask value={password} onChange={e => onSetPassword(e.target.value)} />
              {errorPassword && <div className="error">Incorrect password!</div>}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmPasswordDialog;
