import React, { createContext, useState } from 'react';

const AuthContext = createContext<any>(null);

const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState({}); // current user info

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
