import * as React from 'react';
import { useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { ListBox } from 'primereact/listbox';
import { fetchFromObject } from '../../donation-manager/donation-manager.util';
import './wi-listbox.scss';
import _ from 'lodash';

const WIListbox = (props: any) => {
  const { selectedValues, items, onHandlerChange, optionLabel, optionValue, style, setIsAllOptions } = props;
  const [isCheckedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    setCheckedAll(true);
  }, []);

  useEffect(() => {
    if (setIsAllOptions) {
      setIsAllOptions(isCheckedAll);
    }
  }, [isCheckedAll]);

  useEffect(() => {
    if (setIsAllOptions) {
      selectedValues.length === items.length ? setIsAllOptions(true) : setIsAllOptions(false);
    }
  }, [selectedValues]);

  const itemTemplateFunc = (option: any) => {
    const label = fetchFromObject(option, optionLabel);
    const value = fetchFromObject(option, optionValue);

    return (
      <div className="list-boxs">
        <Checkbox inputId="cb1" value={value} checked={selectedValues?.includes(value) || false}></Checkbox>
        <label htmlFor="cb1" className="p-checkbox-label">
          {label}
        </label>
      </div>
    );
  };

  return (
    <div className="listbox-container">
      <ListBox
        listStyle={style}
        value={selectedValues}
        options={items}
        onChange={onHandlerChange}
        optionLabel={optionLabel}
        optionValue={optionValue}
        multiple={true}
        itemTemplate={itemTemplateFunc}
        filter={props.filter}
        filterBy={props.filterBy}
      />
    </div>
  );
};

export default WIListbox;
