import * as React from 'react';
import './change-password.scss';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { WIFormInputTextV2 } from '../../../../components_v2/common/form-fields';
import { VALIDATIONS } from '../../../../utils/constants';
import _ from 'lodash';
import { ReactComponent as CheckCircleSvg } from '../../../../assets/images/icons/check-circle.svg';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import AuthManagerAPI from '../../../../services/authManager';
import useAuth from '../../../../context/useAuth';

const ChangePasswordForm = (props: any) => {
  const { onHide } = props;
  const formikRef = useRef<any>(null);
  const { auth } = useAuth();
  const { t } = useTranslation('language', { keyPrefix: 'system_user_detail' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const [validations] = useState(_.cloneDeep(VALIDATIONS));
  const { setLoadingProgress, setSuccessProgress, setErrorProgress, setGlobalLanguage, setIsContentLoading } = useLayoutV2();

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required(errorTrans('txt_required')),
    new_password: Yup.string().required(errorTrans('txt_required')),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
      .required(errorTrans('txt_required')),
  });

  const initialFormValues = () => {
    return {
      current_password: '',
      new_password: '',
      confirm_password: '',
    };
  };

  const onSubmit = async () => {
    try {
      await formikRef.current?.validateForm();
      const { values, isValid } = formikRef?.current;

      if (formikRef && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));
        const res = await AuthManagerAPI.changeUserPassword(auth.email, values.current_password, values.new_password);
        if (res?.status === 200) {
          setSuccessProgress(t('txt_change_password_success'));
          onHide();
        } else if (res?.status === 400) {
          throw new Error('txt_login_401');
        } else {
          throw new Error('txt_change_password_failed');
        }
      }
    } catch (error: any) {
      const message = `txt_${error?.response?.data?.error_code}` || error.message || 'txt_change_password_failed';
      setErrorProgress(errorTrans(message));
    }
  };

  const onKeyDown = (event: any): void => {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={initialFormValues()}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {}}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="row-content">
            <div className="row">
              <div className="col-md-12 mb-24">
                <WIFormInputTextV2
                  type="password"
                  title={t('txt_current_password')}
                  name="current_password"
                  isRequired={true}
                  maxLength={40}
                  onKeyDown={onKeyDown}
                />
              </div>
              <div className="col-md-12 mb-24">
                <WIFormInputTextV2
                  type="password"
                  title={t('txt_new_password')}
                  name="new_password"
                  isRequired={true}
                  maxLength={40}
                  onKeyDown={onKeyDown}
                />
              </div>
              <div className="col-md-12 mb-24">
                <WIFormInputTextV2
                  type="password"
                  title={t('txt_confirm_password')}
                  name="confirm_password"
                  isRequired={true}
                  maxLength={40}
                  onKeyDown={onKeyDown}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex flex-column mb-16 gap-8">
                  {validations.map((v: any) => {
                    return (
                      <div className={`d-flex align-items-center gap-24 validate-rule ${v.validate(values.new_password) ? 'validation-pass' : ''}`}>
                        <CheckCircleSvg></CheckCircleSvg>
                        <div className="no-space">{t(v.label)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row mt-24">
              <div className="col-md-12">
                <div className="d-flex justify-content-between gap-24">
                  <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                  <Button className="wi-primary-button-v2 h48 flex-1" type="submit" label={`${t('txt_save')}`} onClick={() => onSubmit()} />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
