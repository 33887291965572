import * as React from 'react';
import { useState, useEffect } from 'react';
import { Column, ColumnAlignType } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { removeEmpty, FIELDS_SEARCH } from '../../utils/utils';
import { RegionManagerAPI } from '../../services';
import CountryService from '../../services/country-service';
import { env } from '../../configs';
import { Link } from 'react-router-dom';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import { PERMISSIONS_V2, STATUS_LIST_2 } from '../../utils/constants';
import WiStatus from '../../components_v2/common/wi-status/wi-status';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { useTranslation } from 'react-i18next';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import useAuth from '../../context/useAuth';
import { useLayoutV2 } from '../../context/LayoutProvider';
import { WiProgress } from '../../components_v2/common/wi-progress/wi-progress';
import { formatIntergerV2, formatMultiLangField } from '../../utils/logic';

const RegionManager = (props: any) => {
  const { auth } = useAuth();
  const permissions = {
    canCreateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_CREATE),
    canUpdateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_UPDATE),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
  };
  const { getLanguage } = useLayoutV2();
  const [isLoading, setIsLoading] = useState(false);
  const [regions, setRegions] = useState({
    data: [],
    totalPage: 0,
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(25);
  const { t } = useTranslation('language', { keyPrefix: 'region_manager' });

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchRegionsData = async (valueSearch?: string) => {
    setIsLoading(true);
    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.REGIONS : undefined,
    });

    try {
      const res = await RegionManagerAPI.getRegions(data);

      if (res && res.status === 200) {
        setIsLoading(false);
        setRegions({
          data: res.data.records || [],
          totalPage: res.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setRegions({
        data: [],
        totalPage: 0,
      });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    const [countriesRes, regionsRes] = await Promise.all([CountryService.getCountries(), RegionManagerAPI.getRegions({ pageNo: page, range: rows })]);
    if (countriesRes.status === 200 && regionsRes.status === 200) {
      setIsLoading(false);
      setRegions({
        data: regionsRes.data.records || [],
        totalPage: regionsRes.data.total || 0,
      });
    }
  };

  const onSearchHistory = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchRegionsData(search);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === 'number') {
      fetchRegionsData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const formatIdentifierBodyTemplate = (rowData: any) => {
    return (
      <a target="_blank" className="table-ref-link-cell" href={`${env.DONATION_URL}?region=${rowData.identifier}`} rel="noreferrer">
        {rowData.identifier}
      </a>
    );
  };

  const tableColumns = [
    {
      field: 'name',
      header: t('txt_name'),
      width: '20%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return formatMultiLangField(rowData?.name, getLanguage());
      },
    },
    {
      field: 'status',
      header: t('txt_status'),
      width: '200px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        const statusItem = STATUS_LIST_2.find((item: any) => item.code === rowData.status);
        return <WiStatus item={statusItem}></WiStatus>;
      },
    },
    {
      field: 'identifier',
      header: t('txt_identifier'),
      width: '15%',
      align: 'center',
      bodyTemplate: formatIdentifierBodyTemplate,
    },
    {
      field: 'default_land_id',
      header: t('txt_active_area'),
      width: '15%',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        const landName = rowData.land?.name?.de || rowData.land?.name;
        if (permissions.canViewArea && rowData.land && rowData.land?.uuid) {
          return (
            <Link className="table-ref-link-cell" to={`/areas/${rowData.land?.uuid}`}>
              {landName}
            </Link>
          );
        }
        return landName;
      },
    },
    {
      field: 'protected_size',
      header: t('txt_progress'),
      width: 'calc(40% - 264px)',
      align: 'left',
      bodyTemplate: WiProgress('', 'size', 'protected_size'),
    },
    {
      field: 'no_donations',
      header: t('txt_donations'),
      width: '10%',
      align: 'right',
      bodyTemplate: (rowData: any) => formatIntergerV2(rowData?.no_donations, getLanguage()),
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-open',
        url: `/regions/${rowData?.uuid}`,
      },
      // {
      //   label: t('txt_active'),
      //   icon: "wi-active",
      //   hidden: !permissions.canUpdateRegion
      // },
      // {
      //   label: t('txt_inactive'),
      //   icon: "wi-inactive",
      //   hidden: !permissions.canUpdateRegion
      // },
      // {
      //   label: t('txt_archive'),
      //   icon: "wi-archive",
      //   hidden: !permissions.canUpdateRegion
      // },
      // {
      //   label: t('txt_download_sample_cert'),
      //   icon: "wi-download",
      // },
      {
        label: t('txt_open_donation_form'),
        icon: 'wi-open-doc',
        externalUrl: `${env.DONATION_URL}?region=${rowData.identifier}`,
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout title={t('txt_title')} className="region-manager">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearchHistory}
            />
            {/* <div className="filter-btn">
              <div
                className="filter-btn-content p-8 ml-16"
              // onClick={() => setIsShowSidebarFilter(true)}
              >
                <FilterSvg ></FilterSvg>
              </div>
            </div> */}
          </div>
          {permissions.canCreateRegion ? (
            <Link className="wi-primary-button-v2" to="/regions/create">
              <div className="wi-btn-label">
                {t('txt_btn_add')} <PlusSvg className="icon-svg"></PlusSvg>
              </div>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={regions.data}
          filters={search}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={regions.totalPage} onPageChange={onBasicPageChange} />
      </div>
    </Layout>
  );
};

export default RegionManager;
