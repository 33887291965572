import { History, Transition } from 'history';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext, useLocation, useNavigate } from 'react-router-dom';
import { Button as PButton } from 'primereact/button';
import React from 'react';
import { Dialog } from 'primereact/dialog';
import './block-modal.scss';

type ExtendNavigator = Navigator & Pick<History, 'block'>;
export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as ExtendNavigator).block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: string, when = true) {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) {
        tx.retry();
      }
    },
    [message],
  );

  useBlocker(blocker, when);
}

const WIBlockDialog = (props: any) => {
  const { visible, onStay, onLeave, onSaveChanges, message, classIcon } = props;

  const buildFooterTemplate = () => (
    <React.Fragment>
      <PButton
        label="Stay"
        // icon="pi pi-times"
        className="p-button-text button-hide"
        onClick={onStay}
      />
      <PButton
        label="Leave Without Saving"
        // icon="pi pi-check"
        className="p-button-text button-hide btn-leave"
        onClick={onLeave}
      />
      <PButton label="Save Changes" className="p-button-text button-confirm" onClick={onSaveChanges} />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        modal
        header="Warning"
        className="wi-block-confirm-dialog wi-dialog wi-block-custom-dialog"
        maskClassName="top-mask-dialog"
        visible={visible}
        style={{ width: '550px' }}
        footer={buildFooterTemplate}
        onHide={onStay}
      >
        <div className="confirmation-content">
          {classIcon && <i className={classIcon} />}
          {message && <span>{message}</span>}
        </div>
      </Dialog>
    </>
  );
};

export const WIBlockDialogV2 = (props: any) => {
  const { visible, onStay, onLeave, onSaveChanges, message, classIcon } = props;

  const buildFooterTemplate = () => (
    <React.Fragment>
      <PButton
        label="Stay"
        // icon="pi pi-times"
        className="p-button-text button-hide"
        onClick={onStay}
      />
      <PButton
        label="Leave Without Saving"
        // icon="pi pi-check"
        className="p-button-text button-hide btn-leave"
        onClick={onLeave}
      />
      <PButton label="Save Changes" className="p-button-text button-confirm" onClick={onSaveChanges} />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        modal
        header="Warning"
        className="wi-block-confirm-dialog wi-dialog wi-block-custom-dialog"
        maskClassName="top-mask-dialog"
        visible={visible}
        style={{ width: '550px' }}
        footer={buildFooterTemplate}
        onHide={onStay}
      >
        <div className="confirmation-content">
          {classIcon && <i className={classIcon} />}
          {message && <span>{message}</span>}
        </div>
      </Dialog>
    </>
  );
};

export default WIBlockDialog;
