import { AxiosClient, cacheAge } from './httpClient';

export default class PartnerManagerAPI {
  static getAllPartners(enableCache: boolean = true) {
    return enableCache ? AxiosClient.get(`/partners`, cacheAge) : AxiosClient.get(`/partners`);
  }

  static getPartners(data: any) {
    return AxiosClient.post(`/partners/pagination`, { ...data });
  }

  static getPartner(uuid: string) {
    return AxiosClient.get(`/partners/${uuid}`);
  }

  static generatePartners(data: any) {
    return AxiosClient.post(`/partners`, data);
  }

  static updatePartners(uuid: any, data: any) {
    return AxiosClient.put(`/partners/${uuid}`, data);
  }

  static deletePartner(uuid: string) {
    return AxiosClient.delete(`/partners/${uuid}`);
  }

  static deleteTenant(uuid: string) {
    return AxiosClient.delete(`/tenant-service/tenants/${uuid}/term-condition`);
  }
}
