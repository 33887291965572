import German from '../../assets/images/flags/icon-lang-en.jpg';
import Canada from '../../assets/images/flags/icon-canada.png';

export enum SCREEN_MODES {
  Desktop,
  Tablet,
  Mobile,
}

export const SCREEN_MODE_OPTIONS = [
  {
    icon: 'fa-solid fa-display',
    value: SCREEN_MODES.Desktop,
    isSelected: true,
    cssClassName: 'screen-desktop',
    title: 'Desktop',
  },
  {
    icon: 'fa-solid fa-tablet-screen-button',
    value: SCREEN_MODES.Tablet,
    cssClassName: 'screen-tablet',
    title: 'Tablet',
  },
  {
    icon: 'fa-solid fa-mobile',
    value: SCREEN_MODES.Mobile,
    cssClassName: 'screen-mobile',
    title: 'Mobile',
  },
];

export const TabViewOptions = [
  {
    title: 'Block Library',
    icon: 'fa-solid fa-table-cells-large',
    value: 0,
  },
  {
    title: 'Block Editor',
    icon: 'fa-solid fa-pen-to-square',
    value: 1,
  },
  // {
  //   title: "Block Editor",
  //   icon: "fa-solid fa-layer-group",
  //   value: 2,
  // },
];

export const replaceParameter = (str: string, source: string, target: string) => {
  const regex = new RegExp(source, 'g');
  return str.replace(regex, target);
};

export const LANGUAGE_MODE_OPTIONS = [
  {
    // icon: 'fa-solid fa-desktop',
    value: German,
    isSelected: true,
    cssClassName: 'btn-lang-de',
    title: 'German',
    code: 'de',
  },
  {
    // icon: 'fa-solid fa-tablet-screen-button',
    value: Canada,
    cssClassName: 'btn-lang-en',
    title: 'English',
    code: 'en',
  },
];

export const PAGE_LANGUAGE = [
  {
    name: 'German',
    code: 'de',
  },
  {
    name: 'English',
    code: 'en',
  },
];

export const PAGE_STATUS = [
  {
    name: 'Draft',
    code: 'draft',
  },
  {
    name: 'Published',
    code: 'published',
  },
  {
    name: 'Archived',
    code: 'archived',
  },
  {
    name: 'Deleted',
    code: 'deleted',
  },
];

export const INVALID_URLS = ['donor-portal', 'map', 'calculator', 'assets', 'donate', 'fonts', 'lp', 'reports', 'spenden'];

export enum PAGE_CODE {
  General = 'general',
  Master = 'master',
  News = 'news',
  Action = 'action',
  EducationResearch = 'ducation-research',
  Expedition = 'expedition',
  LandingPage = 'landing-page',
  Media = 'media',
  Partner = 'partner',
  Press = 'press',
  Project = 'project',
  Region = 'region',
  WildernessRun = 'wilderness-run',
  Blog = 'blog',
  Person = 'person',
}

export const EmptyPageInfo = {
  name: 'Untitled',
  title: 'Untitled',
  language: 'both',
  url: 'https://untitle.de',
  status: 'draft',
};
