import * as React from 'react';
import { Formik } from 'formik';
import { CurrencyList } from '../../../../utils/utils';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { useRef, useState } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { ReceiptsAPI } from '../../../../services';
import { WIFormDropdownV2, WIFormInputNumberV2, WIFormInputTextV2 } from '../../../../components_v2/common/form-fields';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import WIConfirmPassword from '../../../../components_v2/common/wi-confirm-password/wi-confirm-password';
import { PASSWORD_SENDMAIL } from '../../../../utils/constants';

const RECEIPT_DEFAULT_VALUE = {
  name: '',
  amount: 0,
  payment_address: '',
  postal_code: '',
  city: '',
  country: 'DE',
  donated_date: moment().toDate(),
  receipt_date: moment().toDate(),
  currency_code: 'eur',
  company_name: '',
};

const ReceiptFormComponent = (props: any) => {
  const { receipt, onHide, fetchReceiptsData, countries } = props;
  const formikRef = useRef(null);
  const [isShowPasswordDialog, setIsShowPasswordDialog] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState(false);
  const { t } = useTranslation('language', { keyPrefix: 'manual_receipt_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();

  const initialReceiptFormValues = (receipt: any) => {
    let initialReceipt = RECEIPT_DEFAULT_VALUE;
    if (!receipt) {
      initialReceipt.name = '';
      initialReceipt.amount = 0;
      initialReceipt.payment_address = '';
      initialReceipt.postal_code = '';
      initialReceipt.city = '';
      initialReceipt.country = 'DE';
      initialReceipt.donated_date = moment().toDate();
      initialReceipt.receipt_date = moment().toDate();
      initialReceipt.currency_code = 'eur';
      initialReceipt.company_name = '';

      return initialReceipt;
    }

    initialReceipt.name = receipt.name;
    initialReceipt.amount = receipt.amount;
    initialReceipt.payment_address = receipt.payment_address;
    initialReceipt.postal_code = receipt.postal_code;
    initialReceipt.city = receipt.city;
    initialReceipt.country = receipt && receipt.country ? receipt.country : 'DE';
    initialReceipt.donated_date = receipt && receipt.donated_date ? new Date(receipt.donated_date) : moment().toDate();
    initialReceipt.receipt_date = receipt && receipt.receipt_date ? new Date(receipt.receipt_date) : moment().toDate();
    initialReceipt.currency_code = receipt && receipt.currency_code ? receipt.currency_code : 'eur';
    initialReceipt.company_name = receipt.company_name;
    return initialReceipt;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required(errorTrans('txt_required')),
    amount: Yup.number().nullable().min(1, errorTrans('txt_integer_min_1')).required(errorTrans('txt_required')),
    payment_address: Yup.string().nullable().required(errorTrans('txt_required')),
    postal_code: Yup.string().nullable().required(errorTrans('txt_required')),
    city: Yup.string().nullable().required(errorTrans('txt_required')),
    country: Yup.string().nullable().required(errorTrans('txt_required')),
    donated_date: Yup.string().nullable().required(errorTrans('txt_required')),
    receipt_date: Yup.string().nullable().required(errorTrans('txt_required')),
    currency_code: Yup.string().nullable().required(errorTrans('txt_required')),
    company_name: Yup.string().nullable(),
  });

  const insertReceipt = async (dataReceipt: any) => {
    const resGenerateReceipt = await ReceiptsAPI.generateReceipt(dataReceipt);

    if (resGenerateReceipt && resGenerateReceipt.status === 200) {
      onHide();
      setSuccessProgress(t('txt_generate_receipt_success'));
      fetchReceiptsData();
    } else {
      setErrorProgress(errorTrans('txt_failed_to_generate_receipt'));
    }
  };

  const updateReceipt = async (dataReceipt: any) => {
    const resUpdateReceipt = await ReceiptsAPI.updateReceipt(receipt.uuid, dataReceipt);

    if (resUpdateReceipt && resUpdateReceipt.status === 200) {
      onHide();
      setSuccessProgress(t('txt_update_receipt_success'));
      fetchReceiptsData();
    } else {
      setErrorProgress(errorTrans('txt_failed_to_update_receipt'));
    }
  };

  const callGenerateReceipt = () => {
    if (password === PASSWORD_SENDMAIL) {
      setErrorPassword(false);
      setLoadingProgress(errorTrans('txt_loading'));
      const dataReceipt = {
        // @ts-ignore: Object is possibly 'null'.
        ...formikRef?.current.values,
        // @ts-ignore: Object is possibly 'null'.
        company_name: formikRef?.current.values.company_name || '',
        // @ts-ignore: Object is possibly 'null'.
        amount: +formikRef?.current.values.amount,
        // @ts-ignore: Object is possibly 'null'.
        donated_date: moment.utc(moment(new Date(formikRef?.current.values.donated_date)).format('MM/DD/YYYY HH:mm:ss')).toISOString(),
        // @ts-ignore: Object is possibly 'null'.
        receipt_date: moment.utc(moment(new Date(formikRef?.current.values.receipt_date)).format('MM/DD/YYYY HH:mm:ss')).toISOString(),
      };

      if (!receipt) {
        insertReceipt(dataReceipt);
      } else {
        updateReceipt(dataReceipt);
      }

      onHideConfirmDialog();
    } else {
      setErrorPassword(true);
    }
  };

  const generateReceipt = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    // @ts-ignore: Object is possibly 'null'.
    const { dirty, isValid } = formikRef?.current;
    // @ts-ignore: Object is possibly 'null'.
    if (formikRef && dirty && isValid) {
      try {
        setIsShowPasswordDialog(true);
      } catch (error) {
        setErrorProgress(errorTrans('txt_failed_to_generate_receipt'));
      }
    }
  };

  const onHideConfirmDialog = () => {
    setPassword('');
    setIsShowPasswordDialog(false);
    setErrorPassword(false);
  };

  return (
    <div className="receipt-form">
      <div className="receipt-form-container">
        <Formik
          innerRef={formikRef}
          initialValues={initialReceiptFormValues(receipt)}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // console.log("values >>>", values);
          }}
        >
          {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, handleChange }) => (
            <form onSubmit={handleSubmit}>
              <div className="row-content">
                <div className="row mb-24">
                  <div className="col-md-6">
                    <WIFormInputNumberV2
                      name={`amount`}
                      title={t('txt_amount')}
                      isRequired={true}
                      useGrouping={true}
                      mode="decimal"
                      locale="de-DE"
                      minFractionDigits={0}
                      maxFractionDigits={2}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === '.') {
                          e.preventDefault();
                        }
                      }}
                      placeholder="0.00"
                      hideErrorWhenLoading={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <WIFormInputTextV2 name={`name`} title={t('txt_name')} isRequired={true} maxLength={80} />
                  </div>
                </div>
                <div className="row mb-24">
                  <div className="col-md-6">
                    <WIFormDropdownV2
                      name="country"
                      title={t('txt_country')}
                      isRequired={true}
                      options={countries || []}
                      optionLabel="name"
                      optionValue="ISO2"
                      appendTo="self"
                      filter
                      filterBy="name"
                      filterPlaceholder={t('txt_search')}
                    />
                  </div>
                  <div className="col-md-6">
                    <WIFormInputTextV2 name={`city`} title={t('txt_city')} isRequired={true} maxLength={85} />
                  </div>
                </div>
                <div className="row mb-24">
                  <div className="col-md-6">
                    <WIFormInputTextV2 name={`payment_address`} title={t('txt_street_house')} isRequired={true} maxLength={100} />
                  </div>
                  <div className="col-md-6">
                    <WIFormInputTextV2 name={`postal_code`} title={t('txt_postal_code')} isRequired={true} maxLength={10} />
                  </div>
                </div>
                <div className="row mb-24">
                  <div className="col-md-6">
                    <WIFormInputTextV2 name={`company_name`} title={t('txt_organization')} maxLength={80} />
                  </div>

                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label pb-16">
                        {t('txt_currency')}
                        <span className="required-label"> *</span>
                      </label>
                      <SelectButton
                        id="currency_code"
                        name="currency_code"
                        className="wi-selectbutton wi-selectbutton-three"
                        value={values.currency_code}
                        options={CurrencyList}
                        onChange={(item: any) => {
                          if (item.value) {
                            setFieldValue('currency_code', item.value, false);
                          }
                        }}
                        optionLabel="name"
                        optionValue="code"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-24">
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label pb-16">
                        {t('txt_donated_date')}
                        <span className="required-label"> *</span>
                      </label>
                      <Calendar
                        id="basic"
                        name="donated_date"
                        className="wi-calendar-v2"
                        panelClassName="expire-calendar"
                        value={values.donated_date}
                        onBlur={handleBlur}
                        onChange={item => {
                          setFieldValue('donated_date', item.value, false);
                        }}
                        dateFormat="dd.mm.yy"
                        appendTo="self"
                      />
                      <div className={`${touched.donated_date && errors.donated_date ? 'error' : ''}`}>
                        {touched.donated_date && errors.donated_date ? t('txt_field_valid') : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label pb-16">
                        {t('txt_receipt_date')}
                        <span className="required-label"> *</span>
                      </label>
                      <Calendar
                        id="basic"
                        name="receipt_date"
                        className="wi-calendar-v2"
                        panelClassName="expire-calendar"
                        value={values.receipt_date}
                        onBlur={handleBlur}
                        onChange={item => {
                          setFieldValue('receipt_date', item.value, false);
                        }}
                        dateFormat="dd.mm.yy"
                        appendTo="self"
                      />
                      <div className={`${touched.receipt_date && errors.receipt_date ? 'error' : ''}`}>
                        {touched.receipt_date && errors.receipt_date ? t('txt_field_valid') : ''}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between gap-24">
                  <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                  <Button
                    className="wi-primary-button-v2 h48 flex-1"
                    type="submit"
                    label={receipt ? t('txt_update') : t('txt_generate')}
                    onClick={() => generateReceipt()}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <WIConfirmPassword
        visible={isShowPasswordDialog}
        onHide={() => onHideConfirmDialog()}
        onConfirm={callGenerateReceipt}
        password={password}
        onSetPassword={(value: any) => setPassword(value)}
        errorPassword={errorPassword}
        maskClassName="top-mask-dialog"
        confirmLabel={t('txt_confirm')}
        hideLabel={t('txt_cancel')}
      />
    </div>
  );
};

export default ReceiptFormComponent;
