import { useRef } from 'react';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import info_circle from '../../../../assets/images/icons/info-circle.svg';
import { useTranslation } from 'react-i18next';
import { CAMPAIGN_TYPE_LIST, CAMPAIGN_TYPES } from '../../../../utils/constants';
import { useNavigate } from 'react-router-dom';

const PreAddCampaignForm = (props: any) => {
  const { onHide } = props;
  const { t: globalTrans } = useTranslation('language');
  const { t } = useTranslation('language', { keyPrefix: 'campaign_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const formikRef = useRef<any>(null);
  const navigate = useNavigate();

  const commonItemTemplate = (option: any) => {
    return <div>{globalTrans(option?.label)} </div>;
  };

  const onSubmitAddCampagin = async () => {
    try {
      const { values, isValid } = formikRef?.current;
      formikRef.current?.validateForm();
      if (formikRef && isValid) {
        // navigate(`/campaigns/create?type=${values.type}`);
        navigate(`/campaigns/create`);
      }
    } catch (error: any) {}
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(errorTrans('txt_required')),
  });

  const initialFormValues = () => {
    let initialCampagin = {
      type: CAMPAIGN_TYPES.STANDARD,
    };

    return initialCampagin;
  };

  return (
    <>
      <div className="add-campaign">
        <div className="add-campaign-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              //console.log("values >>>", values);
            }}
          >
            {({ values, errors, touched, handleSubmit, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form mb-24">
                        <label className="label pb-8">
                          {t('txt_type')} <span className="required-label ml-4">*</span>
                          <div className="icon-info ml-8">
                            <img src={info_circle} alt="icon info" loading="lazy" className="m-0" />
                          </div>
                        </label>
                        <Dropdown
                          className="dropdown-component-v2"
                          panelClassName="dropdown-panel-v2 dropdown-panel-bottom-v2"
                          name="type"
                          value={values.type}
                          options={CAMPAIGN_TYPE_LIST || []}
                          onChange={handleChange}
                          valueTemplate={commonItemTemplate}
                          itemTemplate={commonItemTemplate}
                          optionLabel="label"
                          optionValue="code"
                        />
                        <div className={`${touched.type && errors.type ? 'error' : ''}`}>{touched.type && errors.type}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between gap-24">
                        <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_discard')}`} onClick={() => onHide()} />
                        <Button
                          className="wi-primary-button-v2 h48 flex-1"
                          type="submit"
                          label={`${t('txt_continue')}`}
                          onClick={() => onSubmitAddCampagin()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default PreAddCampaignForm;
