import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Link, useSearchParams } from 'react-router-dom';
import { CampaignManagerAPI } from '../../services';
import {
  MATCH_FUNDING_TYPE_LIST,
  PERMISSIONS_V2,
  STATUS_CODE,
  STATUS_LIST,
  MATCH_FUNDING_STATUS_LIST,
  MATCH_FUNDING_TYPES,
} from '../../utils/constants';
import { useLayoutV2 } from '../../context/LayoutProvider';
import { formatDateTimeV2, formatDateV2 } from '../../utils/logic';
import useAuth from '../../context/useAuth';
import MatchFundingFilterForm from './components/match-funding-filter-form';
import CreateMatchFundingForm from './components/create-match-funding-form';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import Layout from '../../layout/layout';
import WiStatus from '../../components_v2/common/wi-status/wi-status';
import MatchFundingManagerAPI from '../../services/matchFundingManager';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';

export type FilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    campaign_id: string[];
    status: string[];
    rule_type: string[];
    dates: string[];
  };
  order: [];
};

const MatchFundingManager = () => {
  const { t } = useTranslation('language', { keyPrefix: 'match_funding_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: globalTrans } = useTranslation('language');

  const { auth } = useAuth();
  const permissions = {
    canCreate:
      auth?.permissions?.includes(PERMISSIONS_V2.MATCH_FUNDING_CREATE) || auth?.permissions?.includes(PERMISSIONS_V2.MATCH_FUNDING_CREATE_TENANT),
    canUpdate:
      auth?.permissions?.includes(PERMISSIONS_V2.MATCH_FUNDING_UPDATE) || auth?.permissions?.includes(PERMISSIONS_V2.MATCH_FUNDING_UPDATE_TENANT),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const { getLanguage, setErrorProgress, setLoadingProgress, setSuccessProgress } = useLayoutV2();
  const [matchFunding, setMatchFunding] = useState<any>(null);
  const [matchFundings, setMatchFundings] = useState({ records: [] as any, total: 0 });
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  const [isDownload, setIsDownLoad] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [rows, setRows] = useState(noRows);
  const [page, setPage] = useState(pageNumber);
  const [first, setFirst] = useState(pageNumber * noRows);
  const [search, setSearch] = useState<any>(null);

  const filterCampaign = searchParams?.get('filterCampaign') ? searchParams?.get('campaign_id')?.split(',') || [] : [];
  const filterStatus = (searchParams?.get('filterStatus') || MATCH_FUNDING_STATUS_LIST.map((c: any) => c.code)?.join(',')).split(',');
  const fitlerType = (searchParams?.get('fitlerType') || MATCH_FUNDING_TYPE_LIST.map((c: any) => c.code)?.join(',')).split(',');
  const filterDates = searchParams?.get('filterDates') ? searchParams?.get('filterDates')?.split(',') || [] : [];
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: searchParams?.get('sortField'),
    sortOrder: +(searchParams?.get('sortOrder') || -1),
  });

  const SEARCH_FIELDS: string[] = [];
  const searchField = searchParams?.get('search') || '';
  const getObjectSearchValue = (value: string) => {
    const search_result: any[] = [];
    SEARCH_FIELDS.forEach((key: string) => {
      const obj = { [key]: value };
      search_result.push(obj);
    });

    return search_result;
  };

  const getObjectWithValues = (where_obj: any) => {
    const tempt_obj: any = {};
    for (const [key, value] of Object.entries(where_obj)) {
      if (key !== 'dates' && (Array.isArray(value) || typeof value === 'string') && value.length > 0) {
        tempt_obj[key] = value;
      }
    }
    return tempt_obj;
  };

  const [filterValues, setFilterValues] = useState<FilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getObjectSearchValue(searchField) : [],
      campaign_id: filterCampaign,
      status: filterStatus,
      rule_type: fitlerType,
      dates: filterDates,
    },
    order: [],
  });

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchAllData = async () => {
    setIsLoading(true);
    const filter = buildFilter();
    try {
      const [matchFundingRes, campaignsRes] = await Promise.all([MatchFundingManagerAPI.paginate(filter), CampaignManagerAPI.getAllCampaigns()]);

      [matchFundingRes, campaignsRes].forEach(res => {
        if (!res || res.status !== 200) {
          throw new Error('txt_unknown_load_data_fail');
        }
      });

      setCampaigns(
        _.sortBy(
          campaignsRes.data.records.filter((item: any) => item.status === STATUS_CODE.ACTIVE),
          item => item.name?.de?.toLowerCase(),
        ),
      );
      setMatchFundings(matchFundingRes.data);
    } catch (error: any) {
      console.error('Error fetching data:', error);
      setErrorProgress(errorTrans(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const buildFilter = () => {
    const where_conditions = filterValues.where;
    return {
      pageNo: page.toString(),
      range: rows.toString(),
      where: {
        ...where_conditions,
        search_name: filterValues.search,
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === -1 ? 'DESC' : 'ASC'],
    };
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (typeof page === 'number') {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });

      setSearchParams({ pageNumber: (page + 1).toString(), noRows: rows.toString() }, { replace: true });
    }
  }, [page, rows]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      filterCampaign: filterValues.where?.campaign_id?.join(',') || '',
      filterStatus: filterValues.where?.status?.join(',') || '',
      fitlerType: filterValues.where?.rule_type?.join(',') || '',
      filterDates: filterValues.where.dates?.join(',') || '',
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
    };

    setSearchParams(
      _.omitBy(searchParams, p => !p),
      { replace: true },
    );
    fetchAllData();
  }, [filterValues, sortConfig]);

  const onHideSideBar = () => {
    setIsShowSidebar(false);
    setIsDownLoad(false);
    if (matchFunding) {
      setMatchFunding(null);
    }
  };

  const toggleStatus = async (item: any, status: any) => {
    if (status === item.status) {
      return;
    }

    setLoadingProgress(errorTrans('txt_loading'));
    try {
      if (status === STATUS_CODE.ACTIVE) {
        let activeRes = await MatchFundingManagerAPI.activate(item.uuid);
        if (activeRes?.status === 200 && activeRes?.data) {
          setSuccessProgress(t('txt_update_success'));
          fetchAllData();
        }
      } else {
        let deactiveRes = await MatchFundingManagerAPI.deactivate(item.uuid);
        if (deactiveRes?.status === 200 && deactiveRes?.data) {
          setSuccessProgress(t('txt_update_success'));
          fetchAllData();
        }
      }
    } catch (e) {
      setIsLoading(false);
      setErrorProgress(errorTrans('txt_update_match_funding_failed'));
    }
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-edit',
        hidden: !permissions.canCreate,
        command: (e: any) => {
          setMatchFunding(rowData);
          setIsShowSidebar(true);
        },
      },
      // {
      //   label: t("txt_download_receipt"),
      //   icon: "wi-download",
      //   command: (e: any) => {
      //     setMatchFunding(rowData);
      //     setIsDownLoad(true);
      //     setIsShowSidebar(true);
      //   },
      // },
      {
        label: t('txt_activate'),
        icon: 'wi-active',
        hidden: !permissions.canUpdate || rowData?.status === STATUS_CODE.ACTIVE,
        command: (e: any) => {
          toggleStatus(rowData, STATUS_CODE.ACTIVE);
        },
      },
      {
        label: t('txt_deactivate'),
        icon: 'wi-inactive',
        hidden: !permissions.canUpdate || rowData?.status === STATUS_CODE.INACTIVE,
        command: (e: any) => {
          toggleStatus(rowData, STATUS_CODE.INACTIVE);
        },
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const buildStatusColumnTemplate = (rowData: any) => {
    const statusItem = STATUS_LIST.find((item: any) => item.code === rowData.status);
    return <WiStatus item={statusItem}></WiStatus>;
  };

  const tableColumns = [
    {
      field: 'code',
      header: t('txt_uuid'),
      width: '120px',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return rowData.code || (moment(rowData.created_at).unix() % 10000000).toString().padStart(7, '0');
      },
    },
    {
      field: 'campaign_id',
      header: t('txt_campaign'),
      width: '25%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const campaign = campaigns.find((c: any) => c.uuid === rowData.campaign_id);
        return (
          <Link className="table-ref-link-cell" to={`/campaigns/${rowData.campaign_id}`}>
            {campaign?.name?.de}
          </Link>
        );
      },
    },
    {
      field: 'status',
      header: `${t('txt_status')}`,
      width: '120px',
      align: 'center',
      bodyTemplate: buildStatusColumnTemplate,
    },
    {
      field: 'start_date',
      header: t('txt_start_date'),
      width: '140px',
      align: 'center',
      bodyTemplate: (rowData: any) => formatDateV2(rowData.start_date, getLanguage()),
    },
    {
      field: 'end_date',
      header: t('txt_end_date'),
      width: '140px',
      align: 'center',
      bodyTemplate: (rowData: any) => moment.utc(rowData.end_date).add(-1, 'hour').locale(getLanguage()).format('DD MMM YYYY'),
    },
    {
      field: 'budget',
      header: t('txt_budget'),
      width: '170px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        const lang = getLanguage();
        return `${(rowData?.used_budget || 0)?.toLocaleString(lang)} / ${rowData?.budget?.toLocaleString(lang)}`;
      },
    },
    {
      field: 'rule_type',
      header: t('txt_type'),
      width: '150px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        const type = MATCH_FUNDING_TYPE_LIST.find((c: any) => c.code === rowData.rule_type);
        return type ? globalTrans(type.name) : '';
      },
    },
    {
      field: 'term',
      header: t('txt_term'),
      width: '10%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return rowData.rule_type !== MATCH_FUNDING_TYPES.FIXED
          ? t('txt_limit_term', { value: rowData?.limit?.toLocaleString(getLanguage()) })
          : t('txt_fixed_term', { value: rowData?.fixed_value?.toLocaleString(getLanguage()) });
      },
    },
    {
      field: 'created_at',
      header: `${t('txt_created')}`,
      width: '170px',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.created_at ? formatDateTimeV2(rowData.created_at, getLanguage()) : '-'}</div>;
      },
      align: 'center',
    },
    {
      field: 'updated_at',
      header: `${t('txt_updated')}`,
      width: '170px',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.updated_at ? formatDateTimeV2(rowData.updated_at, getLanguage()) : '-'}</div>;
      },
      align: 'center',
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const onSearchFunc = (searchText: string) => {
    const where_conditions = filterValues.where;
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions['or'] = searchText ? or_conditions : [];
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    p.search = '';
    setFilterValues(p);
  };

  const onFilterChange = (values: any, fields: any) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    fields.forEach((field: any) => {
      const data = values?.get(field);
      if (field !== 'dates') {
        where_conditions[field] = data;
      } else {
        if (data) {
          const dates = data?.map((c: any) => (_.isString(c) ? c : moment(c).format('DD/MM/YYYY')));
          where_conditions[field] = dates;
          where_conditions.start_date = dates[0];
          where_conditions.end_date = dates[1];
        }
      }
    });

    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  return (
    <Layout title={t('txt_title')}>
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={(value: any) => onSearchFunc(search)}
            />
            <div className="filter-btn">
              <div className="filter-btn-content p-8 ml-16" onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg></FilterSvg>
              </div>
            </div>
          </div>
          <Button
            className="wi-primary-button-v2"
            label={t('txt_btn_add')}
            hidden={!permissions.canCreate}
            onClick={(e: any) => {
              setMatchFunding(null);
              setIsShowSidebar(true);
            }}
          >
            <PlusSvg className="icon-svg" />
          </Button>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={matchFundings.records}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={matchFundings.total} onPageChange={onBasicPageChange} />
      </div>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_filter')}</h6>
          </div>
          <MatchFundingFilterForm
            fetchCallBack={() => setIsShowSidebarFilter(false)}
            filterValues={filterValues}
            onRemove={onFilterRemove}
            setSearch={setSearch}
            onSearchFunc={onSearchFunc}
            onHide={() => setIsShowSidebarFilter(false)}
            fields={['campaign_id', 'status', 'rule_type', 'dates']}
            campaigns={campaigns}
            onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        onHide={() => onHideSideBar()}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{matchFunding ? t('txt_update') : t('txt_add_new')}</h6>
          </div>
          <CreateMatchFundingForm
            campaigns={campaigns}
            onHide={() => onHideSideBar()}
            matchFunding={matchFunding}
            fetchCallBack={() => fetchAllData()}
          />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default MatchFundingManager;
