const SEARCH_FIELDS = ['name', 'serial', 'payment_email'];
export const getObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if (key && key !== 'collection_date' && key !== 'start_date' && key !== 'finished_date') {
      if ((Array.isArray(value) || typeof value === 'string') && value.length > 0) {
        tempt_obj[key] = value;
      }
    } else {
      if (Array.isArray(value) && value.length === 2) {
        tempt_obj[key] = value;
        tempt_obj['from_' + key] = value[0];
        tempt_obj['to_' + key] = value[1];
      }
    }
  }
  return tempt_obj;
};

export const getObjectSearchValue = (value: string) => {
  const search_result: any[] = [];
  SEARCH_FIELDS.forEach((key: string) => {
    const obj = {
      [key]: value,
    };
    search_result.push(obj);
  });

  return search_result;
};

export const getSubscriptionPayloadConditions = (where_obj: any, sort_config: any) => {
  const { sortField, sortOrder } = sort_config;
  const where_conditions = getObjectWithValues(where_obj);
  const mapper: any = {
    // code: [["code"]],
    // reference_code: [["reference_code"]],
    // text_2fa: [["text_2fa"]],
    // updated_at: [["updated_at"]],
  };
  const orders = mapper[sortField];
  orders.forEach((r: any) => r.push(sortOrder === -1 ? 'DESC' : 'ASC'));
  const order = [...orders];
  return {
    where: where_conditions,
    order,
  };
};
