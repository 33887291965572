import './dynamic-form.scss';
import React, { useState, useEffect } from 'react';
import { renderFormElement, WIForm } from './components';
import * as _ from 'lodash';
import * as Yup from 'yup';

const DynamicForm = (props: any) => {
  const {
    formSchema,
    data,
    onSubmitData,
    mode,
    globalContent,
    submitButtonConfig,
    isAutoSave = false,
    selectFieldOptions,
    enableValidation,
    disabled,
  } = props;
  const [globalConfig, setGlobalConfig] = useState(formSchema.globalConfiguration);
  const [validationSchema, setValidationSchema] = useState({});
  const [xssConfig, setXSSConfig] = useState(formSchema.xssConfiguration);

  const getSchema = (schema: any) => {
    const newSchema = _.cloneDeep(schema);
    delete newSchema['globalConfiguration'];
    delete newSchema['xssConfiguration'];

    return newSchema;
  };

  useEffect(() => {
    initForm(formSchema);
    setGlobalConfig(formSchema.globalConfiguration);
  }, [formSchema, data, globalContent]);

  const initForm = (formSchema: any) => {
    const yupSchema = buildYupSchemaForObj(formSchema);
    setValidationSchema(yupSchema);
  };

  const buildYupSchemaForObj = (formSchema: any) => {
    const yupSchema = Object.keys(formSchema).reduce((previousSchema, fieldName) => {
      return handleCreateYupSchema(previousSchema, { ...formSchema[fieldName], id: fieldName });
    }, {});

    return Yup.object().shape(yupSchema);
  };

  const handleCreateYupSchema = (schema: any, config: any) => {
    let validator: any = {};
    const { id, validationType, validations = [], supportMultiLanguages = [], children, type, dependency } = config;
    // @ts-ignore: Object is possibly 'null'.
    if (!Yup[validationType]) {
      return schema;
    }

    if (supportMultiLanguages && supportMultiLanguages.length > 0) {
      let childObj: any = {};
      // @ts-ignore: Object is possibly 'null'.
      supportMultiLanguages.forEach(lang => {
        childObj[lang] = createYupSchema(validationType, validations, dependency);
      });

      validator = Yup.object().shape({ ...childObj });
    } else if (type === 'object' && children) {
      validator = buildYupSchemaForObj(children);
    } else if (type === 'array' && children) {
      const yupArraySchema = createYupSchema(validationType, validations, dependency);
      const yupObjSchema = buildYupSchemaForObj(children);
      validator = yupArraySchema.of(yupObjSchema);
    } else {
      validator = createYupSchema(validationType, validations, dependency);
    }
    schema[id] = validator;

    return schema;
  };

  const createYupSchema = (validationType: any, validations: any, dependency: any = null) => {
    let validator: any = {};
    // @ts-ignore: Object is possibly 'null'.
    validator = Yup[validationType]();
    // @ts-ignore: Object is possibly 'null'.
    validations.forEach(validation => {
      const { params, type } = validation;

      if (!validator[type] || (dependency && dependency.name && type === 'required')) {
        return;
      }
      validator = validator[type](...params);
    });

    if (dependency) {
      validator = validator.when(_.last(dependency.name.split('.')), (val: any) => {
        if (val === dependency.value) {
          // @ts-ignore: Object is possibly 'null'.
          return Yup[validationType]().required();
        }
      });
    }

    return validator;
  };

  return (
    <div className={`form-mode form-mode-${(mode || 'sidebar').toLowerCase()}`}>
      <WIForm
        enableReinitialize
        // Multilang
        validationSchema={enableValidation ? validationSchema : null}
        disabled={disabled}
        initialValues={data || {}}
        onSubmitData={onSubmitData}
        globalConfig={globalConfig}
        globalContent={globalContent}
        submitButtonConfig={submitButtonConfig}
        xssConfig={xssConfig}
        isAutoSave={isAutoSave}
        renderForm={(formProps: any) =>
          renderFormElement(getSchema(formSchema), {
            ...formProps,
            parentKey: null,
            isGlobalContentRef: formProps.values.is_global_ref,
            search_content: globalContent?.search_content,
            selectFieldOptions: selectFieldOptions,
            isGlobalDisabled: disabled,
          })
        }
      ></WIForm>
    </div>
  );
};

// export default DynamicForm;
export default React.memo(DynamicForm);
