import * as React from 'react';
import './create-donation-form.scss';
import { useState, useEffect, Fragment, memo } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import WIConfirmDialog from '../../../common/delete-confirm-dialog/delete-confirm-dialog';
import { CommittedDonationItemType, DonationItemUIType } from '../../../../types/commonType';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import * as _ from 'lodash';
import { TabPanel, TabView } from 'primereact/tabview';
import { parseStringToInt } from '../../../../utils/logic';
import useAuth from '../../../../context/useAuth';
import { CURRENCY_CODES, PERMISSIONS_V2 } from '../../../../utils/constants';

const CreateDonationFormComponent = (props: any) => {
  const { setDataDonations, setDataDonationsCAD, setDataDonationsCHF, setDataIsChangingDonations } = props;
  const { auth } = useAuth();
  const permissions = {
    canCreateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_CREATE),
    canUpdateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_UPDATE),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
  };

  const emptyDonation: DonationItemUIType = {
    key: '',
    index: 0,
    money: 0,
    item: {
      de: '',
      en: '',
    },
  };
  const emptyRow = {
    key: '',
    index: -1,
    money: 0,
    item: {
      de: '',
      en: '',
    },
    hasError: false,
  };

  const sortDonationsByMoney = (donations: any) => {
    return _.orderBy(donations, 'money', 'asc').map((item: any, index: any) => {
      item.index = index;
      return item;
    });
  };

  const [deleteDonationDialog, setDeleteDonationDialog] = useState(false);
  const [isDisabledAddDonation, setIsDisabledAddDonation] = useState(false);
  const [isDisabledAddDonationCAD, setIsDisabledAddDonationCAD] = useState(false);
  const [isDisabledAddDonationCHF, setIsDisabledAddDonationCHF] = useState(false);
  const [isChangingDonations, setIsChangingDonations] = useState(false);
  const [isChangingDonationsCAD, setIsChangingDonationsCAD] = useState(false);
  const [isChangingDonationsCHF, setIsChangingDonationsCHF] = useState(false);

  const [donation, setDonation] = useState(emptyDonation);
  const [donationCAD, setDonationCAD] = useState(emptyDonation);
  const [donationCHF, setDonationCHF] = useState(emptyDonation);
  const [donations, setDonations] = useState<DonationItemUIType[]>(sortDonationsByMoney(props.donations) ?? []);
  const [donationsCAD, setDonationsCAD] = useState<DonationItemUIType[]>(sortDonationsByMoney(props.donationsCAD) ?? []);
  const [donationsCHF, setDonationsCHF] = useState<DonationItemUIType[]>(sortDonationsByMoney(props.donationsCHF) ?? []);
  const [committedDonationRow, setCommittedDonationRow] = useState<CommittedDonationItemType>(emptyRow);

  const originalDonations = sortDonationsByMoney(props.originalDonations);
  const originalDonationsCAD = sortDonationsByMoney(props.originalDonationsCAD);
  const originalDonationsCHF = sortDonationsByMoney(props.originalDonationsCHF);

  const donationFuncMap = {
    donation: donation,
    donationCAD: donationCAD,
    donationCHF: donationCHF,
  };
  const setDonationFuncMap = {
    setDonation: setDonation,
    setDonationCAD: setDonationCAD,
    setDonationCHF: setDonationCHF,
  };

  const donationsFuncMap = {
    donations: donations,
    donationsCAD: donationsCAD,
    donationsCHF: donationsCHF,
  };
  const setDonationsFuncMap = {
    setDonations: setDonations,
    setDonationsCAD: setDonationsCAD,
    setDonationsCHF: setDonationsCHF,
  };

  const setIsDisabledAddDonationFuncMap = {
    setIsDisabledAddDonation: setIsDisabledAddDonation,
    setIsDisabledAddDonationCAD: setIsDisabledAddDonationCAD,
    setIsDisabledAddDonationCHF: setIsDisabledAddDonationCHF,
  };

  useEffect(() => {
    setDataIsChangingDonations(isChangingDonations || isChangingDonationsCAD || isChangingDonationsCHF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangingDonations, isChangingDonationsCAD, isChangingDonationsCHF]);

  useEffect(() => {
    let dif = _.differenceWith(originalDonations, donations, _.isEqual);
    let difCAD = _.differenceWith(originalDonationsCAD, donationsCAD, _.isEqual);
    let difCHF = _.differenceWith(originalDonationsCHF, donationsCHF, _.isEqual);
    setDataIsChangingDonations(dif.length > 0 || difCAD.length > 0 || difCHF.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetDonations = (key: CURRENCY_CODES, donations: DonationItemUIType[]) => {
    if (key === CURRENCY_CODES.EUR) {
      let dif = _.differenceWith(originalDonations, donations, _.isEqual);
      setIsChangingDonations(dif.length > 0);
      setDataDonations(donations);
    } else if (key === CURRENCY_CODES.CAD) {
      let dif = _.differenceWith(originalDonationsCAD, donations, _.isEqual);
      setIsChangingDonationsCAD(dif.length > 0);
      setDataDonationsCAD(donations);
    } else if (key === CURRENCY_CODES.CHF) {
      let dif = _.differenceWith(originalDonationsCHF, donations, _.isEqual);
      setIsChangingDonationsCHF(dif.length > 0);
      setDataDonationsCHF(donations);
    }
  };

  const onAddDonation = (
    key: CURRENCY_CODES,
    donationKey: keyof typeof donationFuncMap,
    setDonationKey: keyof typeof setDonationFuncMap,
    donationsKey: keyof typeof donationsFuncMap,
    setDonationsKey: keyof typeof setDonationsFuncMap,
    setIsDisabledAddDonationKey: keyof typeof setIsDisabledAddDonationFuncMap,
  ) => {
    // let _donations = [...donationsFuncMap[`${donationsKey}`]]
    // let _donation = { ...donationFuncMap[`${donationKey}`] }
    let _donations = donationsFuncMap[`${donationsKey}`];
    let _donation = emptyDonation;
    _donation.key = key;
    _donation.index = _donations.length;
    _donations.push(_donation);
    _donations = sortDonationsByMoney(_donations);
    setDonationFuncMap[`${setDonationKey}`](_donation);
    setDonationsFuncMap[`${setDonationsKey}`](_donations);
    setIsDisabledAddDonationFuncMap[`${setIsDisabledAddDonationKey}`](true);
    handleSetDonations(key, _donations);
  };

  const EditDonation = (
    e: any,
    key: CURRENCY_CODES,
    donationsKey: keyof typeof donationsFuncMap,
    setDonationsKey: keyof typeof setDonationsFuncMap,
    setIsDisabledAddDonationKey: keyof typeof setIsDisabledAddDonationFuncMap,
  ) => {
    let { newData, index } = e;

    //let parsedDonation = parseDonation(newData, index, key)
    //let _donations = [...donationsFuncMap[`${donationsKey}`]]
    //_donations[index] = parsedDonation

    let _donations = donationsFuncMap[`${donationsKey}`];
    _donations[index].money = Number(newData.money);
    _donations[index].item = {
      de: newData.item.de,
      en: newData.item.en,
    };
    _donations = sortDonationsByMoney(_donations);
    setDonationsFuncMap[`${setDonationsKey}`](_donations);
    handleSetDonations(key, _donations);
    let result = onHasRowInValid(_donations);
    setIsDisabledAddDonationFuncMap[`${setIsDisabledAddDonationKey}`](result);
  };

  const deleteDonation = (
    key: CURRENCY_CODES,
    donationKey: keyof typeof donationFuncMap,
    setDonationKey: keyof typeof setDonationFuncMap,
    donationsKey: keyof typeof donationsFuncMap,
    setDonationsKey: keyof typeof setDonationsFuncMap,
    setIsDisabledAddDonationKey: keyof typeof setIsDisabledAddDonationFuncMap,
  ) => {
    // let _donations = donationsFuncMap[`${donationsKey}`].filter(
    //   (val) => val.index !== donationFuncMap[`${donationKey}`].index,
    // )

    let _donations = donationsFuncMap[`${donationsKey}`];
    let index = donationFuncMap[`${donationKey}`].index;
    _donations.splice(index, 1);
    _donations = sortDonationsByMoney(_donations);
    setDonationsFuncMap[`${setDonationsKey}`](_donations);
    handleSetDonations(key, _donations);
    setDonationFuncMap[`${setDonationKey}`](emptyDonation);
    setDeleteDonationDialog(false);

    let result = onHasRowInValid(_donations);
    setIsDisabledAddDonationFuncMap[`${setIsDisabledAddDonationKey}`](result);
  };

  const hideDeleteDonationDialog = () => {
    setDeleteDonationDialog(false);
  };

  const confirmDeleteProduct = (donation: any, setDonationKey: keyof typeof setDonationFuncMap) => {
    setDonationFuncMap[`${setDonationKey}`](donation);
    setDeleteDonationDialog(true);
  };

  const onRowEditValidator = (rowData: any) => {
    let key = rowData['key'];
    let index = rowData['index'];
    let money = rowData['money'];
    let item = rowData['item'];
    let hasError = hasErrorDonationItem(money, item);
    let submmittedRow: CommittedDonationItemType = {
      key: key,
      index: index,
      money: money,
      item: {
        de: item.de,
        en: item.en,
      },
      hasError: hasError,
    };
    setCommittedDonationRow(submmittedRow);
    return !hasError;
  };

  const onRowEditCancel = (rowData: any) => {
    setCommittedDonationRow(emptyRow);
  };

  const hasErrorDonationItem = (money: any, item: any): boolean => {
    return Number(money) <= 0 || item.de.length <= 0 || item.en.length <= 0;
  };

  const onHasRowInValid = (donations: any) => {
    let result = false;
    for (let donation of donations) {
      if (hasErrorDonationItem(donation.money, donation.item)) {
        result = true;
        break;
      }
    }
    return result;
  };

  const styles = {
    errorInputTextStyle: {
      border: '1px solid rgb(229, 35, 34)',
      borderRadius: '6px',
    },
    normalInputTextStyle: {},
  };

  const onKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      //13 is the key code for Enter
      event.preventDefault();
    }
  };

  const onKeyPressNumber = (evt: any) => {
    if (evt.target.value.length > 10000) return evt.preventDefault();
    return ['e', 'E', '+', '-', '.', '{', '}', '[', ']'].includes(evt.key) && evt.preventDefault();
  };

  const textEditor = (options: any, hasErrorDE: boolean, hasErrorEN: boolean) => {
    const itemDE = options.rowData.item.de;
    const itemEN = options.rowData.item.en;
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <span className="country-label" style={{ background: '#4d4d4d' }}>
            DE
          </span>
          <InputTextarea
            rows={1}
            autoResize
            onKeyDown={onKeyPress}
            style={hasErrorDE ? styles.errorInputTextStyle : styles.normalInputTextStyle}
            value={itemDE}
            onChange={e =>
              options.editorCallback({
                de: e.target.value,
                en: itemEN,
              })
            }
            maxLength={100}
          />
        </div>
        <p />
        <div style={{ display: 'flex' }}>
          <span className="country-label" style={{ background: '#861135' }}>
            EN
          </span>
          <InputTextarea
            rows={1}
            autoResize
            onKeyDown={onKeyPress}
            style={hasErrorEN ? styles.errorInputTextStyle : styles.normalInputTextStyle}
            value={itemEN}
            onChange={e =>
              options.editorCallback({
                de: itemDE,
                en: e.target.value,
              })
            }
            maxLength={100}
          />
        </div>
      </div>
    );
  };

  const numberEditor = (options: any, hasError: boolean) => {
    return (
      <div>
        <InputNumber
          style={hasError ? styles.errorInputTextStyle : styles.normalInputTextStyle}
          format={false}
          value={options.value}
          onBlur={e => {
            const value = e.target.value ? parseStringToInt(e.target.value) : 0;
            options.editorCallback(value > 10000 ? 10000 : value);
          }}
          max={10000}
          onKeyDown={onKeyPressNumber}
        />
      </div>
    );
  };

  const cellEditor = (options: any, type: string) => {
    let index = Number(options.rowIndex);
    let field = options.field;
    let key = options.rowData.key;

    if (committedDonationRow && key === committedDonationRow.key && index === committedDonationRow.index) {
      if (field === 'money' && committedDonationRow?.money <= 0) {
        return numberEditor(options, true);
      } else if (field === 'item') {
        return textEditor(options, committedDonationRow?.item?.de?.length <= 0, committedDonationRow?.item?.en?.length <= 0);
      }
    }

    if (type === 'number') return numberEditor(options, false);
    else return textEditor(options, false, false);
  };

  const customItemTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <label className="country-label" style={{ background: '#4d4d4d' }}>
            DE
          </label>
          <label>{rowData.item.de}</label>
        </div>
        <p />
        <div style={{ display: 'flex' }}>
          <label className="country-label" style={{ background: '#861135' }}>
            EN
          </label>
          <label>{rowData.item.en}</label>
        </div>
      </React.Fragment>
    );
  };

  const rowEditorTemplate = (rowData: any, props: any, setDonationKey: keyof typeof setDonationFuncMap) => {
    const rowEditor = props.rowEditor;
    if (rowEditor.editing) {
      return rowEditor.element; // default element
    }

    const hidden = !(permissions.canCreateRegion || permissions.canUpdateRegion);
    return (
      <React.Fragment>
        <button type="button" hidden={hidden} onClick={rowEditor.onInitClick} className={rowEditor.initClassName}>
          <span className="p-row-editor-init-icon pi pi-fw pi-pencil p-clickable"></span>
        </button>
        <button type="button" hidden={hidden} onClick={() => confirmDeleteProduct(rowData, setDonationKey)} className={rowEditor.initClassName}>
          <span className="p-row-editor-init-icon pi pi-fw pi-trash p-clickable"></span>
        </button>
      </React.Fragment>
    );
  };

  const dynamicDonationDataTable = (
    donations: DonationItemUIType[],
    isDisabledAddDonation: boolean,
    key: CURRENCY_CODES,
    donationsKey: keyof typeof donationsFuncMap,
    setDonationsKey: keyof typeof setDonationsFuncMap,
    setIsDisabledAddDonationKey: keyof typeof setIsDisabledAddDonationFuncMap,
    donationKey: keyof typeof donationFuncMap,
    setDonationKey: keyof typeof setDonationFuncMap,
  ) => {
    return (
      <div className="row row-content">
        <div className="card p-fluid">
          <DataTable
            value={donations}
            editMode="row"
            onRowEditComplete={e => EditDonation(e, key, donationsKey, setDonationsKey, setIsDisabledAddDonationKey)}
            className="wi-example-table"
            rowEditValidator={onRowEditValidator}
            onRowEditCancel={onRowEditCancel}
            responsiveLayout="scroll"
            scrollHeight="calc(100vh - 166px)"
          >
            <Column field="money" header="Amount" editor={options => cellEditor(options, 'number')} style={{ width: '10%' }}></Column>
            <Column
              field="item"
              header="Title"
              body={customItemTemplate}
              editor={options => cellEditor(options, 'text')}
              style={{ width: 'calc(90% - 50px)' }}
            ></Column>

            <Column
              frozen
              alignFrozen="right"
              rowEditor
              headerStyle={{
                width: '50px',
                minWidth: '50px',
              }}
              bodyStyle={{ textAlign: 'right' }}
              body={(e, props) => rowEditorTemplate(e, props, setDonationKey)}
            ></Column>
          </DataTable>
        </div>
        <div className="AddDonationButton">
          <Button
            icon="pi pi-plus"
            className="p-button-rounded"
            aria-label="Filter"
            disabled={isDisabledAddDonation}
            hidden={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
            onClick={() => onAddDonation(key, donationKey, setDonationKey, donationsKey, setDonationsKey, setIsDisabledAddDonationKey)}
          />
        </div>
        <WIConfirmDialog
          visible={deleteDonationDialog}
          onHide={hideDeleteDonationDialog}
          onConfirm={() => deleteDonation(key, donationKey, setDonationKey, donationsKey, setDonationsKey, setIsDisabledAddDonationKey)}
          message={
            <Fragment>
              Are you sure you want to delete this campaign <b>{donationFuncMap[`${donationKey}`]?.money}</b>?
            </Fragment>
          }
          classIcon="pi pi-times mr-3 dialog-icon"
        />
      </div>
    );
  };

  return (
    <div className="row row-content wi-example-tabview">
      <TabView>
        <TabPanel header="EUR">
          {dynamicDonationDataTable(
            donations,
            isDisabledAddDonation,
            CURRENCY_CODES.EUR,
            'donations',
            'setDonations',
            'setIsDisabledAddDonation',
            'donation',
            'setDonation',
          )}
        </TabPanel>
        <TabPanel header="CAD">
          {dynamicDonationDataTable(
            donationsCAD,
            isDisabledAddDonationCAD,
            CURRENCY_CODES.CAD,
            'donationsCAD',
            'setDonationsCAD',
            'setIsDisabledAddDonationCAD',
            'donationCAD',
            'setDonationCAD',
          )}
        </TabPanel>
        <TabPanel header="CHF">
          {dynamicDonationDataTable(
            donationsCHF,
            isDisabledAddDonationCHF,
            CURRENCY_CODES.CHF,
            'donationsCHF',
            'setDonationsCHF',
            'setIsDisabledAddDonationCHF',
            'donationCHF',
            'setDonationCHF',
          )}
        </TabPanel>
      </TabView>
    </div>
  );
};

export default memo(CreateDonationFormComponent);
