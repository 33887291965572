import * as React from 'react';
import { useField } from 'formik';
import './wi-checkbox.scss';

type CheckboxSvgProps = {
  className?: string;
  label: string;
  errorMessage?: string;
  error?: string;
  touched?: boolean;
  initialValue?: any;
  initialError?: any;
  initialTouched?: any;
  name: string;
  required?: boolean;
  id: string;
  disabled?: boolean;
};

const WICheckBoxV2: React.FC<CheckboxSvgProps> = props => {
  const { className, label, errorMessage, error, touched, initialValue, initialError, initialTouched, ...inputProps } = props;

  const [field, meta] = useField({
    name: props.name,
    type: 'checkbox',
    validate: fieldValue => {
      let error;
      if (props.required && fieldValue === false) {
        error = props.errorMessage || `${props.name} is Required`;
      }
      return error;
    },
  });
  return (
    <div className={`${props.className} checkbox-container`}>
      <input {...field} {...inputProps} type="checkbox" tabIndex={0} />
      <label htmlFor={props.id} className={`checkbox-label-wrapper ${props.required ? `required` : ``}`}>
        <svg role="checkbox" viewBox="0 0 100 100">
          <path className="box" d="M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z" />
          <path className="box-checked" d="M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z" />
          <polyline className="check" points="25.5,53.5 39.5,67.5 72.5,34.5 " />
        </svg>
        <span className="checkbox-label">{props.label}</span>
      </label>
      {meta.error && (
        <div className="error" id={`${inputProps.id}_error`}>
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default WICheckBoxV2;
