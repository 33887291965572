import React from 'react';
import _ from 'lodash';
import InfoCircle from '../../../assets/images/icons/info-circle.svg';
import { useFormikContext } from 'formik';
import { SelectButton } from 'primereact/selectbutton';

const WIFormSelectButtonV2 = (props: any) => {
  const { name, title, isRequired, className, disabled, options, optionLabel, optionValue, itemTemplate } = props;
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  return (
    <div className="element-form">
      <label className="label pb-16">
        {title} {isRequired ? <span className="required-label ml-4">*</span> : <></>}
        <div className="icon-info ml-16">
          <img src={InfoCircle} alt="Info" loading="lazy" className="m-0" />
        </div>
      </label>
      <SelectButton
        className={`wi-selectbutton ${className} ${_.get(errors, name) && _.get(touched, name) ? 'has-error' : ''}`}
        name={name}
        value={_.get(values, name)}
        itemTemplate={itemTemplate}
        options={options}
        optionLabel={optionLabel}
        optionValue={optionValue || 'uuid'}
        disabled={disabled}
        onChange={(item: any) => {
          if (item.value) {
            setFieldValue(name, item.value, true);
          }
        }}
      />
      <div className={`${_.get(errors, name) && _.get(touched, name) ? 'error' : ''}`}>{_.get(touched, name) && _.get(errors, name)}</div>
    </div>
  );
};

export default WIFormSelectButtonV2;
