import { useRef, useState } from 'react';
import React from 'react';
import './update-runner-form.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { parseStringToInt, showNotification } from '../../../../utils/logic';
import { WIButton } from '../../../common';
import { InputText } from 'primereact/inputtext';
import { WildernessRunsManagerAPI } from '../../../../services';
import { InputNumber } from 'primereact/inputnumber';

const UpdateRunnerForm = (props: any) => {
  const { onHide, fetchCallBack, toast, runnerData, wildernessRunID, teamsID } = props;

  const formikRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitFormRunner = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setIsLoading(true);
        let valuesData = {
          ...values,
        };

        let updateRes = await WildernessRunsManagerAPI.updateRunner(wildernessRunID, teamsID, runnerData.id, valuesData);
        if (updateRes?.status === 200 && updateRes?.data) {
          showNotification('success', 'Update runner successfully.', toast);
          onHide();

          fetchCallBack(updateRes.data);
          return;
        }
      }
    } catch {
      showNotification('error', 'Check runner failed.', toast);
      setIsLoading(false);
    }
  };

  const initialFormValues = () => {
    let initialTeam = {
      first_name: '',
      last_name: '',
      archived_units: 0,
      email: '',
    };

    if (runnerData) {
      initialTeam.first_name = runnerData.first_name || '';
      initialTeam.last_name = runnerData.last_name || '';
      initialTeam.archived_units = runnerData.archived_units || 0;
      initialTeam.email = runnerData.email || '';
    }

    return initialTeam;
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().nullable().required('This field is required.'),
    last_name: Yup.string().nullable().required('This field is required.'),
    email: Yup.string().email('Email is invalid format').required('This field is required'),
    archived_units: Yup.number().nullable().min(0, 'This field must be greater than 0.'),
  });

  return (
    <>
      <div className="update-runner-form">
        <div className="update-runner-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({ values, errors, touched, dirty, isValid, handleBlur, handleSubmit, setFieldValue, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          First name
                          <span className="required-label"> *</span>
                        </label>
                        <InputText
                          className={`${touched.first_name && errors.first_name ? 'has-error' : ''}`}
                          value={values.first_name}
                          name="first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={40}
                        />
                        <div className={`${touched.first_name && errors.first_name ? 'error' : ''}`}>
                          {`${touched.first_name && errors.first_name ? errors.first_name : ''}`}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Last name
                          <span className="required-label"> *</span>
                        </label>
                        <InputText
                          className={`${touched.last_name && errors.last_name ? 'has-error' : ''}`}
                          value={values.last_name}
                          name="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={40}
                        />
                        <div className={`${touched.last_name && errors.last_name ? 'error' : ''}`}>
                          {`${touched.last_name && errors.last_name ? errors.last_name : ''}`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          Email <span className="required-label"> *</span>
                        </label>
                        <InputText
                          className={`${touched.email && errors.email ? 'has-error' : ''}`}
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={255}
                        />
                        <div className={`${touched.email && errors.email ? 'error' : ''}`}>{touched.email && errors.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Achieved Unit</label>
                        <InputNumber
                          value={values.archived_units}
                          name="archived_units"
                          onBlur={e => {
                            const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                            setFieldValue('archived_units', value, false);
                            setTimeout(() => {
                              handleBlur(e);
                            }, 200);
                          }}
                          min={0}
                          placeholder="0"
                          locale="de-DE"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn-submit">
                    <WIButton
                      className="update-runner"
                      primary={true}
                      type="submit"
                      label="Update"
                      icon={'pi-angle-right'}
                      onClick={() => onSubmitFormRunner()}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdateRunnerForm;
