import React from 'react';
import WIUploadMediaFileField from '../../../common/upload/wi-upload-media-file-field';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import './wi-file.scss';

export function WIFile(props: any) {
  const { name, disabled } = props;
  const { frame } = props.schema;
  const frameCssClass = frame ? `img-frame-${frame.replace(':', '-')}` : '';
  return (
    <WIFormElement {...props}>
      <WIUploadMediaFileField
        type="file"
        fileType="all"
        id={name}
        name={name}
        required={true}
        // className={frameCssClass}
        isMiddle={true}
        hideLabel={true}
        overSize={true}
        disabled={disabled}
      />
    </WIFormElement>
  );
}
