import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { HOMEPAGE_URL, PARTNER_TYPES, USER_PARTIES, WI_TENANT_ID } from '../utils/constants';
import AuthManagerAPI from '../services/authManager';
import { UserManagerAPI } from '../services/v2';
import { getExpireTime, resetToken, saveToken, buildLoginLink, parseJSON, parseJSONtoObj } from '../utils/logic';
import useAuth from './useAuth';
import { env } from '../configs';
import Layout from '../layout/layout';
import { useTranslation } from 'react-i18next';
import { PartnerManagerAPI } from '../services';

const AuthProtectedRoute = ({ redirectPath = env.LOGIN_URL, children }: any) => {
  const [isAuth, setIsAuth] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const { auth, setAuth } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const params = useParams<Record<string, string>>();

  const getUrlAlias = (path: string) => {
    return Object.keys(params).reduce((path, key) => path?.replace('/' + params[key], `/param-${key}`), location.pathname);
  };

  const isTokenExpire = () => {
    const localJWTToken = localStorage.getItem('access_token');
    let expTime = getExpireTime(localJWTToken || '');
    return !expTime || expTime < moment();
  };

  const checkPageAccess = async (currentUser: any = null) => {
    try {
      let urlAlias = getUrlAlias(location.pathname); // => alias
      if (urlAlias === HOMEPAGE_URL) {
        return;
      }
      const tenantRoutes = ['/campaigns', '/media', '/coupons', '/match-funding'];
      if (currentUser.partner && tenantRoutes.some(r => urlAlias.startsWith(r))) {
        urlAlias += '/param-tenant';
      }
      if (urlAlias.startsWith('/cms-pages') && currentUser.partner?.type === PARTNER_TYPES.FUNDRAISER) {
        navigate(HOMEPAGE_URL, { replace: true });
        return;
      }

      const accessRes = await UserManagerAPI.canAccessPage(urlAlias); // 200 | 401 | 403
      if (accessRes && (accessRes.status === 401 || accessRes.status === 403)) {
        navigate(HOMEPAGE_URL, { replace: true });
      }
    } catch (e: any) {
      navigate(HOMEPAGE_URL, { replace: true });
    }
  };

  const getUserByToken = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (token) {
        const decodeToken: any = jwtDecode(token);
        const res = await UserManagerAPI.getUser(decodeToken?.sub, true);
        if (res && res.status === 200) {
          const currentUser = res?.data?.result;
          currentUser.custom_config = parseJSONtoObj(currentUser?.custom_config);
          i18n.changeLanguage(currentUser?.custom_config?.language_code || 'en');

          if (currentUser.tenant_id !== WI_TENANT_ID) {
            const partnerRes = await PartnerManagerAPI.getPartner(currentUser.tenant_id);
            currentUser.partner = partnerRes?.data?.record;
          }

          setAuth(currentUser);
          setIsAuth(true);
          if (location.pathname !== '/terms-and-conditions' && !currentUser?.has_accepted_policy && currentUser?.party === USER_PARTIES.PARTNER) {
            navigate(`/terms-and-conditions${searchParams?.get('state') ? `?state=${searchParams?.get('state')}` : ''}`, { replace: true });
          } else if (
            location.pathname === '/terms-and-conditions' &&
            currentUser?.has_accepted_policy &&
            currentUser?.party === USER_PARTIES.PARTNER
          ) {
            navigate(HOMEPAGE_URL, { replace: true });
          }

          return currentUser;
        }
      }

      return null;
    } catch (error) {
      resetToken();
      setIsAuth(false);
      return null;
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      setLoaded(false);
      try {
        if (isTokenExpire()) {
          const refreshToken = localStorage.getItem('refresh_token');
          if (refreshToken) {
            const res: any = await AuthManagerAPI.refreshAccessToken(refreshToken);
            const result = res && res.status === 200 ? res.data?.result : null;
            if (result) {
              saveToken({ ...result, refresh_token: refreshToken });
              const currentUser = await getUserByToken();
              await checkPageAccess(currentUser);
              return;
            }
          }
          resetToken();
          setIsAuth(false);
          return;
        }

        const currentUser = await getUserByToken();
        await checkPageAccess(currentUser);
      } catch (e) {
        resetToken();
        setIsAuth(false);
      } finally {
        setLoaded(true);
      }
    };

    Promise.all([checkAuth()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!loaded) {
    return (
      <Layout>
        <div className="loading-container-v2 bg-white">
          <ProgressSpinner className="progress-spinner-v2" />
        </div>
      </Layout>
    );
  }

  if (!isAuth) {
    resetToken();
    window.location.href = buildLoginLink(`${window.location.pathname || ''}${window.location.search || ''}`);
    return;
  }

  return children;
};

export default AuthProtectedRoute;
