import { ErrorMessage, Field, useField } from 'formik';
import React from 'react';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import { SelectButton } from 'primereact/selectbutton';
import './wi-select-buttonfield.scss';

export function SelectButtonField(props: any) {
  const { name, options } = props;

  const CustomDropdownComponent = (props: any) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = field;
    const { setValue } = helpers;

    const justifyTemplate = (option: any) => {
      return option.icon ? <i className={option.icon} style={{ fontSize: '16pt' }}></i> : <span className="p-button-label p-c">{option.label}</span>;
    };

    return (
      <SelectButton
        className="wi-form-select-button"
        itemTemplate={justifyTemplate}
        // optionLabel="label"
        optionValue="value"
        value={value}
        options={options}
        onChange={(e: any) => setValue(e.value)}
        unselectable={false}
      />
    );
  };

  return (
    <WIFormElement {...props}>
      <Field key={name} id={name} name={name} as={CustomDropdownComponent} />
      <ErrorMessage name={name} render={(msg: any) => <div style={{ color: 'red' }}>{msg}</div>} />
    </WIFormElement>
  );
}
