import * as React from 'react';
import { useEffect, useState } from 'react';
import './wilderness-runs-filter.scss';
import { Button } from 'primereact/button';
import { WIR_STATUS_LIST } from '../../../../utils/utils';
import FilterField from './filter-field';
import { WISearchField } from '../../../common';
import CalendarField from './calendar-field';
import MenuButton from '../../../common/menu-button/menu-button';

const WildernessRunsFilterComponent = (props: any) => {
  const { fields, isStatusAll, onRemove, onChange, onSearchFunc, filterValues, onAddDonation, area, partners } = props;
  const [values, setValues] = useState<Map<string, string[]>>();
  const [isDirty, setDirty] = useState<boolean>(false);
  const [search, setSearch] = useState<any>(filterValues.search);

  const getFields = () => {
    const resetValues = new Map<string, string[]>();
    fields.forEach((item: string) => {
      resetValues.set(item, []);
    });
    setValues(resetValues);
  };

  useEffect(() => {
    getFields();
  }, []);

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      if (k === 'dates' && filterValues.where.from && filterValues.where.to) {
        filter.set(k, [filterValues.where.from, filterValues.where.to]);
      } else {
        filter.set(k, filterValues.where[k]);
      }
    });
    setValues(filter);
    setSearch(filterValues.search);
  }, [filterValues]);

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch('');
    await onRemove();
  };

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
    await onChange({ field: event.field, value: event.value });
  };

  const Action_List = () => {
    return [
      {
        label: 'Add',
        description: 'Add a new WIR',
        icon: 'fa-solid fa-circle-plus',
        command: (e: any) => {
          onAddDonation();
        },
      },
    ];
  };

  return (
    <div className="filter-wir-component">
      <div className="flex align-items-center flex-wrap justify-content-between filter-panel">
        <div className="filter-container">
          <WISearchField
            icon={'pi pi-search'}
            placeholder="Search for anything"
            setSearch={(value: any) => setSearch(value.global.value)}
            enterSearch={() => onSearchFunc(search)}
            globalValue={search}
          />
          <FilterField
            name="partner_id"
            label="Partner"
            className="wi-filter-option-wir"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get('partner_id') || []}
            options={partners}
            optionLabel={'name'}
            optionValue={'uuid'}
            visible={values?.has('partner_id') || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="land_id"
            label="Area"
            className="wi-filter-option-wir"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get('land_id') || []}
            options={area}
            optionLabel={'name.de'}
            optionValue={'uuid'}
            visible={values?.has('land_id') || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="status"
            label="Status"
            className="wi-filter-option-wir"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get('status') || []}
            options={WIR_STATUS_LIST}
            optionLabel={'name.de'}
            optionValue={'code'}
            visible={isStatusAll}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <CalendarField
            name="dates"
            label="Dates"
            className="wi-filter-option-wir"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get('dates') || []}
            visible={values?.has('dates') || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="actions-right">
          <div className="remove-filter-button">
            <Button type="button" icon="pi pi-times" label="Clear filter(s)" onClick={onRemoveFilters} />
          </div>
          <div className="actions-wir">
            <MenuButton label="Actions" items={Action_List()}></MenuButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WildernessRunsFilterComponent;
