import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import React from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './import-csv-dialog.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CouponDetailAPI from '../../../../services/couponDetail';
import { WICSVUploadField } from '../../../common';
import { SelectButton } from 'primereact/selectbutton';
import { showNotification } from '../../../../utils/logic';

const ImportCSVDialogComponent = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { visible, onHide, fetchCouponDataHeader, getCouponData, id } = props;

  const toast = useRef(null);
  const formikRef = useRef(null);

  const ImportTypeList = [
    {
      name: 'Code',
      code: 'code',
    },
    {
      name: 'Reference',
      code: 'reference',
    },
  ];

  const importFormSchema = Yup.object().shape({
    donation_type: Yup.string().required('This field is required.'),
    dataImport: Yup.string().required('This field is required.'),
  });

  const initialValues = () => {
    return {
      donation_type: 'code',
      dataImport: '',
    };
  };

  const handleImportUngivenCodesCSV = async (import_data: any) => {
    try {
      let data = {
        _batch: {
          data: import_data.dataImport,
        },
        isCode: import_data.donation_type === 'code',
        batchId: id,
      };

      const res = await CouponDetailAPI.importUngivenCodesCSV(data);
      if (res && res.status === 200) {
        showNotification('success', 'Free codes successfully', toast);
        fetchCouponDataHeader();
        getCouponData();
        onHide();
      } else {
        showNotification('error', 'Free codes failed', toast);
      }
    } catch (error) {
      showNotification('error', 'Free codes failed', toast);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog className="wi-dialog" header={`Import ungiven codes`} visible={visible} style={{ width: '350px' }} modal onHide={onHide}>
        <div className="coupon-detail-import-container">
          <div className="import-btn">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues()}
              validationSchema={importFormSchema}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                handleImportUngivenCodesCSV(values);
              }}
            >
              {({ values, dirty, isValid, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="input-item">
                        <label className="label">
                          Import For <span className="required-label">*</span>
                        </label>
                        <SelectButton
                          className="donation-checkbox"
                          id="is_prepaid"
                          name="is_prepaid"
                          options={ImportTypeList}
                          optionLabel="name"
                          optionValue="code"
                          onChange={(item: any) => {
                            if (item.value) {
                              setFieldValue('donation_type', item.value, true);
                            }
                          }}
                          value={values.donation_type}
                        />
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-start upload-file">
                      <WICSVUploadField name="dataImport" className="input-upload" labelClassName="label-upload-csv" nameFileClassName="name-file" />
                    </div>
                    <div className="col-12 d-flex flex-column align-items-start justify-content-start note-message">
                      <p>
                        Please make sure that all data are correct.
                        <br /> This cannot be undone.
                      </p>
                    </div>
                    <div className="d-flex mt-4 mb-2 justify-content-center">
                      <Button
                        loading={isLoading}
                        disabled={!(isValid && dirty)}
                        type="submit"
                        label="Submit"
                        style={{ flex: 1 }}
                        className="wi-primary-button wi-button-medium"
                      ></Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ImportCSVDialogComponent;
