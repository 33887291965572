import ReceiptManagerAPI from '../../../services/receiptManager';
import { showNotification, generateLinkDownLoad } from '../../../utils/logic';
import moment from 'moment';

const LanguageOptions = {
  eur: 'de',
  chf: 'de',
  cad: 'en',
};
type CurrencyType = 'eur' | 'chf' | 'cad';
type LanguageCode = 'en' | 'de';

const generateFileDownload = (filename: string, data: any) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const url = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf; charset=utf-8' }));
  generateLinkDownLoad(filename, url);
};

const downloadPDF = async (receiptId: string, fileName: string, languageCode: LanguageCode, notify: any) => {
  try {
    let response: any;
    if (receiptId) {
      response = await ReceiptManagerAPI.exportReceipt(receiptId, languageCode);
      if (response && response.status === 200) {
        generateFileDownload(fileName, response.data.content);
        showNotification('success', 'Generate successfully', notify);
      } else {
        showNotification('error', 'Generate failed', notify);
      }
    }
  } catch (error) {
    showNotification('error', 'Generate failed', notify);
  }
};

export const onDownloadPDFReceipt = async (notify: any, rowData: any, e?: any) => {
  const currency_value = rowData.currency_code as CurrencyType;
  const languageCode: any = LanguageOptions[currency_value];

  const name = rowData.name.trim();
  const default_file_name = name.toLowerCase().replace(/(^|\s)\S/g, (l: any) => l.toUpperCase());
  const format_date = moment(new Date(rowData.receipt_date)).format('YYYY-MM-DD');

  const default_other_file_name = `Spendenquittung_${default_file_name.replace(' ', '-')}_${format_date}.pdf`;
  const default_cad_file_name = `Donation_Receipt_${default_file_name.replace(' ', '-')}_${format_date}.pdf`;
  const fileName = currency_value === 'cad' ? default_cad_file_name : default_other_file_name;

  await downloadPDF(rowData.uuid, fileName, languageCode, notify);
};

export const onDownloadPDFReceiptV2 = async (rowData: any) => {
  const currency_value = rowData.currency_code as CurrencyType;
  const languageCode: any = LanguageOptions[currency_value];

  const name = rowData.name.trim();
  const default_file_name = name.toLowerCase().replace(/(^|\s)\S/g, (l: any) => l.toUpperCase());
  const format_date = moment(new Date(rowData.receipt_date)).format('YYYY-MM-DD');

  const default_other_file_name = `Spendenquittung_${default_file_name.replace(' ', '-')}_${format_date}.pdf`;
  const default_cad_file_name = `Donation_Receipt_${default_file_name.replace(' ', '-')}_${format_date}.pdf`;
  const fileName = currency_value === 'cad' ? default_cad_file_name : default_other_file_name;

  if (rowData.uuid) {
    let response = await ReceiptManagerAPI.exportReceipt(rowData.uuid, languageCode);
    if (response && response.status === 200) {
      generateFileDownload(fileName, response.data.content);
      return;
    }
  }
  throw new Error('txt_generate_receipt_failed');
};
