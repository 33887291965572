import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Column, ColumnAlignType } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { env } from '../../configs';
import { removeEmpty } from '../../utils/utils';
import { ContactManagerAPI } from '../../services';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { useTranslation } from 'react-i18next';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import { useLayoutV2 } from '../../context/LayoutProvider';
import useAuth from '../../context/useAuth';
import { PERMISSIONS_V2 } from '../../utils/constants';
import WIConfirmDialog from '../../components_v2/common/delete-confirm-dialog/delete-confirm-dialog';
import { ReactComponent as WarningSvg } from '../../assets/images/icons/warning-symbol.svg';

const PeopleManager = () => {
  const { setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [people, setPeople] = useState({
    data: [],
    totalPage: 0,
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [search, setSearch] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const { t } = useTranslation('language', { keyPrefix: 'people_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

  const { auth } = useAuth();

  const permissions = {
    canCreatePeople: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_CREATE),
    canUpdatePeople: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_UPDATE),
  };

  const fetchMainData = async (valueSearch?: string) => {
    try {
      setIsLoading(true);
      const conditions = removeEmpty({
        pageNo: page,
        range: rows,
        search_text: valueSearch || undefined,
      });
      const res = await ContactManagerAPI.getContacts(conditions);
      if (!res || res.status !== 200) {
        throw new Error('txt_unknown_load_data_fail');
      }

      const data = res.data.records || [];
      setPeople({
        data: data,
        totalPage: res.data.total || 0,
      });
    } catch (error: any) {
      setPeople({ data: [], totalPage: 0 });
      setErrorProgress(errorTrans(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    await Promise.all([fetchMainData()]);
  };

  const onSearch = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchMainData(search);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === 'number') {
      fetchMainData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const onDelete = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setSelectedItem(rowData);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setSelectedItem(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteConfirm = async () => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      const response = await ContactManagerAPI.deleteContact(selectedItem.uuid);

      if (response && response.status === 200 && response.data.result.isValid) {
        onShowDeleteDialog(false);
        fetchMainData();
        setSuccessProgress(t('txt_delete_person_success'));
      } else {
        throw new Error('txt_delete_person_fail');
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const tableColumns = [
    {
      field: 'profile_picture',
      header: `${t('txt_name')}`,
      width: 'calc(15% - 64px)',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const logoPartner = `${env.PUBLIC_UPLOAD_URL}/${rowData?.profile_picture}?u=${rowData?.updated_at}`;
        return <img className="logo-circle" alt={rowData?.name} src={logoPartner} />;
      },
    },
    { field: 'name', header: '', width: '20%', align: 'left' },
    {
      field: 'position',
      header: `${t('txt_position')}`,
      width: '20%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return (
          <div
            className="wi-html-content"
            dangerouslySetInnerHTML={{
              __html: _.isString(rowData?.position) ? rowData?.position : rowData.position?.de,
            }}
          ></div>
        );
      },
    },
    {
      field: 'email',
      header: `${t('txt_email')}`,
      width: '25%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return rowData?.email || '-';
      },
    },
    {
      field: 'phone',
      header: `${t('txt_phone')}`,
      width: '20%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return rowData?.phone || '-';
      },
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        className={col.field}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-open',
        url: `/global-contents/people/${rowData?.uuid}`,
      },
      {
        label: t('txt_delete'),
        icon: 'wi-trash',
        hidden: !permissions.canUpdatePeople,
        dangerous: true,
        command: (e: any) => {
          onDelete(e, rowData);
        },
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout title={t('txt_title')} className="people-manager">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearch}
            />
          </div>
          <Link className="wi-primary-button-v2" to="/global-contents/people/create" hidden={!permissions.canCreatePeople}>
            <div className="wi-btn-label">
              {t('txt_btn_add')} <PlusSvg className="icon-svg"></PlusSvg>
            </div>
          </Link>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={people.data}
          filters={search}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={people.totalPage} onPageChange={onBasicPageChange} />
      </div>
      <WIConfirmDialog
        visible={isShowDeleteDialog}
        onHide={() => onShowDeleteDialog(false)}
        onConfirm={onDeleteConfirm}
        maskClassName="top-mask-dialog"
        confirmLabel={t('txt_confirm')}
        hideLabel={t('txt_cancel')}
        message={
          <Fragment>
            <div style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center', marginBottom: '16px' }}>{t('txt_are_you_sure')}</div>
            <div style={{ fontSize: '13px', textAlign: 'center' }}>
              <div
                dangerouslySetInnerHTML={{ __html: t('txt_confirm_description', { permanently: `<strong>${t('txt_permanently')}</strong>` }) }}
              ></div>
              <div dangerouslySetInnerHTML={{ __html: t('txt_can_not_action', { cant: `<strong>${t('txt_cant_not')}</strong>` }) }}></div>
              <br />
              <b>{t('txt_note')}</b>: <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: t('txt_note_delete', {
                    person: `<strong>${t('txt_person')}</strong>`,
                    unexpected: `<strong>${t('txt_unexpected_issue')}</strong>`,
                  }),
                }}
              ></div>
            </div>
          </Fragment>
        }
        icon={<WarningSvg className="icon-svg"></WarningSvg>}
      />
    </Layout>
  );
};

export default PeopleManager;
