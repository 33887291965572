import { useEffect, useRef } from 'react';
import React from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { DonorManagerAPI } from '../../../../services';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import _ from 'lodash';
import WICheckBoxV2 from '../../../../components_v2/common/wi-checkbox';

const AddDonorFormComponent = (props: any) => {
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const { onHide, countries, fetchCallBack, disableAdd, donor, id } = props;

  const formikRef = useRef(null);
  const { t } = useTranslation('language', { keyPrefix: 'donor_form' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onSubmitAddDonor = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid, resetForm } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));

        let newDonorData = {
          ...values,
          joined_date: moment(values.joined_date).format('DD.MM.YYYY'),
          payment_email: values.payment_email?.trim()?.toLowerCase(),
        };

        let getDonorRes = await DonorManagerAPI.getDonorByEmail(newDonorData.payment_email);
        if (getDonorRes?.status === 200) {
          if (!getDonorRes?.data) {
            let createRes = await DonorManagerAPI.createDonor(newDonorData);
            if (createRes?.status === 200 && createRes?.data) {
              setSuccessProgress(t('txt_create_donor_success'));

              if (newDonorData.isAddingAnotherDonor) {
                resetForm();
              } else {
                onHide();
              }

              fetchCallBack(createRes.data);
              return;
            }
          } else {
            throw new Error('txt_duplicated_donor_email');
          }
        }
        throw new Error('txt_create_donor_fail');
      }
    } catch (e: any) {
      setErrorProgress(errorTrans(e?.message || 'txt_create_donor_fail'));
    }
  };

  const onSubmitUpdateDonor = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));
        let updateData = {
          ...values,
          payment_email: values.payment_email?.trim()?.toLowerCase(),
        };

        if (values.payment_email?.trim()?.toLowerCase() !== donor?.payment_email?.trim()?.toLowerCase()) {
          let getDonorRes = await DonorManagerAPI.getDonorByEmail(updateData.payment_email);
          if (getDonorRes?.status === 200 && getDonorRes?.data) {
            throw new Error('txt_duplicated_donor_email');
          }
        }

        let updateRes = await DonorManagerAPI.updateDonor(id, updateData);
        if (updateRes?.status === 200 && updateRes?.data) {
          setSuccessProgress(t('txt_update_donor_success'));
          fetchCallBack();
          onHide();
          return;
        }

        throw new Error('txt_update_donor_failed');
      }
    } catch (e: any) {
      setErrorProgress(errorTrans(e?.message || 'txt_update_donor_failed'));
    }
  };

  const validationSchema = Yup.object().shape({
    payment_email: Yup.string().email(errorTrans('txt_email_format')).required(errorTrans('txt_required')),
  });

  const initialFormValues = () => {
    let initialDonor = {
      payment_first_name: donor ? donor.payment_first_name : '',
      payment_last_name: donor ? donor.payment_last_name : '',
      payment_email: donor ? donor.payment_email : '',
      payment_address: donor ? donor.payment_address : '',
      payment_additional_address: donor ? donor.payment_additional_address : '',
      payment_post_code: donor ? donor.payment_post_code : '',
      payment_city: donor ? donor.payment_city : '',
      payment_country: donor ? donor.payment_country : 'DE',
      joined_date: donor ? donor.joined_date : moment().toDate(),
      isAddingAnotherDonor: false,
    };

    return initialDonor;
  };

  return (
    <>
      <div className="add-donor-form">
        <div className="add-donor-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              //console.log("values >>>", values);
            }}
          >
            {({ values, errors, touched, dirty, isValid, handleBlur, handleSubmit, setFieldValue, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_first_name')}</label>
                        <InputText
                          type="text"
                          name="payment_first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_first_name}
                          maxLength={40}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_last_name')}</label>
                        <InputText
                          type="text"
                          name="payment_last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_last_name}
                          maxLength={40}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form mb-24">
                        <label className="label mb-8">
                          {t('txt_email')}
                          <span className="required-label ml-4"> *</span>
                        </label>
                        <InputText
                          className={`${touched.payment_email && errors.payment_email ? 'has-error' : ''}`}
                          type="text"
                          name="payment_email"
                          onChange={item => {
                            setFieldValue('payment_email', item.target.value, true);
                          }}
                          onBlur={handleBlur}
                          value={values.payment_email}
                          maxLength={255}
                        />
                        <div className={`${touched.payment_email && errors.payment_email ? 'error' : ''}`}>
                          {touched.payment_email && errors.payment_email ? `${errors.payment_email}` : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_address')}</label>
                        <InputText
                          type="text"
                          name="payment_address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_address}
                          maxLength={100}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_additional_address')}</label>
                        <InputText
                          type="text"
                          name="payment_additional_address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_additional_address}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_postal_code')}</label>
                        <InputText
                          type="text"
                          name="payment_post_code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_post_code}
                          maxLength={10}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_city')}</label>
                        <InputText
                          type="text"
                          name="payment_city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_city}
                          maxLength={85}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_country')}</label>
                        <Dropdown
                          className="dropdown-component-v2"
                          panelClassName="dropdown-panel-v2 dropdown-panel-bottom-v2"
                          name="payment_country"
                          value={values.payment_country}
                          options={countries || []}
                          onChange={(item: any) => {
                            setFieldValue('payment_country', item.value, false);
                            handleChange(item);
                          }}
                          optionLabel="name"
                          optionValue="ISO2"
                          appendTo="self"
                          filter
                          filterBy="name"
                          filterPlaceholder={t('txt_search')}
                        />
                      </div>
                    </div>
                  </div>

                  {!disableAdd && !donor ? (
                    <div className="row">
                      <div className="check-box-container col-md-12">
                        <WICheckBoxV2
                          className={`wi-checkbox-v2`}
                          id={`isAddingAnotherDonor`}
                          name={`isAddingAnotherDonor`}
                          label={t('txt_add_another')}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="row mt-24">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-start gap-24">
                        <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                        <Button
                          className="wi-primary-button-v2 h48 flex-1"
                          type="submit"
                          label={`${donor ? t('txt_save') : t('txt_submit')}`}
                          onClick={() => {
                            if (donor) {
                              onSubmitUpdateDonor();
                            } else {
                              onSubmitAddDonor();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddDonorFormComponent;
