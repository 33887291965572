import * as React from 'react';
import { useState, useEffect } from 'react';
import { useField } from 'formik';
import { SelectButton } from 'primereact/selectbutton';
import flag from '../../../assets/images/flags/flag_placeholder.png';
import PreviewForm from '../preview-form/preview-form';
import './certificate-container.scss';
import PreviewFormInclusive1 from '../preview-inclusive1-form/preview-form ';
import { LIST_TEMPLATE_PREVIEW } from '../../../utils/utils';
import { Dropdown } from 'primereact/dropdown';

const FieldItem = (props: any) => {
  const [field, meta] = useField(props.name);

  return (
    <>
      <div className="col-md-6">
        <div className="element-form">
          <label className="label">
            {props.title}
            <span className="required-label"> *</span>
          </label>
          <textarea className={`${meta.touched && meta.error ? 'has-error' : ''}`} {...field} />
          <div className={`${meta.touched && meta.error ? 'error' : ''}`}>{meta.touched && meta.error}</div>
        </div>
      </div>
    </>
  );
};

const CertificateContainer = (props: any) => {
  const { values, errors, isCampaign, visible, region_logo, forestType, imageTypes, handleUpadateValidateSchema, handleChange } = props;
  const [language, setLanguage] = useState('de');
  const languages = [
    {
      key: 'de',
      value: 'DE',
    },
    {
      key: 'en',
      value: 'EN',
    },
  ];

  const formatCountryBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <img src={flag} className={`flag flag-${rowData.key}`} width={100} alt="flag" />
      </React.Fragment>
    );
  };

  return visible ? (
    <>
      {/* <div className="row row-content">
        <div className="col-md-12">
          <div className="element-form">
            <label className="label">
              Language
            </label>
            
          </div>
        </div>
      </div>
      <div className="row row-content">
        {props.fields.map((field: any, index: number) => (
          <FieldItem key={index.toString()} {...field} />
        ))}
      </div> */}
      <div className="row row-content">
        <div className="col-md-6">
          <label className="label">Certificate Preview</label>
          <SelectButton
            className="wi-selectbutton wi-cert-lang-toggle"
            value={language}
            options={languages}
            itemTemplate={formatCountryBodyTemplate}
            onChange={e => setLanguage(e.value)}
            optionLabel="value"
            optionValue="key"
            unselectable={false}
          />
        </div>
        {isCampaign ? (
          <div className="col-md-6">
            <label className="label"> Certificate Template </label>
            <div className="element-form" style={{ padding: '0px' }}>
              <Dropdown
                value={values.template_id ?? LIST_TEMPLATE_PREVIEW[0].uuid}
                name="template_id"
                options={LIST_TEMPLATE_PREVIEW}
                onChange={(itemValue: any) => {
                  handleUpadateValidateSchema(itemValue.value);
                  handleChange(itemValue);
                }}
                optionLabel="name"
                optionValue="uuid"
                appendTo="self"
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-12" style={{ overflowY: 'hidden', overflowX: 'auto' }}>
          <div className="element-form upload-certifi">
            {isCampaign && values.template_id && values.template_id !== LIST_TEMPLATE_PREVIEW[0].uuid ? (
              <PreviewFormInclusive1
                values={values}
                errors={errors}
                isCampaign={isCampaign}
                region_logo={region_logo}
                forestType={forestType}
                language={language}
                imageTypes={imageTypes}
              />
            ) : (
              <PreviewForm
                values={values}
                errors={errors}
                isCampaign={isCampaign}
                region_logo={region_logo}
                forestType={forestType}
                language={language}
                imageTypes={imageTypes}
              />
            )}
          </div>
        </div>
      </div>
      {/* <div className="col-md-3">
            <div className="element-form upload-certifi">
                <WIUploadField type="file" name="certificate_qr_code" label="Certificate QR Code" />
            </div>
        </div> */}
    </>
  ) : (
    <div></div>
  );
};

export default CertificateContainer;
