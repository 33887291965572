import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthManagerAPI from '../../services/authManager';
import LogoLogo from '../../assets/images/login-logo.svg';
import './login.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { decodeBase64 } from '../../utils/logic';
import { useTranslation } from 'react-i18next';
import { UserManagerAPI } from '../../services/v2';
import { COGNITO_LOGIN_CHALLENGE_NAMES, HOMEPAGE_URL, USER_PARTIES } from '../../utils/constants';
import { jwtDecode } from 'jwt-decode';
import { env } from '../../configs';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [bgLoginIndex] = useState(Math.floor(Math.random() * 12));
  const [searchParams] = useSearchParams();
  const { t: errTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t } = useTranslation('language', { keyPrefix: 'login_process' });

  const getUserByToken = async (ipId?: any) => {
    try {
      if (ipId) {
        const res = await UserManagerAPI.getUser(ipId, true);
        if (res && res.status === 200) {
          const { has_accepted_policy, party } = res?.data?.result;
          if (!has_accepted_policy && party === USER_PARTIES.PARTNER) {
            navigate(`/terms-and-conditions${searchParams?.get('state') ? `?state=${searchParams?.get('state')}` : ''}`);
            return;
          } else {
            const redirectUrl = searchParams?.get('state') ? decodeBase64(searchParams?.get('state') || '') : '';
            if (redirectUrl.includes('login')) {
              navigate(HOMEPAGE_URL, { replace: true });
            } else {
              navigate(redirectUrl || HOMEPAGE_URL, { replace: true });
            }
          }
        }
      }
    } catch (error) {
      setError('txt_login_500');
    }
  };

  const handleSignIn = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!email?.trim() || !password?.trim()) {
      setError('txt_login_not_same');
      return;
    }
    setError('');
    try {
      setIsLoading(true);
      const res = await AuthManagerAPI.signIn(email, password);
      const signInResult = res && res.status === 200 ? res.data.result : null;
      if (signInResult && signInResult.AuthenticationResult?.AccessToken) {
        localStorage.setItem('id_token', signInResult.AuthenticationResult.IdToken || '');
        localStorage.setItem('access_token', signInResult.AuthenticationResult.AccessToken || '');
        localStorage.setItem('refresh_token', signInResult.AuthenticationResult.RefreshToken || '');
        const decodeToken: any = jwtDecode(signInResult.AuthenticationResult.AccessToken);
        await getUserByToken(decodeToken?.sub);
      } else if (signInResult?.ChallengeName === COGNITO_LOGIN_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
        localStorage.setItem('signInChallengeName', signInResult.ChallengeName);
        localStorage.setItem('signInChallengeSession', signInResult.Session);
        localStorage.setItem('signInUsername', email);
        localStorage.setItem('signInPassword', password);
        navigate(`/confirm-new-password${searchParams?.get('state') ? `?state=${searchParams?.get('state')}` : ''}`, { replace: true });
      } else {
        throw new Error(`Unhandled Log-in Logic: ${signInResult}`);
      }
    } catch (error) {
      setError('txt_login_500');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate(HOMEPAGE_URL, { replace: true });
    }
  }, []);

  return (
    <div className="login-container">
      <img src={`${env.PUBLIC_IMAGE_URL}/bg_login${bgLoginIndex}.jpg`} alt="" className="background-cover" />
      <div className="background-backdrop"></div>
      <div className="login-form-wrapper">
        <img src={LogoLogo} alt="" className="login-logo" />
        <div className="login-form">
          <h2 className="login-title mb-24">{t('txt_btn_login')}</h2>
          <form className="row-common" onSubmit={handleSignIn}>
            <div className="element-form mb-32">
              <label className="label mb-16">{t('txt_username')}</label>
              <InputText
                className="inputText"
                id="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="element-form mb-16">
              <label className="label mb-16">{t('txt_password')}</label>
              <InputText
                className="inputText"
                id="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            {error ? <div className="login-error mb-16">{errTrans(error)}</div> : <></>}
            <Button
              className="wi-primary-button-v2 dots-button mb-24 h48 w-100"
              type={'submit'}
              label={t('txt_btn_login')}
              disabled={isLoading}
            ></Button>
            <div className="forgot-pass-wrapper">
              <div>{t('txt_forgot_password')}</div>
              <a className="ml-4" href="/forgot-password">
                {t('txt_reset_password')}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
