import * as React from 'react';
import './wi-status.scss';
import { useTranslation } from 'react-i18next';

const WiStatus = (props: any) => {
  const { item, className } = props;
  const { t } = useTranslation('language');

  return (
    <div
      className={`wi-tag wi-tag-status ml-8 mr-8 pt-10 pr-24 pb-10 pl-10 ${className || ''}`}
      style={{ backgroundColor: item?.background_color, color: item?.color }}
    >
      <span className="icon-circle" style={{ background: item?.color }}>
        {' '}
      </span>
      <p className="wi-tag-label m-0 pl-8">{t(item?.label)}</p>
    </div>
  );
};

export default WiStatus;
