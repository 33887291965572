import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import * as _ from 'lodash';
import './wi-textarea.scss';
import { InputTextarea } from 'primereact/inputtextarea';

export function WITextArea(props: any) {
  const { name, values, disabled, maxLength } = props;
  const { handleBlur, errors, touched, setFieldValue } = useFormikContext();

  const formatedValue = (value: any) => {
    const regex = new RegExp('<br />', 'g');
    return value ? value.replace(regex, '\n') : '';
  };

  const handlerChange = (e: any) => {
    const regex = new RegExp('\n', 'g');
    setFieldValue(name, e.target.value.replace(regex, '<br />'), false);
  };

  return (
    <WIFormElement {...props}>
      <InputTextarea
        className={`wi-form-textarea ${_.get(errors, name) && _.get(touched, name) ? 'has-error' : ''}`}
        id={name}
        name={name}
        value={formatedValue(_.get(values, name))}
        onChange={handlerChange}
        onBlur={handleBlur}
        disabled={disabled}
        maxLength={maxLength}
      />
      <ErrorMessage name={name} render={(msg: any) => <div className="error">{msg}</div>} />
    </WIFormElement>
  );
}
