import { Button } from 'primereact/button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS_LIST } from '../../../../utils/constants';
import { ReactComponent as SaveSvg } from '../../../../assets/images/icons/save.svg';
import { ReactComponent as BackSvg } from '../../../../assets/images/icons/arrow-narrow-left.svg';
import WiStatus from '../../../../components_v2/common/wi-status/wi-status';
import MenuDot from '../../../../components_v2/common/menu-dot/menu-dot';

const ActionFormComponent = (props: any) => {
  const { userData, isEdit, actionBack, onSubmit, menuItems = [] } = props;
  const { t } = useTranslation('language', { keyPrefix: 'system_user_detail' });

  return (
    <div className="action-panel-base-v2 pt-16 pr-24 pb-16 pl-24">
      <div className="d-flex flex-row justify-content-between w-100">
        <div className="d-flex flex-row">
          <WiStatus item={STATUS_LIST.find(s => s.code === userData?.status)}></WiStatus>
        </div>
        <div className="d-flex flex-row gap-16">
          {!isEdit ? <MenuDot items={menuItems} /> : <></>}
          <Button className="wi-danger-button-v2" label={t('txt_back')} onClick={(e: any) => actionBack()} hidden={!isEdit}>
            <BackSvg className="icon-svg" />
          </Button>
          <Button className="wi-primary-button-v2" label={t('txt_save')} onClick={(e: any) => onSubmit()} hidden={!isEdit}>
            <SaveSvg className="icon-svg" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ActionFormComponent;
