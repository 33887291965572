import * as React from 'react';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import './menu-dot.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as DotsSvg } from '../../../assets/images/icons/dots.svg';
import { ReactComponent as EditSvg } from '../../../assets/images/icons/edit.svg';
import { ReactComponent as OpenDocSvg } from '../../../assets/images/icons/device-desktop-share.svg';
import { ReactComponent as DownloadSvg } from '../../../assets/images/icons/download.svg';
import { ReactComponent as AddFolderSvg } from '../../../assets/images/icons/add-folder.svg';
import { ReactComponent as DeleteFolderSvg } from '../../../assets/images/icons/delete-folder.svg';
import { ReactComponent as ExpandSvg } from '../../../assets/images/icons/expand.svg';
import { ReactComponent as CollapseSvg } from '../../../assets/images/icons/collapse.svg';
import { ReactComponent as DoubleCheckSvg } from '../../../assets/images/icons/double-check.svg';
import { ReactComponent as CopySvg } from '../../../assets/images/icons/copy.svg';
import { ReactComponent as TrashSvg } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as OnlineCircleSvg } from '../../../assets/images/icons/online-circle.svg';
import { ReactComponent as OfflineCircleSvg } from '../../../assets/images/icons/offine-circle.svg';
import { ReactComponent as LockSvg } from '../../../assets/images/icons/lock.svg';
import { ReactComponent as NoteBookSvg } from '../../../assets/images/icons/notebook.svg';
import { ReactComponent as ListSvg } from '../../../assets/images/icons/list.svg';
import { ReactComponent as PaintSvg } from '../../../assets/images/icons/paint.svg';
import { ReactComponent as OpenArrowSvg } from '../../../assets/images/icons/open-arrow.svg';
import { ReactComponent as ArrowDownSvg } from '../../../assets/images/icons/chevrons-down.svg';
import { ReactComponent as ImportSvg } from '../../../assets/images/icons/import.svg';
import { ReactComponent as ReserveSvg } from '../../../assets/images/icons/reserve.svg';
import { ReactComponent as PlusSvg } from '../../../assets/images/icons/add-plus.svg';
import { ReactComponent as UserHeartSvg } from '../../../assets/images/menu/user-heart.svg';
import { ReactComponent as KeySvg } from '../../../assets/images/icons/key.svg';
import { ReactComponent as CampaignSvg } from '../../../assets/images/menu/speakerphone.svg';
import { ReactComponent as HeartHandSvg } from '../../../assets/images/menu/heart-handshake.svg';
import { ReactComponent as PersonCircleSvg } from '../../../assets/images/icons/user-circle.svg';
import { ReactComponent as PersonProfileSvg } from '../../../assets/images/icons/user-profile.svg';
import { ReactComponent as ToggleRightSvg } from '../../../assets/images/icons/toggle-right.svg';
import { ReactComponent as ToggleLeftSvg } from '../../../assets/images/icons/toggle-left.svg';
import { ReactComponent as ScreenShareSvg } from '../../../assets/images/icons/screen-share.svg';
import { ReactComponent as ProtectedPlusSvg } from '../../../assets/images/icons/protect-plus.svg';
import { Button } from 'primereact/button';

const loadSVG = (icon: string) => {
  if (icon === 'wi-edit') {
    return <EditSvg className="icon-svg"></EditSvg>;
  } else if (icon === 'wi-key') {
    return <KeySvg className="icon-svg"></KeySvg>;
  } else if (icon === 'wi-open') {
    return <OpenArrowSvg className="icon-svg"></OpenArrowSvg>;
  } else if (icon === 'wi-person') {
    return <UserHeartSvg className="icon-svg"></UserHeartSvg>;
  } else if (icon === 'wi-person-circle') {
    return <PersonCircleSvg className="icon-svg"></PersonCircleSvg>;
  } else if (icon === 'wi-person-profile') {
    return <PersonProfileSvg className="icon-svg"></PersonProfileSvg>;
  } else if (icon === 'wi-toggle-left') {
    return <ToggleLeftSvg className="icon-svg"></ToggleLeftSvg>;
  } else if (icon === 'wi-toggle-right') {
    return <ToggleRightSvg className="icon-svg"></ToggleRightSvg>;
  } else if (icon === 'wi-screen-share') {
    return <ScreenShareSvg className="icon-svg"></ScreenShareSvg>;
  } else if (icon === 'wi-edit-paint') {
    return <PaintSvg className="icon-svg"></PaintSvg>;
  } else if (icon === 'wi-online-circle') {
    return <OnlineCircleSvg className="icon-svg"></OnlineCircleSvg>;
  } else if (icon === 'wi-list') {
    return <ListSvg className="icon-svg"></ListSvg>;
  } else if (icon === 'wi-offline-circle') {
    return <OfflineCircleSvg className="icon-svg"></OfflineCircleSvg>;
  } else if (icon === 'wi-copy') {
    return <CopySvg className="icon-svg"></CopySvg>;
  } else if (icon === 'wi-trash') {
    return <TrashSvg className="icon-svg"></TrashSvg>;
  } else if (icon === 'wi-open-doc') {
    return <OpenDocSvg className="icon-svg"></OpenDocSvg>;
  } else if (icon === 'wi-download') {
    return <DownloadSvg className="icon-svg"></DownloadSvg>;
  } else if (icon === 'wi-double-check') {
    return <DoubleCheckSvg className="icon-svg"></DoubleCheckSvg>;
  } else if (icon === 'wi-add-folder') {
    return <AddFolderSvg className="icon-svg"></AddFolderSvg>;
  } else if (icon === 'wi-delete-folder') {
    return <DeleteFolderSvg className="icon-svg"></DeleteFolderSvg>;
  } else if (icon === 'wi-expand') {
    return <ExpandSvg className="icon-svg"></ExpandSvg>;
  } else if (icon === 'wi-collapse') {
    return <CollapseSvg className="icon-svg"></CollapseSvg>;
  } else if (icon === 'wi-lock') {
    return <LockSvg className="icon-svg"></LockSvg>;
  } else if (icon === 'wi-import') {
    return <ImportSvg className="icon-svg"></ImportSvg>;
  } else if (icon === 'wi-reserve') {
    return <ReserveSvg className="icon-svg"></ReserveSvg>;
  } else if (icon === 'wi-plus') {
    return <PlusSvg className="icon-svg"></PlusSvg>;
  } else if (icon === 'wi-notebook') {
    return <NoteBookSvg className="icon-svg"></NoteBookSvg>;
  } else if (icon === 'wi-campaign') {
    return <CampaignSvg className="icon-svg"></CampaignSvg>;
  } else if (icon === 'wi-protected-plus') {
    return <ProtectedPlusSvg className="icon-svg"></ProtectedPlusSvg>;
  } else if (icon === 'wi-heart-handshake') {
    return <HeartHandSvg className="icon-svg"></HeartHandSvg>;
  } else if (['wi-active', 'wi-inactive', 'wi-archive', 'wi-complete'].includes(icon)) {
    return <span className={`icon-circle icon-circle-big ${icon || ''}`}> </span>;
  }
};

const customMenuItemsDot = (item: any, options: any) => {
  const buildChild = (item: any, options: any) => (
    <>
      <div className={`p-menuitem-containers ${item.icon || ''} ${item.className || ''}`}>
        <span className={`${options.labelClassName} headline`}>{item.label}</span>
        {item.description && (
          <>
            <span className={`${options.labelClassName} description`}>{item.description}</span>
          </>
        )}
      </div>
      {loadSVG(item.icon)}
    </>
  );

  if (item.url) {
    return (
      <>
        <Link
          to={item.url}
          className={'p-menuitem-link wi-menuitem-3-dot-v2' + (!!item.disabled ? ' p-disabled' : '')}
          role="menuitem"
          onClick={e => options.onClick(e)}
        >
          {buildChild(item, options)}
        </Link>
      </>
    );
  } else {
    const propList = item.externalUrl ? { href: item.externalUrl, target: '_blank', rel: 'noreferrer' } : { onClick: (e: any) => options.onClick(e) };
    return (
      <>
        <a
          data-testid="menudotItem"
          {...propList}
          className={`p-menuitem-link wi-menuitem-3-dot-v2 ${item.dangerous ? 'color-red-warning' : ''} ${!!item.disabled ? ' p-disabled' : ''}`}
          role="menuitem"
        >
          {buildChild(item, options)}
        </a>
      </>
    );
  }
};

const MenuDot = (props: any) => {
  const { items } = props;
  const refMenu = useRef<any>(null);

  return (
    <div className="dot-action-v2" data-testid="menudot" onClick={(event: any) => refMenu?.current?.toggle(event)}>
      <Menu
        className="dot-action-menu-v2"
        model={items.filter((c: any) => !c.hidden).map((m: any) => ({ template: customMenuItemsDot, ...m })) || []}
        popup
        ref={refMenu}
      />
      <DotsSvg className="icon-svg"></DotsSvg>
    </div>
  );
};

export default MenuDot;

export const MenuButton = (props: any) => {
  const { items, label } = props;
  const refMenu = useRef<any>(null);

  return (
    <div className="dot-action-v2 menu-action-v2" data-testid="menudot" onClick={(event: any) => refMenu?.current?.toggle(event)}>
      <Menu
        className="dot-action-menu-v2"
        model={items.filter((c: any) => !c.hidden).map((m: any) => ({ template: customMenuItemsDot, ...m })) || []}
        popup
        ref={refMenu}
      />
      <Button className="wi-primary-button-v2" label={label}>
        <ArrowDownSvg className="icon-svg"></ArrowDownSvg>
      </Button>
    </div>
  );
};
