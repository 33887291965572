import { useRef } from 'react';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { FAQsManagerAPI } from '../../../../services';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import { WIFormInputTextV2 } from '../../../../components_v2/common/form-fields';
import { RichTextEditorV2 } from '../../../../components_v2/common/rich-text-editor-v2/rich-text-editor-v2';

const AddFAQFormComponent = (props: any) => {
  const { onHide, fetchCallBack, faqData } = props;
  const formikRef = useRef(null);
  const { t } = useTranslation('language', { keyPrefix: 'faqs_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { getLanguage, setErrorProgress, setLoadingProgress, setSuccessProgress } = useLayoutV2();

  const onSuccess = () => {
    fetchCallBack();
    onHide();
  };

  const onAddFAQ = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));

        if (faqData) {
          const updatedData = {
            ...values,
            uuid: faqData.uuid,
            question: faqData.question,
            answer: faqData.answer,
          };
          updatedData['question'][getLanguage()] = values.question;
          updatedData['answer'][getLanguage()] = values.answer;

          const updateRes = await FAQsManagerAPI.updateFAQs(updatedData);
          if (updateRes?.status === 200 && updateRes?.data) {
            setSuccessProgress(t('txt_update_faq_success'));
            onSuccess();
          } else {
            throw new Error('txt_update_faq_fail');
          }
        } else {
          const newData = {
            ...values,
            question: { de: values.question, en: values.question },
            answer: { de: values.answer, en: values.answer },
          };

          let createRes = await FAQsManagerAPI.generateFAQs(newData);
          if (createRes?.status === 200 && createRes?.data) {
            setSuccessProgress(t('txt_create_faq_success'));
            onSuccess();
          } else {
            throw new Error('txt_create_faq_fail');
          }
        }
      }
    } catch (error: any) {
      console.error('Error fetching data:', error);
      setErrorProgress(errorTrans(error.message));
    }
  };

  const initialFormValues = () => {
    let initialFAQ = {
      category: '',
      question: '',
      answer: '',
    };

    if (faqData) {
      initialFAQ.category = faqData.category || initialFAQ.category;
      initialFAQ.question = faqData.question?.[getLanguage()] || initialFAQ.question;
      initialFAQ.answer = faqData.answer?.[getLanguage()] || initialFAQ.answer;
    }

    return initialFAQ;
  };

  const validationSchema = Yup.object().shape({
    question: Yup.string()
      .nullable()
      .required(errorTrans('txt_required'))
      .max(200, t('txt_question_max_length', { max: 200 })),
    answer: Yup.string().nullable().required(errorTrans('txt_required')),
  });

  return (
    <>
      <div className="add-faq-form">
        <div className="add-faq-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row mb-24">
                    <div className="col-md-12">
                      <WIFormInputTextV2 name={`category`} title={t('txt_category')} isRequired={false} />
                    </div>
                  </div>

                  <div className="row mb-24">
                    <div className="col-md-12">
                      <WIFormInputTextV2 name={`question`} title={t('txt_question')} isRequired={true} maxLength={200} />
                    </div>
                  </div>

                  <div className="row mb-24">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label pb-16">
                          {' '}
                          {t('txt_answer')} <span className="required-label ml-4"> *</span>
                        </label>
                        <RichTextEditorV2 name="answer" isMultipleLine={true} isRequired={true} />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-24">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between gap-24">
                        <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                        <Button
                          className="wi-primary-button-v2 h48 flex-1"
                          type="submit"
                          label={faqData ? t('txt_btn_update') : t('txt_btn_add')}
                          onClick={() => onAddFAQ()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddFAQFormComponent;
