export const MenuItems = [
  {
    label: 'Dashboard',
    icon: 'fa-solid fa-gauge-high',
    url: '/',
    className: 'wi-menu-item',
  },
  {
    label: 'Donation',
    icon: 'fa-solid fa-hand-holding-dollar',
    url: '/donations',
    className: 'wi-menu-item',
  },
  {
    label: 'Subscription',
    icon: 'fa-solid fa-repeat',
    className: 'wi-menu-item',
    url: '/subscriptions',
  },
  {
    label: 'Donor',
    icon: 'fa-solid fa-users-line',
    className: 'wi-menu-item',
    url: '/donors',
  },
  {
    label: 'Region',
    icon: 'fa-solid fa-earth-americas',
    url: '/regions',
    className: 'wi-menu-item',
  },
  {
    label: 'Area',
    icon: 'fa-solid fa-map',
    url: '/areas',
    className: 'wi-menu-item',
  },
  {
    label: 'Campaign',
    icon: 'fa-solid fa-bullhorn',
    url: '/campaigns',
    className: 'wi-menu-item',
  },
  {
    label: 'Partner',
    icon: 'fa-solid fa-handshake',
    url: '/partners',
    className: 'wi-menu-item',
  },
  {
    label: 'Code',
    icon: 'fa-solid fa-tags',
    url: '/codes',
    className: 'wi-menu-item',
  },
  {
    label: 'Media',
    icon: 'fa-solid fa-photo-film',
    url: '/media',
    className: 'wi-menu-item',
  },
  {
    label: 'Page',
    icon: 'fa-solid fa-newspaper',
    url: '/cms-pages',
    className: 'wi-menu-item',
  },
  {
    label: 'Users',
    icon: 'fa-solid fa-user',
    url: '/users',
    className: 'wi-menu-item',
  },
  {
    label: 'Global Content',
    icon: 'fa-solid fa-globe',
    className: 'wi-menu-item',
    url: '/global-contents',
    items: [
      {
        label: 'Headers',
        icon: 'fa-solid fa-header',
        url: '/global-contents/cms-headers',
        className: 'wi-menu-item',
      },
      {
        label: 'Footers',
        icon: 'fa-solid fa-arrow-down',
        url: '/global-contents/cms-footers',
        className: 'wi-menu-item',
      },
      {
        label: 'Global Blocks',
        icon: 'pi pi-instagram',
        url: '/global-contents/cms-global-blocks',
        className: 'wi-menu-item',
      },
      {
        label: 'FAQs',
        icon: 'fa-solid fa-question-circle',
        url: '/global-contents/faqs',
        className: 'wi-menu-item',
      },
      {
        label: 'People',
        icon: 'fa-solid fa-address-card',
        url: '/global-contents/people',
        className: 'wi-menu-item',
      },
      {
        label: 'Donation Example',
        icon: 'fa-solid fa-hand-holding-dollar',
        url: '/global-contents/donation-examples',
        className: 'wi-menu-item',
      },
      {
        label: 'Quote',
        icon: 'fa-solid fa-quote-left',
        url: '/global-contents/cms-quotes',
        className: 'wi-menu-item',
      },
    ],
  },
  {
    label: 'More',
    icon: 'fa-solid fa-toolbox',
    url: '/utilities',
    className: 'wi-menu-item',
    items: [
      {
        label: 'Receipt',
        icon: 'fa-solid fa-receipt',
        url: '/utilities/manual-receipts',
        className: 'wi-menu-item',
      },
      {
        label: 'Goal Manager',
        icon: 'fa-solid fa-bullseye',
        url: '/utilities/targets',
        className: 'wi-menu-item',
      },
      {
        label: 'Coupons',
        icon: 'fa-solid fa-tags',
        url: '/coupons',
        className: 'wi-menu-item',
      },
      {
        label: 'Match Funding',
        icon: 'fa-solid fa-divide',
        url: '/match-funding',
        className: 'wi-menu-item',
      },
    ],
  },
];

export enum MailStatus {
  NotYet = 'not_yet',
  Completed = 'completed',
  Pending = 'pending',
}

export enum COMMON_STATUS {
  INITIALIZED = 'initialized',
  ACTIVE = 'active',
  PAUSED = 'paused',
  ARCHIVED = 'archived',
  CLOSED = 'closed',
  COMPLETED = 'completed',
  NEW = 'new',
  DELETED = 'deleted',
  DEACTIVATE = 'deactivate',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
  BLOCKED = 'blocked',
  DRAFT = 'draft',
  REFUND = 'refund',
  CANCELLED = 'cancelled',
  PUBLISHED = 'published',
  NOT_COMPLETED = 'not_completed',
  NOT_YET = 'not_yet',
  NOT_APPLICABLE = 'not_applicable',
}

export const RegionStatusUtils = [
  {
    name: 'New',
    code: 'new',
  },
  {
    name: 'Active',
    code: 'active',
  },
  {
    name: 'Completed',
    code: 'completed',
  },
  {
    name: 'Inactive',
    code: 'inactive',
  },
];

export const CurrencyList = [
  {
    name: 'EUR',
    code: 'eur',
  },
  {
    name: 'CHF',
    code: 'chf',
  },
  {
    name: 'CAD',
    code: 'cad',
    constant: true,
  },
];

export const CampaignStatus = [
  { name: 'New', code: 'new' },
  { name: 'Inactive', code: 'inactive' },
  { name: 'Active', code: 'active' },
  { name: 'Completed', code: 'completed' },
  // { name: 'Archived', code: 'archived' },
  // { name: 'Deleted', code: 'deleted' },
  // { name: 'Closed', code: 'closed' },
];

export const TypeLandCode = {
  land: 'land',
  region: 'region',
  campaign: 'campaign',
};

export const ImportTypeList = [
  {
    name: 'Region',
    code: TypeLandCode.region,
  },
  {
    name: 'Campaign',
    code: TypeLandCode.campaign,
  },
];

export const ImportNewTypeList = [
  {
    name: 'Region',
    code: TypeLandCode.region,
  },
  {
    name: 'Area',
    code: TypeLandCode.land,
  },
  {
    name: 'Campaign',
    code: TypeLandCode.campaign,
  },
];

export const Yes_NO = [
  {
    name: 'Yes',
    code: 1,
  },
  {
    name: 'No',
    code: 0,
  },
];

export const FIELDS_SEARCH = {
  DASHBOARD: 'serial,land_code,campaign_code,import,created_at,created_user,status,mail_status',
  // DONATION: 'donation_serial,land_code,campaign_code,land_code_v2,campaign_code_v2,payment_email,payment_method,payment_first_name,payment_last_name',
  DONATION: 'payment_method,donor.payment_email,donor.payment_first_name,donor.payment_last_name',
  PARTNERS: 'name,code,status',
  REGIONS: 'name',
  LANDS: 'name,code,status',
  CAMPAIGNS: 'name,code,status',
  CODES: 'campaign_id',
  PAGES: 'name,url,type_id',
  CONTACT: 'name,email,phone',
  FAQS: 'serial,question,answer',
  DOWNLOADFILES: 'file,name,folder_id,file_extension',
  PROJECT: 'name,description',
  DONATIONEXAMPLES: 'example',
  MEDIA: 'name',
};

export const COUPON_STATUS_LIST = [
  {
    code: 'active',
    name: { de: 'Active', en: 'Active' },
  },
  {
    code: 'finished',
    name: { de: 'Finished', en: 'Finished' },
  },
];

export const WIR_STATUS_LIST = [
  {
    code: 'new',
    name: { de: 'New', en: 'New' },
  },
  {
    code: 'active',
    name: { de: 'Active', en: 'Active' },
  },
  {
    code: 'completed',
    name: { de: 'Succeeded', en: 'Succeeded' },
  },
];

export const LIST_FIELD_EXPORT = [
  {
    name: 'Serial',
    code: 'donation_serial',
  },
  {
    name: 'Salutation',
    code: 'payment_salutation',
  },
  {
    name: 'First Name',
    code: 'payment_first_name',
  },
  {
    name: 'Last Name',
    code: 'payment_last_name',
  },
  {
    name: 'Email',
    code: 'payment_email',
  },
  {
    name: 'Address',
    code: 'payment_address',
  },
  {
    name: 'Postal Code',
    code: 'payment_post_code',
  },
  {
    name: 'City',
    code: 'payment_city',
  },
  {
    name: 'Country Code',
    code: 'payment_country',
  },
  {
    name: 'Additional Address',
    code: 'payment_additional_address',
  },
  {
    name: 'Sent Receipt?',
    code: 'is_sent_receipt',
  },
  {
    name: 'Payment Method',
    code: 'payment_method',
  },
  {
    name: 'Transaction ID',
    code: 'payment_transaction_id',
  },
  {
    name: 'Status',
    code: 'status',
  },
  {
    name: 'Payment Date',
    code: 'payment_date',
  },
  {
    name: 'Payment Received Date',
    code: 'payment_received_date',
  },
  {
    name: 'Rhythm',
    code: 'rhythm',
  },
  {
    name: 'Language',
    code: 'payment_language_code',
  },
  {
    name: 'Currency Unit',
    code: 'payment_currency_code',
  },
  {
    name: 'Amount',
    code: 'donation_money',
  },
  {
    name: 'Forest Size (m²)',
    code: 'area',
  },
  {
    name: 'CO₂ (kg)',
    code: 'amount_kg_co2',
  },
  {
    name: 'Donate Type',
    code: 'donation_type',
  },
  {
    name: 'Geo-coordinate (DMS)',
    code: 'nw_geo_coordinate',
  },
  {
    name: 'Is Imported',
    code: 'is_imported',
  },
  {
    name: 'As Company',
    code: 'as_company',
  },
  {
    name: 'Company',
    code: 'payment_company_name',
  },
  {
    name: 'Personalize Certificate',
    code: 'personalize_certificate',
  },
  {
    name: 'Certificate Name',
    code: 'personalize_certificate_display_name',
  },
  {
    name: 'Occasion',
    code: 'personalize_certificate_reason',
  },
  {
    name: 'Subscribe To Newsletter',
    code: 'subscribe_newsletter',
  },
  {
    name: 'Area Code',
    code: 'land_code',
  },
  {
    name: 'Campaign Code',
    code: 'campaign_code',
  },
];

export const HEADERS: any = {
  PRIMARY: {
    name: {
      de: 'Primary Header',
      en: 'Primary Header',
    },
    value: 'Primary',
  },
  SECONDARY: {
    name: {
      de: 'Custom Header',
      en: 'Custom Header',
    },
    value: 'Secondary',
  },
};

export const FOOTERS: any = {
  PRIMARY: {
    name: 'Full Footer',
    value: 'Primary',
  },
  SECONDARY: {
    name: 'Light Footer',
    value: 'Secondary',
  },
};

export const WIR_STATUS = {
  NEW: 'new',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

export const SPONSOR_STATUS_LIST = {
  PAID: 'Paid',
  UNPAID: 'Unpaid',
};

export const LIST_TEMPLATE_PREVIEW = [
  {
    name: 'Default',
    uuid: '40e8db43-0997-4ed4-947a-7930fbf47539',
  },
  {
    name: 'Inclusive 1',
    uuid: 'c081b3a0-a771-4252-83ba-51f3bc43c61c',
  },
];

export const showEvents = [
  'donation.started',
  'payment_intent.created',
  'payment_intent.succeeded',
  'charge.succeeded',
  'checkout.session.completed',
  'certificate.sent.succeeded',
  'certificate.sent.failed',
  'payment_intent.payment_failed',
  'payment_intent.canceled',
  'checkout.session.async_payment_failed',
  'receipt.sent.succeeded',
  'receipt.sent.failed',
  'charge.refund.updated',
  'payment.refunded',
  'charge.dispute.created',
  'payment.refund.failed',
  'charge.refunded',
  'donation.generated',
  'donation.redeemed',
  'invoice.created',
  'invoice.payment_succeeded',
  'invoice.payment_failed',
  'customer.subscription.created',
  'customer.subscription.deleted',
  'customer.subscription.updated',
];

export const showMessageEvent = [
  'payment_intent.payment_failed',
  'checkout.session.async_payment_failed',
  'receipt.sent.failed',
  'certificate.sent.failed',
];

export const showIconEvents = [
  'payment_intent.payment_failed',
  'charge.refund.updated',
  'charge.dispute.created',
  'checkout.session.async_payment_failed',
  'payment.refunded',
  'payment_intent.canceled',
  'receipt.sent.failed',
  'certificate.sent.failed',
  'payment.refund.failed',
  'charge.refunded',
  'invoice.payment_failed',
];

export const getCO2Factor = (landInfo: any, currencyCode: string) => {
  // TODO: need load from config
  // return 60;
  return landInfo?.amount_co2_conversion?.[currencyCode] || 60;
};

export const removeEmpty = (obj: any) => {
  return Object.keys(obj).reduce((acc: any, key) => {
    const _acc = acc;
    if (obj[key] !== undefined) _acc[key] = obj[key];
    return _acc;
  }, {});
};
