import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PARTNER_TYPE_LIST } from '../../../../utils/constants';
import { WIFormDropdownV2, WIFormImageSelectorV2, WIFormInputTextV2 } from '../../../../components_v2/common/form-fields';
import { PartnerManagerAPI } from '../../../../services';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import { createPartner } from '../../../partner-details/partner-business';

const PreAddPartnerForm = (props: any) => {
  const { onHide, partner, refreshData } = props;
  const formikRef = useRef<any>(null);
  const navigate = useNavigate();
  const { t } = useTranslation('language', { keyPrefix: 'partner_details' });
  const { t: globalTrans } = useTranslation('language');
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { turnOffProgress, setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();

  const commonItemTemplate = (option: any) => {
    return <div>{globalTrans(option?.label)} </div>;
  };

  const onSubmit = async () => {
    try {
      await formikRef.current?.validateForm();
      const { values, isValid } = formikRef?.current;

      if (formikRef && isValid && values.type) {
        setLoadingProgress(errorTrans('txt_loading'));
        const partnersRes = await PartnerManagerAPI.getAllPartners(false);
        const partners = partnersRes?.data?.records || [];
        const isDuplicatedCode = partners.some((p: any) => p.uuid !== partner?.uuid && p.code?.toUpperCase() === values.code?.toUpperCase());
        if (isDuplicatedCode) {
          setErrorProgress(errorTrans(`txt_failed_partner_duplicated_code`));
          return;
        }

        let createdPartner = await createPartner(values);
        setSuccessProgress(errorTrans('txt_generate_partner_success'));

        turnOffProgress();
        navigate(`/partners/${createdPartner.uuid}`);
      }
    } catch (error: any) {
      const message = `txt_${error?.response?.data?.error_code}` || error.message || 'txt_failed_generate_partner';
      setErrorProgress(errorTrans(message));
    }
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().length(3, errorTrans('txt_code_must_be_3_characters')).required(errorTrans('txt_required')),
    name: Yup.string().required(errorTrans('txt_required')),
    partner_logo: Yup.string().required(errorTrans('txt_required')),
    type: Yup.string().nullable().required(errorTrans('txt_required')),
  });

  const initialFormValues = () => {
    if (partner) {
      return partner;
    }

    let initialData = {
      code: '',
      name: '',
      type: null,
      description: { de: ' ', en: ' ' },
      partner_logo: '',
      headline_image: '',
      mobile_headline_image: '',
      since_date: new Date(),
    };

    return initialData;
  };

  const onKeyPress = (evt: any) => {
    return ['+', '-', '.', '{', '}', '[', ']'].includes(evt.key) && evt.preventDefault();
  };

  return (
    <Formik innerRef={formikRef} initialValues={initialFormValues()} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => {}}>
      {({ values, handleSubmit, handleChange, setFieldValue, handleBlur }) => (
        <form onSubmit={handleSubmit}>
          <div className="add-partner-container">
            <div className="row-content">
              <div className="row">
                <div className="col-md-12 mb-24">
                  <WIFormInputTextV2
                    title={t('txt_partner_code')}
                    name="code"
                    isRequired={true}
                    maxLength={3}
                    onKeyDown={(e: any) => onKeyPress(e)}
                  />
                </div>
                <div className="col-md-12 mb-24">
                  <WIFormInputTextV2 title={t('txt_partner_name')} name="name" isRequired={true} maxLength={100} />
                </div>
                <div className="col-md-12 mb-24">
                  <WIFormImageSelectorV2 isRequired={true} title={t('txt_partner_logo')} name="partner_logo" />
                </div>
                <div className="col-md-12 mb-24">
                  <WIFormDropdownV2
                    name="type"
                    title={t('txt_partner_type')}
                    isRequired={true}
                    optionLabel="label"
                    optionValue="code"
                    appendTo="self"
                    valueTemplate={commonItemTemplate}
                    itemTemplate={commonItemTemplate}
                    options={PARTNER_TYPE_LIST}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                  />
                </div>
                <div className="col-md-12 mb-24">
                  <div className="element-form">
                    <label className="label mb-16">{t('txt_partner_since')}</label>
                    <div className="calendar-item">
                      <Calendar
                        id="basic"
                        name="since_date"
                        className="wi-calendar-v2"
                        value={values.since_date}
                        onBlur={handleBlur}
                        view="month"
                        dateFormat="mm.yy"
                        iconPos="right"
                        icon="fa-solid fa-calendar-days"
                        showIcon={true}
                        onChange={item => {
                          setFieldValue('since_date', item.value, false);
                        }}
                        appendTo="self"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between gap-24">
                    <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                    <Button className="wi-primary-button-v2 h48 flex-1" type="submit" label={`${t('txt_add_partner')}`} onClick={() => onSubmit()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default PreAddPartnerForm;
