import * as React from 'react';
import './create-coupon-form.scss';
import { Formik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { CurrencyList, getCO2Factor, TypeLandCode } from '../../../../utils/utils';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { CouponsManagerAPI } from '../../../../services';
import { InputText } from 'primereact/inputtext';
import { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import { parseStringToInt, showNotification } from '../../../../utils/logic';
import Leaf1 from '../../../../assets/images/leaf.svg';
import _ from 'lodash';
import * as Yup from 'yup';
import { InputTextarea } from 'primereact/inputtextarea';
import WIUploadMediaField from '../../../common/upload/wi-upload-media-field';
import { WICSVUploadField } from '../../../common';
import { useNavigate } from 'react-router-dom';
import { env } from '../../../../configs';
import { formatSourceOptionTemplate } from '../../../common/column-template-table/column-template';
import { formatImageFileURL, getThumbnailFullpath } from '../../../../utils/mediaUtils';

const onKeyPress = (evt: any) => {
  return ['e', 'E', '+', '-', '.', '{', '}', '[', ']'].includes(evt.key) && evt.preventDefault();
};

const formatSizeOptionTemplate = (name: string, available_size: any) => {
  return (
    <div>
      {name} - Available size [{parseInt(available_size)?.toLocaleString('de-DE')} m<sup>2</sup>]{' '}
    </div>
  );
};

const PartnerItem = (props: any) => {
  const { option } = props;
  return (
    <div className="global-page-item">
      <div className="global-page-picture">
        {option.partner_logo ? (
          <img
            src={`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.partner_logo))}?v=${moment(option.changed).unix()}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option.partner_logo)}`;
            }}
            alt={option.name}
          />
        ) : (
          ''
        )}
      </div>
      <div className="page-details">
        <div className="global-page-name">{option.name}</div>
        <div className="global-page-position">{option.code}</div>
      </div>
    </div>
  );
};

const BATCH_DEFAULT_VALUE = {
  name: '',
  partner_id: '',
  land_id: '',
  type: TypeLandCode.land,
  campaign_id: '',
  is_prepaid: true,
  receipt_type: 'none',
  area: 1,
  currency_unit: 'eur',
  quantity: 1,
  expire_date: moment().add(1, 'year').toDate(),
  certificate_occasion: {
    de: '',
    en: '',
  },
  certificate_description: {
    de: '',
    en: '',
  },
  description_2fa: {
    de: '',
    en: '',
  },
  certificate_logo: '',
  references: null,
};

const options = ['Yes', 'No'];
const RECEIPT_TYPES = [
  { name: 'Partner', code: 'partner' },
  { name: 'Redeemer', code: 'redeemer' },
  { name: 'None', code: 'none' },
];

const partner_none: any = [
  {
    name: 'NONE',
    uuid: '',
  },
];

const CODE_TYPES = [
  {
    name: 'Area',
    code: TypeLandCode.land,
  },
  {
    name: 'Campaign',
    code: TypeLandCode.campaign,
  },
];

const REGEX_GERMAN_NAME = /^[a-zA-Z0-9_ -äöüÄÖÜß]+$/;

const CreateCouponFormComponent = (props: any) => {
  const { data } = props;
  const formikRef = useRef<any>(null);
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [money, setMoney] = useState<any>(1);
  const [amountKgCO2, setAmountKgCO2] = useState<any>(60);
  const [isCampaignSelected, setIsCampaignSelected] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, 'This field is required.')
      .max(30, 'This field must be with a maximum length of 30.')
      .matches(REGEX_GERMAN_NAME, 'Insert only normal character')
      .required('This field is required.'),
    land_id: Yup.string().required('This field is required.'),
    is_prepaid: Yup.boolean(),
    receipt_type: Yup.mixed().oneOf(['partner', 'redeemer', 'none']),
    area: Yup.number().nullable().min(1, 'This field must be greater than 1.').required('This field is required.'),
    quantity: Yup.number()
      .nullable()
      .min(1, 'This field must be greater than 1.')
      .max(100000, 'This field must be less than 100.000.')
      .required('This field is required.'),
    expire_date: Yup.string().required('This field is required.'),
    certificate_occasion: Yup.object().shape({
      de: Yup.string().max(80, 'This field must be with a maximum length of 80.'),
      en: Yup.string().max(80, 'This field must be with a maximum length of 80.'),
    }),
    certificate_description: Yup.object().shape({
      de: Yup.string(),
      en: Yup.string(),
    }),
    description_2fa: Yup.object().shape({
      de: Yup.string(),
      en: Yup.string(),
    }),
    certificate_logo: Yup.mixed(),
  });

  const getTotalSize = (values: any) => (+(+values.area * +values.quantity).toFixed(2)).toLocaleString('de');

  const handleFilterLandActive = () => {
    if (data.lands && data.lands?.length > 0) {
      return _.sortBy(data.lands, item => item.name?.de?.toLowerCase());
    }
    return [];
  };

  const getPartnerList = (partners: any[]) => {
    return partners ? partner_none.concat(_.sortBy(partners, item => item.name?.toLowerCase())) : [];
  };

  const selectedOptionTemplate = (option: any, values: any, available_size: any, props: any) => {
    return option ? formatSizeOptionTemplate(option.name.de ?? option.name, available_size) : <span>{props.placeholder}</span>;
  };

  const handleUpdateTotalSize = (values: any, item: any, propertyName: any) => {
    let donationMoney = 0;
    let co2 = 0;
    let totalSize = 0;
    const land = data.lands.find((c: any) => c.uuid === values.land_id);
    const unit = (propertyName === 'currency_unit' ? item : values.currency_unit) || 'eur';
    let area = unit === 'cad' ? 0.666667 : 1;
    let co2Factor = getCO2Factor(land, unit);
    if (land && land?.amount_area_conversion) {
      area = land?.amount_area_conversion[unit];
    }

    if (propertyName === 'area') {
      donationMoney = item / area;
      co2 = item * co2Factor;
      totalSize = item * values.quantity;
    } else if (propertyName === 'quantity') {
      donationMoney = values.area / area;
      co2 = values.area * co2Factor;
      totalSize = item * values.area;
    } else {
      donationMoney = values.area / area;
      co2 = values.area * co2Factor;
    }

    if (land && totalSize > land?.available_size) {
      values.land_id = ''; // reset
    }

    setMoney(+donationMoney.toFixed(1));
    setAmountKgCO2(Math.floor(co2));
  };

  const generateCoupons = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    // @ts-ignore: Object is possibly 'null'.
    if (formikRef && formikRef?.current?.dirty && formikRef?.current?.isValid) {
      try {
        setIsLoading(true);
        const land = data.lands.find((c: any) => c.uuid === formikRef?.current?.values?.land_id);
        let co2Factor = getCO2Factor(land, formikRef?.current?.values?.currency_unit);
        const resGenerateCoupons = await CouponsManagerAPI.generateCoupon({
          // @ts-ignore: Object is possibly 'null'.
          ...formikRef?.current.values,
          donation_money: money,
          amount_kg_co2: Math.floor(formikRef?.current?.values?.area * co2Factor),
          // @ts-ignore: Object is possibly 'null'.
          expire_date: moment(formikRef?.current.values.expire_date).utc().endOf('day').add(1, 'hour').toISOString(),
        });

        if (resGenerateCoupons && resGenerateCoupons.status === 200) {
          setIsLoading(false);
          if (resGenerateCoupons.data?.success) {
            showNotification('success', 'Generate codes successfully', toast);
            navigateToDetail(resGenerateCoupons.data.record?.id);
          } else {
            setIsLoading(false);
            showNotification('error', 'Failed to generate codes', toast);
          }
        } else {
          setIsLoading(false);
          showNotification('error', 'Failed to generate codes', toast);
        }
      } catch (error) {
        setIsLoading(false);
        showNotification('error', 'Failed to generate codes', toast);
      }
    }
  };

  const navigateToDetail = (uuid: any) => {
    navigate(`/codes/${uuid}`);
  };

  const onLandChange = (value: string, touched: any, setFieldValue: any) => {
    setFieldValue('land_id', value);
    setFieldValue('campaign_id', null);
    const selectedLand = data.lands.find((l: any) => l.uuid === value);
    const region = data.regions?.find((f: any) => f.uuid === selectedLand?.region_id);

    if (region && !(touched.certificate_description?.de || touched.certificate_description?.en)) {
      setFieldValue('certificate_description.de', region.certificate_description.de);
      setFieldValue('certificate_description.en', region.certificate_description.en);
    }
  };

  const onCampaignChange = (value: string, touched: any, setFieldValue: any) => {
    const campaign = data.campaigns.find((l: any) => l.uuid === value);
    if (!campaign) {
      resetDependencyValueOfCampaign(setFieldValue);
      return;
    }

    setFieldValue('land_id', campaign.land_id);
    onLandChange(campaign.land_id, touched, setFieldValue);

    setFieldValue('campaign_id', value);
    setFieldValue('partner_id', campaign.partner_id);
    setFieldValue('certificate_occasion.de', campaign.certificate_reason_description?.de);
    setFieldValue('certificate_occasion.en', campaign.certificate_reason_description?.en);
    setFieldValue('certificate_logo', campaign.campaign_logo);
  };

  const resetDependencyValueOfCampaign = (setFieldValue: any) => {
    setFieldValue('land_id', null);
    setFieldValue('campaign_id', null);
    setFieldValue('certificate_logo', null);
    setFieldValue('certificate_occasion', {
      de: '',
      en: '',
    });
  };

  const resetDependencyValueOfLand = (setFieldValue: any) => {
    setFieldValue('certificate_description', {
      de: '',
      en: '',
    });
  };

  return (
    <div className="coupon-form">
      <div className="coupon-form-container">
        <Formik
          innerRef={formikRef}
          // enableReinitialize={true}
          initialValues={{ ...BATCH_DEFAULT_VALUE }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, isValid, dirty }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="sticky-header-form">
                  <div className="col-12 d-flex align-items-center justify-content-between btn-submit-container">
                    <div className="title-content">
                      <label className="title-header">New</label>
                    </div>
                    <Button
                      loading={isLoading}
                      id="btn-submit-coupons"
                      // disabled={!(isValid)}
                      type="submit"
                      label={'Create'}
                      className="p-button-rounded btn-submit"
                      icon="pi pi-chevron-right"
                      iconPos="right"
                      onClick={generateCoupons}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-content">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 col-sm-3">
                      <div className="element-form">
                        <label className="label">
                          Name <span className="required-label">*</span>
                        </label>
                        <InputText
                          id="name"
                          name="name"
                          value={values.name}
                          onBlur={handleBlur}
                          maxLength={30}
                          keyfilter={REGEX_GERMAN_NAME}
                          onChange={(item: any) => {
                            setFieldValue('name', item.target.value);
                          }}
                        />
                        <div className="helper-text">
                          <i className="fa-solid fa-circle-info"></i> Maximum 30 characters, no special charecters
                        </div>
                        <div className={`${touched.name && errors.name ? 'error' : ''}`}>{touched.name && errors.name}</div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="element-form">
                        <label className="label">
                          Type
                          <span className="required-label"> *</span>
                        </label>
                        <SelectButton
                          id="type"
                          name="type"
                          value={values.type}
                          className="wi-selectbutton"
                          options={CODE_TYPES}
                          onChange={(item: any) => {
                            if (item.value) {
                              setFieldValue('type', item.value, true);
                              setIsCampaignSelected(item.value === TypeLandCode.campaign);
                              resetDependencyValueOfCampaign(setFieldValue);
                              resetDependencyValueOfLand(setFieldValue);
                            }
                          }}
                          optionLabel="name"
                          optionValue="code"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      {values.type === TypeLandCode.land ? (
                        <div className="element-form">
                          <label className="label">
                            Area <span className="required-label">*</span>
                          </label>
                          <Dropdown
                            className="p-dropdown-custom-search"
                            id="land_id"
                            name="land_id"
                            value={values.land_id}
                            scrollHeight={'500px'}
                            options={handleFilterLandActive() || []}
                            optionDisabled={option => option?.available_size < +values.area * +values.quantity}
                            itemTemplate={option => formatSizeOptionTemplate(option.name.de ?? option.name, option?.available_size)}
                            valueTemplate={(option, props) => selectedOptionTemplate(option, values, option?.available_size, props)}
                            onBlur={handleBlur}
                            onChange={(item: any) => {
                              onLandChange(item.value, touched, setFieldValue);
                            }}
                            optionLabel="name.de"
                            optionValue="uuid"
                            appendTo="self"
                            placeholder="Choose an area"
                            filter
                            filterBy="name.de,name"
                            filterPlaceholder={'Search'}
                          />
                          <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Shows only <b className="helper-bold">active</b> areas whose type is{' '}
                            <b className="helper-bold">Public</b>
                          </div>
                          <div className={`${touched.land_id && errors.land_id ? 'error' : ''}`}>{touched.land_id && errors.land_id}</div>
                        </div>
                      ) : (
                        <div className="element-form">
                          <label className="label">
                            Campaign <span className="required-label">*</span>
                          </label>
                          <Dropdown
                            className="p-dropdown-custom-search"
                            id="campaign_id"
                            name="campaign_id"
                            value={values.campaign_id}
                            scrollHeight={'500px'}
                            options={data.campaigns}
                            optionDisabled={option => option?.land?.available_size < +values.area * +values.quantity}
                            itemTemplate={option => formatSourceOptionTemplate(option, option?.land?.available_size, TypeLandCode.campaign)}
                            valueTemplate={(option, props) =>
                              option ? (
                                formatSourceOptionTemplate(option, option?.land?.available_size, TypeLandCode.campaign)
                              ) : (
                                <span>{props.placeholder}</span>
                              )
                            }
                            onBlur={handleBlur}
                            onChange={(item: any) => {
                              onCampaignChange(item.value, touched, setFieldValue);
                            }}
                            optionLabel="name.de"
                            optionValue="uuid"
                            appendTo="self"
                            placeholder="Choose a campaign"
                            filter
                            filterBy="name.de,name"
                            filterPlaceholder={'Search'}
                          />
                          <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Shows only <b className="helper-bold">active</b> campaigns whose type is{' '}
                            <b className="helper-bold">Public</b>
                          </div>
                          <div className={`${touched.campaign_id && errors.campaign_id ? 'error' : ''}`}>
                            {touched.campaign_id && errors.campaign_id}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="element-form">
                        <label className="label">
                          Partner <span className="required-label">*</span>
                        </label>
                        <Dropdown
                          className="p-dropdown-custom-search"
                          id="partner_id"
                          name="partner_id"
                          value={values.partner_id}
                          options={getPartnerList(data.partners)}
                          scrollHeight={'500px'}
                          filter
                          filterBy={['name', 'code'].join(',')}
                          onChange={(item: any) => {
                            setFieldValue('partner_id', item.value);
                            setFieldValue('campaign_id', null);
                            if (!touched.certificate_logo) {
                              const partner = data.partners.find((p: any) => p.uuid === item.value);
                              if (partner) {
                                setFieldValue('certificate_logo', partner.partner_logo);

                                if (values.receipt_type === 'none') {
                                  setFieldValue('receipt_type', 'partner');
                                }
                              } else {
                                setFieldValue('certificate_logo', null);

                                if (values.receipt_type === 'partner') {
                                  setFieldValue('receipt_type', 'none');
                                }
                              }
                            }
                          }}
                          itemTemplate={option => <PartnerItem option={option}></PartnerItem>}
                          optionLabel="name"
                          optionValue="uuid"
                          appendTo="self"
                          placeholder="Choose a partner"
                          disabled={isCampaignSelected}
                          filterPlaceholder={'Search'}
                        />
                        <div className="helper-text">
                          <i className="fa-solid fa-circle-info"></i> Shows only <b className="helper-bold">active</b> partners
                        </div>
                        <div className={`${touched.partner_id && errors.partner_id ? 'error' : ''}`}>{touched.partner_id && errors.partner_id}</div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              Currency <span className="required-label">*</span>
                            </label>
                            <SelectButton
                              id="currency_unit"
                              name="currency_unit"
                              value={values.currency_unit}
                              className="wi-selectbutton"
                              options={CurrencyList}
                              onChange={(item: any) => {
                                if (item.value) {
                                  setFieldValue('currency_unit', item.value, false);
                                  handleUpdateTotalSize(values, item.value, 'currency_unit');
                                }
                              }}
                              optionLabel="name"
                              optionValue="code"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              Number of codes <span className="required-label">*</span>
                            </label>
                            <InputNumber
                              className={`${touched.quantity && errors.quantity ? 'has-error' : ''} text-input-inline text-currency-target`}
                              value={values.quantity}
                              name="quantity"
                              locale="de-DE"
                              onBlur={e => {
                                const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                                setFieldValue('quantity', value, false);
                                handleUpdateTotalSize(values, value, 'quantity');
                                setTimeout(() => {
                                  handleBlur(e);
                                }, 200);
                              }}
                              onKeyDown={e => onKeyPress(e)}
                            />
                            <div className={`${touched.quantity && errors.quantity ? 'error' : ''}`}>{touched.quantity && errors.quantity}</div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              Code value (<span style={{ textTransform: 'lowercase' }}>m</span>
                              <sup>2</sup>)<span className="required-label">*</span>
                            </label>
                            <InputNumber
                              className={`${touched.area && errors.area ? 'has-error' : ''} text-input-inline text-currency-target`}
                              value={values.area}
                              name="area"
                              locale="de-DE"
                              onBlur={e => {
                                const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                                setFieldValue('area', value, false);
                                handleUpdateTotalSize(values, value, 'area');
                                setTimeout(() => {
                                  handleBlur(e);
                                }, 200);
                              }}
                              onKeyDown={e => onKeyPress(e)}
                            />
                            <div className={`${touched.area && errors.area ? 'error' : ''}`}>{touched.area && errors.area}</div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              Pre-payment <span className="required-label">*</span>
                            </label>
                            <SelectButton
                              className="donation-checkbox wi-selectbutton"
                              id="is_prepaid"
                              name="is_prepaid"
                              options={options}
                              onChange={(item: any) => {
                                if (item.value) {
                                  setFieldValue('is_prepaid', item.value === 'Yes', false);
                                }
                              }}
                              value={values.is_prepaid ? 'Yes' : 'No'}
                            />
                            <div className="helper-text">
                              <i className="fa-solid fa-circle-info"></i> <b className="helper-bold">Yes</b> if partner has already paid
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">Reference</label>
                            <div className="col-12 d-flex align-items-center justify-content-start upload-csv-file">
                              <WICSVUploadField
                                name="references"
                                className="input-upload"
                                labelClassName="label-upload-csv"
                                nameFileClassName="name-file"
                              />
                            </div>
                            <div className="helper-text">
                              <i className="fa-solid fa-circle-info"></i> Column order: Reference Code, 2FA Text
                            </div>
                            <div className={`${touched.references && errors.references ? 'error' : ''}`}>
                              {touched.references && errors.references}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="element-form">
                            <label className="label">
                              Receipt Owner <span className="required-label">*</span>
                            </label>
                            <SelectButton
                              className="donation-checkbox wi-selectbutton"
                              id="receipt_type"
                              name="receipt_type"
                              optionDisabled={option => option.code === 'partner' && !values.partner_id}
                              options={RECEIPT_TYPES}
                              optionLabel="name"
                              optionValue="code"
                              onChange={(item: any) => {
                                if (item.value) {
                                  setFieldValue('receipt_type', item.value, false);
                                }
                              }}
                              value={values.receipt_type}
                            />
                            <div className="helper-text">
                              <i className="fa-solid fa-circle-info"></i>
                              {values.receipt_type === 'none' && (
                                <span>
                                  {' '}
                                  <b className="helper-bold" style={{ textTransform: 'capitalize' }}>
                                    {values.receipt_type}
                                  </b>{' '}
                                  means no receipt at all
                                </span>
                              )}
                              {values.receipt_type === 'partner' && (
                                <span>
                                  {' '}
                                  <b className="helper-bold" style={{ textTransform: 'capitalize' }}>
                                    {values.receipt_type}
                                  </b>{' '}
                                  means only a receipt for a partner
                                </span>
                              )}
                              {values.receipt_type === 'redeemer' && (
                                <span>
                                  {' '}
                                  <b className="helper-bold" style={{ textTransform: 'capitalize' }}>
                                    {values.receipt_type}
                                  </b>{' '}
                                  means a receipt for each codes
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="element-form">
                        <label className="label">Certificate logo</label>
                        <WIUploadMediaField
                          type="file"
                          classImage="image-cert"
                          className="certificate_logo"
                          name="certificate_logo"
                          label="Certificate logo"
                          required={true}
                          hideLabel={true}
                          classField="coupon-logo logo-form"
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-3">
                                        <div className="element-form">
                                            <label className="label">Expired date <span className="required-label">*</span></label>
                                            <Calendar
                                                id="basic"
                                                name="expire_date"
                                                minDate={new Date()}
                                                panelClassName="expire-calendar"
                                                value={values.expire_date}
                                                onBlur={handleBlur}
                                                onChange={(item) => {
                                                    setFieldValue('expire_date', item.value, false);
                                                }}
                                                dateFormat="mm-dd-yy"
                                            />
                                            <div className={`${touched.expire_date && errors.expire_date ? 'error' : ''}`}>
                                                {touched.expire_date && errors.expire_date ? 'This field is invalid' : ''}
                                            </div>
                                        </div>
                                    </div> */}

                    <div className="col-md-12">
                      <div className="total-area-banner">
                        <img src={Leaf1} alt="leaf" />
                        <div className="total-area-title">
                          {values.land_id && (
                            <div>
                              Protected {getTotalSize(values)} m<sup>2</sup> of forest
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {values.references && (
                      <>
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">2FA Description DE</label>
                            <InputText
                              value={values.description_2fa.de}
                              name="description_2fa.de"
                              onChange={item => {
                                setFieldValue('description_2fa.de', item.target.value);
                              }}
                              onBlur={handleBlur}
                              maxLength={40}
                              // onKeyDown={(e) => onKeyPress(e)} todo
                            />
                            <div className={`${touched.description_2fa?.de && errors.description_2fa?.de ? 'error' : ''}`}>
                              {touched.description_2fa?.de && errors.description_2fa?.de}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">2FA Description EN</label>
                            <InputText
                              value={values.description_2fa.en}
                              name="description_2fa.en"
                              onChange={item => {
                                setFieldValue('description_2fa.en', item.target.value);
                              }}
                              onBlur={handleBlur}
                              maxLength={40}
                              // onKeyDown={(e) => onKeyPress(e)} todo
                            />
                            <div className={`${touched.description_2fa?.en && errors.description_2fa?.en ? 'error' : ''}`}>
                              {touched.description_2fa?.en && errors.description_2fa?.en}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Certificate Occasion DE</label>
                        <InputText
                          value={values.certificate_occasion.de}
                          name="certificate_occasion.de"
                          onChange={item => {
                            setFieldValue('certificate_occasion.de', item.target.value);
                          }}
                          onBlur={handleBlur}
                          maxLength={80}
                          // onKeyDown={(e) => onKeyPress(e)} todo
                        />
                        <div className={`${touched.certificate_occasion?.de && errors.certificate_occasion?.de ? 'error' : ''}`}>
                          {touched.certificate_occasion?.de && errors.certificate_occasion?.de}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Certificate Occasion EN</label>
                        <InputText
                          id="certificate_occasion.en"
                          value={values.certificate_occasion.en}
                          name="certificate_occasion.en"
                          onChange={item => {
                            setFieldValue('certificate_occasion.en', item.target.value);
                          }}
                          onBlur={handleBlur}
                          maxLength={80}
                          // onKeyDown={(e) => onKeyPress(e)} todo
                        />
                        <div className={`${touched.certificate_occasion?.en && errors.certificate_occasion?.en ? 'error' : ''}`}>
                          {touched.certificate_occasion?.en && errors.certificate_occasion?.en}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Certificate Description DE</label>
                        <InputTextarea
                          value={values.certificate_description.de}
                          name="certificate_description.de"
                          onChange={item => {
                            setFieldValue('certificate_description.de', item.target.value);
                          }}
                          onBlur={handleBlur}
                          // onKeyDown={(e) => onKeyPress(e)} todo
                        />
                        <div className={`${touched.certificate_description?.de && errors.certificate_description?.de ? 'error' : ''}`}>
                          {touched.certificate_description?.de && errors.certificate_description?.de}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Certificate Description EN</label>
                        <InputTextarea
                          value={values.certificate_description.en}
                          name="certificate_description.en"
                          onChange={item => {
                            setFieldValue('certificate_description.en', item.target.value);
                          }}
                          onBlur={handleBlur}
                          // onKeyDown={(e) => onKeyPress(e)} todo
                        />
                        <div className={`${touched.certificate_description?.en && errors.certificate_description?.en ? 'error' : ''}`}>
                          {touched.certificate_description?.en && errors.certificate_description?.en}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-content"></div>
            </form>
          )}
        </Formik>
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default CreateCouponFormComponent;
