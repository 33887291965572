import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import React from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './edit-batch-dialog.scss';
import { Formik } from 'formik';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { showNotification } from '../../../../utils/logic';
import CouponDetailAPI from '../../../../services/couponDetail';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';

const EditBatchDialogComponent = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    visible,
    onHide,
    certificate_occasion,
    certificate_description,
    description_2fa,
    receipt_type,
    name,
    id,
    fetchCouponDataHeader,
    isShow2FA,
  } = props;

  const toast = useRef(null);
  const formikRef = useRef(null);

  const BATCH_DEFAULT_VALUE = {
    name: '',
    description_2fa: { de: '', en: '' },
    certificate_description: { de: '', en: '' },
    certificate_occasion: { de: '', en: '' },
    receipt_type: '',
  };

  const initialReceiptFormValues = () => {
    let initialReceipt = BATCH_DEFAULT_VALUE;
    initialReceipt.name = name;
    initialReceipt.description_2fa = description_2fa;
    initialReceipt.certificate_description = certificate_description;
    initialReceipt.certificate_occasion = certificate_occasion;
    initialReceipt.receipt_type = receipt_type;
    return initialReceipt;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required.'),
    receipt_type: Yup.string().required('This field is required.'),
  });

  const onUpdateBatch = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    try {
      setIsLoading(true);
      // @ts-ignore: Object is possibly 'null'.
      const data = formikRef?.current.values;

      const response = await CouponDetailAPI.updateBatch(id, data);

      if (response && response.status === 200) {
        setIsLoading(false);
        showNotification('success', 'Update Batch successfully', toast);
        onHide();
        fetchCouponDataHeader();
      } else {
        setIsLoading(false);
        showNotification('error', 'Failed to update Batch', toast);
      }
    } catch (error) {
      setIsLoading(false);
      showNotification('error', 'Failed to update donation', toast);
    }
  };

  const getTypes = () => {
    let types = [];
    if (receipt_type === 'partner') {
      types.push({ name: 'Partner', code: 'partner' });
    }
    types.push({ name: 'Redeemer', code: 'redeemer' });
    types.push({ name: 'None', code: 'none' });

    return types;
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={`Update Set`} visible={visible} style={{ width: '1200px' }} className="wi-dialog" modal onHide={onHide}>
        <div className="codes-receipt-form">
          <div className="receipt-form-container">
            <Formik
              enableReinitialize={true}
              innerRef={formikRef}
              initialValues={initialReceiptFormValues()}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {}}
            >
              {({ values, errors, touched, dirty, isValid, handleBlur, handleSubmit, setFieldValue, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Name <span className="asterik">*</span>
                          </label>
                          <InputText value={values.name} name="name" onBlur={handleBlur} onChange={handleChange} />
                          <div className={`${touched.name && errors.name ? 'error' : ''}`}>{touched.name && errors.name}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Receipt Owner <span className="asterik">*</span>
                          </label>
                          <SelectButton
                            className="donation-checkbox wi-selectbutton"
                            id="receipt_type"
                            name="receipt_type"
                            options={getTypes()}
                            optionLabel="name"
                            optionValue="code"
                            onChange={(item: any) => {
                              if (item.value) {
                                setFieldValue('receipt_type', item.value, false);
                              }
                            }}
                            value={values.receipt_type}
                          />
                          <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i>
                            {values.receipt_type === 'none' && (
                              <span>
                                {' '}
                                <b className="helper-bold" style={{ textTransform: 'capitalize' }}>
                                  {values.receipt_type}
                                </b>{' '}
                                means no receipt at all
                              </span>
                            )}
                            {values.receipt_type === 'partner' && (
                              <span>
                                {' '}
                                <b className="helper-bold" style={{ textTransform: 'capitalize' }}>
                                  {values.receipt_type}
                                </b>{' '}
                                means only a receipt for a partner
                              </span>
                            )}
                            {values.receipt_type === 'redeemer' && (
                              <span>
                                {' '}
                                <b className="helper-bold" style={{ textTransform: 'capitalize' }}>
                                  {values.receipt_type}
                                </b>{' '}
                                means a receipt for each codes
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">Certificate Occasion DE</label>
                          <InputText
                            value={values.certificate_occasion.de}
                            name="certificate_occasion.de"
                            onChange={item => {
                              setFieldValue('certificate_occasion.de', item.target.value);
                            }}
                            onBlur={handleBlur}
                            maxLength={80}
                            // onKeyDown={(e) => onKeyPress(e)} todo
                          />
                          <div className={`${touched.certificate_occasion?.de && errors.certificate_occasion?.de ? 'error' : ''}`}>
                            {touched.certificate_occasion?.de && errors.certificate_occasion?.de}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">Certificate Occasion EN</label>
                          <InputText
                            id="certificate_occasion.en"
                            style={{ marginBottom: '10px' }}
                            value={values.certificate_occasion.en}
                            name="certificate_occasion.en"
                            onChange={item => {
                              setFieldValue('certificate_occasion.en', item.target.value);
                            }}
                            onBlur={handleBlur}
                            maxLength={80}
                            // onKeyDown={(e) => onKeyPress(e)} todo
                          />
                          <div className={`${touched.certificate_occasion?.en && errors.certificate_occasion?.en ? 'error' : ''}`}>
                            {touched.certificate_occasion?.en && errors.certificate_occasion?.en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">Certificate Description DE</label>
                          <InputTextarea
                            style={{ height: '120px' }}
                            value={values.certificate_description.de}
                            name="certificate_description.de"
                            onChange={item => {
                              setFieldValue('certificate_description.de', item.target.value);
                            }}
                            onBlur={handleBlur}
                            // onKeyDown={(e) => onKeyPress(e)} todo
                          />
                          <div className={`${touched.certificate_description?.de && errors.certificate_description?.de ? 'error' : ''}`}>
                            {touched.certificate_description?.de && errors.certificate_description?.de}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">Certificate Description EN</label>
                          <InputTextarea
                            style={{ height: '120px' }}
                            value={values.certificate_description.en}
                            name="certificate_description.en"
                            onChange={item => {
                              setFieldValue('certificate_description.en', item.target.value);
                            }}
                            onBlur={handleBlur}
                            // onKeyDown={(e) => onKeyPress(e)} todo
                          />
                          <div className={`${touched.certificate_description?.en && errors.certificate_description?.en ? 'error' : ''}`}>
                            {touched.certificate_description?.en && errors.certificate_description?.en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ display: isShow2FA ? '' : 'none' }}>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">2FA Description DE</label>
                          <InputText
                            value={values.description_2fa.de}
                            name="description_2fa.de"
                            onChange={item => {
                              setFieldValue('description_2fa.de', item.target.value);
                            }}
                            onBlur={handleBlur}
                            // onKeyDown={(e) => onKeyPress(e)} todo
                          />
                          <div className={`${touched.description_2fa?.de && errors.description_2fa?.de ? 'error' : ''}`}>
                            {touched.description_2fa?.de && errors.description_2fa?.de}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">2FA Description EN</label>
                          <InputText
                            value={values.description_2fa.en}
                            name="description_2fa.en"
                            onChange={item => {
                              setFieldValue('description_2fa.en', item.target.value);
                            }}
                            onBlur={handleBlur}
                            // onKeyDown={(e) => onKeyPress(e)} todo
                          />
                          <div className={`${touched.description_2fa?.en && errors.description_2fa?.en ? 'error' : ''}`}>
                            {touched.description_2fa?.en && errors.description_2fa?.en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-4 mb-2">
                      <Button
                        type="submit"
                        label={'Update'}
                        icon="pi pi-angle-right"
                        disabled={!(isValid && dirty)}
                        iconPos="right"
                        loading={isLoading}
                        className="wi-primary-button wi-button-medium"
                        onClick={onUpdateBatch}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default EditBatchDialogComponent;
