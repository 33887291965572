import * as React from 'react';
import './wi-warning.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningSvg } from '../../../assets/images/icons/warning.svg';
import { Button } from 'primereact/button';

const WiWarning = (props: any) => {
  const { popupSaveChange, setPopupSaveChange } = props;
  const { t: globalTrans } = useTranslation('language', { keyPrefix: 'block_dialog' });

  return (
    <>
      <div className={`wi-warning-backdrop ${!popupSaveChange?.visible && 'wi-warning-backdrop-none'}`}>
        <div className="wi-warning-backdrop-container">
          <div className={`wi-warning-content ${popupSaveChange?.visible ? 'wi-warning-show' : 'wi-warning-none'}`}>
            <div className="wi-warning-container pt-24 pr-24 pb-24 pl-24">
              <div className="wi-warning-icon mb-16">
                <WarningSvg />
              </div>
              <div className="wi-warning-text">
                <p className="title m-0 pb-16">{globalTrans('txt_close_withou_saving')}</p>
                <p className="description m-0 pb-16">{globalTrans('txt_unsaved_changes')}</p>
              </div>
              <div className="wi-warning-buttons">
                <Button
                  className="wi-secondary-button-v2 h40 flex-1"
                  type="submit"
                  label={globalTrans('txt_cancel')}
                  onClick={() => {
                    setPopupSaveChange({ ...popupSaveChange, visible: false });
                  }}
                ></Button>
                <Button
                  className={`wi-primary-button-v2 h40 flex-1`}
                  type="submit"
                  label={globalTrans('txt_save_and_close')}
                  onClick={() => {
                    setPopupSaveChange({ ...popupSaveChange, visible: false });
                    popupSaveChange.onSaveChange();
                  }}
                ></Button>
                <Button
                  className="wi-primary-danger-button-v2 h40 flex-1"
                  type="submit"
                  label={globalTrans('txt_confirm')}
                  onClick={() => {
                    setPopupSaveChange({ ...popupSaveChange, visible: false });
                    popupSaveChange.onCancel();
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WiWarning;
