import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import Layout from '../../layout/layout';
import { FAQsManagerAPI } from '../../../services';
import './headers-manager.scss';
import HeaderEditor from './components/header-editor/header-editor';
import _ from 'lodash';
import { ProgressSpinner } from 'primereact/progressspinner';

export const HeadersManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [header, setHeader] = useState<any>();
  const [customHeader, setCustomHeader] = useState<any>();
  const toast = useRef(null);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const result = await FAQsManagerAPI.getHeaders();
      // @ts-ignore: Object is possibly 'null'.
      if (result.status === 200 && result.data.records) {
        //Get Standard Header
        const primaryHeader = result.data.records.filter((item: any) => item.is_primary);
        primaryHeader.forEach((h: any) => {
          h.parameters = JSON.parse(h.parameters);
        });
        setHeader(primaryHeader[0]);

        //Get Custom Header
        const customHeaders = result.data.records.filter((item: any) => !item.is_primary);
        customHeaders.forEach((h: any) => {
          h.parameters = JSON.parse(h.parameters);
        });
        setCustomHeader(customHeaders[0]);
      }
    } catch (error) {}

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateHeaders = async (menuItems: any, buttonData: any) => {
    try {
      setIsLoading(true);
      let parameters = header.parameters;
      parameters = { ...parameters, ..._.pick(buttonData, ['urlName', 'buttonUrl', 'blank_mode']), menuItems };
      await FAQsManagerAPI.updateHeaders({ ...header, parameters: JSON.stringify(parameters) });

      //Update Parameters Of Custom Header
      let customParameters = customHeader.parameters;
      customParameters = { ...customParameters, menuItems };
      await FAQsManagerAPI.updateHeaders({ ...customHeader, parameters: JSON.stringify(customParameters) });
    } catch (error) {}

    await fetchData();
  };

  return (
    <>
      <Layout>
        <Toast ref={toast} />
        {isLoading && (
          <div className="loading-component">
            <ProgressSpinner />
          </div>
        )}
        {header ? <HeaderEditor header={header} onUpdateHeaders={onUpdateHeaders}></HeaderEditor> : <></>}
      </Layout>
    </>
  );
};

export default HeadersManager;
