import * as React from 'react';
import './withdraw-consent.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningSvg } from '../../../../assets/images/icons/warning.svg';
import { Button } from 'primereact/button';

const WithdrawConsent = (props: any) => {
  const { visible, onHide, onConfirm } = props;
  const { t: globalTrans } = useTranslation('language', { keyPrefix: 'partner_details' });

  return (
    <>
      {visible && (
        <div className={`withdraw-consent-backdrop ${!visible && 'withdraw-consent-backdrop-none'}`}>
          <div className="withdraw-consent-backdrop-container">
            <div className={`withdraw-consent-content ${visible ? 'withdraw-consent-show' : 'withdraw-consent-none'}`}>
              <div className="withdraw-consent-container pt-24 pr-24 pb-24 pl-24">
                <div className="withdraw-consent-icon mb-16">
                  <WarningSvg />
                </div>
                <div className="withdraw-consent-text">
                  <p className="title m-0 pb-16">{globalTrans('txt_withdraw_consent')}</p>
                  <p className="description m-0 pb-16">{globalTrans('txt_consent_content')}</p>
                </div>
                <div className="withdraw-consent-buttons">
                  <Button
                    className="wi-secondary-button-v2 h40 flex-1"
                    type="submit"
                    label={globalTrans('txt_cancel')}
                    onClick={() => onHide()}
                  ></Button>
                  <Button
                    className="wi-primary-danger-button-v2 h40 flex-1"
                    type="submit"
                    label={globalTrans('txt_confirm')}
                    onClick={() => onConfirm()}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WithdrawConsent;
