import { useEffect, useState } from 'react';
import React from 'react';
import { Button } from 'primereact/button';
import FilterField from '../../../../components_v2/common/filter-field/filter-field';
import { STATUS_LIST } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import PartnerItem from '../../../../components_v2/common/partner-item/partner-item';

const FilterUserForm = (props: any) => {
  const { fields, onChange, setSearch, onRemove, partners, roles, filterValues, onSubmit } = props;
  const { t } = useTranslation('language', { keyPrefix: 'system_users' });
  const [isDirty, setDirty] = useState<boolean>(false);
  const [values, setValues] = useState<Map<string, string[]>>();

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
  };

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch('');
    await onRemove();
  };

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      filter.set(k, filterValues.where[k]);
    });
    setValues(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  return (
    <>
      <div className="filter-wrapper-v2">
        <div className="filter-form-container">
          <div className="filter-elements mb-16">
            <FilterField
              name="roles"
              label={t('txt_role')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get('roles') || []}
              options={roles}
              optionLabel={'label'}
              optionValue={'uuid'}
              visible={values?.has('roles') || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
            />
          </div>
          <div className="filter-elements mb-16">
            <FilterField
              name="status"
              label={t('txt_status')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get('status') || []}
              options={STATUS_LIST}
              optionLabel={'label'}
              optionValue={'code'}
              visible={values?.has('status') || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
            />
          </div>
          <div className="filter-elements mb-16">
            <FilterField
              name="partnerIds"
              label={t('txt_partner')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get('partnerIds') || []}
              options={partners || []}
              optionLabel={'name'}
              optionValue={'uuid'}
              visible={values?.has('partnerIds') || false}
              isDirty={isDirty}
              itemTemplateCustom={(option: any) => <PartnerItem option={option}></PartnerItem>}
              setDirty={setDirty}
              onChange={onFilterChange}
              filter
              filterBy="name"
              filterPlaceholder={`${t('txt_search')}`}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-start gap-24 mt-8">
                <Button
                  className="wi-danger-button-v2 h48 flex-1"
                  type="submit"
                  label={`${t('txt_clear_filter')}`}
                  onClick={() => onRemoveFilters()}
                />
                <Button
                  className="wi-primary-button-v2 h48 flex-1"
                  type="submit"
                  label={`${t('txt_apply')}`}
                  onClick={() => onSubmit(values, fields)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterUserForm;
