import React from 'react';
import _ from 'lodash';
import InfoCircle from '../../../assets/images/icons/info-circle.svg';
import { useFormikContext } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { parseStringToInt } from '../../../utils/logic';
import { useLayoutV2 } from '../../../context/LayoutProvider';

const WIFormInputNumberV2 = (props: any) => {
  const { name, title, isRequired, disabled, useGrouping, hideErrorWhenLoading } = props;
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } = useFormikContext();
  const { progress } = useLayoutV2();

  return (
    <div className="element-form">
      <label className="label pb-16">
        {title} {isRequired ? <span className="required-label ml-4">*</span> : <></>}
        <div className="icon-info ml-16">
          <img src={InfoCircle} alt="Info" loading="lazy" className="m-0" />
        </div>
      </label>
      <InputNumber
        className={`${_.get(errors, name) && _.get(touched, name) ? 'has-error' : ''}`}
        name={name}
        //onChange={handleChange}
        value={_.get(values, name) || ''}
        locale="de-DE"
        useGrouping={useGrouping || false}
        disabled={disabled}
        onBlur={e => {
          const value = e.target.value ? parseStringToInt(e.target.value) : 0;
          setFieldValue(name, value, true);
          setTimeout(() => {
            handleBlur(e);
          }, 200);
        }}
      />
      {progress.loading && hideErrorWhenLoading ? (
        <></>
      ) : (
        <div className={`${_.get(errors, name) && _.get(touched, name) ? 'error' : ''}`}>{_.get(touched, name) && _.get(errors, name)}</div>
      )}
    </div>
  );
};

export default WIFormInputNumberV2;
