import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState, memo } from 'react';
import { convertDonationExampleToUIItems, createNewForDonations, parseJSON, showNotification, transformDonationList } from '../../../../utils/logic';
import { RegionManagerAPI, LandManagerAPI } from '../../../../services';
import CountryService from '../../../../services/country-service';
import './create-region-form.scss';
import { COMMON_STATUS, RegionStatusUtils } from '../../../../utils/utils';
import { defaultVideoUrl } from '../../../common/useVideoCustom';
import { TabView, TabPanel } from 'primereact/tabview';
import GeneralFormComponent from '../general-form/general-form';
import CertificateContainer from '../../../common/certification/certificate-container';
import CertificateFormComponent from '../certificate-form/certificate-form';
import EmailFormComponent from '../email-form/email-form';
import ThankyouFormComponent from '../thankyou-form/thankyou-form';
import { env } from '../../../../configs';
import { buildTooltipManageTemplate } from '../../../common/column-template-table/column-template';
import { DefaultExampleList, DefaultExampleListEmpty } from '../../../../types/commonType';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import CreateDonationFormComponent from '../../../campaign/components/create-donation-form/create-donation-form';
import useAuth from '../../../../context/useAuth';
import { CURRENCY_CODES, fieldsRegionForm, LAND_TYPES, PERMISSIONS_V2 } from '../../../../utils/constants';

const CreateRegionFormComponent = (props: any) => {
  const { region, toast, id, fetchRegionData } = props;
  const { auth } = useAuth();
  const permissions = {
    canCreateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_CREATE),
    canUpdateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_UPDATE),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [lands, setLands] = useState<any>([]);
  const formikRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isChangingDonations, setIsChangingDonations] = useState(false);
  const [errorRegionForm, setErrorRegionForm] = useState({
    general: false,
    certificate: false,
    email: false,
    donationExample: false,
    thankyou: false,
  });
  const navigate = useNavigate();
  const [donationExample, setDonationExample] = useState<any>({
    donations: [],
    donationsCAD: [],
    donationsCHF: [],
  });

  const generateRegion = async (parameters: any) => {
    let updateData = {
      ...parameters,
      logo: parameters.land_logo,
      identifier: parameters.identifier?.toLowerCase(),
    };
    delete updateData['land_logo'];
    return await RegionManagerAPI.generateRegions(updateData);
  };

  const updateRegion = async (parameters: any) => {
    let updateData = {
      ...parameters,
      logo: parameters.land_logo,
    };
    delete updateData['land_logo'];
    return await RegionManagerAPI.updateRegions(id, updateData);
  };

  const getStatus = (code: string) => {
    if (code) {
      let item = RegionStatusUtils.find(f => f.code.toLowerCase() === code.toLowerCase());
      if (item) {
        return item.code;
      }
    }
    return RegionStatusUtils[0].code;
  };

  const buildHeaderTemplate = () => {
    const title = `Region code`;
    const code = region?.identifier || '';
    const tooltip = `Click to open region page with code ${code}`;
    const URL = `${env.DONATION_URL}?region=${code}`;
    return buildTooltipManageTemplate(title, code, tooltip, URL);
  };

  const transformExampleList = (donation_example_list: any) => {
    const eur = convertDonationExampleToUIItems(donation_example_list.eur, CURRENCY_CODES.EUR);
    let cad = convertDonationExampleToUIItems(donation_example_list.cad, CURRENCY_CODES.CAD);
    let chf = convertDonationExampleToUIItems(donation_example_list.chf, CURRENCY_CODES.CHF);

    return {
      donations: eur,
      donationsCAD: cad,
      donationsCHF: chf,
    };
  };

  const setDataIsChangingDonations = (data: any) => {
    setIsChangingDonations(data);
  };

  const setDataDonations = (data: any) => {
    donationExample.donations = _.cloneDeep(data);
  };

  const setDataDonationsCAD = (data: any) => {
    donationExample.donationsCAD = _.cloneDeep(data);
  };

  const setDataDonationsCHF = (data: any) => {
    donationExample.donationsCHF = _.cloneDeep(data);
  };

  const requestRegionsData = async () => {
    // @ts-ignore: Object is possibly 'null'.
    const formikValues = formikRef?.current?.values;
    transformDonationList(formikValues, donationExample);

    if (region) {
      const resultUpdate = await updateRegion({ ...formikValues });
      if (resultUpdate?.status === 200 && resultUpdate?.data) {
        fetchRegionData(id);
        showNotification('success', 'Update regions successfully!', toast);
      }
    } else {
      const resultCreate = await generateRegion({ ...formikValues });
      showNotification('success', 'Generate regions successfully!', toast);
      const { id } = resultCreate.data;
      if (id) {
        navigate(`/regions/${id}`);
      }
    }
  };

  const validateRegionForm = () => {
    let fieldsRegion = {
      general: false,
      certificate: false,
      email: false,
      donationExample: false,
      thankyou: false,
    };
    // @ts-ignore: Object is possibly 'null'.
    const { errors } = formikRef?.current;
    for (const [key, value] of Object.entries(errors)) {
      if (fieldsRegionForm.general.includes(key)) {
        fieldsRegion.general = true;
      } else if (fieldsRegionForm.certificate.includes(key)) {
        fieldsRegion.certificate = true;
      } else if (fieldsRegionForm.email.includes(key)) {
        fieldsRegion.email = true;
      } else if (fieldsRegionForm.donationExample.includes(key)) {
        fieldsRegion.donationExample = true;
      } else if (fieldsRegionForm.thankyou.includes(key)) {
        fieldsRegion.thankyou = true;
      }
    }
    setErrorRegionForm({ ...fieldsRegion });
  };

  const generateRegions = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      await formikRef?.current?.validateForm();
      validateRegionForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && formikRef?.current?.isValid) {
        setIsLoading(true);
        await requestRegionsData();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      showNotification('error', `Failed to ${region ? 'update' : 'generate'} regions`, toast);
    }
  };

  useEffect(() => {
    fetchCountryData();
    fetchLandsData();
    const updateDonationExample = transformExampleList(
      region ? (_.isEmpty(region.donation_example_list) ? DefaultExampleListEmpty : region.donation_example_list) : DefaultExampleList,
    );
    setDonationExample(updateDonationExample);
  }, [region]);

  const fetchCountryData = async () => {
    const res = await CountryService.getCountries();
    if (res.status === 200 && res.data.data) {
      setCountries(res.data.data);
    }
  };

  const fetchLandsData = async () => {
    const res = await LandManagerAPI.getAllLands();
    if (res && res.status === 200) {
      const landsData = res.data.records
        ?.filter((f: any) => f.region_id === region?.uuid && f.land_type === LAND_TYPES.Public && f.status === COMMON_STATUS.ACTIVE)
        .map((item: any) => {
          return {
            ...item,
            name: typeof item.name === 'object' ? item.name.de : item.name,
          };
        });
      setLands(landsData);
    }
  };

  const regionsFormSchema = Yup.object().shape({
    name: Yup.object().shape({
      de: Yup.string().required('This field is required.').max(50, 'This field must be with a maximum length of 50.'),
      en: Yup.string().required('This field is required.').max(50, 'This field must be with a maximum length of 50.'),
    }),
    headline: Yup.object().shape({
      de: Yup.string().required('This field is required.').max(50, 'This field must be with a maximum length of 50.'),
      en: Yup.string().required('This field is required.').max(50, 'This field must be with a maximum length of 50.'),
    }),
    identifier: Yup.string().required('Identifier is required').max(20, 'This field must be with a maximum length of 20.'),
    default_land_id: Yup.string().when('status', {
      is: (status: any) => status !== 'new',
      then: Yup.string().required('This field is required.'),
    }),
    description: Yup.string().required('Description is required'),
    land_logo: Yup.string().required('Logo is required'),
    headline_image: Yup.string().required('Header picture is required'),
    mobile_headline_image: Yup.string().required('Mobile header picture is required'),
    forest_type: Yup.object().shape({
      de: Yup.string().required('This field is required.').max(40, 'This field must be with a maximum length of 40.'),
      en: Yup.string().required('This field is required.').max(40, 'This field must be with a maximum length of 40.'),
    }),
    certificate_animal_image: Yup.string().required('This field is required.'),
    certificate_tree_image: Yup.string().required('This field is required.'),
    certificate_drone_image: Yup.string().required('This field is required.'),
    certificate_description: Yup.object().shape({
      de: Yup.string().required('This field is required.').max(400, 'This field must be with a maximum length of 400.'),
      en: Yup.string().required('This field is required.').max(400, 'This field must be with a maximum length of 400.'),
    }),
    email_template_id: Yup.object().shape({
      de: Yup.number()
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? null : currentValue;
        })
        .nullable()
        .required('Email Template DE is required')
        .min(1, 'Email Template DE is invalid'),
      en: Yup.number()
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? null : currentValue;
        })
        .nullable()
        .required('Email Template EN is required')
        .min(1, 'Email Template EN is invalid'),
    }),
    email_header_picture: Yup.string().required('Email header picture is required'),
    email_additional_picture: Yup.string().required('Email additional picture is required'),
    is_default_video: Yup.boolean().required('Default video is required'),
    video_url: Yup.object().when('is_default_video', {
      is: false,
      then: Yup.object().shape({
        de: Yup.string().required('Video Url DE is required'),
        en: Yup.string().required('Video Url EN is required'),
      }),
    }),
  });

  const initialRegionsValues = () => {
    return {
      name: region ? { de: `${region.name?.de || ''}`, en: `${region.name?.en || ''}` } : { de: '', en: '' },
      headline: region ? { de: `${region.headline?.de || ''}`, en: `${region.headline?.en || ''}` } : { de: '', en: '' },
      additional_description: region
        ? { de: `${region.additional_description?.de || ''}`, en: `${region.additional_description?.en || ''}` }
        : { de: '', en: '' },
      identifier: region ? `${region.identifier || ''}` : '',
      default_land_id: region ? `${region.default_land_id || ''}` : '',
      country: region ? `${region.country || 'DE'}` : 'DE',
      description: region ? `${parseJSON(region.description) || ''}` : '',
      landing_page_url: region ? `${parseJSON(region.landing_page_url) || ''}` : '',
      land_logo: region ? `${region.logo || ''}` : '',
      status: region ? getStatus(region.status) : RegionStatusUtils[0].code,
      forest_type: region ? { de: `${region.forest_type?.de || ''}`, en: `${region.forest_type?.en || ''}` } : { de: '', en: '' },
      headline_image: region ? `${region.headline_image || ''}` : '',
      mobile_headline_image: region ? `${region.mobile_headline_image || ''}` : '',

      logo: region ? `${region.logo || ''}` : '',
      certificate_animal_image: region ? region.certificate_animal_image || '' : '',
      certificate_tree_image: region ? region.certificate_tree_image || '' : '',
      certificate_drone_image: region ? region.certificate_drone_image || '' : '',
      certificate_qr_code: region ? region.certificate_qr_code || '' : '',
      certificate_description: region
        ? { de: `${region.certificate_description?.de || ''}`, en: `${region.certificate_description?.en || ''}` }
        : { de: '', en: '' },

      email_template_id: region ? { de: region.email_template_id?.de || 0, en: region.email_template_id?.en || 0 } : { de: 0, en: 0 },
      email_header_picture: region ? `${region.email_header_picture || ''}` : '',
      email_additional_picture: region ? `${region.email_additional_picture || ''}` : '',

      donation_example_list: region
        ? _.isEmpty(region.donation_example_list)
          ? DefaultExampleListEmpty
          : region.donation_example_list
        : _.cloneDeep(DefaultExampleList),

      is_default_video: region ? (region?.is_default_video === 1 ? true : false) : true,
      video_url: region ? region?.video_url || defaultVideoUrl : defaultVideoUrl,
      message: region ? { de: `${region?.message?.de || ''}`, en: `${region?.message?.en || ''}` } : { de: '', en: '' },
      video_type: region ? region?.video_type : 'yt',
    };
  };

  return (
    <div className="create-region-form">
      <div className="create-region-form-container">
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={initialRegionsValues()}
          validationSchema={regionsFormSchema}
          onSubmit={(values, { setSubmitting }) => {
            // console.log("values >>>", values);
          }}
        >
          {({ values, errors, touched, dirty, isValid, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="row-content">
                <div className="row">
                  <div className="sticky-header-form">
                    <div className="col-12 d-flex align-items-center justify-content-between btn-submit-container">
                      {buildHeaderTemplate()}
                      <Button
                        loading={isLoading}
                        // disabled={ !isValidForm(!data.isShowCreate, dirty, isValid, isChangingDonations) }
                        id="btn-submit-coupons"
                        type="submit"
                        label={`${region ? 'Update' : 'Create'}`}
                        className="p-button-rounded btn-submit"
                        icon="pi pi-chevron-right"
                        iconPos="right"
                        hidden={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                        onClick={() => generateRegions()}
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="tabs-container">
                      <TabView activeIndex={activeIndex} onTabChange={e => setActiveIndex(e.index)}>
                        <TabPanel className={`${errorRegionForm.general && 'tab-error'}`} header="General">
                          <GeneralFormComponent
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            countries={countries}
                            lands={lands}
                            region={region}
                          />
                        </TabPanel>
                        <TabPanel className={`${errorRegionForm.certificate && 'tab-error'}`} header="Certificate">
                          <CertificateFormComponent
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </TabPanel>
                        <TabPanel className={`${errorRegionForm.email && 'tab-error'}`} header="Email">
                          <EmailFormComponent
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                          />
                        </TabPanel>
                        <TabPanel className={`${errorRegionForm.donationExample && 'tab-error'}`} header="Donation Example">
                          <CreateDonationFormComponent
                            setDataIsChangingDonations={setDataIsChangingDonations}
                            originalDonations={createNewForDonations([], CURRENCY_CODES.EUR)}
                            donations={donationExample.donations}
                            setDataDonations={setDataDonations}
                            originalDonationsCAD={createNewForDonations([], CURRENCY_CODES.CAD)}
                            donationsCAD={donationExample.donationsCAD}
                            setDataDonationsCAD={setDataDonationsCAD}
                            originalDonationsCHF={createNewForDonations([], CURRENCY_CODES.CHF)}
                            donationsCHF={donationExample.donationsCHF}
                            setDataDonationsCHF={setDataDonationsCHF}
                          />
                        </TabPanel>
                        <TabPanel className={`${errorRegionForm.thankyou && 'tab-error'}`} header="Thankyou Page">
                          <ThankyouFormComponent
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                          />
                        </TabPanel>
                      </TabView>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <CertificateContainer values={values} errors={errors} visible={true} fields={[]} />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default memo(CreateRegionFormComponent);
