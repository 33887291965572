import React from 'react';
import _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import InfoCircle from '../../../assets/images/icons/info-circle.svg';
import { useFormikContext } from 'formik';
import { useLayoutV2 } from '../../../context/LayoutProvider';
import { ReactComponent as PlusSvg } from '../../../assets/images/icons/add-plus.svg';
import { ReactComponent as CloseSvg } from '../../../assets/images/icons/close.svg';

const WIFormArrayInputTextV2 = (props: any) => {
  const { name, type = 'text', title, isRequired, disabled, maxLength, onKeyDown, onChange, hideErrorWhenLoading, onAdd, onRemove } = props;
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext();
  const { progress } = useLayoutV2();

  return (
    <div className="element-form">
      <label className="label mb-16 d-flex justify-content-between">
        <span>{title} {isRequired ? <span className="required-label ml-4">*</span> : <></>}</span>
        <div className="icon-info ml-16">
          <img src={InfoCircle} alt="Info" loading="lazy" className="m-0" />
        </div>
        <div className="d-flex justify-content-end" style={{ cursor: 'pointer' }} >
          <div
            onClick={() => onAdd()}
          >
            <PlusSvg className="icon-svg"></PlusSvg>
          </div>
        </div>
      </label>
      {
        _.get(values, name)?.map((item: any, index: number) => (
          <div className="row-content">
            <div className="col-md-12 mb-24" style={{ position: 'relative' }} >
              <InputText
                key={index}
                className={`${_.get(errors, `${name}[${index}]`) && _.get(touched, `${name}[${index}]`) ? 'has-error' : ''}`}
                type={type}
                name={`${name}[${index}]`}
                onChange={onChange || handleChange}
                onBlur={handleBlur}
                value={item || ''}
                maxLength={maxLength}
                disabled={disabled}
                onKeyDown={onKeyDown}
              />
              <span
                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '12px' }}
                onClick={() => onRemove(index)}
              >
                <CloseSvg className="icon-svg"></CloseSvg>
              </span>
              {progress.loading && hideErrorWhenLoading ? null : (
                <div className={`${_.get(errors, `${name}[${index}]`) && _.get(touched, `${name}[${index}]`) ? 'error' : ''}`}>
                  {_.get(touched, `${name}[${index}]`) && _.get(errors, `${name}[${index}]`)}
                </div>
              )}
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default WIFormArrayInputTextV2;
