import { AxiosClient, cacheAge } from '../httpClient';

export default class ContactManagerAPI {
  static getContacts(data: any) {
    return AxiosClient.get(`/contact-cards/pagination`, data);
  }

  static deleteContact(id: string) {
    return AxiosClient.delete(`/contact-cards/${id}`);
  }

  static createContact(data: any) {
    return AxiosClient.post(`/contact-cards`, data);
  }

  static updateContact(data: any) {
    return AxiosClient.put(`/contact-cards/${data.uuid}`, data);
  }

  static getAll(enableCache: boolean = true) {
    return enableCache ? AxiosClient.get(`/contact-cards`, cacheAge) : AxiosClient.get(`/contact-cards`);
  }

  static getContact(uuid: string) {
    return AxiosClient.get(`/contact-cards/${uuid}`);
  }
}
