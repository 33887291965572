export type LanguageItem = {
  de: string;
  en: string;
};

export type DonationItem = {
  money: number;
  item: string;
};

export type AmountItem = {
  eur: number | null;
  cad: number | null;
  chf: number | null;
};

export type DonationsList = {
  donations: DonationItemUIType[];
  donationsCAD: DonationItemUIType[];
  donationsCHF: DonationItemUIType[];
};

export type DonationItemUIType = {
  key: string;
  index: number;
  money: number;
  item: {
    de: string;
    en: string;
  };
};

export type CommittedDonationItemType = {
  key: string;
  index: number;
  money: number;
  item: {
    de: string;
    en: string;
  };
  hasError: boolean;
};

export type BaseType = {
  name: LanguageItem;
  code: string;
  status: string;
  landingpage_url: string | null;
  donation_example_list: {
    eur: {
      en: DonationItem[];
      de: DonationItem[];
    };
    cad: {
      en: DonationItem[];
      de: DonationItem[];
    };
    chf: {
      en: DonationItem[];
      de: DonationItem[];
    };
  };
  certificate_animal_image: string;
  certificate_tree_image: string;
  certificate_drone_image: string;
  certificate_qr_code: string;
  email_template_id: {
    de: number | null;
    en: number | null;
  };
  email_template_id_error_message: {
    de: string;
    en: string;
  };
  email_header_picture: string;
  email_additional_picture: string;
};

export type ConditionItemType = {
  field: string;
  operator: string;
  value: string;
};

export type SearchType = {
  conditions: ConditionItemType[];
};

export const DefaultExampleList = {
  eur: {
    en: [
      {
        money: 192,
        item: 'the annual CO2 emissions of an average German (11.520 Kg CO2)',
      },
      {
        money: 64,
        item: 'the average size of a classroom (64 m2)',
      },
      {
        money: 50,
        item: "the size of Robert's research camp (50 m2)",
      },
      {
        money: 640,
        item: 'the treetop of a jungle giant (640 m2)',
      },
    ],
    de: [
      {
        money: 192,
        item: 'den jährlichen CO2 Ausstoß eines durchschnittlichen Deutschen (11.520 Kg CO2)',
      },
      {
        money: 64,
        item: 'die durchschnittliche Größe eines Klassenzimmers (64 m2)',
      },
      {
        money: 50,
        item: 'die Größe von Roberts Forschungscamp (50 m2)',
      },
      {
        money: 640,
        item: 'die Baumkrone eines Urwaldriesen (640 m2)',
      },
    ],
  },
  cad: {
    en: [
      {
        money: 288,
        item: 'the annual CO2 emissions of an average German (11.520 Kg CO2)',
      },
      {
        money: 97,
        item: 'the average size of a classroom (64 m2)',
      },
      {
        money: 76,
        item: "the size of Robert's research camp (50 m2)",
      },
      {
        money: 961,
        item: 'the treetop of a jungle giant (640 m2)',
      },
    ],
    de: [
      {
        money: 288,
        item: 'den jährlichen CO2 Ausstoß eines durchschnittlichen Deutschen (11.520 Kg CO2)',
      },
      {
        money: 97,
        item: 'die durchschnittliche Größe eines Klassenzimmers (64 m2)',
      },
      {
        money: 76,
        item: 'die Größe von Roberts Forschungscamp (50 m2)',
      },
      {
        money: 961,
        item: 'die Baumkrone eines Urwaldriesen (640 m2)',
      },
    ],
  },
  chf: {
    en: [
      {
        money: 288,
        item: 'the annual CO2 emissions of an average German (11.520 Kg CO2)',
      },
      {
        money: 97,
        item: 'the average size of a classroom (64 m2)',
      },
      {
        money: 76,
        item: "the size of Robert's research camp (50 m2)",
      },
      {
        money: 961,
        item: 'the treetop of a jungle giant (640 m2)',
      },
    ],
    de: [
      {
        money: 288,
        item: 'den jährlichen CO2 Ausstoß eines durchschnittlichen Deutschen (11.520 Kg CO2)',
      },
      {
        money: 97,
        item: 'die durchschnittliche Größe eines Klassenzimmers (64 m2)',
      },
      {
        money: 76,
        item: 'die Größe von Roberts Forschungscamp (50 m2)',
      },
      {
        money: 961,
        item: 'die Baumkrone eines Urwaldriesen (640 m2)',
      },
    ],
  },
};

export const DefaultExampleListEmpty = {
  eur: {
    en: [],
    de: [],
  },
  cad: {
    en: [],
    de: [],
  },
  chf: {
    en: [],
    de: [],
  },
};
