import * as React from 'react';
import './global-settings.scss';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../context/LayoutProvider';
import { ReactComponent as WarningSvg } from '../../assets/images/icons/warning-symbol.svg';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';
import WIConfirmDialog from '../../components_v2/common/delete-confirm-dialog/delete-confirm-dialog';
import GlobalSettingsForm from './components/global-setting-form/global-setting-form';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import GlobalSettingsAPI from '../../services/global-contents/globalSettings';
import { formatDateTimeV2, parseJSONtoObj } from '../../utils/logic';
import _ from 'lodash';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';

const GlobalSettings = (props: any) => {
  const { t } = useTranslation('language', { keyPrefix: 'global_settings' });
  const { getLanguage, setErrorProgress, setSuccessProgress } = useLayoutV2();
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [search, setSearch] = useState<any>(null);
  const [globalSetting, setGlobaSetting] = useState<any>(null);
  const [globalSettings, setGlobaSettings] = useState<any>(null);

  const fetchData = async () => {
    setIsLoading(true);
    const result = await GlobalSettingsAPI.getAll();
    // @ts-ignore: Object is possibly 'null'.
    if (result.status === 200 && result.data.records) {
      setGlobaSettings(result.data.records);
    } else {
      setGlobaSettings([]);
    }
    setIsLoading(false);
  };

  const callDeleteGlobaSetting = async () => {
    try {
      let res = await GlobalSettingsAPI.deleteGlobalSetting(globalSetting?.id);
      if (res && res.status === 200 && res.data.result.isValid) {
        setGlobaSetting(null);
        setIsShowDeleteDialog(false);
        fetchData();
        setSuccessProgress(t('txt_delete_setting_success'));
        return;
      } else {
        console.log(res);
      }
    } catch (error) {
      setErrorProgress(errorTrans('txt_failed_delete_setting'));
    }
  };

  const onEditGlobalSetting = (rowData: any) => {
    setGlobaSetting(rowData);
    setIsShowSidebar(true);
  };

  const onDeleteGlobalSetting = async (e: any, rowData: any) => {
    setGlobaSetting(rowData);
    setIsShowDeleteDialog(true);
  };

  const renderColumnValue = (rowData: any, column: any) => {
    const settingValue = parseJSONtoObj(rowData?.setting_value);
    if (settingValue && settingValue?.[getLanguage()]) {
      return <div className="setting-value">{settingValue?.[getLanguage()]}</div>;
    } else {
      return <div className="setting-value">{settingValue?.toString()}</div>;
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableColumns = [
    { field: 'setting_type', header: t('txt_setting_type'), width: '200px', align: 'left' },
    { field: 'setting_key', header: t('txt_setting_key'), width: 'calc(100% - 1064px)', align: 'left' },
    { field: 'setting_value', header: t('txt_setting_value'), width: '600px', align: 'left', bodyTemplate: renderColumnValue },
    {
      field: 'updated_at',
      header: t('txt_updated_at'),
      width: '200px',
      align: 'left',
      bodyTemplate: (rowData: any) => formatDateTimeV2(rowData.updated_at, getLanguage()),
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        className={col.field}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-edit',
        command: () => onEditGlobalSetting(rowData),
      },
      {
        label: t('txt_delete'),
        icon: 'wi-delete-folder',
        dangerous: true,
        command: (e: any) => {
          onDeleteGlobalSetting(e, rowData);
        },
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout title={t('txt_global_settings')} className="global-settings">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value)}
              enterSearch={() => setSearch(search)}
              globalValue={search}
            />
          </div>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={globalSettings}
          filters={search}
          globalFilterFields={['setting_value', 'setting_type', 'setting_key']}
          emptyMessage={t('txt_no_global_setting')}
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
      </div>
      <Sidebar
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        visible={isShowSidebar}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{t('txt_update')}</h6>
          </div>
          <GlobalSettingsForm globalSetting={globalSetting} callBack={fetchData} onHide={() => setIsShowSidebar(false)} />
        </div>
      </Sidebar>
      <WIConfirmDialog
        visible={isShowDeleteDialog}
        onHide={() => setIsShowDeleteDialog(false)}
        onConfirm={callDeleteGlobaSetting}
        maskClassName="top-mask-dialog"
        confirmLabel={t('txt_confirm')}
        hideLabel={t('txt_cancel')}
        message={
          <React.Fragment>
            <div style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center', marginBottom: '16px' }}>{t('txt_are_you_sure')}</div>
            <div style={{ fontSize: '13px', textAlign: 'center' }}>
              <div
                dangerouslySetInnerHTML={{ __html: t('txt_confirm_description', { permanently: `<strong>${t('txt_permanently')}</strong>` }) }}
              ></div>
              <div dangerouslySetInnerHTML={{ __html: t('txt_can_not_action', { cant: `<strong>${t('txt_cant_not')}</strong>` }) }}></div>
              <br />
              <b>{t('txt_note')}</b>: <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: t('txt_note_delete', {
                    setting: `<strong>${t('txt_setting')}</strong>`,
                    unexpected: `<strong>${t('txt_unexpected_issue')}</strong>`,
                  }),
                }}
              ></div>
            </div>
          </React.Fragment>
        }
        icon={<WarningSvg className="icon-svg"></WarningSvg>}
      />
    </Layout>
  );
};

export default GlobalSettings;
