import _ from 'lodash';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

const CalendarField = (props: any) => {
  const ref = useRef(null);
  const [selectedValues, setSelectedValues] = useState<any>();
  const [isValid, setValid] = useState<boolean>(false);
  const { name, label, visible, selectedItems, isDirty, setDirty, className, pannelClassName, onChange } = props;

  useEffect(() => {
    const value = selectedItems || [];
    setSelectedValues(value?.length > 0 ? value.map((c: any) => moment(c, 'DD/MM/YYYY').toDate()) : null);

    if (selectedItems.length === 2) {
      setValid(true);
    }
    setDirty(false);
  }, [selectedItems, isDirty]);

  const onPanelToggle = (panel: any, event: any) => {
    panel.current.toggle(event);
  };

  const onHandlerChange = (event: any) => {
    setSelectedValues(event.value);
    setValid(false);
    if (event.value && event.value[0] && event.value[1]) {
      onChange({
        field: name,
        value: event.value,
      });
      setValid(true);
    }
  };

  const generateHeadline = (): string => {
    if (selectedValues && selectedValues.length > 0) {
      return `${label} | ${selectedValues.map((c: any) => moment(c).format('DD/MM/YY')).join(' - ')}`;
    }
    return label;
  };

  return visible ? (
    <>
      <Button
        type="button"
        icon="pi pi-plus-circle"
        label={generateHeadline()}
        className={`${className} ${selectedValues?.length && selectedValues?.length > 0 ? (isValid ? 'filter-active' : 'unvalid-date') : ''}`}
        aria-haspopup
        aria-controls="overlay_panel"
        onClick={e => onPanelToggle(ref, e)}
      />
      <OverlayPanel
        id="overlay_panel"
        ref={ref}
        showCloseIcon={false}
        style={{ width: '500px' }}
        appendTo={document.body}
        className={pannelClassName}
      >
        <Calendar inline readOnlyInput selectionMode="range" value={selectedValues} onChange={onHandlerChange} />
      </OverlayPanel>
    </>
  ) : (
    <></>
  );
};

export default CalendarField;
