import * as React from 'react';
import './wi-button.scss';

const WIButton = (props: any) => {
  let classname = 'wi-button';
  classname += props.primary ? ' primary' : ' secondary';
  classname += ` ${props.size}`;
  const iconName = `pi ${props.icon}`;
  return (
    <>
      <button
        type={props.type || 'button'}
        className={`${classname} ${props.className}`}
        disabled={props.disabled || false}
        onClick={props.onClick}
        hidden={props.hidden}
      >
        {props.label} {props.icon ? <i className={iconName} /> : null}
      </button>
    </>
  );
};

export default WIButton;
