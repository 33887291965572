import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { getObjectSearchValue, getObjectWithValues } from './components/donor-component-util';
import moment from 'moment';
import DonorManagerAPI from '../../services/donorManager';
import CountryService from '../../services/country-service';
import { Sidebar } from 'primereact/sidebar';
import AddDonorFormComponent from './components/add-donor-form/add-donor-form';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';
import { useTranslation } from 'react-i18next';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import { Button } from 'primereact/button';
import DonorFilterForm from './components/donor-filter-form/donor-filter-form';
import { useLayoutV2 } from '../../context/LayoutProvider';
import useAuth from '../../context/useAuth';
import { PERMISSIONS_V2 } from '../../utils/constants';
import { formatDateV2 } from '../../utils/logic';

export type DonorFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    joined_date: any;
  };
  order: [];
};

const DonorManager = () => {
  const { getLanguage, setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const filterJoinedDate = searchParams?.get('filterJoinedDate') ? searchParams?.get('filterJoinedDate')?.split(',') || [] : [];
  const sortField = searchParams?.get('sortField') || 'joined_date';
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const searchField = searchParams?.get('search') || '';
  const [filterValues, setFilterValues] = useState<DonorFilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getObjectSearchValue(searchField) : [],
      joined_date: filterJoinedDate,
    },
    order: [],
  });

  const [donors, setDonors] = useState({
    data: [] as any,
    totalPage: 0,
  });

  const [search, setSearch] = useState<any>(null);
  const [page, setPage] = useState(pageNumber);
  const [first, setFirst] = useState(pageNumber * noRows);
  const [rows, setRows] = useState(noRows);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  const [countries, setCountries] = useState([]);
  const { t } = useTranslation('language', { keyPrefix: 'donor_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const [donor, setDonor] = useState<any>();
  const { auth } = useAuth();

  const permissions = {
    canCreateDonor: auth?.permissions?.includes(PERMISSIONS_V2.DONOR_CREATE),
    canUpdateDonor: auth?.permissions?.includes(PERMISSIONS_V2.DONOR_UPDATE),
    canViewDonor: auth?.permissions?.includes(PERMISSIONS_V2.DONOR_VIEW),
  };

  const getCountries = async () => {
    try {
      const response = await CountryService.getCountries();
      if (response && response.status === 200) {
        if (response.data.data && response.data.data.length > 0) {
          return response.data.data;
        }
      }
    } catch (error) {
      console.log('getCountries: ' + error);
    }
  };

  const buildAddressTemplate = (data: any) => {
    let arrData = [];
    let address = data?.payment_address?.trim() ? `${data.payment_address.trim()}` : '';
    address && arrData.push(address);
    let postCode = data?.payment_post_code?.trim() ? `${data.payment_post_code.trim()}` : '';
    let city = data?.payment_city?.trim() ? `${data.payment_city.trim()}` : '';
    let postCodeCity = `${postCode} ${city}`.trim();
    postCodeCity && arrData.push(postCodeCity);
    let countryCode = data?.payment_country?.trim() ? `${data.payment_country.trim()}` : '';
    let countryObj: any = _.find(countries, { ISO2: countryCode });
    countryObj && arrData.push(countryObj?.name);
    let joinedAddress = arrData.join(', ');

    return joinedAddress;
  };

  const tableColumns: any = [
    {
      field: 'full_name',
      header: t('txt_name'),
      width: '25%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return (rowData.payment_first_name || '') + ' ' + (rowData.payment_last_name || '');
      },
    },
    {
      field: 'payment_email',
      header: t('txt_email'),
      width: '30%',
      align: 'left',
    },
    {
      field: 'address',
      header: t('txt_address'),
      width: 'calc(35% - 64px)',
      align: 'left',
      disabledSort: true,
      bodyTemplate: buildAddressTemplate,
    },
    {
      field: 'joined_date',
      header: t('txt_joined_date'),
      width: '10%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return rowData?.joined_date ? formatDateV2(rowData?.joined_date, getLanguage()) : '';
      },
    },
  ];

  const dynamicColumns = tableColumns.map((col: any, i: any) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
        // sortable={!col?.disabledSort}
      />
    );
  });

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const onAddDonor = () => {
    setDonor(null);
    setIsShowSidebar(true);
  };

  const onOpenDonorPortal = async (e: any, rowData: any) => {
    setLoadingProgress(errorTrans('txt_loading'));
    if (rowData) {
      const paymentEmail = rowData.payment_email?.toLowerCase();
      const donorData = { donorEmail: paymentEmail, language: 'de' };

      try {
        let newTab: any = window.open();
        const response = await DonorManagerAPI.generateDonorPortalURL(donorData);
        if (response?.status === 200 && response?.data) {
          const data = response.data;
          newTab.location.href = data.link;
          setSuccessProgress(t('txt_link_donor_portal_success'));
        } else {
          throw new Error('txt_link_donor_portal_fail');
        }
      } catch (error: any) {
        setErrorProgress(errorTrans(error?.message || 'txt_link_donor_portal_fail'));
      }
    }
  };

  const onEditDonor = (rowData: any) => {
    setDonor(rowData);
    setIsShowSidebar(true);
  };

  const fetchDonorsData = async () => {
    setIsLoading(true);

    const countries = await getCountries();
    setCountries(countries);

    const where_conditions = getObjectWithValues(filterValues.where);
    let filter = {
      pageNo: page.toString(),
      range: rows.toString(),
      where: {
        ...where_conditions,
        search_name: filterValues.search,
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === -1 ? 'DESC' : 'ASC'],
    };
    try {
      const response = await DonorManagerAPI.getDonorsBySearch(filter);
      if (response && response.status === 200) {
        if (response.data.total > 0) {
          setDonors({
            data: response.data.records,
            totalPage: response.data.total,
          });
        } else {
          setDonors({
            data: [],
            totalPage: response.data.length || 0,
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setDonors({
        data: [],
        totalPage: 0,
      });
    }
  };

  const onFilterChange = (values: any) => {
    const field = 'joined_date';
    const value = values.get(field);
    const where_conditions = getObjectWithValues(filterValues.where);
    const dates = value.map((c: any) => (_.isString(c) ? c : moment(c).format('DD/MM/YYYY')));
    where_conditions[field] = dates;
    where_conditions['from_' + field] = dates[0];
    where_conditions['to_' + field] = dates[1];

    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions['or'] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    p.search = '';
    setFilterValues(p);
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      // {
      //   label: t('txt_edit'),
      //   icon: "wi-open",
      //   url: `/donors/${rowData?.id}`,
      //   hidden: !permissions.canViewDonor
      // },
      {
        hidden: !permissions.canUpdateDonor,
        label: t('txt_update_donor'),
        icon: 'wi-edit',
        command: (e: any) => onEditDonor(rowData),
      },
      {
        label: t('txt_open_donor_portal'),
        icon: 'wi-open-doc',
        command: (e: any) => onOpenDonorPortal(e, rowData),
      },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  useEffect(() => {
    if (typeof page === 'number') {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });

      setSearchParams(
        {
          pageNumber: (page + 1).toString(),
          noRows: rows.toString(),
        },
        { replace: true },
      );
    }
  }, [page, rows]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterJoinedDate: filterValues.where.joined_date?.join(',') || '',
    };

    setSearchParams(
      _.omitBy(searchParams, p => !p),
      { replace: true },
    );
    fetchDonorsData();
  }, [filterValues, sortConfig]);

  return (
    <Layout title={t('txt_title')} className="donor-manager">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={(value: any) => onSearchFunc(search)}
            />
            <div className="filter-btn">
              <div className="filter-btn-content p-8 ml-16" onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg></FilterSvg>
              </div>
            </div>
          </div>
          <Button
            className="wi-primary-button-v2"
            type={'button'}
            label={t('txt_btn_add')}
            onClick={(e: any) => onAddDonor()}
            hidden={!permissions.canCreateDonor}
          >
            <PlusSvg className="icon-svg"></PlusSvg>
          </Button>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={donors.data}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" className="p-align-right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={donors.totalPage} onPageChange={onBasicPageChange} />
      </div>
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline mt-24 mb-24">
            <h6>{t(`${donor ? 'txt_update_donor' : 'txt_btn_add'}`)}</h6>
          </div>
          <AddDonorFormComponent
            countries={countries}
            fetchCallBack={(donorItem: any) => fetchDonorsData()}
            onHide={() => setIsShowSidebar(false)}
            donor={donor}
            id={donor?.id}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_filter')}</h6>
          </div>
          <DonorFilterForm
            fetchCallBack={() => setIsShowSidebarFilter(false)}
            filterValues={filterValues}
            onRemove={onFilterRemove}
            setSearch={setSearch}
            onHide={() => setIsShowSidebarFilter(false)}
            fields={['joined_date']}
            onSubmit={(values: any, fields: any) => onFilterChange(values)}
          />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default DonorManager;
