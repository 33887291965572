import loadImage from 'blueimp-load-image';
import { Buffer } from 'buffer';
import _ from 'lodash';
import moment from 'moment';
import fileIconExcel from '../assets/images/file-icons/Excel.svg';
import fileIconPdf from '../assets/images/file-icons/PDF.svg';
import fileIconPlain from '../assets/images/file-icons/Text.svg';
import fileIconWord from '../assets/images/file-icons/Document.svg';
import fileIconZip from '../assets/images/file-icons/ZIP.svg';
import fileIconRar from '../assets/images/file-icons/RAR.svg';
import fileIconAvi from '../assets/images/file-icons/AVI.svg';
import fileIconMov from '../assets/images/file-icons/MOV.svg';
import fileIconMp4 from '../assets/images/file-icons/MP4.svg';
import axios from 'axios';
import Compressor from 'compressorjs';
import { MediaV2ManagerAPI } from '../services';
import { TreeNodeType } from '../types/treeNodeType';
import { MediaType } from './constants';

export const PNG_MAZ_SIZE = 250 * 1024;

export const parseImageMetadata = async (file: any): Promise<any> => {
  const emptyMetaInfo = {
    description: { de: '', en: '' },
    alternative_text: { de: '', en: '' },
    title: { de: '', en: '' },
    xResolution: 0,
    yResolution: 0,
    camera_model: '',
    shoot_date: '',
    dimensions: { width: 0, height: 0 },
    originator: '',
    copyright: '',
  };

  const extension = _.some(IMAGE_EXTENSIONS, type => type === getFileExtension(file.name));
  if (!extension) return emptyMetaInfo;

  return new Promise((resolve, reject) => {
    try {
      const fileMetaInfo = _.cloneDeep(emptyMetaInfo);
      loadImage.parseMetaData(
        file,
        data => {
          if (data?.iptc) {
            const objectName: any = data.iptc[5] || '|';
            const caption: any = data.iptc[120] || '|';
            const objectNames = _.split(objectName, '|'); // maxLength: 64
            const captions = _.split(caption, '|'); // maxLength: 2000
            const shootDate: any = data.iptc[55] ? moment(data.iptc[55]).format('DD MMM y') : '';
            const originator: any = data.iptc[80] || '';
            const copyright: any = data.iptc[116] || '';

            fileMetaInfo['alternative_text'] = {
              de: captions[0] ? Buffer.from(captions[0], 'ascii').toString('utf8') : '',
              en: captions[1] ? Buffer.from(captions[1], 'ascii').toString('utf8') : '',
            };
            fileMetaInfo['title'] = {
              de: objectNames[0] ? Buffer.from(objectNames[0], 'ascii').toString('utf8') : '',
              en: objectNames[1] ? Buffer.from(objectNames[1], 'ascii').toString('utf8') : '',
            };
            fileMetaInfo['shoot_date'] = shootDate;
            fileMetaInfo['originator'] = Buffer.from(originator, 'ascii').toString('utf8');
            fileMetaInfo['copyright'] = Buffer.from(copyright, 'ascii').toString('utf8');
          }
          if (data?.exif) {
            const model: any = data.exif[272] || '';
            const xResolution: any = data.exif[282] || 0;
            const yResolution: any = data.exif[283] || 0;

            fileMetaInfo['camera_model'] = model;
            fileMetaInfo['xResolution'] = xResolution;
            fileMetaInfo['yResolution'] = yResolution;
          }

          resolve(fileMetaInfo);
        },
        { maxMetaDataSize: 262144 },
      );
    } catch (error) {
      console.log(`File is error: ${file.name} - ${error}`);
      reject(`File is error: ${file.name}`);
    }
  });
};

export const formatFileNameWithFolder = (folderPath: any, name: any) => {
  return folderPath ? `${folderPath}--${name}` : name;
};

export const getOriginalFileName = (name: string) => {
  return name ? _.split(name, '--').pop() : '';
};

export const formatImageFileURL = (name: string) => {
  return name ? name.split('--').join('/') : '';
};

export const formatFileSize = (size: number) => {
  return (size / (size > 1048576 ? 1048576 : 1024)).toFixed(2).toLocaleString() + (size > 1048576 ? ' MB' : ' KB');
};

export const getFileType = (extension: any) => {
  if (_.some(IMAGE_EXTENSIONS, type => type.toLowerCase() === extension.toLowerCase())) {
    return 'Image';
  } else if (_.some(DOCUMENT_EXTENSIONS, type => type.toLowerCase() === extension.toLowerCase())) {
    return 'Document';
  } else if (_.some(COMPRESS_EXTENSIONS, type => type === extension.toLowerCase())) {
    return 'Compressed';
  } else if (_.some(VIDEO_EXTENSIONS, type => type === extension.toLowerCase())) {
    return 'Video';
  }

  return MediaType[0].key; // General.;
};

export const getThumbnailFullpath = (fullpath: string) => {
  let result = fullpath;
  for (let type of ['.jpg', '.jpeg']) {
    result = result.replace(type, '_thumbnail.webp');
    result = result.replace(type.toUpperCase(), '_thumbnail.webp');
  }
  return result;
};

export const canBeThumbnail = (fullpath: string) =>
  fullpath.includes('.jpg') || fullpath.includes('.jpeg') || fullpath.includes('.JPG') || fullpath.includes('.JPEG');

export const getCompressedThumbnail = (image: any) => {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: 1, // 0.6 can also be used, but its not recommended to go below.
      maxWidth: 800,
      resize: 'cover',
      mimeType: 'image/webp',
      success: (compressedResult: any) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        console.log('compress thumbnail image', image.size, compressedResult.size);
        resolve(compressedResult);
      },
      error: (e: any) => reject(e),
    });
  });
};

export const getCompressedImage = (image: any) => {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
      // mimeType: 'image/webp',
      // convertTypes: ['image/png', 'image/webp', 'image/jpg'],
      // convertSize: image.size * 0.7,
      success: (compressedResult: any) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        console.log('compress image', image.size, compressedResult.size);
        resolve(compressedResult);
      },
      error: (e: any) => reject(e),
    });
  });
};

export const getCompressedPNG = async (stringBase64: any) => {
  const base64Data = stringBase64.split(',')[1];
  const binaryData = Buffer.from(base64Data, 'base64');
  return await MediaV2ManagerAPI.compressPNG(binaryData);
};

export const getBase64 = async (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = _ => resolve(reader.result);
    reader.onerror = e => reject(e);
  });
};

export const getFolderByFolderParentId = (parentId: any, folderData: any): TreeNodeType[] => {
  const folders: any = _.orderBy(
    _.filter(folderData, (f: any) => f.parentId === parentId),
    ['name'],
    ['asc'],
  );

  return folders.map((f: any) => ({
    uuid: f.uuid,
    parentId: f.parentId,
    key: f.url || f.name,
    label: f.name,
    data: f.name,
    icon: 'pi pi-fw pi-folder',
    children: getFolderByFolderParentId(f.uuid, folderData),
  }));
};

export const getImageDimensions = (fileBase64: any) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = fileBase64;
    img.onload = _ => {
      const imgSize = {
        width: img.width,
        height: img.height,
      };

      resolve(imgSize);
    };
    img.onerror = e => reject(e);
  });
};

export const uploadPhysicalFileToS3 = async (presignedUrl: any, stringBase64: any, type: any, needCompressPNG = true) => {
  const config = {
    headers: {
      'Content-Type': type,
    },
  };

  let base64Data: string = _.last(stringBase64.split(',')) || '';
  if (needCompressPNG && (presignedUrl.includes('.png') || presignedUrl.includes('.PNG'))) {
    const res = await getCompressedPNG(stringBase64);
    if (res && res.data && res.data.base64) {
      base64Data = res.data.base64;
    }
  }
  if (base64Data) {
    const binaryData = Buffer.from(base64Data, 'base64');
    await axios.put(presignedUrl, binaryData, config);
    return binaryData.length;
  }
  return 0;
};

const LIMIT_FILE_SIZE = 26214400; // 25MB

export const IconConfig: any = {
  doc: fileIconWord,
  docx: fileIconWord,
  xls: fileIconExcel,
  xlsx: fileIconExcel,
  pdf: fileIconPdf,
  txt: fileIconPlain,
  zip: fileIconZip,
  rar: fileIconRar,
  '7z': fileIconZip,
  avi: fileIconAvi,
  mov: fileIconMov,
  mp4: fileIconMp4,
};

export const IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'svg'];

const DOCUMENT_EXTENSIONS = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'txt'];

const COMPRESS_EXTENSIONS = ['zip', 'rar', '7z'];

const VIDEO_EXTENSIONS = ['avi', 'mov', 'mp4'];

const FILE_EXTENSIONS = [...IMAGE_EXTENSIONS, ...DOCUMENT_EXTENSIONS, ...COMPRESS_EXTENSIONS, ...VIDEO_EXTENSIONS];

export const isFileOverSize = (file: any) => file.size > LIMIT_FILE_SIZE;

export const isFileTypeNotAllowed = (file: any) => {
  const extension = getFileExtension(file.name);
  return !FILE_EXTENSIONS.some(type => type.toLowerCase() === extension.toLowerCase());
};

export const getFileApplicationType = (file: any): string => {
  return _.split(_.split(file, ';base64').shift(), ':').pop() || '';
};

export const getFileExtension = (fileName: any) => {
  return fileName?.slice(((fileName?.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const requestBase64StringFromImageURL = async (fileURL: string): Promise<any> => {
  try {
    const response = await axios.get(fileURL, { responseType: 'arraybuffer' });
    if (response.status === 200) {
      const result = 'data:image/' + response.headers['content-type'] + ';base64,' + Buffer.from(response.data).toString('base64');
      return result;
    } else {
      return '';
    }
  } catch (e) {
    console.log('Error: fetch image error', e);
    return '';
  }
};
