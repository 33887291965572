//react-hooks/exhaustive-deps
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import './import-detail.scss';
import Layout from '../layout/layout';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { HistoryImportAPI } from '../../services';
import { Link, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { MailStatus } from '../../utils/utils';
import { Button } from 'primereact/button';
import { TreeTable } from 'primereact/treetable';
import { showNotification } from '../../utils/logic';
import _ from 'lodash';

const ImportDetail = () => {
  let { id } = useParams();
  const toast = useRef(null);
  const [donationImport, setDonationImport] = useState({
    passed_records: '',
    dataPassed: [],
    dataFailed: [],
    sendMailCompleted: [],
    sendMailFailed: [],
    is_send_receipt: true,
    status: '',
  });
  const mailStatusObject = useRef({
    status: '',
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const res = await HistoryImportAPI.getDetailImport(id || '');
    if (res && res.status === 200) {
      setIsLoading(false);
      const details = res.data.record;
      mailStatusObject.current.status = details.mail_status;
      const recordsSuccess = details.passed_records;
      const errorRecords = details.failed_records;
      const errorRecordsFormat = errorRecords.map((item: any, index: number) => {
        return {
          key: `${index}`,
          data: {
            name: item.no,
            message: '',
          },
          children: (_.isArray(item.errors) ? item.errors : [])?.map((errorItem: any, indexError: number) => {
            return {
              key: `${index}-${indexError}`,
              data: {
                name: errorItem.instancePath,
                message: errorItem.message,
              },
            };
          }),
        };
      });
      setDonationImport({
        status: details.status,
        passed_records: details.passed_records,
        dataPassed: recordsSuccess,
        dataFailed: errorRecordsFormat,
        is_send_receipt: details.is_send_receipt,
        sendMailCompleted: recordsSuccess.filter((item: any) => item?.mailStatus?.sendMail),
        sendMailFailed: recordsSuccess.filter((item: any) => item?.mailStatus?.sentMail === false),
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const sendMail = async () => {
    const result = await HistoryImportAPI.sendEMails(id, donationImport.passed_records || '');
    const mailsCompleted = donationImport.dataPassed.filter((r: any) => r.mailStatus && r.mailStatus.sendMail);
    mailStatusObject.current.status = MailStatus.Pending;
    setDonationImport({
      ...donationImport,
      sendMailCompleted: mailsCompleted,
    });
    if (result.data.result) {
      showNotification('success', 'Send mail successfully', toast);
    } else {
      showNotification('error', 'Failed to send mail', toast);
    }
  };

  const submitSendMail = () => {
    sendMail();
    const interval = setInterval(() => {
      if (mailStatusObject.current.status !== MailStatus.Completed) {
        fetchData();
      } else {
        clearInterval(interval);
      }
    }, 60000);
  };

  const retrySendMail = async () => {
    sendMail();
    const intervalRetry = setInterval(() => {
      if (mailStatusObject.current.status === MailStatus.Completed && donationImport.sendMailCompleted.length !== donationImport.dataPassed.length) {
        fetchData();
      } else {
        clearInterval(intervalRetry);
      }
    }, 60000);
  };

  const formatMailStatusTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        {rowData.mailStatus?.sendMail ? (
          <div className="completed-container">
            <i className="pi pi-check mr-2 completed-icon"></i>
          </div>
        ) : rowData.mailStatus ? (
          <div className="remove-container">
            <i className="pi pi-times remove-icon"></i>
          </div>
        ) : (
          <div></div>
        )}
      </React.Fragment>
    );
  };

  const formatSendMailTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        {rowData.data.send_email ? (
          <div className="completed-container">
            <i className="pi pi-check mr-2 completed-icon"></i>
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  };

  const formatSerialBodyTemplate = (rowData: any) => {
    return (
      <Link to={`/donations/${rowData.data?.uuid}`} className="btn-serial">
        {rowData.data?.donation_serial}
      </Link>
    );
  };

  return (
    <Layout>
      <div className="import-detail">
        <Toast ref={toast} />
        <div className="detail-container">
          <div className="certificate">
            <label>
              Import successfully, click{' '}
              <a target="_blank" href={require('../../assets/pdf/Beispiel.pdf')} rel="Certificate sample">
                here
              </a>{' '}
              to see the certificate sample.
            </label>
          </div>
          <div className="d-flex flex-column flex-lg-row flex-nowrap align-content-center justify-content-start btn-send">
            <div className="mail-errors">
              {donationImport.sendMailFailed.length > 0 ? <div>Send mails failed: {donationImport.sendMailFailed.length}</div> : <div />}
            </div>
            <div className="status-sendmail">
              {mailStatusObject.current.status === MailStatus.Pending ? (
                <div className="sendmail-lable">
                  Pending: {donationImport.dataPassed.length - donationImport.sendMailCompleted.length} / {donationImport.dataPassed.length} mails
                </div>
              ) : (
                <div />
              )}

              <div className="sendmail-lable">
                Sent: {donationImport.sendMailCompleted.length} / {donationImport.dataPassed.length} mails{' '}
                {donationImport.is_send_receipt ? 'with' : 'without'} receipt
              </div>
              {mailStatusObject.current.status === MailStatus.Completed &&
              donationImport.sendMailCompleted.length !== donationImport.dataPassed.length ? (
                <Button onClick={retrySendMail}>Retry</Button>
              ) : (
                <div />
              )}
              {mailStatusObject.current.status === MailStatus.NotYet ? (
                <Button onClick={submitSendMail} disabled={donationImport.status != MailStatus.Completed}>
                  Send Emails
                </Button>
              ) : (
                <div />
              )}
            </div>
          </div>
          <div className="table-import">
            <TabView
              activeIndex={activeIndex}
              onTabChange={e => {
                setActiveIndex(e.index);
              }}
            >
              <TabPanel header="Success">
                <DataTable
                  value={donationImport.dataPassed}
                  paginator
                  responsiveLayout="scroll"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={100}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  loading={isLoading}
                  scrollable
                  scrollHeight="calc(100vh - 166px)"
                >
                  <Column
                    field="data.donation_serial"
                    header="Serial"
                    align="center"
                    style={{ width: '10%' }}
                    body={formatSerialBodyTemplate}
                  ></Column>
                  <Column field="data.nw_geo_coordinate" header="Geo-coordinate (DMS)" align="center" style={{ width: '10%' }}></Column>
                  <Column field="data.payment_email" header="Email" style={{ width: '20%' }}></Column>
                  <Column field="data.personalize_certificate_display_name" header="Certificate Name" style={{ width: '20%' }}></Column>
                  <Column field="data.payment_company_name" header="Company Name" style={{ width: '20%' }}></Column>
                  <Column field="data.send_email" header="Send Email" align="center" body={formatSendMailTemplate} style={{ width: '10%' }}></Column>
                  <Column
                    field="mailStatus.sendMail"
                    header="Mail Status"
                    align="center"
                    body={formatMailStatusTemplate}
                    style={{ width: '10%' }}
                    sortable
                  ></Column>
                </DataTable>
              </TabPanel>
              <TabPanel header="Failed">
                <TreeTable value={donationImport.dataFailed} paginator rows={5} rowsPerPageOptions={[5, 10, 20, 50]} loading={isLoading}>
                  <Column field="name" header="Row" style={{ width: '25%' }} expander></Column>
                  <Column field="message" header="Message" style={{ width: '75%' }}></Column>
                </TreeTable>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImportDetail;
