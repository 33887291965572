import * as React from 'react';
import './change-log.scss';
import { formatDateTimeV2 } from '../../../../utils/logic';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

const LogCard = (props: any) => {
  const { item, t } = props;

  return (
    <div className="log-item mt-16 mb-16">
      <div className="log-time">{formatDateTimeV2(item.time)}</div>
      <div className="user-update pt-16 pb-16">
        <div className="label"> {t('txt_updated')} </div>
        <div className="user-info">
          <div className="user-name pr-16">
            {t('txt_by')} {item.display_name || item.first_name}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChangeLog = (props: any) => {
  const { data } = props;
  const { t } = useTranslation('language', { keyPrefix: 'system_user_detail' });

  return (
    <div className="change-log pb-24">
      <div className="change-log-container mr-8">
        <div className="log-list">
          {data.map((item: any, index: number) => (
            <LogCard key={index} item={item} t={t} />
          ))}
        </div>
        <div className="submit-log">
          <Button className="wi-primary-button-v2 h48" type={'button'} label={t('txt_got_it')} onClick={(e: any) => {}} />
        </div>
      </div>
    </div>
  );
};

export default ChangeLog;
