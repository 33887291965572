import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLanguage from './locales/en/language.json';
import deLanguage from './locales/de/language.json';

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      language: enLanguage,
    },
    de: {
      language: deLanguage,
    },
  },
});

export default i18n;
