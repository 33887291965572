import React from 'react';
import _ from 'lodash';
import InfoCircle from '../../../assets/images/icons/info-circle.svg';
import { useFormikContext } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';

const WIFormTextAreaV2 = (props: any) => {
  const { name, title, isRequired, disabled, maxLength } = props;
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext();

  return (
    <div className="element-form">
      <label className="label pb-16">
        {title} {isRequired ? <span className="required-label ml-4">*</span> : <></>}
        <div className="icon-info ml-16">
          <img src={InfoCircle} alt="Info" loading="lazy" className="m-0" />
        </div>
      </label>
      <InputTextarea
        className={`${_.get(errors, name) && _.get(touched, name) ? 'has-error' : ''}`}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={_.get(values, name) || ''}
        maxLength={maxLength}
        disabled={disabled}
        autoResize={false}
      />
      <div className={`${_.get(errors, name) && _.get(touched, name) ? 'error' : ''}`}>{_.get(touched, name) && _.get(errors, name)}</div>
    </div>
  );
};

export default WIFormTextAreaV2;
