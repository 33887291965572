import * as React from 'react';
import { useState, useEffect } from 'react';
import { Column, ColumnAlignType } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { CampaignManagerAPI, PartnerManagerAPI, RegionManagerAPI } from '../../services';
import { formatDateTimeV2, getUserType } from '../../utils/logic';
import { Link, useSearchParams } from 'react-router-dom';
import { env } from '../../configs';
import { getObjectSearchValue, getPayloadConditions, getObjectWithValues } from './campaign-manager-util';
import _ from 'lodash';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import { PERMISSIONS_V2, STATUS_LIST_2, USER_TYPES } from '../../utils/constants';
import WiStatus from '../../components_v2/common/wi-status/wi-status';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';
import { useTranslation } from 'react-i18next';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import { Sidebar } from 'primereact/sidebar';
import CampaignFilterForm from './components/campaign-filter-form';
import { Button } from 'primereact/button';
import PreAddCampaignForm from './components/pre-add-campaign-form';
import { useLayoutV2 } from '../../context/LayoutProvider';
import useAuth from '../../context/useAuth';

export type CampaignFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    region_id: string[];
    partner_id?: string[];
    status?: string[];
    type?: string[];
  };
  order: [];
};

const CampaignManager = (props: any) => {
  const { getLanguage } = useLayoutV2();
  const { auth } = useAuth();
  let userType: USER_TYPES = getUserType(auth);
  const permissions = {
    canCreateCampaign:
      auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_CREATE) || auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_CREATE_TENANT),
    canUpdateCampaign:
      auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_UPDATE) || auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_UPDATE_TENANT),
    canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
    canViewPartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_VIEW),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState({
    data: [],
    totalPage: 0,
  });
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(25);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [partners, setPartners] = useState([]);
  const [regions, setRegions] = useState([]);
  const [search, setSearch] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const filterRegions = searchParams?.get('filterRegions') ? searchParams?.get('filterRegions')?.split(',') || [] : [];
  const filterPartners = searchParams?.get('filterPartners') ? searchParams?.get('filterPartners')?.split(',') || [] : [];
  const filterStatus = searchParams?.get('filterStatus') ? searchParams?.get('filterStatus')?.split(',') || [] : [];
  const filterTypes = searchParams?.get('filterTypes') ? searchParams?.get('filterTypes')?.split(',') || [] : [];
  const sortField = searchParams?.get('sortField') || 'serial';
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const [sortConfig, setSortConfig] = useState<any>({
    // sortField: sortField,
    // sortOrder: sortOrder,
    sortField: 'serial',
    sortOrder: -1,
  });
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 25), 25), 1000);
  const searchField = searchParams?.get('search') || '';
  const [filterValues, setFilterValues] = useState<CampaignFilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getObjectSearchValue(searchField) : [],
      region_id: filterRegions,
      partner_id: filterPartners,
      status: filterStatus,
      type: filterTypes,
    },
    order: [],
  });
  const { t } = useTranslation('language', { keyPrefix: 'campaign_manager' });
  const { t: campaignTrans } = useTranslation('language', { keyPrefix: 'campaign_type' });
  const { t: globalTrans } = useTranslation('language');

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchCampaignsData = async (valueSearch?: string) => {
    setIsLoading(true);

    const where_conditions = getPayloadConditions(filterValues.where, sortConfig);

    try {
      const [regionRes, res] = await Promise.all([
        RegionManagerAPI.getAllRegions(),
        CampaignManagerAPI.searchCampaigns({
          pageNo: page,
          range: rows,
          ...where_conditions,
        }),
      ]);
      if (res && res.status === 200) {
        setIsLoading(false);
        const campaignList = res.data.records || [];
        campaignList.forEach((c: any) => {
          c.region = regionRes?.data?.records?.find((r: any) => r.uuid === c.land?.region_id);
          c.region_name = c.region ? c.region.name?.de : '';
        });

        setCampaigns({
          data: campaignList,
          totalPage: res.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setCampaigns({
        data: [],
        totalPage: 0,
      });
    }
  };

  const fetchRelatedData = async () => {
    try {
      const [resPartners, resRegions] = await Promise.all([PartnerManagerAPI.getAllPartners(), RegionManagerAPI.getAllRegions()]);
      if (resPartners && resPartners.status === 200) {
        setPartners(resPartners.data.records || []);
      }
      if (resRegions && resRegions.status === 200) {
        setRegions(resRegions.data.records || []);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (typeof page === 'number') {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });
      setSearchParams(
        {
          pageNumber: (page + 1).toString(),
          noRows: rows.toString(),
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterRegions: filterValues.where.region_id?.join(',') || '',
      filterStatus: filterValues.where.status?.join(',') || '',
      filterPartners: filterValues.where.partner_id?.join(',') || '',
      filterTypes: filterValues.where.type?.join(',') || '',
    };
    setSearchParams(
      _.omitBy(searchParams, (p: any) => !p),
      { replace: true },
    );
    fetchCampaignsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  useEffect(() => {
    fetchRelatedData();
  }, []);

  // By default: Not show "Partner" column
  // Set "Region" column width to 310 pixels and Partner column to NULL
  let regionTableColumnWidth = '310px';
  let partnerNameTableColumn = null;

  // If the current user is WI Employee, show "Partner" column
  if (userType === USER_TYPES.WIEmployee) {
    regionTableColumnWidth = '160px';
    partnerNameTableColumn = {
      field: 'partner_name',
      header: 'Partner',
      width: '150px',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const partnerName = rowData.partner_name || '';
        return permissions.canViewPartner && rowData.partner ? (
          <Link className="table-ref-link-cell" to={`/partners/${rowData.partner?.uuid}`}>
            {partnerName}
          </Link>
        ) : (
          partnerName
        );
      },
    };
  }

  const tableColumns = [
    {
      field: 'name',
      header: t('txt_name'),
      width: 'calc(100% - 1054px)',
      align: 'left',
      bodyTemplate: (rowData: any, column: any) => {
        const regionName = rowData.region?.name?.de || rowData.region?.name || '';
        const partnerName = rowData.partner?.name || '';
        return (
          <div className="cell-info">
            <div className="cell-main-info mb-8">{rowData.name.de}</div>
          </div>
        );
      },
    },
    {
      field: 'status',
      header: t('txt_status'),
      width: '150px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        const statusItem = STATUS_LIST_2.find((item: any) => item.code === rowData.status);
        return <WiStatus item={statusItem}></WiStatus>;
      },
    },
    {
      field: 'region_name',
      header: 'Region',
      width: regionTableColumnWidth,
      align: 'left',
      bodyTemplate: (rowData: any, column: any) => {
        const regionName = rowData.region?.name?.de || rowData.region?.name || '';
        return permissions.canViewRegion && rowData.region ? (
          <Link className="table-ref-link-cell" to={`/regions/${rowData.region?.uuid}`}>
            {regionName}
          </Link>
        ) : (
          regionName
        );
      },
    },
    partnerNameTableColumn,
    {
      field: 'code',
      header: t('txt_identifier'),
      width: '200px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        return (
          <a className="table-ref-link-cell" href={`${env.DONATION_URL}?campaign=${rowData.code}`} target="_blank" rel="noreferrer">
            {rowData.code}
          </a>
        );
      },
    },
    {
      field: 'protected_size',
      header: t('txt_protected_size'),
      width: '160px',
      align: 'right',
      bodyTemplate: (rowData: any) => (parseFloat(rowData.protected_size) || 0)?.toLocaleString(getLanguage()),
    },
    {
      field: 'created_at',
      header: `${t('txt_created')}`,
      width: '170px',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.created_at ? formatDateTimeV2(rowData.created_at, getLanguage()) : '-'}</div>;
      },
      align: 'center',
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    if (col) {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          style={{ width: col.width }}
          body={col.bodyTemplate}
          align={col.align as ColumnAlignType}
        />
      );
    }
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-open',
        url: `/campaigns/${rowData?.uuid}`,
      },
      // {
      //   label: t('txt_active'),
      //   icon: "wi-active",
      //   hidden: !permissions.canUpdateCampaign
      // },
      // {
      //   label: t('txt_inactive'),
      //   icon: "wi-inactive",
      //   hidden: !permissions.canUpdateCampaign
      // },
      // {
      //   label: t('txt_complete'),
      //   icon: "wi-complete",
      //   hidden: !permissions.canUpdateCampaign
      // },
      // // {
      // //   label: t('txt_download_sample_cert'),
      // //   icon: "wi-download",
      // // },
      {
        label: t('txt_open_donation_form'),
        icon: 'wi-open-doc',
        externalUrl: `${env.DONATION_URL}?campaign=${rowData.code}`,
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const onFilterChange = (values: any, fields: any) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    fields.forEach((field: any) => {
      const data = values?.get(field);
      where_conditions[field] = data;
    });
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    p.search = '';
    setFilterValues(p);
  };

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions['or'] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  return (
    <Layout title={t('txt_title')} className="campaign-manager">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={() => onSearchFunc(search)}
              globalValue={search}
            />
            <div className="filter-btn">
              <div className="filter-btn-content p-8 ml-16" onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg></FilterSvg>
              </div>
            </div>
          </div>
          {permissions.canCreateCampaign ? (
            <Button
              className="wi-primary-button-v2"
              label={t('txt_btn_add')}
              onClick={(e: any) => setIsShowSidebar(true)}
              hidden={!permissions.canCreateCampaign}
            >
              <PlusSvg className="icon-svg" />
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={campaigns.data}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
          //filters={search}
          // onSort={(e: any) => {
          //   setSortConfig({
          //     sortField: e.sortField,
          //     sortOrder: e.sortOrder,
          //   });
          // }}
          // sortField={sortConfig.sortField}
          // sortOrder={sortConfig.sortOrder}
          // selectionMode="single"
          // onSelectionChange={(e) => onSelectedCell(e.value)}
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={campaigns.totalPage} onPageChange={onBasicPageChange} />
      </div>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_filter')}</h6>
          </div>
          <CampaignFilterForm
            fetchCallBack={() => setIsShowSidebarFilter(false)}
            filterValues={filterValues}
            onRemove={onFilterRemove}
            setSearch={setSearch}
            onSearchFunc={onSearchFunc}
            onHide={() => setIsShowSidebarFilter(false)}
            fields={['partner_id', 'status', 'region_id', 'type']}
            partners={partners}
            regions={regions}
            userType={userType}
            onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{t('txt_btn_add')}</h6>
          </div>
          <PreAddCampaignForm onHide={() => setIsShowSidebar(false)} />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default CampaignManager;
