import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { LandManagerAPI } from '../../services';
import { formatDateTimeV2, formatIntergerV2, formatMultiLangField } from '../../utils/logic';
import { FIELDS_SEARCH, removeEmpty } from '../../utils/utils';
import RegionManagerAPI from '../../services/regionManager';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import { PERMISSIONS_V2, STATUS_LIST_2 } from '../../utils/constants';
import WiStatus from '../../components_v2/common/wi-status/wi-status';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { useTranslation } from 'react-i18next';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import { useLayoutV2 } from '../../context/LayoutProvider';
import useAuth from '../../context/useAuth';
import { WiProgress } from '../../components_v2/common/wi-progress/wi-progress';

const LandManagerComponent = () => {
  const { getLanguage } = useLayoutV2();
  const [isLoading, setIsLoading] = useState(false);
  const [lands, setLands] = useState({
    data: [],
    totalPage: 0,
  });
  const [regions, setRegions] = useState([]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const { t } = useTranslation('language', { keyPrefix: 'area_manager' });
  const { t: globalTrans } = useTranslation('language');
  const { auth } = useAuth();

  const permissions = {
    canCreateArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_CREATE),
    canUpdateArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_UPDATE),
    canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchLandsData = async (valueSearch?: string) => {
    setIsLoading(true);

    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.LANDS : undefined,
    });

    try {
      const [res, regionRes] = await Promise.all([LandManagerAPI.getLands(data), RegionManagerAPI.getAllRegions()]);
      if (res && res.status === 200) {
        const dataLands = res.data.records || [];
        const dataRegions = (regionRes.data.records || []).map((f: any) => ({ id: f.uuid, name: f.name.de }));
        setIsLoading(false);
        handleRegionName(dataLands, dataRegions);
        setRegions(dataRegions);
        setLands({
          data: dataLands,
          totalPage: res.data.total || 0,
        });

        return dataLands;
      }
      return [];
    } catch (error) {
      setIsLoading(false);
      setRegions([]);
      setLands({
        data: [],
        totalPage: 0,
      });
    }
  };

  const onSearch = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchLandsData(search);
  };

  const loadData = async () => fetchLandsData(search);

  useEffect(() => {
    if (typeof page === 'number') {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const handleRegionName = (land: any, regionList = []) => {
    if (land && regionList) {
      land.forEach((l: any) => {
        const selectedRegion: any = (regionList || regions).find((r: any) => r.id === l.region_id);
        l.region_name = selectedRegion ? selectedRegion.name : null;
      });
    }
  };

  const tableColumns = [
    { field: 'serial', header: t('txt_serial'), width: '5%', align: 'left' },
    {
      field: 'name',
      header: t('txt_name'),
      width: '15%',
      align: 'left',
      bodyTemplate: (rowData: any) => formatMultiLangField(rowData?.name, getLanguage()),
    },
    {
      field: 'code',
      header: t('txt_identifier'),
      width: '200px',
      align: 'center',
    },
    {
      field: 'status',
      header: t('txt_status'),
      width: '200px',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        const statusItem = STATUS_LIST_2.find((item: any) => item.code === rowData.status);
        return <WiStatus item={statusItem}></WiStatus>;
      },
    },
    {
      field: 'land_type',
      header: t('txt_type'),
      width: '10%',
      align: 'center',
      bodyTemplate: (rowData: any) =>
        rowData.land_type === 'public' ? globalTrans('area_type.txt_public') : globalTrans('area_type.txt_campaign_only'),
    },
    {
      field: 'region_id',
      header: t('txt_region'),
      width: '15%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return permissions.canViewRegion ? (
          <a className="table-ref-link-cell" href={`/regions/${rowData.region_id}`}>
            {rowData.region_name}
          </a>
        ) : (
          <span>{rowData?.region_name}</span>
        );
      },
    },
    {
      field: 'protected_size',
      header: t('txt_progress'),
      width: '21%',
      align: 'left',
      bodyTemplate: WiProgress('calculator', 'size', 'available_size'),
    },
    {
      field: 'no_donations',
      header: t('txt_donations'),
      width: '9%',
      align: 'right',
      bodyTemplate: (rowData: any) => formatIntergerV2(rowData['no_donations'], getLanguage()),
    },
    {
      field: 'created_at',
      header: `${t('txt_created')}`,
      width: '15%',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.created_at ? formatDateTimeV2(rowData.created_at, getLanguage()) : '-'}</div>;
      },
      align: 'center',
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-open',
        url: `/areas/${rowData?.uuid}`,
      },
      // {
      //   label: t('txt_active'),
      //   icon: "wi-active",
      //   hidden: !permissions.canUpdateArea
      // },
      // {
      //   label: t('txt_inactive'),
      //   icon: "wi-inactive",
      //   hidden: !permissions.canUpdateArea
      // },
      // {
      //   label: t('txt_complete'),
      //   icon: "wi-complete",
      //   hidden: !permissions.canUpdateArea
      // },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout title={t('txt_title')} className="land-manager">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearch}
            />
            {/* <div className="filter-btn">
                  <div
                    className="filter-btn-content p-8 ml-16"
                  // onClick={() => setIsShowSidebarFilter(true)}
                  >
                    <FilterSvg ></FilterSvg>
                  </div>
                </div> */}
          </div>
          <Link className="wi-primary-button-v2" to="/areas/create" hidden={!permissions.canCreateArea}>
            <div className="wi-btn-label">
              {t('txt_btn_add')} <PlusSvg className="icon-svg"></PlusSvg>
            </div>
          </Link>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={lands.data}
          filters={search}
          globalFilterFields={['name', 'code']}
          emptyMessage={t('txt_no_records')}
          scrollable
          scrollHeight="calc(100vh - 166px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={lands.totalPage} onPageChange={onBasicPageChange} />
      </div>
    </Layout>
  );
};

export default LandManagerComponent;
