import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { onExportMultipleDonations } from '../../../../utils/donation.util';
import { Checkbox } from 'primereact/checkbox';
import { LIST_FIELD_EXPORT } from '../../../../utils/utils';
import './export-donation-dialog.scss';
import { WIListBox } from '../../../common';

const ExportDonationDialogComponent = (props: any) => {
  const { visible, onHide, selectedDonations } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [isHideHeader, setIsHideHeader] = useState<boolean>(true);
  const [isAllFields, setIsAllFields] = useState<boolean>(true);
  const [getAllDonations, setGetAllDonations] = useState<boolean>(false);
  const toast = useRef(null);
  const [listFields, setListFields] = useState<any[]>(LIST_FIELD_EXPORT);

  const handleCheckedAllFields = (e: any) => {
    setIsAllFields(e.checked);
    if (e.checked) {
      const fieldValues = listFields.map(field => field.code);
      setSelectedValues(fieldValues);
    } else {
      setSelectedValues([]);
    }
  };

  useEffect(() => {
    if (isAllFields) {
      const fieldValues = listFields.map(field => field.code);
      setSelectedValues(fieldValues);
    }
  }, [isAllFields]);

  const exportDonationFunc = () => {
    setIsLoading(true);
    const ids = selectedDonations.map((donation: any) => donation.uuid);
    const fields: any = [];
    selectedValues.forEach(value => {
      const item = listFields.find(field => field.code === value);
      fields.push({
        label: item.name,
        value: item.code,
      });
    });

    onExportMultipleDonations(ids, fields, getAllDonations, isHideHeader, toast).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Export ${selectedDonations ? selectedDonations.length : ''}  Donation${selectedDonations && selectedDonations.length > 1 ? 's' : ''}`}
        visible={visible}
        style={{ width: '710px' }}
        modal
        className="wi-dialog wi-export-dialog"
        onHide={onHide}
      >
        <div className="dialog-export-container">
          <div className="row">
            <div className="col-md-12">
              <div className="flex align-items-center mb-2">
                <Checkbox inputId="option1" name="is_all_donation" onChange={e => setGetAllDonations(e.checked)} checked={getAllDonations} />
                <label htmlFor="option1" className="ml-2 label-checkbox">
                  Export latest donations (Max: 30000)
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="flex align-items-center mb-2">
                <Checkbox inputId="option2" name="include_header" onChange={e => setIsHideHeader(e.checked)} checked={isHideHeader} />
                <label htmlFor="option2" className="ml-2 label-checkbox">
                  Include header
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="flex align-items-center">
                <Checkbox inputId="option3" name="is_all_field" onChange={handleCheckedAllFields} checked={isAllFields} />
                <label htmlFor="option3" className="ml-2 label-checkbox">
                  All fields
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-2">
              <label className="label">Export Fields:</label>
              <WIListBox
                selectedValues={selectedValues}
                items={listFields}
                setIsAllOptions={setIsAllFields}
                onHandlerChange={(e: any) => setSelectedValues(e.value)}
                optionLabel="name"
                optionValue="code"
                style={{ maxHeight: '370px' }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              type="submit"
              label={'Download'}
              icon="pi pi-download"
              iconPos="right"
              loading={isLoading}
              className="wi-primary-button wi-button-medium"
              onClick={exportDonationFunc}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ExportDonationDialogComponent;
