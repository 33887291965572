import * as React from 'react';
import './copy-right.scss';

const CopyRightFooter = (props: any) => {
  const { customCopyRight } = props;

  return (
    <div className={`copy-right ${customCopyRight ? 'custom-copy-class' : ''}`}>
      made with <span className="icon-heart"> &#10084;</span> {`in vietnam & germany`}
    </div>
  );
};

export default CopyRightFooter;
