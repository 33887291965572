import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { WIFormDropdownV2, WIFormInputTextV2 } from '../../../components_v2/common/form-fields';
import moment from 'moment';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import CountryService from '../../../services/country-service';
import CampaignCouponManagerAPI from '../../../services/campaignCouponManager';
import { CampaignManagerAPI } from '../../../services';
import { generateLinkDownLoad } from '../../../utils/logic';
import { useLayoutV2 } from '../../../context/LayoutProvider';
import { CURRENCY_CODES } from '../../../utils/constants';

/**
 * Type of "Coupon Receipt Download" form
 */
type CouponReceiptDownload = {
  name: string;
  country: string;
  city: string;
  address: string;
  postal_code: string;
  donation_date: Date;
  receipt_date: Date;
};

const DownloadReceiptForm = (props: any) => {
  const { onHide, coupon, fetchCallBack } = props;
  const formikRef = useRef<any>(null);
  const { t } = useTranslation('language', { keyPrefix: 'coupon_receipt_download' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setErrorProgress, setSuccessProgress, setLoadingProgress } = useLayoutV2();

  /**
   * Submit form of downloading Coupon Receipt
   */
  const onSubmit = async () => {
    try {
      await formikRef?.current?.validateForm();
      const { values, isValid } = formikRef?.current;
      // If receipt info is valid, start downloading the receipt
      if (formikRef && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));
        // Format "Donation Date" and "Receipt Data"
        const receiptInfo = _.cloneDeep(values);
        const donationDate = new Date(receiptInfo.donation_date);
        const receiptDate = new Date(receiptInfo.receipt_date);
        receiptInfo.donation_date = moment.utc([donationDate.getFullYear(), donationDate.getMonth(), donationDate.getDate()]);
        receiptInfo.receipt_date = moment.utc([receiptDate.getFullYear(), receiptDate.getMonth(), receiptDate.getDate()]);
        // Download Coupon Receipt
        const response = await CampaignCouponManagerAPI.downloadCouponReceipt(coupon?.uuid, receiptInfo);
        // Convert Base64 Receipt to Object URL for downloading
        if (response?.status === 200) {
          const byteCharacters = atob(response?.data?.result);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const receiptObjectUrl = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf; charset=utf-8' }));
          // Generate downloading file Name
          const currencyCode = coupon?.currency_code?.toLowerCase();
          let fileName = '';
          if (currencyCode === CURRENCY_CODES.EUR || currencyCode === CURRENCY_CODES.CHF) {
            fileName += 'Spendenquittung';
          } else {
            fileName += 'Donation_Receipt';
          }
          if (coupon?.campaign_id) {
            const campaignRes = await CampaignManagerAPI.getCampaign(coupon.campaign_id);
            if (campaignRes?.status === 200 && campaignRes?.data?.record?.partner_name) {
              fileName += `_${campaignRes?.data?.record?.partner_name}`;
            }
          }
          fileName += `_${receiptInfo.donation_date.format('YYYY-MM-DD')}.pdf`;
          // Start downloading the receipt
          generateLinkDownLoad(fileName, receiptObjectUrl);
          setSuccessProgress(t('txt_download_receipt_success'));
          fetchCallBack();
          onHide();
        } else {
          throw new Error('txt_generate_receipt_failed');
        }
      }
    } catch (ex) {
      console.log(ex);
      setErrorProgress(errorTrans('txt_generate_receipt_failed'));
    }
  };

  const [countries, setCountries] = useState([]);

  const DEFAULT_RECEIPT_INFO = {
    name: '',
    country: '',
    city: '',
    address: '',
    postal_code: '',
    donation_date: moment().isValid() ? moment().toDate() : new Date(),
    receipt_date: moment().isValid() ? moment().toDate() : new Date(),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(errorTrans('txt_required')),
    country: Yup.string().required(errorTrans('txt_required')),
    city: Yup.string().required(errorTrans('txt_required')),
    address: Yup.string().required(errorTrans('txt_required')),
    postal_code: Yup.string().required(errorTrans('txt_required')),
    donation_date: Yup.date().required(errorTrans('txt_required')),
    receipt_date: Yup.date().required(errorTrans('txt_required')).min(Yup.ref('donation_date'), errorTrans('txt_error_date')),
  });

  const initialFormValues = () => {
    let initialReceiptInfo = DEFAULT_RECEIPT_INFO;
    if (coupon?.receipt_info) {
      const receiptInfo = JSON.parse(coupon.receipt_info);
      initialReceiptInfo = _.pick(receiptInfo, ['name', 'country', 'city', 'address', 'postal_code', 'donation_date', 'receipt_date']);
      initialReceiptInfo.donation_date = moment(receiptInfo.donation_date).toDate();
      initialReceiptInfo.receipt_date = moment(receiptInfo.receipt_date).toDate();
    }
    return initialReceiptInfo;
  };

  const fetchCountryData = async () => {
    try {
      const res = await CountryService.getCountries();
      if (res.status === 200 && res.data.data) {
        setCountries(res.data.data);
      } else {
        setCountries([]);
      }
    } catch (error) {
      setCountries([]);
    }
  };

  /**
   * Check if the form has been fulfilled fully for downloading the Receipt
   * @param formValues: Form values (including: name, country, city, address...)
   * @returns TRUE: Fully fulfilled / FALSE
   */
  const isReadyForDownload = (formValues: CouponReceiptDownload) => {
    if (!formValues) return false;
    if (Object.values(formValues).findIndex(value => !value) >= 0) return false;
    return true;
  };

  useEffect(() => {
    fetchCountryData();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={initialFormValues()}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {}}
    >
      {({ values, handleSubmit, handleChange, setFieldValue, handleBlur }) => (
        <form onSubmit={handleSubmit}>
          <div className="row-content">
            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2 title={t('txt_name')} name="name" isRequired={true} />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormDropdownV2
                  title={t('txt_country')}
                  name="country"
                  isRequired={true}
                  optionLabel="name"
                  optionValue="ISO2"
                  appendTo="self"
                  options={countries}
                  filter
                  filterBy="name"
                  filterPlaceholder={t('txt_search')}
                  onChange={(item: any) => {
                    setFieldValue('country', item.value, false);
                  }}
                />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2 title={t('txt_city')} name="city" isRequired={true} />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2 title={t('txt_address')} name="address" isRequired={true} />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2 title={t('txt_postal_code')} name="postal_code" isRequired={true} />
              </div>
            </div>

            <div className="row mb-24">
              <div className="col-md-6">
                <div className="element-form">
                  <label className="label mb-12">
                    {t('txt_donation_date')} <span className="required-label ml-4">*</span>
                  </label>
                  <Calendar
                    id="basic"
                    name="donation_date"
                    className="d-flex"
                    panelClassName="expire-calendar"
                    value={values.donation_date}
                    onBlur={handleBlur}
                    onChange={item => {
                      setFieldValue('donation_date', item.value, false);
                    }}
                    dateFormat="dd.mm.yy"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="element-form">
                  <label className="label mb-12">
                    {t('txt_receipt_date')} <span className="required-label ml-4">*</span>
                  </label>
                  <Calendar
                    id="basic"
                    name="receipt_date"
                    className="d-flex"
                    panelClassName="expire-calendar"
                    value={values.receipt_date}
                    onBlur={handleBlur}
                    onChange={item => {
                      setFieldValue('receipt_date', item.value, false);
                    }}
                    dateFormat="dd.mm.yy"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between gap-24">
                  <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                  <Button
                    className="wi-primary-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t('txt_download')}`}
                    onClick={() => onSubmit()}
                    disabled={!isReadyForDownload(values)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default DownloadReceiptForm;
