import * as React from 'react';
import { env } from '../../../../configs';
import './draggable-component.scss';

const DraggableComponent = (props: any) => {
  const { id: key, name, thumbnail, code, blockId, onDragStart, dropped, draggable = true, onDragEnd } = props;

  return (
    <div className="draggable-component" key={key} draggable={draggable} onDragStart={ev => onDragStart(ev, name, blockId)} onDragEnd={onDragEnd}>
      <h1 className="title-draggable-component">{name}</h1>
      <img className="cta-thumbnail" src={`${env.PUBLIC_IMAGE_URL}/${thumbnail}`} alt={name} />
    </div>
  );
};
export default DraggableComponent;
