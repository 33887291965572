import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import { env } from '../../../../configs';
import { ErrorMessage, useFormikContext } from 'formik';
import { ContentStyleForSingleLine, Plugins, ToolbarsSingleLine } from '../../../common/header-editor/header-editor';
import _ from 'lodash';

export function WiTextEditor1Line(props: any) {
  const { name, values, disabled } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const editorRef = useRef<any>(null);

  const onRichTextKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  return (
    <WIFormElement {...props}>
      <div className={`editor-wrapper ${disabled && 'editor-disabled'}`}>
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          scriptLoading={{ async: true }}
          apiKey={env.EDITOR_API_KEY}
          init={{
            height: 100,
            menubar: false,
            statusbar: false,
            resize: false,
            plugins: Plugins,
            toolbar: ToolbarsSingleLine,
            branding: false,
            paste_as_text: true,
            content_style: ContentStyleForSingleLine,
            paste_data_images: false,
            setup: function (editor) {
              editor.ui.registry.addButton('removeContentButton', {
                icon: 'remove',
                tooltip: 'Remove content',
                onAction: function (_) {
                  editor.setContent('');
                },
              });
            },
          }}
          onKeyDown={e => {
            onRichTextKeyDown(e);
          }}
          id={name}
          textareaName={name}
          onEditorChange={(newValue, editor) => {
            setFieldValue(name, newValue, true);
          }}
          onBlur={() => setFieldTouched(name)}
          value={_.get(values, name)}
          disabled={disabled}
        />
      </div>

      <ErrorMessage name={name} render={(msg: any) => <div className="error">{msg}</div>} />
    </WIFormElement>
  );
}
