import { useRef, useState } from 'react';
import React from 'react';
import './add-goal-form.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ImportTypeList, TypeLandCode } from '../../../../utils/utils';
import { SelectButton } from 'primereact/selectbutton';
import { GoalManagerAPI } from '../../../../services';
import { env } from '../../../../configs';
import _ from 'lodash';
import moment from 'moment';
import { formatImageFileURL, getThumbnailFullpath } from '../../../../utils/mediaUtils';
import { Button } from 'primereact/button';
import { RichTextEditorV2 } from '../../../../components_v2/common/rich-text-editor-v2/rich-text-editor-v2';
import { useTranslation } from 'react-i18next';
import { WIFormDropdownV2, WIFormImageSelectorV2, WIFormInputNumberV2, WIFormInputTextV2 } from '../../../../components_v2/common/form-fields';
import { useLayoutV2 } from '../../../../context/LayoutProvider';

const partner_none: any = [
  {
    name: 'NONE',
    uuid: '',
  },
];

const PartnerItem = (props: any) => {
  const { option } = props;
  return (
    <div className="global-page-item">
      <div className="global-page-picture">
        {option.partner_logo ? (
          <img
            src={`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.partner_logo))}?v=${moment(option.changed).unix()}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option.partner_logo)}`;
            }}
            alt={option.name}
          />
        ) : (
          ''
        )}
      </div>
      <div className="page-details">
        <div className="global-page-name">{option.name}</div>
        <div className="global-page-position">{option.code}</div>
      </div>
    </div>
  );
};

const AddGoalFormComponent = (props: any) => {
  const { onHide, fetchCallBack, goalData, regions, campaigns, partners } = props;
  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledPartner, setIsDisabledPartner] = useState(goalData?.goal_type === TypeLandCode.campaign);
  const { t } = useTranslation('language', { keyPrefix: 'goals_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();

  const onAddGoal = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans(`txt_loading`));
        let valuesData = {
          ...values,
          donation_goal: +values.donation_goal,
        };
        delete valuesData.area_selected;

        if (goalData) {
          let updateRes = await GoalManagerAPI.updateGoal(goalData.id, valuesData);
          if (updateRes?.status === 200 && updateRes?.data) {
            setSuccessProgress(t('txt_update_goal_success'));
            onHide();

            fetchCallBack(updateRes.data);
            return;
          }
        } else {
          let createRes = await GoalManagerAPI.generateGoal(valuesData);
          if (createRes?.status === 200 && createRes?.data) {
            setSuccessProgress(t('txt_add_goal_scuccess'));
            onHide();

            fetchCallBack(createRes.data);
            return;
          }
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      if (error && error.response.data.errors === 'Duplicate Goal Code') {
        setErrorProgress(error.response.data.errors);
      } else {
        setErrorProgress(errorTrans(`txt_check_goal_failed`));
      }
      setIsLoading(false);
    }
  };

  const initialFormValues = () => {
    let initialGoal = {
      goal_type: TypeLandCode.region,
      source_id: '',
      area_selected: null,
      name: '',
      partner_id: '',
      code: '',
      donation_goal: 0,
      description: '',
      cover_photo: '',
    };

    if (goalData) {
      initialGoal.goal_type = goalData.goal_type || TypeLandCode.region;
      initialGoal.source_id = goalData.source_id ? goalData.source_id : '';
      initialGoal.area_selected = null;
      initialGoal.name = goalData.name ? goalData.name : '';
      initialGoal.partner_id = goalData.partner_id ? goalData.partner_id : '';
      initialGoal.code = goalData.code ? goalData.code : '';
      initialGoal.donation_goal = goalData.donation_goal || 0;
      initialGoal.description = goalData.description ? goalData.description : '';
      initialGoal.cover_photo = goalData.cover_photo ? goalData.cover_photo : '';
    }

    return initialGoal;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required(errorTrans('txt_required')),
    source_id: Yup.string().nullable().required(errorTrans('txt_required')),
    code: Yup.string().nullable().required(errorTrans('txt_required')),
    cover_photo: Yup.string().nullable().required(errorTrans('txt_required')),
    description: Yup.string().nullable().required(errorTrans('txt_required')),
    donation_goal: Yup.number()
      .nullable()
      .moreThan(0, errorTrans('txt_value_min'))
      .required(errorTrans('txt_required'))
      .test('is-valid-amount', errorTrans('txt_goal_must_available'), function (value: any) {
        // @ts-ignore: Object is possibly 'null'.
        const available_size = parseInt(formikRef?.current?.values?.area_selected?.available_size)
          ? // @ts-ignore: Object is possibly 'null'.
            parseInt(formikRef?.current?.values?.area_selected?.available_size)
          : goalData?.available_size;
        return value <= available_size;
      }),
  });

  const formatOptionTemplate = (option: any, available_size: any) => {
    return (
      <div>
        {option.name.de} - {t('txt_available_size')} [{parseInt(available_size)?.toLocaleString('de-DE')} m<sup>2</sup>]{' '}
      </div>
    );
  };

  const selectedOptionTemplate = (option: any, values: any, available_size: any, props: any) => {
    if (available_size < values.custom_area) {
      values.donation_type === 'land' ? (values.land_id = '') : (values.campaign_id = '');
      option = null;
    }

    return option ? formatOptionTemplate(option, available_size) : <span>{props.placeholder}</span>;
  };

  const getPartnerList = (partners: any[]) => {
    return partners ? partner_none.concat(_.sortBy(partners, item => item.name?.toLowerCase())) : [];
  };

  const formatString = (inputString: string) => {
    const formattedString = inputString.replace(/[^a-zA-Z0-9]/g, '');
    return formattedString.toLowerCase();
  };

  return (
    <>
      <div className="add-goal-form">
        <div className="add-goal-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({ values, errors, touched, dirty, isValid, handleBlur, handleSubmit, setFieldValue, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-12 mb-24">
                      <div className="element-form">
                        <label className="label pb-16">
                          {' '}
                          {t('txt_name')} <span className="required-label ml-4"> *</span>
                        </label>
                        <RichTextEditorV2 name="name" isMultipleLine={true} isRequired={true} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-24">
                      <div className="element-form">
                        <label className="label pb-16">
                          {t('txt_type')}
                          <span className="required-label"> *</span>
                        </label>
                        <SelectButton
                          id="goal_type"
                          name="goal_type"
                          value={values.goal_type}
                          className="wi-selectbutton wi-selectbutton-two"
                          options={ImportTypeList}
                          onChange={(item: any) => {
                            if (item.value) {
                              setFieldValue('goal_type', item.value, true);
                              setIsDisabledPartner(item.value === TypeLandCode.campaign);
                              setFieldValue('source_id', null, true);
                            }
                          }}
                          optionLabel="name"
                          optionValue="code"
                          disabled={goalData?.total_area > 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-24">
                      <div className="element-form">
                        <WIFormDropdownV2
                          name="partner_id"
                          title={t('txt_partner')}
                          isRequired={true}
                          optionLabel="name"
                          optionValue="uuid"
                          appendTo="self"
                          itemTemplate={(option: any) => <PartnerItem option={option}></PartnerItem>}
                          options={getPartnerList(partners)}
                          onChange={(item: any) => {
                            setFieldValue('partner_id', item.value, false);
                          }}
                          filter
                          filterBy={['name', 'code'].join(',')}
                          disabled={isDisabledPartner || goalData?.total_area > 0}
                        />
                        <div className="helper-text">
                          <i className="fa-solid fa-circle-info"></i> {t('txt_show_only')} <b className="helper-bold">{t('txt_active')}</b>{' '}
                          {t('txt_partners')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-24">
                      <div className="element-form">
                        <WIFormDropdownV2
                          name="source_id"
                          title={t(values.goal_type === TypeLandCode.region ? 'txt_region' : 'txt_campaign')}
                          isRequired={true}
                          optionLabel={`name.de`}
                          optionValue="uuid"
                          appendTo="self"
                          itemTemplate={(option: any) => {
                            const available_size = values.goal_type === TypeLandCode.region ? option?.available_size : option?.land?.available_size;
                            return formatOptionTemplate(option, available_size);
                          }}
                          valueTemplate={(option: any, props: any) => {
                            const available_size = values.goal_type === TypeLandCode.region ? option?.available_size : option?.land?.available_size;
                            return selectedOptionTemplate(option, values, available_size, props);
                          }}
                          options={values.goal_type === TypeLandCode.region ? regions : campaigns || []}
                          onChange={(item: any) => {
                            const area_item_selected =
                              values.goal_type === TypeLandCode.region
                                ? regions.find((region: any) => region.uuid === item.value)
                                : campaigns.find((campaign: any) => campaign.uuid === item.value);
                            setFieldValue('source_id', item.value, true);
                            setFieldValue(
                              'area_selected',
                              values.goal_type === TypeLandCode.region ? area_item_selected : area_item_selected.land,
                              true,
                            );
                            if (values.goal_type === TypeLandCode.campaign) {
                              setFieldValue('partner_id', area_item_selected.partner_id, true);
                            }
                          }}
                          disabled={goalData?.total_area > 0}
                          filter
                          filterBy="name,name.de,code,identifier"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-24">
                      <div className="element-form">
                        <WIFormInputTextV2 title={t('txt_code')} name="code" isRequired={true} maxLength={20} disabled={goalData} />
                      </div>
                    </div>
                    <div className="col-md-6 mb-24">
                      <div className="element-form">
                        <WIFormInputNumberV2
                          title={t('txt_goal_m2')}
                          name="donation_goal"
                          useGrouping={true}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          locale="de-DE"
                          hideErrorWhenLoading={true}
                          isRequired={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-24">
                      <div className="element-form">
                        <label className="label pb-16">
                          {' '}
                          {t('txt_description')} <span className="required-label ml-4"> *</span>
                        </label>
                        <RichTextEditorV2 name="description" isMultipleLine={true} isRequired={true} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-24">
                      <div className="element-form">
                        <WIFormImageSelectorV2 isRequired={true} title={t('txt_picture')} name="cover_photo" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between gap-24">
                    <Button className="wi-danger-button-v2 h48 flex-1" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                    <Button
                      className="wi-primary-button-v2 h48 flex-1"
                      type="submit"
                      label={t(goalData ? 'txt_update' : 'txt_add')}
                      onClick={() => onAddGoal()}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddGoalFormComponent;
