import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Layout from '../../layout/layout';
import CreateRegionFormComponent from './create-region-form/create-region-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RegionManagerAPI } from '../../../services';

export const CreateRegionManager = () => {
  let { id } = useParams();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [region, setRegion] = useState<any>(null);

  const fetchRegionData = async (uuid: any) => {
    try {
      setIsLoading(true);
      const res = await RegionManagerAPI.getRegion(uuid);
      if (res && res.status === 200) {
        setRegion(res.data.record);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRegionData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout customLayout={true} customCopyRight={true}>
      <Toast ref={toast} />
      {isLoading && (
        <div className="loading-component">
          <ProgressSpinner />
        </div>
      )}
      <div className="d-flex flex-column w-100 text-left">
        <CreateRegionFormComponent region={region} toast={toast} id={id} fetchRegionData={fetchRegionData} />
      </div>
    </Layout>
  );
};

export default CreateRegionManager;
