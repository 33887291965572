import * as React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import crown from '../../../../assets/images/icons/crown.svg';
import building from '../../../../assets/images/icons/building.svg';
import { formatDateTimeV2, parseJSONtoObj } from '../../../../utils/logic';
import WiStatus from '../../../../components_v2/common/wi-status/wi-status';
import WIPaginator from '../../../../components_v2/common/wi-paginator/wi-paginator';
import { STATUS_LIST, USER_PARTIES, STATUS_CODE, PERMISSIONS_V2, WI_TENANT_ID } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { env } from '../../../../configs';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import MenuDot from '../../../../components_v2/common/menu-dot/menu-dot';
import { UserManagerAPI } from '../../../../services/v2';
import useAuth from '../../../../context/useAuth';

const UserTable = (props: any) => {
  const { users, isLoading, onBasicPageChange, paginator, partners, refreshData } = props;
  const { auth } = useAuth();

  const { getLanguage } = useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'system_users' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const permissions = {
    canViewUser: auth?.permissions?.includes(PERMISSIONS_V2.USER_VIEW),
    canUpdateUser: auth?.permissions?.includes(PERMISSIONS_V2.USER_UPDATE),
  };
  const renderUserColumn = (item: any) => {
    const config = item?.custom_config ? parseJSONtoObj(item?.custom_config) : {};
    return (
      <div className="d-flex align-items-center w-100">
        <div className="user-image mr-24">
          <div className="user-avatar-with-role">
            {item.avatar ? (
              <img className="m-0 avatar" src={`${env.PUBLIC_UPLOAD_URL}/${item?.avatar}`} alt="logo user" loading="lazy" />
            ) : (
              <div className="user-avatar-default" style={{ background: config?.avartar_color }}>
                {item.first_name?.charAt(0)?.toUpperCase()}
                {item.last_name?.charAt(0)?.toUpperCase()}
              </div>
            )}
            <div className="user-role">
              <img className="m-0" src={item.tenant_id === WI_TENANT_ID ? crown : building} alt="badge" loading="lazy" />
            </div>
          </div>
        </div>
        <div className="cell-info">
          <div className="cell-main-info pb-12">
            {item.first_name} {item.last_name} {item.display_name && `(${item.display_name})`}
          </div>
          <div className="cell-sub-info">{item.email}</div>
        </div>
      </div>
    );
  };

  const updateUserStatus = async (userData: any, status: string) => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      let res = await UserManagerAPI.updateUser({ ...userData, status }, userData.uuid);
      if (res?.status === 200 && res?.data) {
        setSuccessProgress(t('txt_updated_user_status_success'));
        refreshData();
      } else {
        throw new Error('txt_update_user_failed');
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.Message));
    }
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_edit'),
        icon: 'wi-open',
        url: `/users/${rowData?.uuid}`,
      },
      {
        label: t('txt_active'),
        icon: 'wi-active',
        hidden: !permissions.canUpdateUser || rowData?.status === STATUS_CODE.ACTIVE || rowData?.status === STATUS_CODE.NEW,
        command: () => updateUserStatus(rowData, STATUS_CODE.ACTIVE),
      },
      {
        label: t('txt_inactive'),
        icon: 'wi-inactive',
        hidden: !permissions.canUpdateUser || rowData?.status === STATUS_CODE.INACTIVE,
        command: () => updateUserStatus(rowData, STATUS_CODE.INACTIVE),
      },
      {
        label: t('txt_archive'),
        icon: 'wi-archive',
        hidden: !permissions.canUpdateUser || rowData?.status === STATUS_CODE.ARCHIVED,
        command: () => updateUserStatus(rowData, STATUS_CODE.ARCHIVED),
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const tableColumns = [
    {
      field: 'name',
      header: `${t('txt_name')}`,
      width: 'calc(80% - 534px)',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return renderUserColumn(rowData);
      },
    },
    {
      field: 'partner_name',
      header: `${t('txt_organisation')}`,
      width: '20%',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        return (
          <p className="m-0"> {rowData.party === USER_PARTIES.WILDERNESS_INTERNATIONAL ? t('txt_wilderness') : rowData?.partner_name || '-'} </p>
        );
      },
    },
    {
      field: 'status',
      header: `${t('txt_status')}`,
      width: '130px',
      bodyTemplate: (rowData: any) => {
        const statusItem = STATUS_LIST.find((item: any) => item.code === rowData.status);
        return <WiStatus item={statusItem}></WiStatus>;
      },
      align: 'center',
    },
    {
      field: 'last_login',
      header: `${t('txt_last_login')}`,
      width: '170px',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.last_login ? formatDateTimeV2(rowData.last_login, getLanguage()) : '-'}</div>;
      },
      align: 'center',
    },
    {
      field: 'created_at',
      header: `${t('txt_created')}`,
      width: '170px',
      bodyTemplate: (rowData: any) => {
        return <div>{rowData.created_at ? formatDateTimeV2(rowData.created_at, getLanguage()) : '-'}</div>;
      },
      align: 'center',
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
        }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  return (
    <div className="p-table-v2 pt-12 pl-24 pr-24 d-flex flex-column">
      <DataTable
        loading={isLoading}
        value={users.data}
        dataKey="id"
        emptyMessage={t('txt_no_records')}
        responsiveLayout="scroll"
        scrollable
        scrollHeight="calc(100vh - 214px)"
        scrollDirection="both"
      >
        {dynamicColumns}
        <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
      </DataTable>
      <WIPaginator first={paginator.first} rows={paginator.rows} totalRecords={users.totalPage} onPageChange={onBasicPageChange} />
    </div>
  );
};

export default UserTable;
