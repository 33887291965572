import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { Button } from 'primereact/button';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { generateLinkDownLoad } from '../../../utils/logic';
import { DonationManagerAPI } from '../../../services';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../../context/LayoutProvider';
import { LANGUAGE_CODES, LANGUAGE_LIST } from '../../../utils/constants';

const DownloadCertificateDialogComponent = (props: any) => {
  const { onHide, data, yearsDonation } = props;
  const { t } = useTranslation('language', { keyPrefix: 'subscription_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const { t: globalTrans } = useTranslation('language');

  const formikRef = useRef<any>(null);
  const [languageCode, setLanguageCode] = useState(LANGUAGE_CODES.GERMAN);
  const CERTIFICATE_DEFAULT_VALUE = {
    year_donation: moment().year().toString(),
  };

  const initialFormValues = () => {
    let initialReceipt = CERTIFICATE_DEFAULT_VALUE;
    return initialReceipt;
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    year_donation: Yup.string().required(errorTrans('txt_required')),
  });

  const generateCertificate = async (values: any) => {
    if (values) {
      try {
        setLoadingProgress(errorTrans('txt_loading'));
        let response: any;
        if (true) {
          response = await DonationManagerAPI.generateRecurringCertificatePDF(data?.id, languageCode, values?.year_donation);
          if (response && response.status === 200) {
            const fileName = languageCode === LANGUAGE_CODES.GERMAN ? `Urkunde_${data?.name}_DE` : `Certificate_${data?.name}_EN`;
            generateFileDownload(fileName, response.data.content);
            setSuccessProgress(errorTrans('txt_generate_success'));
          } else {
            throw new Error('txt_generate_failed');
          }
          onHide();
        }
      } catch (error: any) {
        setErrorProgress(errorTrans(error?.message || 'txt_generate_failed'));
      }
    }
  };

  const generateFileDownload = (filename: string, data: any) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const url = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf; charset=utf-8' }));

    generateLinkDownLoad(filename, url);
  };

  return (
    <div className="download-certificate-form">
      <div className="certificate-form-container">
        <Formik
          innerRef={formikRef}
          initialValues={initialFormValues()}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ values, errors, touched, dirty, isValid, handleBlur, handleSubmit, setFieldValue, handleChange }) => (
            <form>
              <div className="row-content">
                <div className="row">
                  <div className="col-md-12 language-container">
                    <div className="element-form">
                      <label className="label pb-16"> {t('txt_choose_language')} </label>
                      <SelectButton
                        className="wi-selectbutton wi-selectbutton-two"
                        name="language_option"
                        value={languageCode}
                        options={LANGUAGE_LIST.map((l: any) => ({ ...l, label: globalTrans(l.label) }))}
                        onChange={(item: any) => {
                          setLanguageCode(item.value);
                        }}
                        optionLabel="label"
                        optionValue="code"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-24">
                  <div className="col-md-12 years-container">
                    <div className="element-form">
                      <label className="label pb-16"> {t('txt_year_donation')} </label>
                      <Dropdown
                        className={'wi-years-dropdown'}
                        panelClassName="p-dropdown-panel"
                        name="year_donation"
                        value={values.year_donation}
                        options={yearsDonation}
                        onChange={(item: any) => {
                          setFieldValue('year_donation', item.value, false);
                          handleChange(item);
                        }}
                        appendTo="self"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-24 gap-24">
                  <Button type="button" className="wi-danger-button-v2 h48 flex-1" label={t('txt_cancel')} onClick={() => onHide()}></Button>
                  <Button
                    type="button"
                    label={t('txt_download')}
                    icon="pi pi-download"
                    disabled={!isValid}
                    onClick={() => generateCertificate(values)}
                    iconPos="right"
                    className="wi-primary-button-v2 h48 flex-1"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DownloadCertificateDialogComponent;
