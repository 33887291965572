import React from 'react';
import AnimateHeight from 'react-animate-height';
import { IComponent } from '../../interfaces/IComponent';
import DraggableComponent from '../draggable/draggable-component';

export const BlockCategory = (props: any) => {
  const { blockItem, blockKey, onBlockClick, onDragStart, onDragEnd } = props;

  return (
    <React.Fragment key={blockKey}>
      <div className="block-category">
        <div className="block-category-title">
          <i className={`pi pi-chevron-${blockItem.isOpen ? 'down' : 'up'}`} onClick={() => onBlockClick(blockKey)}>
            <label>{blockKey}</label>
          </i>
        </div>
        <AnimateHeight duration={300} height={blockItem.isOpen ? `auto` : 0}>
          <div className="block-item">
            {blockItem.values.map((component: IComponent, index: number) => {
              return (
                <DraggableComponent
                  key={`component-${index}`}
                  name={component.name}
                  blockId={component.uuid}
                  code={component.code}
                  thumbnail={component.thumbnail}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  dropped={true}
                  draggable={true}
                />
              );
            })}
            <div className="draggable-component empty-draggable" />
            <div className="draggable-component empty-draggable" />
            <div className="draggable-component empty-draggable" />
          </div>
        </AnimateHeight>
      </div>
    </React.Fragment>
  );
};

export const BlockCategories = (props: any) => {
  const { categories, setBlockCategory, onDragStart, onDragEnd } = props;

  //const title = ctaGroups[blockKey].typeTitle;
  const onBlockClick = (key: string) => {
    categories[key].isOpen = !categories[key].isOpen;
    setBlockCategory({ ...categories });
  };

  return (
    <>
      {categories &&
        Object.keys(categories).map(key => (
          <BlockCategory
            key={key}
            blockItem={categories[key]}
            blockKey={key}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onBlockClick={onBlockClick}
          />
        ))}
    </>
  );
};
