import _ from 'lodash';
import moment from 'moment';
import { env } from '../configs';
import { buildHeaderV2 } from './templatePrimaryHeader';

export const replaceParameter = (str: string, source: string, target: string) => {
  const regex = new RegExp(source, 'g');
  return str?.replace(regex, target);
};

export const buildTemplateHTML = (data: any, headHtml: string, selectedComponent?: any, lang = 'de') => `
  ${headHtml}
  <body class="small-scrollbar" ${selectedComponent ? `onload="smoothScroll(document.getElementById('${selectedComponent}'))"` : ''}>
    ${data
      .map((c: any, i: number) => {
        let content = c.template;
        if (i == 0) {
          content = buildHeaderV2(c, lang);
        } else {
          content = replace(c, lang);
        }

        return `
            <section id='${c.key}'
                    class="${selectedComponent ? (selectedComponent === `${c.key}` ? 'selected-component' : 'unselected-component') : ''}">
              ${content}
            </section>
          `;
      })
      .join('')}

    <script defer src="${env.LIB_THEME_URL}"></script>
    <script defer src="${env.LIB_CUSTOM_SELECT_URL}"></script>
    <script defer src="${env.LIB_SLIDERS_URL}"></script>
    <script defer src="${env.LIB_ACCORDION_URL}"></script>
    <script defer src="${env.LIB_MIN_MINIGAME_URL}"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/cms-press-list.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/wi-news.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/wi-partners.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/wi-regions.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/wi-education.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/tree-slider.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/wi-projekte.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/wi-wilderness-running.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/wi-medien.min.js"></script>
    <script defer src="${env.LIB_SLIM_URL}"></script>
    <script defer src="${env.LIB_POPPER_URL}"></script>
    <script defer src="${env.LIB_BOOTSTRAP_JS_URL}"></script>
    <script defer src="${env.REALTIME_JS_URL}"></script>
  </body>
`;
const getValueByLang = (value: any, lang = 'de') => {
  if (value && (_.isString(value) || _.isBoolean(value))) {
    return value;
  }

  if (value && _.isArray(value)) {
    return value;
  }

  return value && typeof value !== 'string' ? value[lang] : value;
};

const getImageValue = (data: string) => {
  if (data.startsWith('https://')) {
    return data;
  }
  return `${env.PUBLIC_UPLOAD_URL}/${data}?u=${moment().minute()}`;
};

const getFieldValue = (configuration: any, data: any) => {
  if (configuration && (configuration.type === 'imageField' || configuration.type === 'fileField' || configuration.type === 'videoField')) {
    if (!data) {
      return;
    }
    return getImageValue(data);
  }
  return data;
};

const isFieldNotEmpty = (data: any, configuration: any, lang = 'de') => {
  let is_notEmpty = !!data;
  if (!data) {
    return false;
  }

  if (_.isArray(data)) {
    return data.length > 0;
  }

  if (data) {
    if (typeof data === 'object') {
      is_notEmpty = !!data[`${lang}`];
      if (configuration.defaultValue) {
        is_notEmpty = is_notEmpty && data[`${lang}`] !== configuration.defaultValue[`${lang}`];
      }
    } else {
      is_notEmpty = is_notEmpty && data !== configuration.defaultValue;
    }
  }

  return is_notEmpty;
};

const getThumbnailFullpath = (fullpath: string) => {
  let result = fullpath;
  for (let type of ['.jpg', '.jpeg']) {
    result = result.replace(type, '_thumbnail.webp');
    result = result.replace(type.toUpperCase(), '_thumbnail.webp');
  }
  return result;
};

const processTemplateWithData = (template: any, data: any, configuration: any, lang: any, parametersKey: any) => {
  let formattedData = scan(data, configuration, lang);
  template = replaceParameter(template, parametersKey, typeof formattedData === 'boolean' ? formattedData : formattedData || '');

  if (configuration && configuration.type === 'imageField' && formattedData) {
    // Have Thumbnails
    if (formattedData.includes('.jpg') || formattedData.includes('.jpeg') || formattedData.includes('.JPG') || formattedData.includes('.JPEG')) {
      const resolutionUrl = getThumbnailFullpath(formattedData);
      template = replaceParameter(template, `${parametersKey.slice(0, -2)}.thumbnail}}`, resolutionUrl);
    }

    // Have Thumbnails
    if (formattedData.includes('.svg') || formattedData.includes('.png') || formattedData.includes('.SVG') || formattedData.includes('.PNG')) {
      template = replaceParameter(template, `${parametersKey.slice(0, -2)}.thumbnail}}`, formattedData);
    }
  }

  let is_notEmpty = isFieldNotEmpty(formattedData, configuration, lang);
  template = replaceParameter(template, `${parametersKey.slice(0, -2)}.is_not_empty}}`, `${is_notEmpty}`);
  template = replaceParameter(template, `${parametersKey.slice(0, -2)}.is_not_empty_shortcut}}`, is_notEmpty ? '' : 'false');

  return template;
};

const scan = (parameter: any, configuration: any, lang = 'de') => {
  let data = configuration?.type === 'toggleButtons' ? !!parameter : getValueByLang(parameter, lang);

  if (configuration && configuration.template && configuration.children && _.isArray(data)) {
    data = data
      .map((c: any) => {
        const { children, parameters } = configuration;
        let childTemplate = configuration.template;
        Object.keys(children).forEach(f => {
          childTemplate = loopHierarchyObject(childTemplate, c[f], children[f], lang, ['item', f]);
        });

        return childTemplate;
      })
      .join('');
  }

  return getFieldValue(configuration, data);
};

const loopHierarchyObject = (template: any, data: any, configuration: any, lang: any, keys: any) => {
  if (configuration && configuration.children && data && !_.isArray(data)) {
    Object.keys(configuration.children).forEach((item: string) => {
      template = loopHierarchyObject(template, data[item], configuration.children[item], lang, [...keys, item]);
    });
  } else {
    template = processTemplateWithData(template, data, configuration, lang, `{{${keys.join('.')}}}`);
  }

  return template;
};

const replace = (block: any, lang = 'de') => {
  let template = block.template;
  Object.keys(block.configuration).forEach(key => {
    let configuration = block.configuration[key];
    let data = block.parameters[key];
    if (configuration?.type === 'imageField' && !data) {
      data = block.parameters[key.replace('mobile_', '')];
    }

    if (configuration && ['headline_color', 'text_color'].includes(key) && !data) {
      data = '#FFFFFF';
    } else if (configuration && ['headline_size'].includes(key) && !data) {
      data = 'h3';
    }

    template = loopHierarchyObject(template, data, configuration, lang, ['parameters', key]);
  });

  return template;
};
