import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Layout from '../../layout/layout';
import { useTranslation } from 'react-i18next';
import ActionFormComponent from './components/action-form/action-form';
import UserDetailForm from './components/user-detail-form/user-detail-form';
import { USER_PARTIES, STATUS_CODE, WI_TENANT_ID, PERMISSIONS_V2 } from '../../utils/constants';
import { Sidebar } from 'primereact/sidebar';
import ChangeLog from './components/change-log/change-log';
import { UserManagerAPI } from '../../services/v2';
import { useNavigate, useParams } from 'react-router-dom';
import { useLayoutV2 } from '../../context/LayoutProvider';
import { parseJSONtoObj } from '../../utils/logic';
import useAuth from '../../context/useAuth';
import ChangePasswordForm from './components/change-password/change-password';

const logs = [
  {
    time: '2024-03-20T10:58:33.000Z',
    display_name: 'Huu',
    first_name: 'Huu',
    avatar: '',
    uuid: 'a8005171-0bab-43dd-8124-ff775ab7b644',
  },
  {
    time: '2024-03-20T10:58:33.000Z',
    display_name: 'Huu',
    avatar: '',
    uuid: 'a8005171-0bab-43dd-8124-ff775ab7b644',
  },
  {
    time: '2024-03-20T10:58:33.000Z',
    display_name: 'Huu',
    first_name: 'Huu',
    avatar: '',
    uuid: 'a8005171-0bab-43dd-8124-ff775ab7b644',
  },
  {
    time: '2024-03-20T10:58:33.000Z',
    display_name: 'Huu',
    first_name: 'Huu',
    avatar: '',
    uuid: 'a8005171-0bab-43dd-8124-ff775ab7b644',
  },
  {
    time: '2024-03-20T10:58:33.000Z',
    display_name: 'Huu',
    first_name: 'Huu',
    avatar: '',
    uuid: 'a8005171-0bab-43dd-8124-ff775ab7b644',
  },
  {
    time: '2024-03-20T10:58:33.000Z',
    display_name: 'Huu',
    first_name: 'Huu',
    avatar: '',
    uuid: 'a8005171-0bab-43dd-8124-ff775ab7b644',
  },
];

const UserDetails = (props: any) => {
  const { isProfileMode } = props;
  let { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  id = isProfileMode ? auth.uuid : id;
  // ========================================================================================
  const formikRef = useRef(null);
  const [user, setUser] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isShowChangeLog, setIsShowChangeLog] = useState(false);
  const [isShowPermissions, setIsShowPermissions] = useState(false);
  const { t } = useTranslation('language', { keyPrefix: 'system_user_detail' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress, setGlobalLanguage, setIsContentLoading } = useLayoutV2();

  const permissions = {
    canUpdateUser: auth?.permissions?.includes(PERMISSIONS_V2.USER_UPDATE),
  };
  const getUserDetails = async (loading = false) => {
    try {
      setIsContentLoading(loading);
      const res = await UserManagerAPI.getUser(id);
      if (res && res.status === 200) {
        const config = res.data?.result?.custom_config ? parseJSONtoObj(res.data?.result?.custom_config) : {};
        setUser({ ...res.data?.result, ...config });
        setIsEdit(false);
      } else if (res && res.status === 404) {
        navigate('/users');
      } else {
        throw new Error('txt_unknown_load_data_fail');
      }
    } catch (error: any) {
      setErrorProgress(error.message);
    } finally {
      setIsContentLoading(false);
    }
  };

  const isDisabledForm = () => {
    return user?.status !== STATUS_CODE.ACTIVE;
  };

  const isDisabledFiledForm = () => {
    return user?.status === STATUS_CODE.INACTIVE || user?.status === STATUS_CODE.ARCHIVED;
  };

  const actionBack = () => {
    setIsEdit(false);
    // @ts-ignore: Object is possibly 'null'.
    formikRef?.current?.resetForm();
  };

  const updateUserStatus = async (userData: any, status: string) => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      let res = await UserManagerAPI.updateUser({ ...userData, status }, id);
      if (res?.status === 200 && res?.data) {
        setSuccessProgress(t('txt_updated_user_status_success'));
        getUserDetails(false);
      } else {
        throw new Error('txt_update_user_failed');
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.Message));
    }
  };

  const onUpdateUser = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));

        const config = {
          language_code: values.language || 'en',
          avartar_color: user?.avartar_color,
        };

        let valueUser = {
          ...values,
          first_name: values.first_name?.trim(),
          last_name: values.last_name?.trim(),
          display_name: values.display_name?.trim(),
          tenant_id: values.party === USER_PARTIES.WILDERNESS_INTERNATIONAL ? WI_TENANT_ID : values.partner_id,
          status: user?.status,
          custom_config: JSON.stringify(config),
        };

        delete valueUser.email;
        delete valueUser.partner_id;

        let createRes = await UserManagerAPI.updateUser(valueUser, id);
        if (createRes?.status === 200 && createRes?.data) {
          if (isProfileMode) {
            setGlobalLanguage(values.language);
          }
          setSuccessProgress(t('txt_updated_user_success'));
          getUserDetails(false);
        } else {
          throw new Error('txt_update_user_failed');
        }
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.Message));
    }
  };

  useEffect(() => {
    getUserDetails(true);
    // getPermissions();
  }, []);

  const menuItems = [
    {
      label: `${t('txt_edit')}`,
      className: 'item-menu',
      icon: 'wi-edit',
      hidden: isDisabledFiledForm(),
      command: () => setIsEdit(true),
    },
    {
      label: `${t('txt_change_password')}`,
      className: 'item-menu',
      icon: 'wi-key',
      hidden: !isProfileMode,
      command: () => setIsChangePassword(true),
    },
    {
      label: t('txt_active'),
      icon: 'wi-active',
      hidden: !permissions.canUpdateUser || isProfileMode || user?.status === STATUS_CODE.ACTIVE || user?.status === STATUS_CODE.NEW,
      command: () => updateUserStatus(user, STATUS_CODE.ACTIVE),
    },
    {
      label: t('txt_inactive'),
      icon: 'wi-inactive',
      hidden: !permissions.canUpdateUser || isProfileMode || user?.status === STATUS_CODE.INACTIVE,
      command: () => updateUserStatus(user, STATUS_CODE.INACTIVE),
    },
    {
      label: t('txt_archive'),
      icon: 'wi-archive',
      hidden: !permissions.canUpdateUser || isProfileMode || user?.status === STATUS_CODE.ARCHIVED,
      command: () => updateUserStatus(user, STATUS_CODE.ARCHIVED),
    },
    {
      label: `${t('txt_permission')}`,
      icon: 'wi-lock',
      hidden: isProfileMode,
      // command: () => setIsShowPermissions(true)
    },
    {
      label: `${t('txt_change_log')}`,
      icon: 'wi-notebook',
      hidden: isProfileMode,
      command: () => setIsShowChangeLog(true),
    },
  ];

  return (
    <Layout title={isProfileMode ? t('txt_profile_detail') : t('txt_user_detail')}>
      <ActionFormComponent isEdit={isEdit} userData={user} onSubmit={() => onUpdateUser()} actionBack={actionBack} menuItems={menuItems} />
      <UserDetailForm
        userData={user}
        isProfileMode={isProfileMode}
        isEdit={isEdit}
        formikRef={formikRef}
        isDisabledForm={isDisabledForm}
        isDisabledFiledForm={isDisabledFiledForm}
      />
      <Sidebar
        visible={isShowPermissions}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '20em' }}
        onHide={() => setIsShowPermissions(false)}
      >
        <div className="sidebar-content">
          <div className="headline mt-24 mb-24">
            <h6 className="m-0">
              {t('txt_permission_max')} {user?.first_name}
            </h6>
          </div>
          <ChangeLog fetchCallBack={() => setIsShowPermissions(false)} data={logs} onHide={() => setIsShowPermissions(false)} />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowChangeLog}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '20em' }}
        onHide={() => setIsShowChangeLog(false)}
      >
        <div className="sidebar-content">
          <div className="headline mt-24 mb-24">
            <h6 className="m-0">{t('txt_change_log')}</h6>
          </div>
          <ChangeLog fetchCallBack={() => setIsShowChangeLog(false)} data={logs} onHide={() => setIsShowChangeLog(false)} />
        </div>
      </Sidebar>
      <Sidebar
        visible={isProfileMode && isChangePassword}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '500px' }}
        onHide={() => setIsChangePassword(false)}
      >
        <div className="sidebar-content">
          <div className="headline mt-24 mb-24">
            <h6 className="m-0">{t('txt_change_password')}</h6>
          </div>
          <ChangePasswordForm onHide={() => setIsChangePassword(false)} />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default UserDetails;

export const ProfileDetails = (props: any) => {
  return <UserDetails isProfileMode={true}></UserDetails>;
};
