import * as React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import './wi-confirm-password.scss';
import { Password } from 'primereact/password';
import { useTranslation } from 'react-i18next';

const WIConfirmPassword = (props: any) => {
  const {
    visible,
    onConfirm,
    onHide,
    password,
    errorPassword,
    onSetPassword,
    hideLabel = 'No, keep it',
    confirmLabel = 'Yes, delete it',
    className,
    maskClassName,
  } = props;
  const { t } = useTranslation('language', { keyPrefix: 'block_dialog' });

  const buildFooterTemplate = () => (
    <React.Fragment>
      <Button label={hideLabel} className={'wi-secondary-button-v2 flex-1 m-0'} onClick={onHide} />
      <Button label={confirmLabel} className="wi-primary-button-v2 flex-1 m-0" onClick={onConfirm} />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        modal
        header="Warning"
        className={'confirm-password-dialog-v2' + (className ? ` ${className}` : '')}
        maskClassName={maskClassName ? ` ${maskClassName}` : ''}
        visible={visible}
        style={{ width: '500px' }}
        footer={buildFooterTemplate}
        onHide={onHide}
      >
        <div className="confirmation-content">
          <div className="confirmation-container w-100">
            <div className="pass_text">
              <div className="password-filed">
                <label htmlFor="pass-word" className="text-bold pb-16">
                  {t('txt_password')}
                </label>
                <Password id="pass-word" className="wi-password-v2 w-100" toggleMask value={password} onChange={e => onSetPassword(e.target.value)} />
                {errorPassword && <div className="error">{t('txt_incorrect_password')}</div>}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default WIConfirmPassword;
