import { ErrorMessage, Field, useField, useFormikContext } from 'formik';
import React from 'react';
import { WIFormElement } from '../wi-form-element/wi-form-element';
import { InputSwitch } from 'primereact/inputswitch';
import './wi-toggle.scss';

export const WIToggle = (props: any) => {
  const { name, options } = props;

  const CustomTogle = (props: any) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = field;
    const { setValue } = helpers;
    const { handleChange, handleBlur } = useFormikContext();

    return (
      <InputSwitch
        className="wi-form-toggle-button"
        checked={value}
        trueValue={options && options.length === 2 ? options[0]?.value : true}
        falseValue={options && options.length === 2 ? options[1]?.value : false}
        onChange={(e: any) => setValue(e.value)}
        onBlur={handleBlur}
      />
    );
  };

  return (
    <WIFormElement {...props}>
      <Field key={name} id={name} name={name} as={CustomTogle} />
      <ErrorMessage name={name} render={(msg: any) => <div style={{ color: 'red' }}>{msg}</div>} />
    </WIFormElement>
  );
};
