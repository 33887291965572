import * as React from 'react';
import './create-land-form.scss';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { isValidForm, isValidateSpecialCharacter } from '../../../../utils/logic';
import { createEmptyLand, LandType } from './landType';
import * as Yup from 'yup';
import * as _ from 'lodash';
import LandManagerAPI from '../../../../services/landManager';
import useAuth from '../../../../context/useAuth';
import { LAND_TYPE_LIST, LAND_STATUS_LIST, PERMISSIONS_V2 } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { WIFormDropdownV2, WIFormInputNumberV2, WIFormInputTextV2, WIFormTextAreaV2 } from '../../../../components_v2/common/form-fields';
import ActionFormComponent from '../action-form/action-form';
import { useLayoutV2 } from '../../../../context/LayoutProvider';

const CreateLandFormComponent = (props: any) => {
  const { data, fetchLandData } = props;
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { auth } = useAuth();
  const { t } = useTranslation('language', { keyPrefix: 'area_details' });
  const [isEdit, setIsEdit] = useState(false);
  const { setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: globalTrans } = useTranslation('language');

  const permissions = {
    canUpdateArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_UPDATE),
  };

  const isDisabledFiledForm = () => {
    return data?.isShowCreate ? false : !isEdit;
  };

  const menuItems = [
    {
      label: t('txt_edit'),
      icon: 'wi-edit',
      command: () => setIsEdit(true),
    },
  ];

  const callCreateLandAPI = async (data: any) => {
    try {
      const res = await LandManagerAPI.createLand(data);
      if (res && res.status === 200) {
        if (res.data?.result?.error?.code) {
          setErrorProgress(errorTrans('txt_land_code_existed'));
        } else {
          const uuid = res.data?.result.id;
          setSuccessProgress(errorTrans('txt_generate_land_success'));
          return uuid;
        }
        return '';
      }
    } catch (error) {
      console.log(error);
    }
    setErrorProgress(errorTrans('txt_failed_generate_land'));
    return '';
  };

  const callUpdateLandAPI = async (uuid: string, data: any) => {
    try {
      const res = await LandManagerAPI.updateLand(uuid, data);
      if (res && res.status === 200) {
        setSuccessProgress(errorTrans('txt_update_land_success'));
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    setErrorProgress(errorTrans('txt_failed_update_land'));
    return false;
  };

  const onPressCreateLand = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    // @ts-ignore: Object is possibly 'null'.
    if (formikRef && isValidForm(!data.isShowCreate, formikRef.current.dirty, formikRef.current.isValid, true)) {
      //@ts-ignore: Object is possibly 'null'.
      const landData = formikRef?.current.values;
      delete landData['protected_size'];
      landData['size'] = Number(landData.size);
      landData['code'] = landData['code']?.toLowerCase();
      setLoadingProgress(errorTrans('txt_loading'));
      if (data.isShowCreate) {
        const uuid = await callCreateLandAPI(landData);
        if (uuid) {
          navigate(`/areas/${uuid}`);
        }
      } else {
        const isSuccess = await callUpdateLandAPI(data.selectedLand.uuid, landData);
        if (isSuccess) {
          await fetchLandData(data.selectedLand.uuid);
          setIsEdit(false);
        } else {
          //landData["land_logo"] = data.selectedLand.land_logo;
          return;
        }
      }
    }
  };
  const onInitValuesLand = (): LandType => {
    if (!data.isShowCreate && data.selectedLand) {
      let land = data.selectedLand;

      return {
        name: _.isString(land.name) ? land.name : land.name['de'],
        code: land.code,
        status: land.status,
        size: Number(land.size),
        amount_co2_conversion: land.amount_co2_conversion,
        amount_area_conversion: land.amount_area_conversion,
        legal_name: land.legal_name,
        protected_size: land.protected_size,
        region_id: land.region_id,
        land_type: getLandUseFor(land.land_type),
        note: land.note
      };
    }

    const newLand = createEmptyLand();

    return {
      ...newLand,
    };
  };

  const validationLandSchema = Yup.object().shape({
    name: Yup.string().required('Area name is required'),
    code: Yup.string().required('Area code is required'),
    status: Yup.string().required('Status is required'),
    amount_co2_conversion: Yup.object().shape({
      eur: Yup.number().nullable().required('eur is require'),
      cad: Yup.number().nullable().required('cad is require'),
      chf: Yup.number().nullable().required('chf is require'),
    }),
    amount_area_conversion: Yup.object().shape({
      eur: Yup.number().nullable().required('eur is require'),
      cad: Yup.number().nullable().required('cad is require'),
      chf: Yup.number().nullable().required('chf is require'),
    }),
    size: Yup.number().required('Size is require').min(1, 'Size is invalid'),
    region_id: Yup.string().required('Region is required'),
    land_type: Yup.string().required('Area Type is required'),
  });

  const actionBack = () => {
    setIsEdit(false);
    // @ts-ignore: Object is possibly 'null'.
    formikRef?.current?.resetForm();
  };

  const getLandUseFor = (code: string) => {
    let item = LAND_TYPE_LIST.find(f => f.code.toLowerCase() === code.toLowerCase());
    if (item) {
      return item.code;
    }

    return LAND_TYPE_LIST[0].code;
  };

  const commonItemTemplate = (option: any) => {
    return <div>{option?.name ? globalTrans(option?.name) : ''} </div>;
  };

  return (
    <>
      <ActionFormComponent isEdit={isEdit} data={data} onSubmit={() => onPressCreateLand()} actionBack={actionBack} menuItems={menuItems} />
      <div className="land-form">
        <div className="land-form-container">
          <div className="land-form-content">
            <Formik
              enableReinitialize={true}
              innerRef={formikRef}
              initialValues={onInitValuesLand()}
              validationSchema={validationLandSchema}
              onSubmit={(values, { setSubmitting }) => {
                //console.log("values >>>", values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row-content">
                    <h2 className="m-0 pt-24 land-text label">{t('txt_area_information')}</h2>
                    {data.isShowCreate && (
                      <div className="row mt-24 mb-24">
                        <div className="col-md-6">
                          <WIFormInputTextV2
                            name={`code`}
                            title={t('txt_identifier')}
                            isRequired={true}
                            maxLength={20}
                            onChange={(e: any) => {
                              const valueText = e.target.value;
                              if (isValidateSpecialCharacter(valueText)) {
                                handleChange(e);
                              }
                            }}
                            disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row mt-24 mb-24">
                      <div className="col-md-6">
                        <WIFormInputTextV2
                          name={`name`}
                          title={t('txt_area_name')}
                          isRequired={true}
                          maxLength={50}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                      <div className="col-md-6">
                        <WIFormDropdownV2
                          name="land_type"
                          title={t('txt_type')}
                          isRequired={true}
                          options={LAND_TYPE_LIST}
                          optionLabel="name"
                          optionValue="code"
                          valueTemplate={commonItemTemplate}
                          itemTemplate={commonItemTemplate}
                          appendTo="self"
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                    </div>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-6">
                        <WIFormDropdownV2
                          name="status"
                          title={t('txt_status')}
                          isRequired={true}
                          options={LAND_STATUS_LIST}
                          optionLabel="name"
                          optionValue="code"
                          valueTemplate={commonItemTemplate}
                          itemTemplate={commonItemTemplate}
                          appendTo="self"
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                      <div className="col-md-6">
                        <WIFormDropdownV2
                          name="region_id"
                          title={t('txt_regions')}
                          isRequired={true}
                          options={data.regions || []}
                          optionLabel="name"
                          optionValue="id"
                          appendTo="self"
                          filter
                          filterBy="name"
                          filterPlaceholder={t('txt_search')}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                    </div>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-6">
                        <WIFormInputNumberV2
                          name={`size`}
                          title={t('txt_size')}
                          isRequired={true}
                          useGrouping={true}
                          mode="decimal"
                          locale="de-DE"
                          minFractionDigits={0}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          hideErrorWhenLoading={true}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea || data.selectedLand.uuid}
                        />
                      </div>
                      <div className="col-md-6">
                        <WIFormInputTextV2
                          name={`legal_name`}
                          title={t('txt_legal_name')}
                          maxLength={100}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                    </div>
                    <div className="row mt-24 mb-32">
                      <div className="col-md-12">
                        <WIFormTextAreaV2 name={`note`} title={t('txt_note')} disabled={isDisabledFiledForm() || !permissions.canUpdateArea} />
                      </div>
                    </div>
                    <h2 className="m-0 pb-24 land-text label">{t('txt_amount_conversion')}</h2>
                    <p className="m-0 land-text">{t('txt_co2_kg')}</p>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-4">
                        <WIFormInputNumberV2
                          name={`amount_co2_conversion.eur`}
                          title={t('txt_eur')}
                          isRequired={true}
                          useGrouping={true}
                          mode="decimal"
                          locale="de-DE"
                          minFractionDigits={1}
                          maxFractionDigits={3}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          hideErrorWhenLoading={true}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                      <div className="col-md-4">
                        <WIFormInputNumberV2
                          name={`amount_co2_conversion.cad`}
                          title={t('txt_cad')}
                          isRequired={true}
                          useGrouping={true}
                          mode="decimal"
                          locale="de-DE"
                          minFractionDigits={1}
                          maxFractionDigits={3}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          hideErrorWhenLoading={true}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                      <div className="col-md-4">
                        <WIFormInputNumberV2
                          name={`amount_co2_conversion.chf`}
                          title={t('txt_chf')}
                          isRequired={true}
                          useGrouping={true}
                          mode="decimal"
                          locale="de-DE"
                          minFractionDigits={1}
                          maxFractionDigits={3}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          hideErrorWhenLoading={true}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                    </div>
                    <p className="m-0 land-text">{t('txt_area_m2')}</p>
                    <div className="row mt-24 mb-24">
                      <div className="col-md-4">
                        <WIFormInputNumberV2
                          name={`amount_area_conversion.eur`}
                          title={t('txt_eur')}
                          isRequired={true}
                          useGrouping={true}
                          mode="decimal"
                          locale="de-DE"
                          minFractionDigits={1}
                          maxFractionDigits={3}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          hideErrorWhenLoading={true}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                      <div className="col-md-4">
                        <WIFormInputNumberV2
                          name={`amount_area_conversion.cad`}
                          title={t('txt_cad')}
                          isRequired={true}
                          useGrouping={true}
                          mode="decimal"
                          locale="de-DE"
                          minFractionDigits={1}
                          maxFractionDigits={3}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          hideErrorWhenLoading={true}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                      <div className="col-md-4">
                        <WIFormInputNumberV2
                          name={`amount_area_conversion.chf`}
                          title={t('txt_chf')}
                          isRequired={true}
                          useGrouping={true}
                          mode="decimal"
                          locale="de-DE"
                          minFractionDigits={1}
                          maxFractionDigits={3}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                          }}
                          hideErrorWhenLoading={true}
                          disabled={isDisabledFiledForm() || !permissions.canUpdateArea}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLandFormComponent;
