import * as React from 'react';
import { memo } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import WIUploadMediaField from '../../../common/upload/wi-upload-media-field';
import { parseStringToInt } from '../../../../utils/logic';
import useAuth from '../../../../context/useAuth';
import { PERMISSIONS_V2 } from '../../../../utils/constants';

const EmailFormComponent = (props: any) => {
  const { values, errors, touched, handleBlur, setFieldValue } = props;
  const { auth } = useAuth();
  const permissions = {
    canCreateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_CREATE),
    canUpdateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_UPDATE),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
  };

  return (
    <>
      <div className="row row-content">
        <div className="col-md-6">
          <div className="element-form">
            <label className="label">
              Email template DE <span className="required-label">*</span>
            </label>
            <InputNumber
              className={`${touched.email_template_id?.de && errors.email_template_id?.de ? 'has-error' : ''}`}
              locale="de-DE"
              useGrouping={false}
              name="email_template_id.de"
              value={values.email_template_id?.de || 0}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
              onBlur={e => {
                const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                setFieldValue('email_template_id.de', value, true);
                setTimeout(() => {
                  handleBlur(e);
                }, 200);
              }}
            />
            <div className={`${touched.email_template_id?.de && errors.email_template_id?.de ? 'error' : ''}`}>
              {touched.email_template_id?.de && errors.email_template_id?.de}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="element-form">
            <label className="label">
              Email template EN <span className="required-label">*</span>
            </label>
            <InputNumber
              className={`${touched.email_template_id?.en && errors.email_template_id?.en ? 'has-error' : ''}`}
              locale="de-DE"
              useGrouping={false}
              name="email_template_id.en"
              value={values.email_template_id?.en || 0}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
              onBlur={e => {
                const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                setFieldValue('email_template_id.en', value, true);
                setTimeout(() => {
                  handleBlur(e);
                }, 200);
              }}
            />
            <div className={`${touched.email_template_id?.en && errors.email_template_id?.en ? 'error' : ''}`}>
              {touched.email_template_id?.en && errors.email_template_id?.en}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="element-form">
            <WIUploadMediaField
              type="file"
              hideLabelFileName={true}
              classImage="image-cert"
              className="img-frame-16-9"
              name="email_header_picture"
              label="Email header picture"
              required={true}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="element-form">
            <WIUploadMediaField
              type="file"
              hideLabelFileName={true}
              classImage="image-cert"
              className="img-frame-16-9"
              name="email_additional_picture"
              label="Email additional picture"
              required={true}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(EmailFormComponent);
