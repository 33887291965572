import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { useLayoutV2 } from '../../../context/LayoutProvider';
import { QuoteManagerAPI } from '../../../services';
import { WIFormImageSelectorV2, WIFormInputTextV2 } from '../../../components_v2/common/form-fields';
import { RichTextEditorV2 } from '../../../components_v2/common/rich-text-editor-v2/rich-text-editor-v2';

const QuoteFormComponent = (props: any) => {
  const { quote, fetchQuotesData, onHide } = props;
  const { setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t } = useTranslation('language', { keyPrefix: 'quote_manager' });
  const formikRef = useRef(null);

  const onSuccess = () => {
    fetchQuotesData();
    onHide();
  };

  const onSubmitQuoteForm = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));

        if (quote) {
          quote.quote.de = values.quote;
          quote.owner_name = values.owner_name.de;
          quote.owner_position.de = values.owner_position.de;

          if (quote.owner_picture !== values.owner_picture) {
            quote.owner_picture = values.owner_picture;
          } else {
            delete quote['owner_picture'];
            delete quote[`${quote['owner_picture']}_extension`];
          }

          const updateRes = await QuoteManagerAPI.updateQuote(quote);
          if (updateRes?.status === 200 && updateRes?.data) {
            setSuccessProgress(t('txt_update_quote_success'));
            onSuccess();
          } else {
            throw new Error('txt_failed_update_quote');
          }
        } else {
          const newData = {
            ...values,
            owner_position: { de: values.owner_position.de },
            quote: { de: values.quote.de },
          };

          let createRes = await QuoteManagerAPI.createQuote(newData);
          if (createRes?.status === 200 && createRes?.data) {
            setSuccessProgress(t('txt_generate_quote_success'));
            onSuccess();
          } else {
            throw new Error('txt_failed_generate_quote');
          }
        }
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  };

  const initialFormValues = () => {
    let initialValues = {
      owner_name: '',
      owner_picture: '',
      owner_position: { de: '' },
      quote: { de: '' },
    };

    if (quote) {
      initialValues.owner_name = quote?.category || initialValues.owner_name;
      initialValues.owner_picture = quote?.owner_picture || initialValues.owner_picture;
      initialValues.owner_position.de = quote?.owner_position?.de || initialValues.owner_position.de;
      initialValues.quote.de = quote?.quote?.de || initialValues.quote.de;
    }

    return initialValues;
  };

  const validationSchema = Yup.object().shape({
    quote: Yup.object().shape({
      de: Yup.string().required(errorTrans('txt_required')),
    }),
  });

  return (
    <div className="quote-form">
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues()}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="row-content">
              <div className="row mb-24">
                <div className="col-md-12">
                  <WIFormImageSelectorV2 isRequired={true} title={t('txt_owner_picture')} name="owner_picture" />
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-12">
                  <WIFormInputTextV2 name={`owner_name`} title={t('txt_owner_name')} isRequired={false} />
                </div>
              </div>

              <div className="row mb-24">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label pb-16"> {t('txt_owner_position')} </label>
                    <RichTextEditorV2 name="owner_position.de" isMultipleLine={false} isRequired={true} />
                  </div>
                </div>
              </div>

              <div className="row mb-24">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label pb-16">
                      {' '}
                      {t('txt_quote')} <span className="required-label ml-4"> *</span>
                    </label>
                    <RichTextEditorV2 name="quote.de" isMultipleLine={true} isRequired={true} />
                  </div>
                </div>
              </div>

              <div className="row mt-24">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between gap-24">
                    <Button className="wi-danger-button-v2 h48 flex-1" type="submit" label={`${t('txt_cancel')}`} onClick={() => onHide()} />
                    <Button
                      className="wi-primary-button-v2 h48 flex-1"
                      type="submit"
                      label={quote ? t('txt_update') : t('txt_create')}
                      onClick={() => onSubmitQuoteForm()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default QuoteFormComponent;
