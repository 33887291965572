import * as React from 'react';
import './snack-bar.scss';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

const SnackBarComponent = (props: any) => {
  const { selectedDonations, visible, clearDonations, downloadCertificate, downloadReceipt, exportDonation } = props;
  const canDownload = selectedDonations && selectedDonations.length <= 20;
  const canExport = selectedDonations && selectedDonations.length <= 1000;

  const { t } = useTranslation('language', { keyPrefix: 'donation_manager' });

  return (
    <div className={`snack-bar-component ${!visible && 'snack-bar-none'} `}>
      <div className="snack-bar-container">
        <div className="items-selected">
          <Button icon="pi pi-plus" onClick={clearDonations} />
          <span className="number-selected"> {selectedDonations ? selectedDonations.length : 0} </span> {t('txt_items')}
        </div>
        <div className="btns-container">
          <Button disabled={!canDownload} label={t('txt_download_certificate')} onClick={downloadCertificate} />
          <Button disabled={!canDownload} label={t('txt_download_receipt')} onClick={downloadReceipt} />
          <Button disabled={!canExport} label={t('txt_export_donations')} onClick={exportDonation} />
        </div>
      </div>
    </div>
  );
};

export default SnackBarComponent;
