import packageJson from '../../package.json';

console.log("App Version:", packageJson.version);
console.log('Run with env: ', process.env.REACT_APP_ENVIRONMENT);

// React reads environment variables from .env.local
// by default when running npm start locally.
// You can add or update environment variables in .env.local,
// but you need to restart the app for the changes to take effect.
// All variables must start with the REACT_APP_ prefix.
// Reference link: https://create-react-app.dev/docs/adding-custom-environment-variables

const DOMAIN = process.env.REACT_APP_DOMAIN || '';
const CMS_URL = `https://${DOMAIN}`;
// To the origin of the current webpage's URL, which includes the protocol (http/https), hostname, and port (if specified)
const ADMIN_URL = window.location.origin;
// We use the /api endpoint from the CMS URL to forward requests to the service URL.
const API_URL = `https://wild-service.${DOMAIN}`;
const DONATION_URL = `https://donate.${DOMAIN}`;
const LOGIN_URL = `/login`;

const AWS_CLIENT_ID = process.env.REACT_APP_AWS_CLIENT_ID || '';
const SIGNATURE = process.env.REACT_APP_SIGNATURE || '';
const AUTH_KEY = process.env.REACT_APP_AUTH_KEY || '';

const AWS_CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL || '';
const CTA_LIBRARY_URL = `${AWS_CLOUDFRONT_URL}/cms`;

const BASE_STRIPE_URL = process.env.REACT_APP_STRIPE_URL || '';
const STRIPE_URL = `${BASE_STRIPE_URL}/payments/`;
const PAYPAL_URL = process.env.REACT_APP_PAYPAL_URL || '';

const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY || '';
// const TINYMCE_API_KEY = 'n7uljqowueacugh4dp8bbnvxs76lap83q6zysofz2eb5qolm'; // Backup Key

export const env = {
  AXIOS_URL_DEV: 'http://localhost:4242',
  AXIOS_URL: API_URL,
  CLIENT_ID: AWS_CLIENT_ID,
  AUTH_KEY,
  SIGNATURE,
  CMS_URL,
  ADMIN_URL,
  CTA_LIBRARY_URL,
  DONATION_URL,
  LOGIN_URL,
  PUBLIC_IMAGE_URL: `${AWS_CLOUDFRONT_URL}/static-images`,
  PUBLIC_UPLOAD_URL: `${AWS_CLOUDFRONT_URL}/media`,
  EDITOR_API_KEY: TINYMCE_API_KEY,
  PAYPAL_URL,
  STRIPE_URL,
  BASE_STRIPE_URL,

  // CMS LIBRARY
  LIB_ABOVE_URL: `${CTA_LIBRARY_URL}/css/above_the_fold.min.css`,
  LIB_MAIN_URL: `${CTA_LIBRARY_URL}/css/main.min.css`,
  LIB_THEME_URL: `${CTA_LIBRARY_URL}/js/min/theme.min.js`,
  LIB_SLIDERS_URL: `${CTA_LIBRARY_URL}/js/min/sliders.min.js`,
  LIB_ACCORDION_URL: `${CTA_LIBRARY_URL}/js/min/accordion.min.js`,
  LIB_CUSTOM_SELECT_URL: `${CTA_LIBRARY_URL}/js/min/custom_select.min.js`,
  LIB_CUSTOM_NUMBER_URL: `${CTA_LIBRARY_URL}/js/min/custom_number_input.min.js`,
  LIB_DOUBLE_OPTION_URL: `${CTA_LIBRARY_URL}/js/min/double_optin.min.js`,
  LIB_LAZY_LOADING_URL: `${CTA_LIBRARY_URL}/js/min/lazy_loading.min.js`,
  LIB_MENU_URL: `${CTA_LIBRARY_URL}/js/min/menu.min.js`,
  LIB_NEW_LETTER_URL: `${CTA_LIBRARY_URL}/js/min/newsletter_signup.min.js`,
  LIB_OTP_OUT_URL: `${CTA_LIBRARY_URL}/js/min/opt_out.min.js`,
  LIB_RESUBSCRIBE_URL: `${CTA_LIBRARY_URL}/js/min/resubscribe.min.js`,
  LIB_STICKY_URL: `${CTA_LIBRARY_URL}/js/min/sticky_cta_btn.min.js`,
  LIB_404_MINIGAME_URL: `${CTA_LIBRARY_URL}/css/404_minigame.min.css`,
  LIB_MIN_MINIGAME_URL: `${CTA_LIBRARY_URL}/js/min/404-minigame.min.js`,
  LIB_SLIM_URL: `${CTA_LIBRARY_URL}/slim.min.js`,
  LIB_POPPER_URL: `${CTA_LIBRARY_URL}/popper.min.js`,
  LIB_BOOTSTRAP_JS_URL: `${CTA_LIBRARY_URL}/bootstrap.min.js`,
  REALTIME_JS_URL: `${CTA_LIBRARY_URL}/realtime-data.js`,
  LIB_BOOTSTRAP_URL: `${CTA_LIBRARY_URL}/bootstrap.min.css`,
};
