import * as React from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { TITLE_APP } from '../../../utils/constants';

const PageComponent = (props: any) => {
  useDocumentTitle(`${TITLE_APP}${props.title}`);

  return <props.component />;
};

export default PageComponent;
