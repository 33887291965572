import { AxiosClient } from '../httpClient';

export default class MediaV2ManagerAPI {
  static getFilesInsideFolder(folder_id: any, data: any) {
    return AxiosClient.get(`media-service/folders/${folder_id}/files`, data);
  }

  static getFileUrl(filePath: any) {
    return AxiosClient.get(`media-service/file-downloading-url/${filePath}`);
  }

  static getFileUploadingPresignedUrl(filePath: any) {
    return AxiosClient.get(`media-service/file-uploading-presigned-url/${filePath}`);
  }

  static getMultipleFileUploadingPresignedUrls(data: any) {
    return AxiosClient.post('media-service/multiple-file-uploading-presigned-urls', data);
  }

  static deleteFile(id: string) {
    return AxiosClient.delete(`media-service/files/${id}`);
  }

  static createFile(data: any) {
    return AxiosClient.post(`media-service/files/`, data);
  }

  static updateFile(data: any, clearCache = true) {
    return AxiosClient.put(`media-service/files/${data.uuid}?clear_cache=${clearCache ? '1' : '0'}`, data);
  }

  static compressPNG(data: any) {
    return AxiosClient.post(`media-service/compress`, data, { headers: { 'Content-Type': 'image/png' } });
  }
}
