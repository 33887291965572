import { useEffect, useState } from 'react';
import React from 'react';
import { Button } from 'primereact/button';
import FilterField from '../../../components_v2/common/filter-field/filter-field';
import { useTranslation } from 'react-i18next';
import { GOAL_STATUS_LIST_V2 } from '../../../utils/constants';

const GoalFilterForm = (props: any) => {
  const { fields, onChange, setSearch, onRemove, regions, campaigns, partners, filterValues, onSubmit } = props;
  const { t } = useTranslation('language', { keyPrefix: 'goals_manager' });
  const [isDirty, setDirty] = useState<boolean>(false);
  const [values, setValues] = useState<Map<string, string[]>>();
  const { t: globalTrans } = useTranslation('language');

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
  };

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch('');
    await onRemove();
  };

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      filter.set(k, filterValues.where[k]);
    });
    setValues(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  return (
    <>
      <div className="filter-wrapper-v2">
        <div className="filter-form-container">
          <div className="filter-elements mb-16">
            <FilterField
              name="campaign_id"
              label={t('txt_campaign')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get('campaign_id') || []}
              options={campaigns || []}
              optionLabel={'name.de'}
              optionValue={'uuid'}
              visible={values?.has('campaign_id') || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
              filter
              filterBy="name,name.de"
              filterPlaceholder={t('txt_search')}
            />
          </div>
          <div className="filter-elements mb-16">
            <FilterField
              name="region_id"
              label={t('txt_region')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get('region_id') || []}
              options={regions || []}
              optionLabel={'name.de'}
              optionValue={'uuid'}
              visible={values?.has('region_id') || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
              filter
              filterBy="name,name.de"
              filterPlaceholder={t('txt_search')}
            />
          </div>
          <div className="filter-elements mb-16">
            <FilterField
              name="partner_id"
              label={t('txt_partner')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get('partner_id') || []}
              options={partners || []}
              optionLabel={'name'}
              optionValue={'uuid'}
              visible={values?.has('partner_id') || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
              filter
              filterBy="name"
              filterPlaceholder={t('txt_search')}
            />
          </div>
          <div className="filter-elements mb-16">
            <FilterField
              name="status"
              label={t('txt_status')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get('status') || []}
              options={GOAL_STATUS_LIST_V2}
              optionLabel={'label'}
              optionValue={'code'}
              visible={values?.has('status') || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-start gap-24 mt-8">
                <Button
                  className="wi-danger-button-v2 h48 flex-1"
                  type="submit"
                  label={`${t('txt_clear_filter')}`}
                  onClick={() => onRemoveFilters()}
                />
                <Button
                  className="wi-primary-button-v2 h48 flex-1"
                  type="submit"
                  label={`${t('txt_apply')}`}
                  onClick={() => onSubmit(values, fields)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalFilterForm;
