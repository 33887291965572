import { DonationManagerAPI } from '../services';
import { showNotification, generateLinkDownLoad } from './logic';
import moment from 'moment';

const LanguageOptions = {
  eur: 'de',
  chf: 'de',
  cad: 'en',
};
type CurrencyType = 'eur' | 'chf' | 'cad';
type PdfType = 'certificate' | 'receipt';
type LanguageCode = 'en' | 'de';

export const downloadRecurringReceiptPDF = async (donationId: string, fileName: string, languageCode: LanguageCode, notify: any) => {
  try {
    let response: any;
    if (donationId) {
      response = await DonationManagerAPI.generateRecurringReceiptPDF(donationId, languageCode);
      if (response && response.status === 200) {
        generateFileDownload(fileName, response.data.content);
        showNotification('success', 'Generate successfully', notify);
      } else {
        showNotification('error', 'Generate failed', notify);
      }
    }
  } catch (error) {
    showNotification('error', 'Generate failed', notify);
  }
};

const downloadPDFByYear = async (donationId: string, fileName: string, notify: any, yearDonation: any) => {
  try {
    let response: any;
    if (donationId) {
      response = await DonationManagerAPI.generateReceiptsPDFByYear(donationId, yearDonation);
      if (response && response.status === 200) {
        generateFileDownload(fileName, response.data.content);
        showNotification('success', 'Generate successfully', notify);
      } else {
        showNotification('error', 'Generate failed', notify);
      }
    }
  } catch (error) {
    showNotification('error', 'Generate failed', notify);
  }
};

const downloadPDFByYearV2 = async (donationId: string, fileName: string, yearDonation: any) => {
  try {
    let response: any;
    if (donationId) {
      response = await DonationManagerAPI.generateReceiptsPDFByYear(donationId, yearDonation);
      if (response && response.status === 200) {
        generateFileDownload(fileName, response.data.content);
      } else {
        throw new Error('txt_generate_failed');
      }
    }
  } catch (error) {
    throw new Error('txt_generate_failed');
  }
};

const downloadCertificatePDFByYear = async (donationId: string, fileName: string, language: any, yearDonation: any) => {
  try {
    let response: any;
    if (donationId) {
      response = await DonationManagerAPI.generateRecurringCertificatePDF(donationId, language, yearDonation);
      if (response && response.status === 200) {
        generateFileDownload(fileName, response.data.content);
      } else {
        throw new Error('txt_generate_failed');
      }
    }
  } catch (error) {
    throw new Error('txt_generate_failed');
  }
};

const downloadPDF = async (
  donationId: string,
  fileName: string,
  pdfType: PdfType,
  languageCode: LanguageCode,
  setSuccessProgress: any,
  setErrorProgress: any,
  yearDonation?: any,
) => {
  try {
    let response: any;
    if (donationId) {
      response = await DonationManagerAPI.generateDonationPDF(donationId, pdfType, languageCode, yearDonation);
      if (response && response.status === 200) {
        generateFileDownload(fileName, response.data.content);
        setSuccessProgress('Generate successfully');
      } else {
        setErrorProgress('Generate failed');
      }
    }
  } catch (error) {
    setErrorProgress('Generate failed');
  }
};

const downloadMultipleCertificatePDF = async (
  ids: any,
  languageCode: LanguageCode,
  fileName: string,
  setSuccessProgress: any,
  setErrorProgress: any,
) => {
  try {
    let response: any;
    if (ids) {
      response = await DonationManagerAPI.generateMultipleCertificatesPDF(ids, languageCode);
      if (response && response.status === 200) {
        generateFileDownload(fileName, response.data.base64Zip);
        setSuccessProgress('Generate successfully');
      } else {
        setErrorProgress('Generate failed');
      }
    }
  } catch (error) {
    setErrorProgress('Generate failed');
  }
};

const downloadMultipleReceiptPDF = async (ids: any, languageCode: LanguageCode, fileName: string) => {
  if (ids) {
    const response = await DonationManagerAPI.generateMultipleReceiptsPDF(ids, languageCode);
    if (response && response.status === 200) {
      generateFileDownload(fileName, response.data.base64Zip);
    } else {
      throw new Error('txt_generate_receipt_failed');
    }
  }
};

export const onDownloadCertificate = async (
  setSuccessProgress: any,
  setErrorProgress: any,
  e: any,
  rowData: any,
  languageCode: any,
  pdfType: any,
  yearDonation?: any,
) => {
  const default_de_file_name = `Urkunde_${rowData.donation_serial.trim()}_DE.pdf`;
  const default_en_file_name = `Certificate_${rowData.donation_serial.trim()}_EN.pdf`;
  const fileName = languageCode === 'de' ? default_de_file_name : default_en_file_name;

  await downloadPDF(rowData.uuid, fileName, pdfType, languageCode, setSuccessProgress, setErrorProgress, yearDonation);
};

export const onDownloadMultipleCertificate = async (ids: any, languageCode: any, setSuccessProgress: any, setErrorProgress: any) => {
  const momnetDate = moment(new Date()).format('DDMMYYYY');
  const fileName = `Urkunden-${momnetDate}.zip`;
  await downloadMultipleCertificatePDF(ids, languageCode, fileName, setSuccessProgress, setErrorProgress);
};

export const onDownloadMultipleReceipt = async (ids: any, languageCode: any) => {
  const momnetDate = moment(new Date()).format('DDMMYYYY');
  const fileName = `Spendenquittungen-${momnetDate}.zip`;
  await downloadMultipleReceiptPDF(ids, languageCode, fileName);
};

export const onDownloadReceipt = async (setSuccessProgress: any, setErrorProgress: any, rowData: any, e?: any) => {
  const currency_value = rowData.payment_currency_code as CurrencyType;
  const languageCode: any = LanguageOptions[currency_value];

  const default_other_file_name = `Spendenquittung_${rowData.donation_serial.trim()}.pdf`;
  const default_cad_file_name = `Donation_Receipt_${rowData.donation_serial.trim()}.pdf`;
  const fileName = currency_value === 'cad' ? default_cad_file_name : default_other_file_name;

  await downloadPDF(rowData.uuid, fileName, 'receipt', languageCode, setSuccessProgress, setErrorProgress);
};

export const onDownloadReceiptByYear = async (notify: any, rowData: any, yearDonation: any) => {
  const currency_value = rowData.payment_currency_code as CurrencyType;

  const default_other_file_name = `Spendenquittung_${rowData.donation_serial.trim()}.pdf`;
  const default_cad_file_name = `Donation_Receipt_${rowData.donation_serial.trim()}.pdf`;
  const fileName = currency_value === 'cad' ? default_cad_file_name : default_other_file_name;

  await downloadPDFByYear(rowData.uuid, fileName, notify, yearDonation);
};

export const onDownloadReceiptDonationByYear = async (donation: any, yearDonation: any, payment_currency_code: any) => {
  const currency_value = payment_currency_code as CurrencyType;

  const default_other_file_name = `Spendenquittung_${donation?.donation_serial}_${+yearDonation + 1}.pdf`;
  const default_cad_file_name = `Donation_Receipt_${donation?.donation_serial}_${+yearDonation + 1}.pdf`;
  const fileName = currency_value === 'cad' ? default_cad_file_name : default_other_file_name;

  await downloadPDFByYearV2(donation?.uuid, fileName, yearDonation);
};

export const onDownloadCertificateDonationByYear = async (donation: any, yearDonation: any) => {
  const default_de_file_name = `Urkunde_${donation.donation_serial.trim()}_${+yearDonation + 1}_DE.pdf`;
  const default_en_file_name = `Certificate_${donation.donation_serial.trim()}_${+yearDonation + 1}_EN.pdf`;
  const fileName = donation.payment_language_code === 'de' ? default_de_file_name : default_en_file_name;

  await downloadCertificatePDFByYear(donation?.uuid, fileName, donation.payment_language_code, yearDonation);
};

const generateFileDownload = (filename: string, data: any) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const url = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf; charset=utf-8' }));
  generateLinkDownLoad(filename, url);
};

const handleExportMultipleDonations = async (ids: any, fields: any, include_header: any, is_all: any, fileName: string, notify: any) => {
  try {
    let response: any;
    if (ids) {
      response = await DonationManagerAPI.exportMultipleDonations(ids, fields, include_header, is_all);
      if (response && response.status === 200) {
        generateFileDownload(fileName, response.data.export_file);
        showNotification('success', 'Export successfully', notify);
      } else {
        showNotification('error', 'Export failed', notify);
      }
    }
  } catch (error) {
    showNotification('error', 'Export failed', notify);
  }
};

export const onExportMultipleDonations = async (ids: any, selectedValues: any, is_all: any, include_header: any, notify: any) => {
  const momnetDate = moment(new Date()).format('DDMMYYYY');
  const fileName = `Spendeninformationen-${momnetDate}.csv`;
  await handleExportMultipleDonations(ids, selectedValues, include_header, is_all, fileName, notify);
};
