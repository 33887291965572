const SEARCH_FIELDS = ['first_name', 'last_name', 'display_name', 'email'];

const getObjectSearchValue = (value: string) => {
  const search_result: any[] = [];
  SEARCH_FIELDS.forEach((key: string) => {
    const obj = {
      [key]: value,
    };
    search_result.push(obj);
  });

  return search_result;
};

const getObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if ((Array.isArray(value) || typeof value === 'string') && value.length > 0) {
      tempt_obj[key] = value;
    }
  }
  return tempt_obj;
};

const getPayloadConditions = (where_obj: any, sort_config: any) => {
  const { sortField, sortOrder } = sort_config;
  const where_conditions = getObjectWithValues(where_obj);
  const mapper: any = {
    updated_at: [['updated_at']],
    created_at: [['created_at']],
  };
  const orders = mapper[sortField];
  orders.forEach((r: any) => r.push(sortOrder === -1 ? 'DESC' : 'ASC'));
  const order = [...orders];
  return {
    where: where_conditions,
    order,
  };
};

export { getObjectWithValues, getObjectSearchValue, getPayloadConditions };
