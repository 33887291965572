import { AxiosClient } from '../httpClient';

export default class MediaFolderManagerAPI {
  static getAllV2() {
    return AxiosClient.get(`/media-service/folders`);
  }

  static createMediaFolderV2(data: any) {
    return AxiosClient.post(`/media-service/folders`, data);
  }

  static deleteMediaFolderV2(id: string) {
    return AxiosClient.delete(`/media-service/folders/${id}`);
  }

  static updateMediaFolderV2(data: any) {
    return AxiosClient.put(`/media-service/folders/${data.uuid}`, data);
  }
}
