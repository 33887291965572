import React from 'react';
import PageComponent from '../components/common/page';
import DonationManager from '../components/donation-manager/donation-manager';
import DonationDetail from '../components/donation-manager/components/donation-detail/donation-detail';
import ImportPage from '../components/import-page/import-page';
import ImportDetail from '../components/import-detail/import-detail';
import EditDonationDetailV2 from '../components/donation-manager/components/edit-donation-detail/edit-donation-detail-v2';
import RecurringYearlyManager from '../components/recurring-yearly-manager/recurring-yearly-manager';
import CreateRegionManager from '../components/region/components/create-region-component';
import CreateCampaignManager from '../components/campaign/components/create-campaign-component';
import CreateCouponManager from '../components/coupon-manager/components/create-coupon-manager';
import CouponDetailPage from '../components/coupon-detail/coupon-detail';
import BuilderPage from '../components/pages/builder-page';
import PageDetails from '../components/pages/page-details';
import RunnersDetails from '../components/runners-details/runners-details';
import TeamsDetails from '../components/teams-details/teams-details';
import WildernessRunDetails from '../components/wilderness-run-details/wilderness-run-details';
import WildernessRunManager from '../components/wilderness-run-manager/wilderness-run-manager';
import HeadersManager from '../components/global-contents/header-manager/headers-manager';
import GlobalBlockManager from '../components/global-contents/global-block/global-block';
import CMSPagePreview from '../components/pages/cms-page-preview/preview-page';
import FootersManager from '../components/global-contents/footer-manager/footers-manager';
import MatchFundingManager from '../pages/match-funding-manager/match-funding-manager';
import FAQsManagerV2 from '../pages/faqs-manager-v2/faqs-manager-v2';
import QuoteManager from '../pages/quote-manager/quote-manager';
import ReceiptManager from '../pages/manual-receipts-manager/receipt-manager';
import SubscriptionsManager from '../pages/subscription-manager/subscription-component';
import DonorManager from '../pages/donor-manager/donor-manager';
import LandManagerComponent from '../pages/land-manager/land-manager';
import CreateLandManager from '../pages/land-manager/components/create-land-manager';
import RegionManager from '../pages/region-manager/region-manager';
import UsersManager from '../pages/users-manager/users-manager';
import UserDetails, { ProfileDetails } from '../pages/user-detail/user-detail';
import PartnerManagerV2 from '../pages/partner-manager';
import CodeManagerV2 from '../pages/code-manager/code-manager-v2';
import CampaignManager from '../pages/campaign-manager/campaign-manager';
import CouponManager from '../pages/coupon-manager/coupon-manager';
import GoalManager from '../pages/goal-manager/goal-manager';
import MediaManager from '../pages/media-manager/media-manager';
import DonationExamplesManager from '../pages/donation-example-manager/donation-examples-manager';
import CreatePersonManager from '../pages/people-manager/components/create-person-manager';
import PeopleManager from '../pages/people-manager/people-manager';
import PartnerDetails from '../pages/partner-details/components/partner-details';
import PageManager from '../pages/cms-page-manager/cms-page-manager';
import GlobalSettings from '../pages/global-settings/global-settings';

export const AdminRoutes = [
  {
    path: '/users',
    element: <PageComponent component={UsersManager} title="Users" />,
  },
  {
    path: '/users/:id',
    element: <PageComponent component={UserDetails} title="User Details" />,
  },
  {
    path: '/donors',
    element: <PageComponent component={DonorManager} title="Donors" />,
  },
  {
    path: '/donations',
    element: <PageComponent component={DonationManager} title="Donations" />,
  },
  {
    path: '/donations/:id',
    element: <PageComponent component={DonationDetail} title="Donation Details" />,
  },
  {
    path: '/edit-donations/:id',
    element: <PageComponent component={EditDonationDetailV2} title="Donations" />,
  },
  {
    path: '/import',
    element: <PageComponent component={ImportPage} title="Import Donations" />,
  },
  {
    path: '/import-detail/:id',
    element: <PageComponent component={ImportDetail} title="Import Details" />,
  },
  {
    path: '/subscriptions',
    element: <PageComponent component={SubscriptionsManager} title="Subscriptions" />,
  },
  {
    path: '/subscriptions/geo',
    element: <PageComponent component={RecurringYearlyManager} title="Subscriptions" />,
  },
  {
    path: '/regions',
    element: <PageComponent component={RegionManager} title="Regions" />,
  },
  {
    path: '/regions/create',
    element: <PageComponent component={CreateRegionManager} title="Create Region" />,
  },
  {
    path: '/regions/:id',
    element: <PageComponent component={CreateRegionManager} title="Region Details" />,
  },
  {
    path: '/areas',
    element: <PageComponent component={LandManagerComponent} title="Areas" />,
  },
  {
    path: '/areas/create',
    element: <PageComponent component={CreateLandManager} title="Create Area" />,
  },
  {
    path: '/areas/:id',
    element: <PageComponent component={CreateLandManager} title="Area Details" />,
  },
  {
    path: '/partners',
    element: <PageComponent component={PartnerManagerV2} title="Partners" />,
  },
  {
    path: '/codes',
    element: <PageComponent component={CodeManagerV2} title="Code Batches" />,
  },
  {
    path: '/codes/create',
    element: <PageComponent component={CreateCouponManager} title="Create Code Batch" />,
  },
  {
    path: '/codes/:id',
    element: <PageComponent component={CouponDetailPage} title="Code Batch Details" />,
  },
  {
    path: '/wilderness-runs',
    element: <PageComponent component={WildernessRunManager} title="Wilderness Runs" />,
  },
  {
    path: '/wilderness-runs/:id',
    element: <PageComponent component={WildernessRunDetails} title="Wilderness Run Details" />,
  },
  {
    path: '/wilderness-runs/:runId/teams/:id',
    element: <PageComponent component={TeamsDetails} title="Wilderness Run Team Details" />,
  },
  {
    path: '/wilderness-runs/:runId/teams/:teamId/runners/:id',
    element: <PageComponent component={RunnersDetails} title="Wilderness Runner Details" />,
  },
  {
    path: '/utilities/manual-receipts',
    element: <PageComponent component={ReceiptManager} title="Manual Receipts" />,
  },
  {
    path: '/utilities/targets',
    element: <PageComponent component={GoalManager} title="Targets" />,
  },
  {
    path: '/global-contents/faqs',
    element: <PageComponent component={FAQsManagerV2} title="FAQs" />,
  },
  {
    path: '/global-contents/cms-headers',
    element: <PageComponent component={HeadersManager} title="CMS Headers" />,
  },
  {
    path: '/global-contents/cms-footers',
    element: <PageComponent component={FootersManager} title="CMS Footers" />,
  },
  {
    path: '/global-contents/cms-quotes',
    element: <PageComponent component={QuoteManager} title="CMS Quotes" />,
  },
  {
    path: '/global-contents/donation-examples',
    element: <PageComponent component={DonationExamplesManager} title="Donation Examples" />,
  },
  {
    path: '/global-contents/people',
    element: <PageComponent component={PeopleManager} title="People" />,
  },
  {
    path: '/global-contents/people/create',
    element: <PageComponent component={CreatePersonManager} title="Create Person" />,
  },
  {
    path: '/global-contents/people/:id',
    element: <PageComponent component={CreatePersonManager} title="Person Details" />,
  },
  {
    path: '/global-contents/cms-global-blocks',
    element: <PageComponent component={GlobalBlockManager} title="CMS Global Blocks" />,
  },
  {
    path: '/global-settings',
    element: <PageComponent component={GlobalSettings} title="global Settings" />,
  },
];

export const CommonRoutes = [
  {
    path: '/profile/settings',
    element: <PageComponent component={ProfileDetails} title="User Profile" />,
  },
  {
    path: '/campaigns',
    element: <PageComponent component={CampaignManager} title="Campaigns" />,
  },
  {
    path: '/campaigns/create',
    element: <PageComponent component={CreateCampaignManager} title="Create Campaign" />,
  },
  {
    path: '/campaigns/:id',
    element: <PageComponent component={CreateCampaignManager} title="Campaign Details" />,
  },
  {
    path: '/cms-pages',
    element: <PageComponent component={PageManager} title="CMS Pages" />,
  },
  {
    path: '/cms-pages/create',
    element: <PageComponent component={PageDetails} title="Create CMS Page" />,
  },
  {
    path: '/cms-pages/:id',
    element: <PageComponent component={PageDetails} title="CMS Page Details" />,
  },
  {
    path: '/cms-pages/:id/content',
    element: <PageComponent component={BuilderPage} title="CMS Page Content" />,
  },
  {
    path: '/cms-pages/:id/preview',
    element: <PageComponent component={CMSPagePreview} title="CMS Page Content" />,
  },
  {
    path: '/partners/:id',
    element: <PageComponent component={PartnerDetails} title="Partner Details" />,
  },
  {
    path: '/media',
    element: <PageComponent component={MediaManager} title="Media" />,
  },
  {
    path: '/coupons',
    element: <PageComponent component={CouponManager} title="Coupons" />,
  },
  {
    path: '/match-funding',
    element: <PageComponent component={MatchFundingManager} title="Match Funding" />,
  },
];
