import React, { useRef, useState, useLayoutEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import './search-donor.scss';
import _ from 'lodash';

const SearchDonorComponent = (props: any) => {
  const { setIsShowSidebar, donors, selectedDonor, onBlur, onSelected, getDonors, hiddenAdd } = props;

  const op = useRef(null);
  const inputRef = useRef(null);
  const [isShowPanel, setIsShowPanel] = useState(false);
  const [search, setSearch] = useState('');
  let timeoutOpenPanel: any = null;
  let timeoutBlur: any = null;

  useLayoutEffect(() => {
    if (selectedDonor && selectedDonor.item) {
      setIsShowPanel(true);
    }
  }, [selectedDonor]);

  const onSeletedItem = (value: any) => {
    onSelected(value);
    setIsShowPanel(true);
    // @ts-ignore: Object is possibly 'null'.
    op.current.hide();
  };

  const customPanel = () => {
    return (
      <div className="donors-panel">
        {!hiddenAdd && (
          <div
            className="add-new-donor"
            onClick={() => {
              // @ts-ignore: Object is possibly 'null'.
              op.current.hide();
              setIsShowSidebar(true);
            }}
          >
            <span className="p-icon-add">
              <i className="fa-solid fa-circle-plus"></i>
            </span>
            <div className="title">Add new a donor</div>
          </div>
        )}
        <div className="custom-dropdown-panel">
          {donors.map((item: any, index: number) => (
            <div
              key={index}
              className={`custom-dropdown-item ${item?.id === selectedDonor?.item?.id ? 'p-highlight-item' : ''}`}
              onClick={() => onSeletedItem(item)}
            >
              <div className="name-donor">
                {item.payment_first_name || ''} {item.payment_last_name || ''}
              </div>
              <div className="email-donor">{item.payment_email}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const debouncedSetSelectedItem = useRef(
    _.debounce(newValue => {
      getDonors(newValue);
    }, 500),
  );

  const onChangeValueSearch = (e: any) => {
    const newValue = e.target.value;
    setSearch(newValue);
    debouncedSetSelectedItem.current(newValue);
  };

  const handleInputFocus = (event: any) => {
    // @ts-ignore: Object is possibly 'null'.
    op.current.toggle(event);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Ngăn chặn hành động mặc định của sự kiện Enter
    }
  };

  const openDonorPanel = () => {
    setIsShowPanel(false);
    clearTimeout(timeoutOpenPanel);
    timeoutOpenPanel = setTimeout(() => {
      // @ts-ignore: Object is possibly 'null'.
      inputRef.current.focus();
    }, 1);
  };

  return (
    <div className="search-auto">
      {selectedDonor && selectedDonor.item && isShowPanel ? (
        <>
          <div className="donor-selected">
            <div className="donor-item-selected">
              <div className="name-item" onClick={() => openDonorPanel()}>
                <span className="icon">
                  <i className="fa-solid fa-user-pen"></i>
                </span>
                <span className="name">
                  {selectedDonor.item.payment_first_name} {selectedDonor.item.payment_last_name}
                </span>
              </div>
              <div className="email-item">{selectedDonor.item.payment_email}</div>
            </div>
          </div>
        </>
      ) : (
        <div className="p-inputgroup p-search">
          <span className="p-inputgroup-addon">
            <i className="pi pi-search"></i>
          </span>
          <InputText
            ref={inputRef}
            value={search}
            onFocus={handleInputFocus}
            placeholder="Find or add a donor"
            onChange={onChangeValueSearch}
            onBlur={(e: any) => {
              if (selectedDonor && selectedDonor.item) {
                setIsShowPanel(true);
                clearTimeout(timeoutBlur);
                timeoutBlur = setTimeout(() => {
                  // @ts-ignore: Object is possibly 'null'.
                  op.current.hide();
                }, 200);
              } else {
                onBlur(e);
              }
            }}
            name="donor_id"
            id="donor_id"
            autoComplete="off"
            onKeyDown={handleKeyDown}
          />
        </div>
      )}
      <OverlayPanel ref={op} className="overlaypanel-search-donor col-md-4">
        {customPanel()}
      </OverlayPanel>
    </div>
  );
};

export default SearchDonorComponent;
