import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useLayoutV2 } from '../../context/LayoutProvider';
import { useTranslation } from 'react-i18next';
import { FAQsManagerAPI } from '../../services';
import { formatDateTimeV2 } from '../../utils/logic';
import { removeEmpty } from '../../utils/utils';
import { PERMISSIONS_V2 } from '../../utils/constants';
import useAuth from '../../context/useAuth';
import Layout from '../../layout/layout';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import AddFAQFormComponent from './components/add-faq-form/add-faq-form';
import WIConfirmDialog from '../../components_v2/common/delete-confirm-dialog/delete-confirm-dialog';
import { ReactComponent as WarningSvg } from '../../assets/images/icons/warning-symbol.svg';

const FAQsManagerV2 = () => {
  const { auth } = useAuth();
  const { setLoadingProgress, setErrorProgress, setSuccessProgress, getLanguage } = useLayoutV2();

  const [search, setSearch] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const { t } = useTranslation('language', { keyPrefix: 'faqs_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: dialogTrans } = useTranslation('language', { keyPrefix: 'delete_dialog' });
  const [isLoading, setIsLoading] = useState(false);
  const [faqs, setFAQs] = useState<any>({
    data: [],
    totalRecords: 0,
  });
  const [faq, setFAQ] = useState<any>(null);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);

  const permissions = {
    canCreateFAQ: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_CREATE),
    canUpdateFAQ: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_UPDATE),
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchFAQsData = async (valueSearch?: string) => {
    setIsLoading(true);
    try {
      const conditions = removeEmpty({
        pageNo: 0,
        range: 10000,
        search_text: valueSearch || undefined,
      });
      const [faqsResult, articlesResult] = await Promise.all([FAQsManagerAPI.paginateFAQs(conditions), FAQsManagerAPI.getAllArticles()]);

      let listFAQs: Array<any> = [];
      if (faqsResult && faqsResult.status === 200 && faqsResult.data?.records?.length > 0) {
        listFAQs = listFAQs.concat(faqsResult.data.records);
      }

      if (articlesResult && articlesResult.status === 200 && articlesResult.data?.records?.length > 0) {
        if (valueSearch) {
          const newArticles = _.filter(articlesResult.data?.records, faq => {
            const question = faq.question[getLanguage()].toLowerCase();
            const answer = faq.answer[getLanguage()].toLowerCase();
            const categories = faq.category || '';

            return (
              question.includes(valueSearch.toLowerCase()) ||
              answer.includes(valueSearch.toLowerCase()) ||
              categories.toLowerCase().includes(valueSearch.toLowerCase())
            );
          });
          listFAQs = listFAQs.concat(newArticles);
        } else {
          listFAQs = listFAQs.concat(articlesResult.data?.records);
        }
      }

      let cloneListFAQs = _.orderBy(_.cloneDeep(listFAQs), ['updated_at'], ['desc']);
      const totalRecords = cloneListFAQs.length;
      cloneListFAQs = _.drop(cloneListFAQs, page * rows).slice(0, rows);
      if (cloneListFAQs) {
        setIsLoading(false);
        setFAQs({
          data: cloneListFAQs || [],
          totalRecords: totalRecords || 0,
        });
        return;
      }
    } catch (error) {
      setIsLoading(false);
    }

    setFAQs({
      data: [],
      totalRecords: 0,
    });
  };

  const onDeleteConfirm = async () => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      const response = await FAQsManagerAPI.deleteFAQ(faq.uuid);
      if (response?.status === 200 && response?.data) {
        onShowDeleteDialog(false);
        fetchFAQsData();
        setSuccessProgress(t('txt_delete_faq_success'));
      } else {
        throw new Error('txt_delete_faq_fail');
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  };

  const onSearch = async () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    await fetchFAQsData(search);
  };

  useEffect(() => {
    fetchFAQsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === 'number') {
      fetchFAQsData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const onDeleteFAQ = async (e: any, rowData: any) => {
    setFAQ(rowData);
    setIsShowDeleteDialog(true);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setFAQ(null);
    setIsShowDeleteDialog(isVisible);
  };

  const tableColumns = [
    {
      field: 'category',
      header: t('txt_category'),
      width: '15%',
      align: 'left',
      disabledSort: true,
    },
    {
      field: 'question',
      header: t('txt_question'),
      width: '20%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const question = _.isString(rowData?.question) ? rowData?.question : rowData.question?.[getLanguage()];
        return <div style={{ maxWidth: '100%' }}>{question || '-'}</div>;
      },
      disabledSort: true,
    },
    {
      field: 'answer',
      header: t('txt_answer'),
      width: 'calc(52% - 64px)',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const answer = _.isString(rowData?.answer) ? rowData?.answer : rowData.answer?.[getLanguage()];
        return !!answer ? (
          <div
            className="wi-html-content"
            dangerouslySetInnerHTML={{
              __html: answer,
            }}
          ></div>
        ) : (
          '-'
        );
      },
      disabledSort: true,
    },
    // {
    //     field: "created_at",
    //     header: t('txt_created_at'),
    //     width: "10%",
    //     align: "left",
    //     bodyTemplate: (rowData: any) => {
    //         return <div>{formatDateTimeV2(rowData.created_at, getLanguage())}</div>;
    //     },
    //     disabledSort: true,
    // },
    {
      field: 'updated_at',
      header: t('txt_updated_at'),
      width: '13%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return <div>{formatDateTimeV2(rowData.updated_at, getLanguage())}</div>;
      },
      disabledSort: true,
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
        }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: `${t('txt_edit')}`,
        icon: 'wi-edit',
        command: (e: any) => {
          setFAQ(rowData);
          setIsShowSidebar(true);
        },
        hidden: !permissions.canUpdateFAQ,
        disabled: rowData.source === 'HelpScout',
      },
      {
        label: t('txt_delete'),
        icon: 'wi-trash',
        hidden: !permissions.canUpdateFAQ,
        disabled: rowData.source === 'HelpScout',
        dangerous: true,
        command: (e: any) => {
          onDeleteFAQ(e, rowData);
        },
      },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const onHideSideBar = () => {
    setIsShowSidebar(false);
    if (faq) {
      setFAQ(null);
    }
  };

  return (
    <Layout title={t('txt_title')}>
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={(value: any) => onSearch()}
            />
          </div>

          <Button
            className="wi-primary-button-v2"
            label={t('txt_btn_add_faq')}
            hidden={!permissions.canCreateFAQ}
            onClick={(e: any) => {
              setFAQ(null);
              setIsShowSidebar(true);
            }}
          >
            <PlusSvg className="icon-svg" />
          </Button>
        </div>
      </div>

      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={faqs.data}
          filters={search}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          dataKey="id"
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
          lazy
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={faqs.totalRecords} onPageChange={onBasicPageChange} />
      </div>

      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '30em' }}
        onHide={() => onHideSideBar()}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{faq ? t('txt_update_faq') : t('txt_btn_add_faq')}</h6>
          </div>
          <AddFAQFormComponent fetchCallBack={() => fetchFAQsData()} onHide={() => onHideSideBar()} faqData={faq} />
        </div>
      </Sidebar>

      <WIConfirmDialog
        visible={isShowDeleteDialog}
        onHide={() => onShowDeleteDialog(false)}
        onConfirm={onDeleteConfirm}
        maskClassName="top-mask-dialog"
        confirmLabel={t('txt_confirm')}
        hideLabel={t('txt_cancel')}
        message={
          <Fragment>
            <div style={{ fontSize: '18px', fontWeight: '700', marginBottom: '16px', textAlign: 'center' }}>{t('txt_delete_question')}</div>
            <div style={{ fontSize: '13px', textAlign: 'center' }}>
              <div dangerouslySetInnerHTML={{ __html: t('txt_delete_warning') }} />
            </div>
          </Fragment>
        }
        icon={<WarningSvg className="icon-svg"></WarningSvg>}
      />
    </Layout>
  );
};

export default FAQsManagerV2;
