import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import './donation-detail.scss';
import { Button } from 'primereact/button';
import _ from 'lodash';
import { Timeline } from 'primereact/timeline';
import {
  DonationManagerAPI,
  DonorManagerAPI,
  GoalManagerAPI,
  PartnerManagerAPI,
  SubscriptionManagerAPI,
  CampaignManagerAPI,
} from '../../../../services';
import { onDownloadReceipt, onDownloadReceiptDonationByYear, onDownloadCertificateDonationByYear } from '../../../../utils/donation.util';
import GenerateCertificateDonationDialogComponent from '../generate-certificate-donation-dialog/generate-certificate-donation-dialog';
import { WIConfirmDialog } from '../../../common';
import moment from 'moment-timezone';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IDonation, IEvent } from '../../../pages/interfaces/IComponent';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { showEvents, showMessageEvent, showIconEvents } from '../../../../utils/utils';
import { env } from '../../../../configs';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import CountryService from '../../../../services/country-service';
import m from 'moment';
import { generateMapUrl, compareDate, parseJSON, formatStringToNumber, formatDateTimeV2 } from '../../../../utils/logic';
import { NO_ACTION_LIST, updateDonationData, disabledEditAction, isValidDonorInfo } from '../../donation-manager.util';
import { Link } from 'react-router-dom';
import { Column, ColumnAlignType } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import useAuth from '../../../../context/useAuth';
import {
  DONATION_RHYTHM_LIST,
  DONATION_STATUS_LIST,
  PERMISSIONS_V2,
  STATUS_CODE,
  DONATION_STATUS,
  SUBSCRIPTION_STATUS_LIST,
  DONATION_TYPE_LIST,
} from '../../../../utils/constants';
import { Sidebar } from 'primereact/sidebar';
import ChangeDonorForm from './components/change-donor-form/change-donor-form';
import AssignCampaignForm from './components/assign-campaign-form/assign-campaign-form';
import { useTranslation } from 'react-i18next';
import { useLayoutV2 } from '../../../../context/LayoutProvider';
import Layout from '../../../../layout/layout';
import WiStatus from '../../../../components_v2/common/wi-status/wi-status';
import MenuDot from '../../../../components_v2/common/menu-dot/menu-dot';
import AddDonorFormComponent from '../../../../pages/donor-manager/components/add-donor-form/add-donor-form';

const removeFirstWord = (value: string) => {
  const indexOfSpace = value.indexOf(' ');
  return indexOfSpace === -1 ? '' : value.slice(indexOfSpace + 1);
};

const renderPaymentMethod = (paymentData: any) => {
  return paymentData && paymentData?.payment_method
    ? paymentData?.payment_method.includes('Stripe')
      ? removeFirstWord(paymentData?.payment_method)
      : paymentData?.payment_method
    : '-';
};

const customizedMarker = (item: any) => {
  return (
    <span className="custom-marker shadow-1" style={{ backgroundColor: item?.color }}>
      <i className={item?.icon}></i>
    </span>
  );
};

const customizedContent = (item: any) => {
  return (
    <div className="custome-content-timeline">
      <div className="title-event">{item?.name}</div>
      <div className="message-event">{item?.message}</div>
      <div className="date-event">{item?.updated_at}</div>
    </div>
  );
};

const formDate = (value: any, lang: any = 'de') => {
  if (value) {
    const date = m(new Date(value), 'MMM DD, YYYY HH:mm:ss').utc().add(1, 'hour').locale(lang).format('MMM DD, YYYY HH:mm');
    return date.replace('01:00:00', '00:00:00');
  }

  return '-';
};

const DonationDetail = (props: any) => {
  const { auth } = useAuth();
  const permissions = {
    canCreateDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_CREATE),
    canUpdateDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_UPDATE),
    canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
    canViewCampaign: auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_VIEW),
    canViewCode: auth?.permissions?.includes(PERMISSIONS_V2.CODE_VIEW),
    canViewGoal: auth?.permissions?.includes(PERMISSIONS_V2.GOAL_VIEW),
    canCreateDonationWithArea: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_X),
    canUpdateDonor: auth?.permissions?.includes(PERMISSIONS_V2.DONOR_UPDATE),
  };
  const { t } = useTranslation('language', { keyPrefix: 'donation_detail' });
  const { t: globalTrans } = useTranslation('language');
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { getLanguage, setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const donationID = window.location.pathname ? window.location.pathname.split('/')[2] : '';
  const [isLoading, setIsLoading] = useState(false);
  const [donation, setDonation] = useState<IDonation>();
  const [parentDonation, setParentDonation] = useState<IDonation>();
  const [code, setCode] = useState<any>(null);
  const [isShowCreateCertificateDialog, setIsShowCreateCertificateDialog] = useState(false);
  const [isEditDonation, setIsEditDonation] = useState(false);
  const [isDisabledEditDonation, setIsDisabledEditDonation] = useState(false);
  const formikRef = useRef(null);
  const [eventsPayment, setEventsPayment] = useState([]);
  const [countries, setCountries] = useState([]);
  const [partners, setPartners] = useState([]);
  const [isError, setIsError] = useState({
    paymentDate: false,
    paymentReceviedDate: false,
  });
  const [childrendonation, setChildrendonation] = useState({
    data: [],
    totalPage: 0,
    totalCharged: 0,
  });
  const [subscriptionData, setSubscriptionData] = useState<any>();
  const [batchCode, setBatchCode] = useState<any>();
  const [goal, setGoal] = useState<any>();
  const [donationReceipts, setDonationReceipts] = useState<any>([]);
  const [isShowConfirm, setIsShowConfirm] = useState<any>(false);
  const [isShowSidebarDonor, setIsShowSidebarDonor] = useState(false);
  const [isShowSidebarCampaign, setIsShowSidebarCampaign] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [donors, setDonors] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedDonor, setSelectedDonor] = useState<any>({ name: '', item: null });
  const handleDisableIcon = () => {
    return NO_ACTION_LIST.some(item => item === donation?.status?.toLowerCase());
  };

  const handleDisableOldData = () => {
    if (donation?.old_donation_id) {
      const paymentDate = new Date(donation?.payment_date || '');
      const year = paymentDate.getFullYear();
      const dateValid = [2021, 2022, 2023];
      return !dateValid.includes(year);
    }

    return false;
  };

  const disabledChangeDonor = () => {
    return donation?.rhythm !== 0 || (donation?.status !== DONATION_STATUS.SUCCEEDED && donation?.status !== DONATION_STATUS.RESERVED);
  };

  const disabledChangeCampaign = () => {
    return donation?.rhythm !== 0 || (donation?.status !== DONATION_STATUS.SUCCEEDED && donation?.status !== DONATION_STATUS.RESERVED);
  };

  const hideOpenDonationPage = () => {
    if (donation?.status !== STATUS_CODE.COMPLETED || code?.status === '1' || (donation?.rhythm > 0 && donation?.parent_donation_id)) {
      return true;
    }
  };

  const itemsDonationAction = [
    {
      disabled: isDisabledEditDonation || isEditDonation,
      label: t('txt_edit'),
      icon: 'wi-edit',
      command: () => setIsEditDonation(true),
      className: 'item-menu',
      hidden: !permissions.canUpdateDonation,
    },
    {
      label: t('txt_open_donation_page'),
      icon: 'wi-open-doc',
      externalUrl: `${env.CMS_URL}/donations/${donation?.donation_serial}`,
      className: 'item-menu',
      hidden: hideOpenDonationPage(),
    },
    {
      hidden: !permissions.canUpdateDonor,
      label: t('txt_edit_donor'),
      icon: 'wi-person',
      command: () => setIsShowSidebarDonor(true),
      className: 'item-menu',
    },
    {
      hidden: !permissions.canUpdateDonation || disabledChangeDonor(),
      disabled: disabledChangeDonor(),
      label: t('txt_change_donor'),
      icon: 'wi-person',
      command: () => setIsShowSidebar(true),
      className: 'item-menu',
    },
    {
      hidden: !permissions.canUpdateDonation || disabledChangeCampaign(),
      disabled: disabledChangeCampaign(),
      label: t('txt_assign_campaign'),
      icon: 'wi-campaign',
      command: () => setIsShowSidebarCampaign(true),
      className: 'item-menu',
    },
    {
      hidden: !permissions.canUpdateDonation || donation?.status !== DONATION_STATUS.RESERVED || donation.donation_type === 'code',
      disabled: isEditDonation,
      label: t('txt_mark_as_paid'),
      icon: 'wi-active',
      command: () => setIsShowConfirm(true),
      className: 'item-menu',
    },
    {
      disabled: donation ? handleDisableIcon() : false,
      className: 'item-menu',
      label: t('txt_download_certificate'),
      icon: 'wi-download',
      command: (e: any) => onGenerateCertificateDialog(e),
    },
    {
      disabled: donation ? donation.disabled_receipt || handleDisableIcon() || !isValidDonorInfo(donation) || handleDisableOldData() : false,
      className: 'item-menu',
      label: t('txt_download_receipt'),
      icon: 'wi-download',
      command: () => {
        setLoadingProgress(errorTrans('txt_loading'));
        onDownloadReceipt(setSuccessProgress, setErrorProgress, donation);
      },
    },
    // {
    //     disabled: true,
    //     className: 'item-menu',
    //     label: 'Cancel',
    //     icon: 'pi pi-times',
    //     hidden: !permissions.canUpdateDonation
    // },
    // {
    //     disabled: true,
    //     className: 'item-menu',
    //     label: 'Refund',
    //     icon: 'pi pi-times',
    //     hidden: !permissions.canUpdateDonation
    // }
  ].filter(c => !c.hidden);

  const onHideChangeDonor = () => {
    setSelectedDonor({
      name: `${donation?.payment_first_name || ''} ${donation?.payment_last_name || ''} - ${donation?.payment_email}`,
      item: donation,
    });
    setIsShowSidebar(false);
  };

  const onHideAssignCampaign = () => {
    setIsShowSidebarCampaign(false);
  };

  const onGenerateCertificateDialog = (e: any) => {
    setIsShowCreateCertificateDialog(true);
  };

  const updateFilterPaymentsDonation = (events: IEvent[]) => {
    return events.filter((item: IEvent) => {
      if (showEvents.some(itemEvent => itemEvent === item.event_type)) {
        return item;
      }
    });
  };

  const convertNameEvents = (events: IEvent[]) => {
    if (events) {
      return events.map((itemEvent: IEvent) => {
        switch (itemEvent.event_type) {
          case 'donation.started':
            itemEvent.name = 'Initiated';
            break;
          case 'payment_intent.created':
            itemEvent.name = 'Payment Initiated';
            break;
          case 'payment_intent.succeeded':
            itemEvent.name = 'Payment - Received';
            break;
          case 'checkout.session.completed':
            itemEvent.name = 'Payment - Succeeded';
            break;
          case 'charge.succeeded':
            itemEvent.name = 'Payment - Succeeded';
            break;
          case 'certificate.sent.succeeded':
            itemEvent.name = 'Certificate - Sent';
            break;
          case 'certificate.sent.failed':
            itemEvent.name = 'Certificate - Not sent';
            break;
          case 'payment_intent.payment_failed':
            itemEvent.name = 'Payment - Failed';
            break;
          case 'payment_intent.canceled':
            itemEvent.name = 'Payment - Cancelled';
            break;
          case 'checkout.session.async_payment_failed':
            itemEvent.name = 'Payment - Failed';
            break;
          case 'receipt.sent.succeeded':
            itemEvent.name = 'Receipt - Sent';
            break;
          case 'receipt.sent.failed':
            itemEvent.name = 'Receipt - Not sent';
            break;
          case 'charge.refund.updated':
            itemEvent.name = 'Payment - Refunded';
            break;
          case 'payment.refunded':
            itemEvent.name = 'Payment - Refunded';
            break;
          case 'charge.dispute.created':
            itemEvent.name = 'Payment - Refunded';
            break;
          case 'payment.refund.failed':
            itemEvent.name = 'Payment - Not refunded';
            break;
          case 'charge.refunded':
            itemEvent.name = 'Payment - Refunded';
            break;
          case 'donation.generated':
            itemEvent.name = 'Generated';
            break;
          case 'donation.redeemed':
            itemEvent.name = 'Redeemed';
            break;
          case 'invoice.created':
            itemEvent.name = 'Payment - Initiated';
            break;
          case 'invoice.payment_succeeded':
            itemEvent.name = 'Payment - Succeeded';
            break;
          case 'invoice.payment_failed':
            itemEvent.name = 'Payment - Failed';
            break;
          case 'customer.subscription.created':
            itemEvent.name = 'Subscription - Started';
            break;
          case 'customer.subscription.deleted':
            itemEvent.name = 'Subscription - Finished';
            break;
          case 'customer.subscription.updated':
            itemEvent.name = 'Subscription - Updated';
            break;
          default:
            itemEvent.name = itemEvent.event_type?.split('.').join(' ') || itemEvent.event_type;
            break;
        }
        return {
          ...itemEvent,
        };
      });
    }
  };

  const updateDateEvents = (itemEvent: any, donationData: any) => {
    if (itemEvent && donationData) {
      if (donationData?.is_imported && donationData?.events) {
        if (itemEvent.event_type === 'checkout.session.completed' || itemEvent.event_type === 'donation.started') {
          return formDate(donationData?.payment_date, getLanguage());
        }
        if (itemEvent.event_type === 'payment_intent.succeeded') {
          return formDate(donationData?.payment_received_date, getLanguage());
        }
      }
      return moment(new Date(itemEvent.updated_at), 'MMM DD, YYYY HH:mm:ss').utc().add(1, 'hour').format('MMM DD, YYYY HH:mm');
    }
  };

  const updateFlowPaymentDonation = (donationData: IDonation) => {
    if (donationData) {
      let sorted: any = donationData.events || [];
      if (donationData?.status === DONATION_STATUS.CANCELLED) {
        const isPaymentIntentCancelled = sorted.find((event: any) => event.event_type === 'payment_intent.canceled');
        const isCheckoutSessionExpired = sorted.find((event: any) => event.event_type === 'checkout.session.expired');

        if (!isPaymentIntentCancelled && isCheckoutSessionExpired) {
          sorted = sorted.map((event: any) => {
            if (event.event_type === 'checkout.session.expired') {
              return {
                ...event,
                event_type: 'payment_intent.canceled',
              };
            }
            return event;
          });
        }
      }

      if (donationData?.status === DONATION_STATUS.FAILED) {
        const isPaymentIntentCancelled = sorted.find((event: any) => event.event_type === 'payment_intent.canceled');
        if (isPaymentIntentCancelled) {
          sorted = sorted.filter((event: any) => event.event_type !== 'payment_intent.canceled');
        }
      }

      sorted = updateFilterPaymentsDonation(sorted);
      sorted = _.sortBy(sorted, 'updated_at')
        .reverse()
        .map(itemEvent => {
          return {
            ...itemEvent,
            icon: showIconEvents.some(event => event === itemEvent.event_type) ? 'pi pi-times' : 'pi pi-check',
            color: showIconEvents.some(event => event === itemEvent.event_type) ? '#E52322' : '#94C24A',
            updated_at: updateDateEvents(itemEvent, donationData),
            message: showMessageEvent.some(event => event === itemEvent.event_type) ? itemEvent.message : '',
          };
        });

      sorted = convertNameEvents(sorted);

      const paymentSucceeded = sorted.findIndex((c: any) => c.event_type === 'checkout.session.completed'); // Payment - Succeeded
      const paymentReceived = sorted.findIndex((c: any) => c.event_type === 'payment_intent.succeeded'); // Payment - Received
      if (paymentSucceeded && paymentSucceeded === 0 && paymentReceived) {
        if (paymentSucceeded < paymentReceived) {
          const temp = sorted[paymentSucceeded];
          sorted[paymentSucceeded] = sorted[paymentReceived];
          sorted[paymentReceived] = temp;
        }
      }

      setEventsPayment(sorted.filter((event: IEvent) => event));
    }
  };

  const fetchCountryData = async () => {
    try {
      const res = await CountryService.getCountries();
      if (res.status === 200 && res.data.data) {
        setCountries(res.data.data);
      } else {
        setCountries([]);
      }
    } catch (error) {
      setCountries([]);
    }
  };

  const fetchPartnerData = async () => {
    try {
      const res = await PartnerManagerAPI.getAllPartners();
      if (res.status === 200 && res.data) {
        const defaultPartner = {
          name: 'None',
        };
        res.data.records.unshift(defaultPartner);
        setPartners(res.data.records);
      } else {
        setPartners([]);
      }
    } catch (error) {
      setPartners([]);
    }
  };

  const groupDonationByYear = (donations: any) => {
    let donationReceiptsByYears: any = [];
    const today = moment();
    const january31 = moment().month(0).date(31);
    const currentYear = today.year();

    const groupedByYear = donations.reduce((result: any, item: any) => {
      const year = new Date(item.payment_date).getFullYear();

      if (!result[year]) {
        result[year] = [];
      }
      if (year < currentYear) {
        if (!(year === currentYear - 1 && today.isBefore(january31))) {
          result[year].push(item);
        }
      }
      return result;
    }, {});
    for (const key in groupedByYear) {
      if (groupedByYear.hasOwnProperty(key)) {
        const value = groupedByYear[key];
        if (value && value.length > 0) {
          const filterValue = value.filter((item: any) => item.status === 'completed');
          const receiptByYear = {
            payment_date: `31.01.${parseInt(key) + 1}`,
            total_amount: _.sumBy(filterValue, (d: any) => +d.donation_money) || 0,
            total_protected_forest: _.sumBy(filterValue, (d: any) => +d.area) || 0,
            year: key,
          };
          donationReceiptsByYears.push(receiptByYear);
        }
      }
    }

    setDonationReceipts(donationReceiptsByYears);
  };

  const getChildrenDonation = async (dataDonation: any) => {
    setIsLoading(true);
    try {
      const res = await DonationManagerAPI.getDonationsBySearch({
        pageNo: 0,
        range: 500,
        where: {
          parent_donation_id: dataDonation.uuid,
        },
      });
      if (res && res.status === 200) {
        groupDonationByYear(res.data.records);
        const updateChildrenDonation: any = _.sortBy(res.data.records, 'payment_date').reverse();
        const donationCompleted = res.data.records.filter((item: any) => item.status === 'completed');
        const totalSubscriptionMoney = _.sumBy(donationCompleted, (d: any) => formatStringToNumber(d.donation_money)) || 0;
        const donationMoney = dataDonation && dataDonation.status === 'completed' ? formatStringToNumber(dataDonation.donation_money) : 0;
        setIsLoading(false);
        setChildrendonation({
          totalPage: res.data.total || 0,
          data: updateChildrenDonation,
          totalCharged: totalSubscriptionMoney + donationMoney,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setChildrendonation({
        data: [],
        totalPage: 0,
        totalCharged: 0,
      });
    }
  };

  const fetchSubscriptionsByID = async (subscriptionId: any) => {
    try {
      const res = await SubscriptionManagerAPI.getSubscriptionsByID(subscriptionId);
      if (res && res.status === 200) {
        setSubscriptionData(res.data);
      } else {
        setSubscriptionData(null);
      }
    } catch (error) {
      setSubscriptionData(null);
    }
  };

  const fetchBachCodeByID = async (boostedCodeID: any) => {
    try {
      const res = await DonationManagerAPI.getCodeById(boostedCodeID);
      if (res && res.status === 200) {
        setBatchCode(res.data);
      } else {
        setBatchCode(null);
      }
    } catch (error) {
      setBatchCode(null);
    }
  };

  const getGoalDetails = async (goal_id: string) => {
    try {
      const res = await GoalManagerAPI.getGoalDetails(goal_id);
      if (res && res.status === 200) {
        setGoal(res.data.record);
      } else {
        setGoal(null);
      }
    } catch (error) {
      setGoal(null);
    }
  };

  const getDonors = async (donationData: any) => {
    try {
      if (donationData) {
        setSelectedDonor({
          name: `${donationData.payment_first_name || ''} ${donationData.payment_last_name || ''} - ${donationData.payment_email}`,
          item: donationData,
        });
      }
      const res = await DonorManagerAPI.searchDonors('');
      if (res && res.status === 200) {
        const sortDonors: any = _.sortBy(res.data, 'joined_date').reverse();
        setDonors(res.data?.length > 0 ? sortDonors : []);
      }
    } catch (error) {
      console.log('error >>>', error);
    }
  };

  const getCampaigns = async (donationData: any) => {
    try {
      const res = await CampaignManagerAPI.getAllCampaigns();
      if (res && res.status === 200) {
        const filteredCampaigns: any = _.filter(res?.data?.records, campaign => _.get(campaign, 'land.region_id') === donationData?.region_id);
        setCampaigns(filteredCampaigns?.length > 0 ? filteredCampaigns : []);
      }
    } catch (error) {
      console.log('error >>>', error);
    }
  };

  const fetchDonationDetail = async () => {
    setIsLoading(true);
    try {
      const res = await DonationManagerAPI.getDonation(donationID);
      if (res && res.status === 200) {
        const donationData = await updateDonationData(res.data);
        setIsDisabledEditDonation(disabledEditAction(donationData));
        setDonation(donationData);
        updateFlowPaymentDonation(donationData);
        if (res.data?.subscription_id) {
          fetchSubscriptionsByID(res.data?.subscription_id);
        }
        if (res.data?.boosted_code_id) {
          fetchBachCodeByID(res.data?.boosted_code_id);
        }
        if (res.data?.goal_id) {
          getGoalDetails(res.data?.goal_id);
        }
        getDonors(donationData);
        getCampaigns(donationData);
        try {
          if (res.data.parent_donation_id) {
            const resParentDonation = await DonationManagerAPI.getDonation(res.data.parent_donation_id);
            if (resParentDonation && resParentDonation.status === 200) {
              const parentDonationData = await updateDonationData(resParentDonation.data);
              setParentDonation(parentDonationData);
            }
          } else {
            getChildrenDonation(res.data);
          }
          if (res.data.code_id) {
            const resCode = await DonationManagerAPI.getCodeById(res.data.code_id);
            setCode(resCode.data);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const renderParentName = () => {
    return parentDonation ? `${parentDonation.donation_serial}` : '';
  };

  const navigateToParentName = () => {
    if (donation) {
      return donation?.parent_donation_id || '#';
    }
  };

  const navigateToStripe = () => {
    return subscriptionData?.id ? `${env.BASE_STRIPE_URL}/subscriptions/${subscriptionData?.id}` : '#';
  };

  const renderDonationType = () => {
    const donationType: any = DONATION_TYPE_LIST.find(item => item.code === donation?.donation_type);
    return globalTrans(donationType?.name || '-');
  };

  const renderCountryName = () => {
    if (donation) {
      const country: any = countries.find((countryItem: any) => countryItem.ISO2 === donation.payment_country);
      return country?.name || donation?.payment_country;
    }
  };

  const renderPartnerName = () => {
    if (donation) {
      const partner: any = partners.find((partnerItem: any) => partnerItem.uuid === donation.partner_id);
      return !!partner?.uuid ? partner?.name : '-';
    }
  };

  const renderRhythm = (rhythmData: any) => {
    if (rhythmData) {
      const rhythm = globalTrans(DONATION_RHYTHM_LIST.find(c => c.code === donation?.rhythm)?.name || 'rhythms.txt_once');
      if (rhythm && +rhythmData.rhythm > 0 && donation?.is_first_time) {
        return (
          <label className="wi-form-displayed-value">
            {rhythm} <i className="fa-solid fa-clock-rotate-left rhythm-icon" title="First time donation"></i>
          </label>
        );
      }

      if (rhythm && +rhythmData.rhythm > 0 && !donation?.is_first_time) {
        return (
          <label className="wi-form-displayed-value">
            {rhythm} <i className="fa-solid fa-arrows-rotate rhythm-icon" title="Subsequent donation"></i>
          </label>
        );
      }

      return <label className="wi-form-displayed-value">{rhythm}</label>;
    }
  };

  const renderOccasion = () => {
    if (donation?.donation_type === 'code') {
      const occasionBatch = parseJSON(code?.batch?.certificate_occasion);
      return occasionBatch ? occasionBatch : parentDonation?.personalize_certificate_reason || donation?.personalize_certificate_reason || '-';
    }
    return parentDonation?.personalize_certificate_reason || donation?.personalize_certificate_reason || '-';
  };

  const navigateBaseTransactionID = (donationData: any) => {
    if (donationData) {
      if (donationData.payment_transaction_id && donationData.payment_transaction_id.includes('pi_')) {
        return `${env.STRIPE_URL}${donationData.payment_transaction_id}`;
      }
      return `${env.PAYPAL_URL}${donationData.payment_transaction_id}`;
    }
    return '#';
  };

  const getGeoCoordinate = (donation: any) => {
    if (donation && !donation.is_first_time && parentDonation && parentDonation.subDonations) {
      const year = moment(donation.payment_date).year();
      const oldest = _.first(
        _.sortBy(
          parentDonation.subDonations.filter(s => moment(s.payment_date).year() === year),
          'payment_date',
        ),
      );
      return oldest?.nw_geo_coordinate ? (
        <a
          className="wi-form-displayed-value"
          href={navigatgeToMap({ ...oldest, region: donation.region, land: donation.land, campaign: donation.campaign })}
          target="_blank"
          rel="noreferrer"
        >
          {oldest?.nw_geo_coordinate || ''}
        </a>
      ) : (
        <div className="wi-form-displayed-value">-</div>
      );
    }

    return donation?.nw_geo_coordinate ? (
      <a className="wi-form-displayed-value" href={navigatgeToMap(donation)} target="_blank" rel="noreferrer">
        {donation?.nw_geo_coordinate || ''}
      </a>
    ) : (
      <div className="wi-form-displayed-value">-</div>
    );
  };

  const navigatgeToMap = (donation: any) => {
    const parentInfo: any = {};
    if (donation && !donation.is_first_time && parentDonation && parentDonation.subDonations && donation.nw_geo_coordinate) {
      parentInfo.pdf_cerfiticate_display_name = parentDonation.pdf_cerfiticate_display_name;
      parentInfo.area = _.sumBy(parentDonation.subDonations, (c: any) => +c.area);
    }

    if (donation) {
      return `${env.CMS_URL || 'http://wild.appbisweb.com'}/map?id=${generateMapUrl({ ...donation, ...parentInfo })}`;
    }
    return '#';
  };

  const formatAmountBodyTemplate = (rowData: any) => {
    return (
      <div>
        <b style={{ color: 'black', marginRight: '5px' }}>
          {rowData ? parseFloat(rowData.donation_money?.toString())?.toLocaleString('de-DE') : '-'}
        </b>{' '}
        <span>{rowData.payment_currency_code?.toUpperCase()}</span>
      </div>
    );
  };

  const formatSerialBodyTemplate = (rowData: any) => {
    return (
      <a className="wi-form-displayed-value" href={`/donations/${rowData.uuid}`}>
        {rowData.land_id ? rowData.donation_serial : `00-${rowData.serial}`}
      </a>
    );
  };

  const formatTransactionIDBodyTemplate = (rowData: any) => {
    if (rowData?.is_imported) {
      return <span>{rowData?.payment_transaction_id || '-'}</span>;
    } else {
      return rowData.payment_transaction_id ? (
        <a className="wi-form-displayed-value" href={navigateBaseTransactionID(rowData)} target="_blank" rel="noreferrer">
          {rowData?.payment_transaction_id || ''}
        </a>
      ) : (
        '-'
      );
    }
  };

  const buildStatusSubscription = (statusData: any) => {
    const status = statusData || 'pending';
    const statusItem = SUBSCRIPTION_STATUS_LIST.find((item: any) => item.code === status);
    return <WiStatus className="wi-status-subscription" item={statusItem}></WiStatus>;
  };

  const markAsPaid = async () => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      const resUpdate = await DonationManagerAPI.updateDonation(donationID, {
        payment_first_name: donation?.payment_first_name?.trim(),
        payment_last_name: donation?.payment_last_name?.trim(),
        payment_email: donation?.payment_email.trim(),
        status: DONATION_STATUS.SUCCEEDED,
        payment_date: m.utc(m(new Date()).format('MM/DD/YYYY HH:mm:ss')).add(-1, 'hour'),
        payment_received_date: m.utc(m(new Date()).format('MM/DD/YYYY HH:mm:ss')).add(-1, 'hour'),
      });
      if (resUpdate && resUpdate.status === 200) {
        await fetchDonationDetail();
        setSuccessProgress(t('txt_update_donation_success'));
      } else {
        setErrorProgress(errorTrans('txt_failed_update_donation'));
      }
    } catch (e) {
      setErrorProgress(errorTrans('txt_failed_update_donation'));
    }
  };

  const updateDonationDetail = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, errors } = formikRef.current;

      if (dirty && _.isEmpty(errors) && !isError.paymentDate && !isError.paymentReceviedDate) {
        setLoadingProgress(errorTrans('txt_loading'));

        const paymentDate = new Date(m(values.payment_date).format('MM/DD/YY 00:00:00'));
        const paymentReceived_date = new Date(m(values.payment_received_date).format('MM/DD/YY 00:00:00'));

        const donationUpdate = {
          ...values,
          disabled_receipt: !values.enable_receipt,
          payment_date: m.utc(m(new Date(paymentDate)).format('MM/DD/YYYY HH:mm:ss')).add(-1, 'hour'),
          payment_received_date: m.utc(m(new Date(paymentReceived_date)).format('MM/DD/YYYY HH:mm:ss')).add(-1, 'hour'),
          updated_at: m.utc(m(new Date()).format('MM/DD/YYYY HH:mm:ss')),
          personalize_certificate: values.personalize_certificate_display_name ? true : false,
          payment_first_name: values.payment_first_name?.trim(),
          payment_last_name: values.payment_last_name?.trim(),
          payment_email: values.payment_email.trim(),
          payment_address: values.payment_address.trim(),
          payment_additional_address: values.payment_additional_address.trim(),
          payment_post_code: values.payment_post_code.trim(),
          payment_city: values.payment_city.trim(),
          payment_country: values.payment_country.trim(),
        };

        if (!donation?.is_imported) {
          delete donationUpdate.payment_date;
          delete donationUpdate.payment_received_date;
        }

        setIsError({
          paymentDate: false,
          paymentReceviedDate: false,
        });

        const resUpdate = await DonationManagerAPI.updateDonation(donationID, donationUpdate);
        if (resUpdate && resUpdate.status === 200) {
          await fetchDonationDetail();
          setIsEditDonation(false);
          setSuccessProgress(t('txt_update_donation_success'));
        } else {
          setErrorProgress(errorTrans('txt_failed_update_donation'));
        }
      }
    } catch (error) {
      setErrorProgress(errorTrans('txt_failed_update_donation'));
    }
  };

  const initialDonationFormValues = () => {
    return {
      personalize_certificate_display_name:
        donation && donation.personalize_certificate_display_name !== null ? donation.personalize_certificate_display_name : '',
      personalize_certificate_reason: donation && donation.personalize_certificate_reason !== null ? donation.personalize_certificate_reason : '',
      note: donation && donation.notes !== null ? (donation?.notes?.length > 0 ? donation?.notes[0].message : '') : '',
      payment_first_name: donation && donation.payment_first_name !== null ? donation.payment_first_name?.trim() : '',
      payment_last_name: donation && donation.payment_last_name !== null ? donation.payment_last_name?.trim() : '',
      payment_email: donation && donation.payment_email !== null ? donation.payment_email.trim() : '',
      payment_address: donation && donation.payment_address !== null ? donation.payment_address.trim() : '',
      payment_additional_address: donation && donation.payment_additional_address !== null ? donation.payment_additional_address.trim() : '',
      payment_post_code: donation && donation.payment_post_code !== null ? donation.payment_post_code.trim() : '',
      payment_city: donation && donation.payment_city !== null ? donation.payment_city.trim() : '',
      payment_country: donation && donation.payment_country !== null ? donation.payment_country.trim() : '',
      payment_company_name: donation && donation.payment_company_name !== null ? donation.payment_company_name : '',
      payment_date:
        donation && donation.payment_date !== null
          ? new Date(donation.payment_date)
          : new Date(moment.tz(new Date(), 'Europe/Berlin').format('MMM DD, YYYY HH:mm:ss')),
      payment_received_date:
        donation && donation.payment_received_date !== null
          ? new Date(donation.payment_received_date)
          : new Date(moment.tz(new Date(), 'Europe/Berlin').format('MMM DD, YYYY HH:mm:ss')),
      is_anonymous: donation && donation.is_anonymous !== null ? donation.is_anonymous : false,
      disabled_receipt: donation && donation.disabled_receipt !== null ? donation.disabled_receipt : false,
      enable_receipt: donation && donation.disabled_receipt !== null ? !donation.disabled_receipt : true,
      partner_id: donation && donation.partner_id !== null ? donation.partner_id : '',
    };
  };

  const validationDonationSchema = Yup.object().shape({
    payment_email: Yup.string().email(errorTrans('txt_validate_email')).required(errorTrans('txt_required')),
  });

  const tableColumns = [
    {
      field: 'donation_serial',
      header: t('txt_ID'),
      width: '10%',
      align: 'left',
      bodyTemplate: formatSerialBodyTemplate,
    },
    {
      field: 'donation_money',
      header: t('txt_amount'),
      width: '10%',
      align: 'right',
      bodyTemplate: formatAmountBodyTemplate,
    },
    {
      field: 'status',
      header: '',
      width: '15%',
      align: 'center',
      bodyTemplate: (rowData: any) => {
        return <WiStatus item={DONATION_STATUS_LIST.find((item: any) => item.code === rowData?.status)}></WiStatus>;
      },
      disabledSort: true,
    },
    {
      field: 'rhythm',
      header: t('txt_rhythm'),
      width: '12%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        const rhythm = globalTrans(DONATION_RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name || 'rhythms.txt_once');
        return rhythm;
      },
    },
    {
      field: 'payment_transaction_id',
      header: t('txt_transaction_id'),
      width: '20%',
      align: 'left',
      bodyTemplate: formatTransactionIDBodyTemplate,
    },
    {
      field: 'payment_date',
      header: t('txt_date'),
      width: '18%',
      align: 'left',
      bodyTemplate: (rowData: any) => formatDateTimeV2(rowData.payment_date, getLanguage()),
    },
    {
      field: 'payment_method',
      header: t('txt_method'),
      width: '15%',
      align: 'left',
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
        }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
        sortable={!col.disabledSort}
      />
    );
  });

  const tableColumnsReceipt = [
    {
      field: 'payment_date',
      header: t('txt_date'),
      width: '30%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return rowData.payment_date;
      },
    },
    {
      field: 'total_amount',
      header: t('txt_amount'),
      width: '30%',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return (
          <div>
            <b style={{ color: 'black', marginRight: '5px' }}>
              {rowData ? parseFloat(rowData.total_amount?.toString())?.toLocaleString('de-DE') : '-'}
            </b>{' '}
            <span>{donation?.payment_currency_code?.toUpperCase()}</span>
          </div>
        );
      },
    },
    {
      field: 'total_protected_forest',
      header: t('txt_protected_forest'),
      width: 'calc(40% - 64px)',
      align: 'left',
      bodyTemplate: (rowData: any) => {
        return (
          <div>
            <b style={{ color: 'black', marginRight: '5px' }}>
              {rowData ? parseFloat(rowData.total_protected_forest?.toString())?.toLocaleString('de-DE') : '-'}
            </b>{' '}
            <span>m²</span>
          </div>
        );
      },
    },
  ];

  const dynamicColumnsReceipt = tableColumnsReceipt.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  useEffect(() => {
    fetchCountryData();
    fetchDonationDetail();
    fetchPartnerData();
  }, []);

  return (
    <Layout title={t('txt_donation_details')}>
      {isLoading ? (
        <div className="loading-component">
          <ProgressSpinner />
        </div>
      ) : (
        <div className="donation-detail mt-16 pb-24 pl-24 pr-24">
          <div className="donation-detail__header">
            <div className="header-container">
              <div className="amount">
                <div className="amount-container">
                  <div className="value-amount"> {donation ? parseFloat(donation.donation_money?.toString())?.toLocaleString('de-DE') : ''} </div>
                  <div className="currency-amount">{donation?.payment_currency_code?.toUpperCase() || ''} </div>
                  <WiStatus item={DONATION_STATUS_LIST.find((item: any) => item.code === donation?.status)}></WiStatus>
                </div>
              </div>
              <div className="btn-action-container">
                <h3 className="h3 donation-serial mb-0 mr-8">{donation?.land_id ? donation?.donation_serial || '' : `00-${donation?.serial}`}</h3>
                {donation?.is_first_time ? <MenuDot items={itemsDonationAction}></MenuDot> : ''}
              </div>
            </div>
            <div className="row row-amount">
              {isEditDonation && (
                <div className="d-flex gap-16 pb-16 pr-0 justify-content-end">
                  <Button
                    label={t('txt_cancel')}
                    className="wi-danger-button-v2"
                    onClick={() => {
                      // @ts-ignore: Object is possibly 'null'.
                      formikRef?.current?.resetForm();
                      setIsEditDonation(false);
                    }}
                  />
                  <Button type="submit" label={t('txt_save')} className="wi-primary-button-v2" onClick={() => updateDonationDetail()} />
                </div>
              )}
            </div>
          </div>
          <div className="donation-detail__content">
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialDonationFormValues()}
              validationSchema={validationDonationSchema}
              onSubmit={(values, actions) => {
                // nothing to do
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div className="donation-detail__container d-flex flex-column gap-32">
                    <div className="row first-row mt-32">
                      <div className="col-12 col-md-6 donation-item">
                        <div className="donation-detail__information">
                          <div className="donation-detail__information-title">
                            <h3 className="h3 wi-form-section-title"> {t('txt_donation_information')} </h3>
                          </div>
                          <div className="donation-detail__information-content">
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_forest_size')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">
                                  {donation ? parseFloat(donation.area.toString())?.toLocaleString('de-DE') : '-'} m²
                                </div>
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_compensated_co2')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">
                                  {donation ? parseFloat(donation.amount_kg_co2.toString())?.toLocaleString('de-DE') : '-'} kg
                                </div>
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_region')}</label>
                              </div>
                              <div className="col-md-8">
                                {donation?.region?.uuid ? (
                                  <Link
                                    className={`wi-form-displayed-value ${permissions.canViewRegion ? '' : 'disabled'}`}
                                    to={`/regions/${donation?.region?.uuid}` || '#'}
                                    target="_blank"
                                  >
                                    {donation?.region?.name['de'] || ''}{' '}
                                  </Link>
                                ) : (
                                  <div className="wi-form-displayed-value">-</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_area')}</label>
                              </div>
                              <div className="col-md-8">
                                {donation?.land?.uuid ? (
                                  <Link
                                    className={`wi-form-displayed-value ${permissions.canViewArea ? '' : 'disabled'}`}
                                    to={`/areas/${donation?.land?.uuid}` || '#'}
                                    target="_blank"
                                  >
                                    {`${donation?.land?.name?.de || donation?.land?.name}`}
                                  </Link>
                                ) : (
                                  <div className="wi-form-displayed-value">-</div>
                                )}
                              </div>
                            </div>
                            {donation && donation?.campaign && (
                              <div className="row content-row">
                                <div className="col-md-4">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_campaign')}</label>
                                </div>
                                <div className="col-md-8">
                                  {donation?.campaign?.uuid ? (
                                    <Link
                                      className={`wi-form-displayed-value ${permissions.canViewCampaign ? '' : 'disabled'}`}
                                      to={`/campaigns/${donation?.campaign?.uuid}` || '#'}
                                      target="_blank"
                                    >
                                      {donation?.campaign?.name['de'] || ''}{' '}
                                    </Link>
                                  ) : (
                                    <div className="wi-form-displayed-value">-</div>
                                  )}
                                </div>
                              </div>
                            )}
                            {code && (
                              <>
                                <div className="row content-row">
                                  <div className="col-md-4">
                                    <label className="wi-form-title text-secondary-gray">{t('txt_code')}</label>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="wi-form-displayed-value">{`${code.prefix_code}-${code.code}` || ''}</div>
                                  </div>
                                </div>
                                <div className="row content-row">
                                  <div className="col-md-4">
                                    <label className="wi-form-title text-secondary-gray">{t('txt_set')}</label>
                                  </div>
                                  <div className="col-md-8">
                                    {code.batch_id ? (
                                      <Link
                                        className={`wi-form-displayed-value ${permissions.canViewCode ? '' : 'disabled'}`}
                                        to={`/codes/${code.batch_id}` || '#'}
                                        target="_blank"
                                      >
                                        {`${code?.batch?.name}` || ''}{' '}
                                      </Link>
                                    ) : (
                                      <div className="wi-form-displayed-value">-</div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {donation?.boosted_code_id && batchCode ? (
                              <div className="row content-row">
                                <div className="col-md-4">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_boosted_by')}</label>
                                </div>
                                <div className="col-md-8">
                                  {
                                    <Link
                                      className={`wi-form-displayed-value ${permissions.canViewCode ? '' : 'disabled'}`}
                                      to={
                                        `/codes/${batchCode?.batch_id}?pageNumber=1&noRows=20&search=${batchCode?.code}&sortField=code&filterStatus=active%2Credeemed%2Cinactive%2Cinvalid` ||
                                        '#'
                                      }
                                      target="_blank"
                                    >
                                      {batchCode?.prefix_code} - {batchCode?.code}
                                    </Link>
                                  }
                                </div>
                              </div>
                            ) : (
                              <div />
                            )}
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_geo_coordinate_dms')}</label>
                              </div>
                              <div className="col-md-8">
                                {[DONATION_STATUS.SUCCEEDED, DONATION_STATUS.RESERVED].includes(donation?.status) ? (
                                  getGeoCoordinate(donation)
                                ) : (
                                  <div className="wi-form-displayed-value">-</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_payment_method')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">{renderPaymentMethod(donation)}</div>
                              </div>
                            </div>
                            <div className="row content-row" style={{ display: donation?.matchFunding?.matchFundingCode ? 'flex' : 'none' }}>
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_match_funding_rule_id')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">{donation?.matchFunding?.matchFundingCode || '-'}</div>
                              </div>
                            </div>
                            <div className="row content-row" style={{ display: donation?.matchFunding?.matchFundingValue ? 'flex' : 'none' }}>
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_actual_paid_amount')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">
                                  {(
                                    parseFloat((donation?.donation_money || 0).toString()) -
                                    parseFloat((donation?.matchFunding?.matchFundingValue || 0).toString())
                                  ).toLocaleString('de-DE')}{' '}
                                  {donation?.payment_currency_code?.toUpperCase()}
                                </div>
                              </div>
                            </div>
                            <div className="row content-row" style={{ display: donation?.matchFunding?.matchFundingValue ? 'flex' : 'none' }}>
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_match_funding_amount')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">
                                  {parseFloat((donation?.matchFunding?.matchFundingValue || 0).toString()).toLocaleString('de-DE')}{' '}
                                  {donation?.payment_currency_code?.toUpperCase()}
                                </div>
                              </div>
                            </div>
                            <div className="row content-row" style={{ display: donation?.matchFunding?.matchFundingProtectedM2 ? 'flex' : 'none' }}>
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_protected_by_donor')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">
                                  {(
                                    parseFloat((donation?.area || 0).toString()) -
                                    parseFloat((donation?.matchFunding?.matchFundingProtectedM2 || 0).toString())
                                  ).toLocaleString('de-DE')}{' '}
                                  m²
                                </div>
                              </div>
                            </div>
                            <div className="row content-row" style={{ display: donation?.matchFunding?.matchFundingProtectedM2 ? 'flex' : 'none' }}>
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_protected_by_match_funding_rule')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">
                                  {parseFloat((donation?.matchFunding?.matchFundingProtectedM2 || 0).toString()).toLocaleString('de-DE')} m²
                                </div>
                              </div>
                            </div>
                            <div className="row content-row" style={{ display: donation?.coupon ? 'flex' : 'none' }}>
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_coupon')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">{donation?.coupon?.name || '-'}</div>
                              </div>
                            </div>
                            <div className="row content-row" style={{ display: donation?.coupon ? 'flex' : 'none' }}>
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_actual_paid_amount')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">
                                  {(
                                    parseFloat((donation?.donation_money || 0).toString()) -
                                    parseFloat((donation?.coupon?.coupon_value || 0).toString())
                                  ).toLocaleString('de-DE')}{' '}
                                  {donation?.payment_currency_code?.toUpperCase()}
                                </div>
                              </div>
                            </div>
                            <div className="row content-row" style={{ display: donation?.coupon ? 'flex' : 'none' }}>
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_coupon_value')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">
                                  {parseFloat((donation?.coupon?.coupon_value || 0).toString()).toLocaleString('de-DE')}{' '}
                                  {donation?.coupon?.currency_code?.toUpperCase()}
                                </div>
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_transaction_id')}</label>
                              </div>
                              <div className="col-md-8">
                                {donation?.is_imported ? (
                                  <div className="wi-form-displayed-value">{donation?.payment_transaction_id || '-'}</div>
                                ) : donation?.payment_transaction_id ? (
                                  <a className="wi-form-displayed-value" href={navigateBaseTransactionID(donation)} target="_blank" rel="noreferrer">
                                    {donation?.payment_transaction_id || ''}
                                  </a>
                                ) : (
                                  <div className="wi-form-displayed-value">-</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_donation_date')}</label>
                              </div>
                              <div className="col-md-8">
                                {isEditDonation && donation?.is_imported ? (
                                  <div className="edit-donation-field">
                                    <Calendar
                                      className={`${touched.payment_date && errors.payment_date ? 'has-error' : ''}`}
                                      name="payment_date"
                                      value={values.payment_date}
                                      onChange={e => {
                                        setFieldValue('payment_date', e.value || values.payment_date, false);
                                        const isValidate = compareDate(e.value, values.payment_received_date);
                                        setIsError({
                                          paymentDate: isValidate,
                                          paymentReceviedDate: isValidate && false,
                                        });
                                      }}
                                    />
                                    {isError.paymentDate ? <small className="error">{errorTrans('txt_donation_date_earlier')}</small> : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">{formDate(donation?.payment_date, getLanguage())}</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_payment_received_date')}</label>
                              </div>
                              <div className="col-md-8">
                                {isEditDonation && donation?.is_imported ? (
                                  <div className="edit-donation-field">
                                    <Calendar
                                      className={`${touched.payment_received_date && errors.payment_received_date ? 'has-error' : ''}`}
                                      name="payment_received_date"
                                      value={values.payment_received_date}
                                      onChange={e => {
                                        setFieldValue('payment_received_date', e.value || values.payment_received_date, false);
                                        const isValidate = compareDate(values.payment_date, e.value);
                                        setIsError({
                                          paymentDate: isValidate && false,
                                          paymentReceviedDate: isValidate,
                                        });
                                      }}
                                    />
                                    {isError.paymentReceviedDate ? (
                                      <small className="error">{errorTrans('txt_payment_received_date_later')}</small>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">{formDate(donation?.payment_received_date, getLanguage())}</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_last_update_date')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">{formDate(donation?.updated_at, getLanguage())}</div>
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_donation_type')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">{renderDonationType()}</div>
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_rhythm')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">{renderRhythm(donation) || 'Once'}</div>
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_goal')}</label>
                              </div>
                              <div className="col-md-8">
                                <div className="wi-form-displayed-value">{goal?.code || '-'}</div>
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-4">
                                <label className="wi-form-title text-secondary-gray">{t('txt_parent_donation')}</label>
                              </div>
                              <div className="col-md-8">
                                {parentDonation?.donation_serial ? (
                                  <a className="wi-form-displayed-value" href={navigateToParentName()} target="_blank" rel="noreferrer">
                                    {renderParentName()}{' '}
                                  </a>
                                ) : (
                                  <div className="wi-form-displayed-value">-</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 donation-item">
                        <div className="donation-detail__information">
                          <div className="donation-detail__information-title">
                            <h3 className="h3 wi-form-section-title">{t('txt_donor_information')}</h3>
                          </div>
                          <div className="donation-detail__information-content">
                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_name')}</label>
                              </div>
                              <div className="col-md-7">
                                <div className="wi-form-displayed-value">
                                  {donation?.payment_first_name || ''} {donation?.payment_last_name || ''}
                                </div>
                              </div>
                            </div>

                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_email')}</label>
                              </div>
                              <div className="col-md-7">
                                <div className="wi-form-displayed-value">{donation?.payment_email || '-'}</div>
                              </div>
                            </div>

                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_address')}</label>
                              </div>
                              <div className="col-md-7">
                                {isEditDonation ? (
                                  <div className="edit-donation-field">
                                    <input
                                      className={`${touched.payment_address && errors.payment_address ? 'has-error' : ''}`}
                                      type="text"
                                      name="payment_address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.payment_address}
                                      maxLength={100}
                                    />
                                    {errors.payment_address && touched.payment_address ? (
                                      <small className="error">{errors.payment_address}</small>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">{donation?.payment_address || '-'}</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_additional_address')}</label>
                              </div>
                              <div className="col-md-7">
                                {isEditDonation ? (
                                  <div className="edit-donation-field">
                                    <input
                                      className={`${touched.payment_additional_address && errors.payment_additional_address ? 'has-error' : ''}`}
                                      type="text"
                                      name="payment_additional_address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.payment_additional_address}
                                      maxLength={100}
                                    />
                                    {errors.payment_additional_address && touched.payment_additional_address ? (
                                      <small className="error">{errors.payment_additional_address}</small>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">{donation?.payment_additional_address || '-'}</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_postal_code')}</label>
                              </div>
                              <div className="col-md-7">
                                {isEditDonation ? (
                                  <div className="edit-donation-field">
                                    <input
                                      className={`${touched.payment_post_code && errors.payment_post_code ? 'has-error' : ''}`}
                                      type="text"
                                      name="payment_post_code"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.payment_post_code}
                                      maxLength={10}
                                    />
                                    {errors.payment_post_code && touched.payment_post_code ? (
                                      <small className="error">{errors.payment_post_code}</small>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">{donation?.payment_post_code || '-'}</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_city')}</label>
                              </div>
                              <div className="col-md-7">
                                {isEditDonation ? (
                                  <div className="edit-donation-field">
                                    <input
                                      className={`${touched.payment_city && errors.payment_city ? 'has-error' : ''}`}
                                      type="text"
                                      name="payment_city"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.payment_city}
                                      maxLength={85}
                                    />
                                    {errors.payment_city && touched.payment_city ? <small className="error">{errors.payment_city}</small> : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">{donation?.payment_city || '-'}</div>
                                )}
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_country')}</label>
                              </div>
                              <div className="col-md-7">
                                {isEditDonation ? (
                                  <div className="edit-donation-field">
                                    <Dropdown
                                      id="dropdown_countries"
                                      value={values.payment_country}
                                      options={countries}
                                      onChange={(item: any) => {
                                        setFieldValue('payment_country', item.value, true);
                                      }}
                                      optionLabel="name"
                                      optionValue="ISO2"
                                      appendTo="self"
                                      filter
                                      filterBy="name"
                                      filterPlaceholder={t('txt_search')}
                                    />
                                    {errors.payment_country && touched.payment_country ? (
                                      <small className="error">{errors.payment_country}</small>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">{renderCountryName()}</div>
                                )}
                              </div>
                            </div>
                            {isEditDonation ? (
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_company_name')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="edit-donation-field">
                                    <input
                                      className={`${touched.payment_company_name && errors.payment_company_name ? 'has-error' : ''}`}
                                      type="text"
                                      name="payment_company_name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.payment_company_name}
                                      maxLength={80}
                                    />
                                    {errors.payment_company_name && touched.payment_company_name ? (
                                      <small className="error">{errors.payment_company_name}</small>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_company_name')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="edit-donation-field">
                                    <div className="wi-form-displayed-value">{donation?.payment_company_name || '-'}</div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_partner')}</label>
                              </div>
                              <div className="col-md-7">
                                {isEditDonation ? (
                                  <div className="edit-donation-field">
                                    <Dropdown
                                      id="dropdown_partners"
                                      value={values.partner_id}
                                      className="dropdown_partners_edit"
                                      options={partners}
                                      onChange={(item: any) => {
                                        setFieldValue('partner_id', item.value, true);
                                      }}
                                      optionLabel="name"
                                      optionValue="uuid"
                                      appendTo="self"
                                      filter
                                      filterBy="name"
                                      filterPlaceholder={t('txt_search')}
                                    />
                                    {errors.partner_id && touched.partner_id ? <small className="error">{errors.partner_id}</small> : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">{renderPartnerName()}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-container">
                      <div className="col-12 col-md-6 donation-item">
                        <div className="donation-detail__information">
                          <div className="donation-detail__information-title">
                            <h3 className="h3 wi-form-section-title">{t('txt_timeline')}</h3>
                          </div>
                          <div className="donation-detail__information-content">
                            <Timeline
                              value={eventsPayment.filter(e => !!e)}
                              className="customized-timeline"
                              marker={customizedMarker}
                              content={customizedContent}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 donation-item">
                        <div className="donation-detail__information">
                          <div className="donation-detail__information-title">
                            <h3 className="h3 wi-form-section-title">{t('txt_orther_information')}</h3>
                          </div>
                          <div className="donation-detail__information-content">
                            {isEditDonation ? (
                              // <AnimateHeight
                              //     duration={300}
                              //     height={values?.personalize_certificate ? `auto` : 0}
                              // >
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_certificate_name')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="edit-donation-field">
                                    <input
                                      className={`${touched.personalize_certificate_display_name && errors.personalize_certificate_display_name ? 'has-error' : ''}`}
                                      type="text"
                                      name="personalize_certificate_display_name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.personalize_certificate_display_name}
                                      maxLength={80}
                                    />
                                    {errors.personalize_certificate_display_name && touched.personalize_certificate_display_name ? (
                                      <small className="error">{errors.personalize_certificate_display_name}</small>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              // </AnimateHeight>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_certificate_name')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">
                                    {parentDonation?.personalize_certificate_display_name || donation?.personalize_certificate_display_name || '-'}
                                  </div>
                                </div>
                              </div>
                            )}

                            {isEditDonation && !parseJSON(code?.batch?.certificate_occasion) ? (
                              // <AnimateHeight
                              //     duration={300}
                              //     height={values?.personalize_certificate ? `auto` : 0}
                              // >
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_occasion')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="edit-donation-field">
                                    <input
                                      className={`${touched.personalize_certificate_reason && errors.personalize_certificate_reason ? 'has-error' : ''}`}
                                      type="text"
                                      name="personalize_certificate_reason"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.personalize_certificate_reason}
                                      maxLength={80}
                                      disabled={parseJSON(code?.batch?.certificate_occasion)}
                                    />
                                    {errors.personalize_certificate_reason && touched.personalize_certificate_reason ? (
                                      <small className="error">{errors.personalize_certificate_reason}</small>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              // </AnimateHeight>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_occasion')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">{renderOccasion()}</div>
                                </div>
                              </div>
                            )}

                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_subscribe_to_the_newsletter')}</label>
                              </div>
                              <div className="col-md-7">
                                <div className="wi-form-displayed-value">
                                  {parentDonation?.subscribe_newsletter || donation?.subscribe_newsletter ? 'Yes' : 'No'}
                                </div>
                              </div>
                            </div>
                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_is_manually_added')}</label>
                              </div>
                              <div className="col-md-7">
                                <div className="wi-form-displayed-value">{donation?.is_imported ? 'Yes' : 'No' || 'No'}</div>
                              </div>
                            </div>
                            {isEditDonation ? (
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_anonymous')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="edit-donation-field">
                                    <Checkbox name="is_anonymous" onChange={handleChange} checked={values.is_anonymous} />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_anonymous')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">{donation?.is_anonymous ? 'Yes' : 'No' || 'No'}</div>
                                </div>
                              </div>
                            )}
                            {isEditDonation ? (
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_receipt')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="edit-donation-field">
                                    <Checkbox
                                      name="enable_receipt"
                                      onChange={handleChange}
                                      checked={values.enable_receipt}
                                      disabled={
                                        parseFloat((donation?.coupon?.coupon_value || 0).toString()) >=
                                        parseFloat((donation?.donation_money || 0).toString())
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_receipt')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">{!donation?.disabled_receipt ? 'Yes' : 'No'}</div>
                                </div>
                              </div>
                            )}
                            {!!donation?.old_donation_id ? (
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_old_donation_id')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">{donation?.old_donation_id}</div>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {!!donation?.old_parent_donation ? (
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_old_parent_donation')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">{donation?.old_parent_donation}</div>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                            <div className="row content-row">
                              <div className="col-md-5">
                                <label className="wi-form-title text-secondary-gray">{t('txt_note')}</label>
                              </div>
                              <div className="col-md-7">
                                {isEditDonation ? (
                                  <div className="edit-donation-field">
                                    <InputTextarea
                                      className={`${touched.note && errors.note ? 'has-error' : ''}`}
                                      name="note"
                                      value={values.note}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      rows={5}
                                      autoResize
                                    />
                                    {errors.note && touched.note ? <small className="error">{errors.note}</small> : null}
                                  </div>
                                ) : (
                                  <div className="wi-form-displayed-value">
                                    {donation && donation?.notes?.length > 0 ? donation?.notes[0].message : '-'}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {donation && donation.rhythm !== 0 && !donation?.parent_donation_id ? (
                      <div className="row">
                        <div className="col-12 col-md-12 donation-item">
                          <div className="donation-detail__information">
                            <div className="donation-detail__information-title">
                              <h3 className="h3 wi-form-section-title">{t('txt_subscription_information')}</h3>
                            </div>
                            <div className="donation-detail__information-content">
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_total')}</label>
                                </div>
                                <div className="col-md-7 status-subscription">
                                  <div className="wi-form-displayed-value">
                                    {parseFloat(childrendonation.totalCharged?.toString())?.toLocaleString('de-DE') || '-'}{' '}
                                    {subscriptionData?.currency?.toUpperCase()}
                                  </div>
                                </div>
                              </div>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_stripe_subscription_id')}</label>
                                </div>
                                <div className="col-md-7">
                                  {subscriptionData?.id ? (
                                    <a className="wi-form-displayed-value" href={navigateToStripe()} target="_blank" rel="noreferrer">
                                      {subscriptionData?.id || ''}{' '}
                                    </a>
                                  ) : (
                                    <div className="wi-form-displayed-value">-</div>
                                  )}
                                </div>
                              </div>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_start_date')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">
                                    {formDate(subscriptionData?.created_at || donation?.payment_date, getLanguage())}
                                  </div>
                                </div>
                              </div>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_end_date')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">{formDate(subscriptionData?.finished_date, getLanguage())}</div>
                                </div>
                              </div>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_next_collection_date')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">
                                    {subscriptionData?.status === 'finished' ? '-' : formDate(subscriptionData?.collection_date, getLanguage())}
                                  </div>
                                </div>
                              </div>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_next_collection_amount')}</label>
                                </div>
                                <div className="col-md-7">
                                  {subscriptionData?.status === 'finished' ? (
                                    <div className="wi-form-displayed-value">-</div>
                                  ) : (
                                    <div className="wi-form-displayed-value">
                                      {subscriptionData?.amount ? parseFloat(subscriptionData?.amount?.toString())?.toLocaleString('de-DE') : '-'}{' '}
                                      {subscriptionData?.currency?.toUpperCase()}{' '}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_next_collection_payment_method')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">
                                    {subscriptionData?.status === 'finished'
                                      ? '-'
                                      : renderPaymentMethod(childrendonation.data.length > 0 ? childrendonation.data[0] : donation)}
                                  </div>
                                </div>
                              </div>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_current_rhythm')}</label>
                                </div>
                                <div className="col-md-7">
                                  <div className="wi-form-displayed-value">{renderRhythm(subscriptionData) || '-'}</div>
                                </div>
                              </div>
                              <div className="row content-row">
                                <div className="col-md-5">
                                  <label className="wi-form-title text-secondary-gray">{t('txt_status')}</label>
                                </div>
                                <div className="col-md-7 status-subscription">
                                  <div className="wi-form-displayed-value">
                                    {subscriptionData?.status ? buildStatusSubscription(subscriptionData?.status || 'pending') : '-'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}

                    {donation && donation.rhythm !== 0 && !donation?.parent_donation_id ? (
                      <div className="row">
                        <div className="col-12 col-md-12 donation-item">
                          <div className="donation-detail__information">
                            <div className="donation-detail__information-title">
                              <h3 className="h3 wi-form-section-title">{t('txt_yearly_donation_receipt_certificates')}</h3>
                            </div>
                          </div>
                          <div className="table-detail-container">
                            <div className="table-children">
                              <DataTable
                                loading={isLoading}
                                value={donationReceipts}
                                scrollable
                                scrollHeight="calc(100vh - 166px)"
                                scrollDirection="both"
                                responsiveLayout="scroll"
                              >
                                {dynamicColumnsReceipt}
                                <Column
                                  frozen
                                  alignFrozen="right"
                                  align="right"
                                  style={{ width: '64px' }}
                                  body={rowData => {
                                    return (
                                      <MenuDot
                                        items={[
                                          {
                                            label: t('txt_download_receipt'),
                                            icon: 'wi-download',
                                            command: (e: any) => {
                                              setLoadingProgress(errorTrans('txt_loading'));
                                              onDownloadReceiptDonationByYear(donation, rowData.year, donation?.payment_currency_code)
                                                .then(() => {
                                                  setSuccessProgress(errorTrans('txt_generate_success'));
                                                })
                                                .catch((err: any) => {
                                                  setErrorProgress(errorTrans(err.message));
                                                });
                                            },
                                            // description: 'Download receipt as PDF'
                                          },
                                          {
                                            label: t('txt_download_certificate'),
                                            icon: 'wi-download',
                                            command: (e: any) => {
                                              setLoadingProgress(errorTrans('txt_loading'));
                                              onDownloadCertificateDonationByYear(donation, rowData.year)
                                                .then(() => {
                                                  setSuccessProgress(errorTrans('txt_generate_success'));
                                                })
                                                .catch((err: any) => {
                                                  setErrorProgress(errorTrans(err.message));
                                                });
                                            },
                                            // description: 'Download certificate as PDF'
                                          },
                                        ]}
                                      ></MenuDot>
                                    );
                                  }}
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </form>
              )}
            </Formik>
            {childrendonation && childrendonation.data.length > 0 ? (
              <div className="table-donation-detail pt-32">
                <div className="headline-children text-left">
                  <h3 className="h3 wi-form-section-title">{t('txt_subscription_history')}</h3>
                </div>
                <div className="table-detail-container">
                  <div className="table-children">
                    <DataTable
                      loading={isLoading}
                      value={childrendonation.data}
                      scrollable
                      scrollHeight="calc(100vh - 166px)"
                      scrollDirection="both"
                      responsiveLayout="scroll"
                      paginator
                      paginatorClassName="wi-paginator-container-v2"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={25}
                      rowsPerPageOptions={[5, 25, 50, 100, 250]}
                      paginatorDropdownAppendTo="self"
                    >
                      {dynamicColumns}
                    </DataTable>
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
      <WIConfirmDialog
        visible={isShowConfirm}
        onHide={() => setIsShowConfirm(false)}
        onConfirm={() => {
          setIsShowConfirm(false);
          markAsPaid();
        }}
        hideLabel={t('txt_no')}
        confirmLabel={t('txt_yes')}
        className="info-dialog"
        classIcon="fa-solid fa-info mr-3 dialog-icon"
        message={<span style={{ fontSize: '18px', fontWeight: '700' }}>{t('txt_mark_donation_success')}</span>}
      />
      <Sidebar
        visible={isShowSidebarDonor}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '700px' }}
        onHide={() => setIsShowSidebarDonor(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_edit_donor')}</h6>
          </div>
          <AddDonorFormComponent
            countries={countries}
            fetchCallBack={(donorItem: any) => fetchDonationDetail()}
            donor={donation}
            id={donation?.donor_id}
            onHide={() => setIsShowSidebarDonor(false)}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '35em' }}
        onHide={() => onHideChangeDonor()}
      >
        <div className="sidebar-content">
          <div className="headline  pt-24 pb-24">
            <h6> {t('txt_change_donor')} </h6>
          </div>
          <ChangeDonorForm
            donors={donors}
            setDonors={setDonors}
            donationID={donationID}
            onHide={() => onHideChangeDonor()}
            donation={donation}
            fetchDonationDetail={fetchDonationDetail}
            selectedDonor={selectedDonor}
            setSelectedDonor={setSelectedDonor}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebarCampaign}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '35em' }}
        onHide={() => onHideAssignCampaign()}
      >
        <div className="sidebar-content">
          <div className="headline  pt-24 pb-24">
            <h6> {t('txt_assign_campaign')} </h6>
          </div>
          <AssignCampaignForm
            campaigns={campaigns}
            donationID={donationID}
            onHide={() => onHideAssignCampaign()}
            donation={donation}
            fetchDonationDetail={fetchDonationDetail}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowCreateCertificateDialog}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '500px' }}
        onHide={() => setIsShowCreateCertificateDialog(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_download_certificate')}</h6>
          </div>
          <GenerateCertificateDonationDialogComponent donation={donation} onHide={() => setIsShowCreateCertificateDialog(false)} />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default DonationDetail;
