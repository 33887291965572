import * as React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Sidebar } from 'primereact/sidebar';
import { PartnerManagerAPI, LandManagerAPI, CouponsManagerAPI, RegionManagerAPI } from '../../services';
import { formatIntergerV2, onExportCoupon } from '../../utils/logic';
import { COUPON_STATUS_LIST } from '../../utils/utils';
import Layout from '../../layout/layout';
import WISearchField from '../../components_v2/common/wi-search-field/wi-search-field';
import WIPaginator from '../../components_v2/common/wi-paginator/wi-paginator';
import MenuDot from '../../components_v2/common/menu-dot/menu-dot';
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';
import { getCouponObjectSearchValue, getCouponObjectWithValues, getCouponPayloadConditions } from './code-manager-util';
import useAuth from '../../context/useAuth';
import { PERMISSIONS_V2 } from '../../utils/constants';
import { useLayoutV2 } from '../../context/LayoutProvider';
import CodeFilterForm from './components/code-filter-form';
import { WiProgress } from '../../components_v2/common/wi-progress/wi-progress';

export type CodeFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    land_id: string[];
    partner_id: string[];
    status: string[];
    dates: string[];
  };
  order: [];
};

const CodeManagerV2 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const sortField = searchParams?.get('sortField') || 'serial';
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const filterLands = searchParams?.get('filterLands') ? searchParams?.get('filterLands')?.split(',') || [] : [];
  const filterPartners = searchParams?.get('filterPartners') ? searchParams?.get('filterPartners')?.split(',') || [] : [];
  const filterStatus = (searchParams?.get('filterStatus') || COUPON_STATUS_LIST.map(c => c.code).join(',')).split(',');
  const filterDates = searchParams?.get('filterDates') ? searchParams?.get('filterDates')?.split(',') || [] : [];
  const [search, setSearch] = useState<any>(null);
  const searchField = searchParams?.get('search') || '';
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  const { t } = useTranslation('language', { keyPrefix: 'codes_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { getLanguage, setErrorProgress, setLoadingProgress, setSuccessProgress } = useLayoutV2();

  const statusTypeAll = 'all';
  const [isLoading, setIsLoading] = useState(false);
  const [lands, setLands] = useState([]);
  const [partners, setPartners] = useState([]);
  const [regions, setRegions] = useState([]);
  const [coupons, setCoupons] = useState({
    data: [],
    totalPage: 0,
  });
  const [page, setPage] = useState(pageNumber);
  const [first, setFirst] = useState(pageNumber * noRows);
  const [rows, setRows] = useState(noRows);
  const [selectedStatusCoupon, setSelectedStatusCoupon] = useState<any>(filterStatus.length !== 1 ? statusTypeAll : filterStatus[0]);
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const [filterValues, setFilterValues] = useState<CodeFilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getCouponObjectSearchValue(searchField) : [],
      land_id: filterLands,
      partner_id: filterPartners,
      status: filterStatus,
      dates: filterDates,
    },
    order: [],
  });
  const navigate = useNavigate();
  const { auth } = useAuth();

  const permissions = {
    canCreateCode: auth?.permissions?.includes(PERMISSIONS_V2.CODE_CREATE),
    canViewPartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_VIEW),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW),
    canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchLandsData = async () => {
    const resLands = await LandManagerAPI.getAllLands();
    if (resLands && resLands.status === 200) {
      resLands.data.records.forEach((element: any) => {
        element.name = _.isString(element.name) ? element.name : element.name?.de;
      });
      setLands(resLands.data.records || []);
    }
  };

  const fetchPartnerData = async () => {
    const resPartner = await PartnerManagerAPI.getAllPartners(false);
    if (resPartner && resPartner.status === 200) {
      setPartners(resPartner.data.records || []);
    }
  };

  const fetchRegionData = async () => {
    const resRegions = await RegionManagerAPI.getAllRegions();
    if (resRegions && resRegions.status === 200) {
      setRegions(resRegions.data.records || []);
    }
  };

  const fetchCouponsData = async () => {
    setIsLoading(true);
    const payload = getCouponPayloadConditions(filterValues.where, sortConfig);

    try {
      const resCoupons = await CouponsManagerAPI.getCoupons({
        pageNo: page,
        range: rows,
        ...payload,
      });
      if (resCoupons && resCoupons.status === 200) {
        setIsLoading(false);
        setCoupons({
          data: resCoupons.data.records || [],
          totalPage: resCoupons.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setCoupons({
        data: [],
        totalPage: 0,
      });
    }
  };

  useEffect(() => {
    //fetchLandsData();
    fetchPartnerData();
    fetchCouponsData();
    fetchRegionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === 'number') {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });
      setSearchParams(
        {
          pageNumber: (page + 1).toString(),
          noRows: rows.toString(),
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  useEffect(() => {
    if (typeof selectedStatusCoupon === 'string') {
      let p = _.cloneDeep({ ...filterValues });
      if (selectedStatusCoupon === statusTypeAll) {
      } else {
        p.where['status'] = [selectedStatusCoupon];
      }
      setFilterValues(p);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusCoupon]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterLands: filterValues.where.land_id?.join(',') || '',
      filterPartners: filterValues.where.partner_id?.join(',') || '',
      filterStatus: filterValues.where.status?.join(',') || '',
      filterDates: filterValues.where.dates?.join(',') || '',
    };
    setSearchParams(
      _.omitBy(searchParams, p => !p),
      { replace: true },
    );
    fetchCouponsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  const onFilterChange = (values: any, fields: any) => {
    const { field, value } = values;
    const where_conditions = getCouponObjectWithValues(filterValues.where);
    fields.forEach((field: any) => {
      const data = values?.get(field);
      where_conditions[field] = data;
    });
    if (field !== 'dates') {
      where_conditions[field] = value;
    } else {
      const dates = value.map((c: any) => moment(c).format('DD/MM/YYYY'));
      where_conditions[field] = dates;
    }
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    if (selectedStatusCoupon === statusTypeAll) {
      p.where['status'] = [...COUPON_STATUS_LIST.map(c => c.code)];
    } else {
      p.where['status'] = [selectedStatusCoupon];
    }
    p.search = '';
    setFilterValues(p);
  };

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getCouponObjectWithValues(filterValues.where);
    const or_conditions = getCouponObjectSearchValue(searchText);
    where_conditions['or'] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  const navigateToDetail = (rowData: any) => {
    navigate(`/codes/${rowData.uuid ? rowData.uuid : rowData.data.uuid}`);
  };

  const tableColumns = [
    { field: 'serial', header: `${t('txt_id')}`, width: '90px', align: 'left' },
    { field: 'name', header: `${t('txt_name')}`, width: '17%', align: 'left' },
    {
      field: 'region_name',
      header: `${t('txt_region')}`,
      width: '15%',
      align: 'left',
      disabledSort: true,
      bodyTemplate: (rowData: any, column: any) => {
        const child = rowData[column.field]?.de;
        return permissions.canViewRegion && rowData.region_id && rowData.region_id ? (
          <Link onClick={e => e.stopPropagation()} className="table-ref-link-cell" to={`/regions/${rowData.region_id}`}>
            {child}
          </Link>
        ) : (
          child
        );
      },
    },
    {
      field: 'partner_name',
      header: `${t('txt_partner')}`,
      width: '13%',
      align: 'left',
      disabledSort: false,
      bodyTemplate: (rowData: any, column: any) => {
        const child = rowData[column.field];
        return permissions.canViewPartner && rowData.partner_id && rowData.partner_id ? (
          <Link onClick={e => e.stopPropagation()} className="table-ref-link-cell" to={`/partners/${rowData.partner_id}`}>
            {child}
          </Link>
        ) : (
          child
        );
      },
    },
    {
      field: 'no_valid_coupons',
      header: `${t('txt_quantity')}`,
      width: '140px',
      align: 'right',
      disabledSort: true,
      bodyTemplate: (rowData: any) => formatIntergerV2(rowData['no_valid_coupons'], getLanguage()),
    },
    {
      field: 'area',
      header: `${t('txt_value_m2')}`,
      width: '160px',
      type: 'numeric',
      align: 'right',
      bodyTemplate: (rowData: any) => formatIntergerV2(rowData['area'], getLanguage()),
    },
    {
      field: 'no_valid_coupons',
      header: `${t('txt_progress')}`,
      width: 'calc(55% - 454px)',
      minWidth: '250px',
      bodyTemplate: WiProgress('normal', 'no_valid_coupons', 'no_used_coupons'),
      align: 'center',
      disabledSort: true,
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          minWidth: col.minWidth || 'none',
          width: col.width,
        }}
        dataType={col.type}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: `${t('txt_edit')}`,
        icon: 'wi-edit',
        url: `/codes/${rowData.uuid ? rowData.uuid : rowData.data.uuid}`,
      },
      {
        label: `${t('txt_export_csv')}`,
        icon: 'wi-download',
        command: async (e: any) => {
          setLoadingProgress(errorTrans('txt_loading'));
          try {
            await onExportCoupon(e, rowData);
            setSuccessProgress(errorTrans('txt_export_success'));
          } catch (error: any) {
            setErrorProgress(errorTrans('txt_export_fail'));
          }
        },
      },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const onSelectedCell = (values: any) => {
    const { field, rowData } = values;
    if (field.includes('field')) {
      return;
    }
    navigateToDetail(rowData);
  };

  return (
    <Layout title={t('txt_title')}>
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={'pi pi-search'}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={(value: any) => onSearchFunc(search)}
            />
            <div className="filter-btn">
              <div className="filter-btn-content p-8 ml-16" onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg></FilterSvg>
              </div>
            </div>
          </div>
          <Link className="wi-primary-button-v2" to="/codes/create" hidden={!permissions.canCreateCode}>
            <div className="wi-btn-label">
              {t('txt_btn_add')}
              <PlusSvg className="icon-svg" />
            </div>
          </Link>
        </div>
      </div>

      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={coupons.data}
          scrollable
          dataKey="uuid"
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
          lazy
          onSort={(e: any) => {
            setSortConfig({
              sortField: e.sortField,
              sortOrder: e.sortOrder,
            });
          }}
          sortField={sortConfig.sortField}
          sortOrder={sortConfig.sortOrder}
          // onRowClick={navigateToDetail}
          cellSelection
          selectionMode="single"
          onSelectionChange={e => onSelectedCell(e.value)}
        >
          {dynamicColumns}
          <Column frozen alignFrozen="right" style={{ width: '64px' }} body={buildMenu}></Column>
        </DataTable>
        <WIPaginator first={first} rows={rows} totalRecords={coupons.totalPage} onPageChange={onBasicPageChange} />
      </div>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_filter')}</h6>
          </div>
          <CodeFilterForm
            fetchCallBack={() => setIsShowSidebarFilter(false)}
            filterValues={filterValues}
            onRemove={onFilterRemove}
            setSearch={setSearch}
            onSearchFunc={onSearchFunc}
            onHide={() => setIsShowSidebarFilter(false)}
            fields={['region_id', 'land_id', 'partner_id']}
            lands={lands}
            partners={partners}
            regions={regions}
            onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
          />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default CodeManagerV2;
