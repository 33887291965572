import _ from 'lodash';
import xss, { getDefaultWhiteList } from 'xss';

let customWhiteList = {};
let options = {
  whiteList: {}, // empty, means filter out all tags
  stripIgnoreTag: true, // filter out all HTML not in the whilelist
  stripIgnoreTagBody: ['script'], // the script tag is a special case, we need to filter out its content
};

export const xssFilter = (formData: any, xssConfig: any) => {
  for (const property in formData) {
    const element = formData[property];

    if (xssConfig && xssConfig[property] === 'textarea') {
      customWhiteList = { br: [] };
    } else if (xssConfig && xssConfig[property] === 'richTextEditor') {
      customWhiteList = _.assign(getDefaultWhiteList(), {
        strong: ['style'],
        em: ['style'],
        u: ['style'],
        span: ['style'],
        li: ['class'],
        p: ['class'],
        a: ['target', 'href', 'title', 'style', 'onClick'],
        sub: ['style'],
        sup: ['style'],
      });
    } else if (xssConfig && xssConfig[property] === 'textField') {
      customWhiteList = {};
    } else if (xssConfig && xssConfig[property] === 'textHtml') {
      continue;
    } else if (xssConfig && xssConfig[property] === 'textFieldCustom') {
      customWhiteList = {
        sub: ['style'],
        sup: ['style'],
      };
    }

    if (typeof element === 'object') {
      xssFilter(formData[property], xssConfig);
    } else if (typeof element === 'string') {
      options.whiteList = customWhiteList;
      formData[property] = xss(formData[property], options);
    }
  }
};

export const clearHtmlTags = (str: string) => xss(str, options);

export const convertHtmlToText = (str: any) => {
  str = str.toString();
  return str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ');
};
