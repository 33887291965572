import * as React from 'react';
import { useEffect, useState } from 'react';
import AuthManagerAPI from '../../services/authManager';
import LogoLogo from '../../assets/images/login-logo.svg';
import './login.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { env } from '../../configs';
import { HOMEPAGE_URL } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [bgLoginIndex] = useState(Math.floor(Math.random() * 12));
  const { t: errTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t } = useTranslation('language', { keyPrefix: 'login_process' });

  const validateEmail = (text: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(text);
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!email?.trim() || !validateEmail(email)) {
      setError('txt_email_format');
      return;
    }

    setError('');
    try {
      setIsLoading(true);
      const res = await AuthManagerAPI.forgotUserPassword(email);
      const result = res && res.status === 200 ? res.data.result : null;
      if (result) {
        setIsCompleted(true);
      } else {
        throw new Error(`Unhandled Log-in Logic: ${result}`);
      }
    } catch (error) {
      setError('txt_email_format');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate(HOMEPAGE_URL, { replace: true });
    }
  });

  return (
    <div className="login-container">
      <img src={`${env.PUBLIC_IMAGE_URL}/bg_login${bgLoginIndex}.jpg`} alt="" className="background-cover" />
      <div className="background-backdrop"></div>
      <div className="login-form-wrapper">
        <img src={LogoLogo} alt="" className="login-logo" />
        <div className="login-form">
          {isCompleted ? (
            <>
              <h2 className="login-title mb-24 w-100 text-center">{t('txt_please_check_your_email')}</h2>
              <div className="note mb-24" dangerouslySetInnerHTML={{ __html: t('txt_please_check_your_email_note', { email }) }}></div>
              <div className="forgot-pass-wrapper">
                <div>{t('txt_didnt_receive_email')}</div>
                <a
                  className="ml-4"
                  href="/forgot-password"
                  onClick={() => {
                    setIsCompleted(false);
                    setError('');
                  }}
                >
                  {t('txt_retry')}
                </a>
              </div>
            </>
          ) : (
            <>
              <h2 className="login-title mb-24 w-100 text-center">{t('txt_title_reset_password')}</h2>
              <div className="note color-gray text-center mb-24">{t('txt_forgot_password_instructions')}</div>
              <form className="row-common" onSubmit={handleSubmit}>
                <div className="element-form mb-32">
                  <label className="label mb-16">{t('txt_username')}</label>
                  <InputText
                    className="inputText"
                    id="email"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    disabled={isLoading}
                  />
                </div>
                {error ? <div className="login-error mb-16">{errTrans(error)}</div> : <></>}
                <Button
                  className="wi-primary-button-v2 dots-button mb-24 h48 w-100"
                  type={'submit'}
                  label={t('txt_send')}
                  disabled={isLoading}
                ></Button>
                <div className="forgot-pass-wrapper">
                  <div>{t('txt_already_reset_password')}</div>
                  <a className="ml-4" href="/login">
                    {t('txt_btn_login')}
                  </a>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
