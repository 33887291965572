import { BaseType, DefaultExampleList, LanguageItem } from '../../../../types/commonType';
import * as _ from 'lodash';
import { defaultMessage, defaultMessageHeadline, defaultVideoUrl } from '../../../common/useVideoCustom';
import { LIST_TEMPLATE_PREVIEW } from '../../../../utils/utils';

type ItemCampaignType = {
  land_id: string | null;
  partner_id: string | null;
  partner_name: string | null;
  additional_description: LanguageItem;
  certificate_campaign_description: LanguageItem;
  certificate_reason_description: LanguageItem;
  headline_description: LanguageItem;
  campaign_logo: string;
  is_default_video: boolean | null;
  video_type: string | null;
  video_url: {
    de: string;
    en: string;
    de_name: string;
    de_size: string;
    en_name: string;
    en_size: string;
  };
  video_headline: LanguageItem;
  message_headline: LanguageItem;
  message: LanguageItem;
  default_message: LanguageItem;
  default_message_headline: LanguageItem;
  template_id: string;
  template_data: Array<ItemTemplateDataType>;
  template_data_banner1: string;
  template_data_banner2: string;
  template_data_banner3: string;
  template_data_banner4: string;
  template_data_banner5: string;
  template_data_banner6: string;
  template_data_qr_code: string;
  template_data_wi_logo: string;
  template_data_illustration_logo: string;
  signature: string;
  signature_name: LanguageItem;
  signature_second: string;
  signature_name_second: LanguageItem;

  email_template_id: {
    de: number | null;
    en: number | null;
  };
  email_template_id_error_message: {
    de: string;
    en: string;
  };
  forest_type: {
    de: string;
    en: string;
  };
};

type ItemTemplateDataType = {
  template_id: string;
  banners: Array<string>;
  qr_code: string;
  illustration_logo: string;
  wi_logo: string;
  signature: string;
  signature_name: LanguageItem;
  signature_second: string;
  signature_name_second: LanguageItem;
};

export type CampaignType = ItemCampaignType & BaseType;

export const createEmptyCampaign = (): CampaignType => {
  return {
    land_id: '',
    partner_id: '',
    partner_name: '',
    name: {
      de: '',
      en: '',
    },
    additional_description: {
      de: '',
      en: '',
    },
    certificate_campaign_description: {
      de: '',
      en: '',
    },
    certificate_reason_description: {
      de: '',
      en: '',
    },
    code: '',
    headline_description: {
      de: '',
      en: '',
    },
    landingpage_url: null,
    status: '',
    campaign_logo: '',
    certificate_animal_image: '',
    certificate_tree_image: '',
    certificate_drone_image: '',
    certificate_qr_code: '',
    donation_example_list: _.cloneDeep(DefaultExampleList),
    email_template_id: {
      de: null,
      en: null,
    },
    email_template_id_error_message: {
      de: '',
      en: '',
    },
    video_headline: {
      de: '',
      en: '',
    },
    video_url: defaultVideoUrl,
    video_type: 'yt',
    message: {
      de: '',
      en: '',
    },
    message_headline: {
      de: '',
      en: '',
    },
    default_message: defaultMessage,
    default_message_headline: defaultMessageHeadline,
    email_header_picture: '',
    email_additional_picture: '',
    is_default_video: true,
    template_id: LIST_TEMPLATE_PREVIEW[0].uuid,
    template_data: [],
    template_data_banner1: '',
    template_data_banner2: '',
    template_data_banner3: '',
    template_data_banner4: '',
    template_data_banner5: '',
    template_data_banner6: '',
    template_data_qr_code: '',
    template_data_wi_logo: '',
    template_data_illustration_logo: '',
    signature: '',
    signature_name: {
      de: '',
      en: '',
    },
    signature_second: '',
    signature_name_second: {
      de: '',
      en: '',
    },
    forest_type: {
      de: '',
      en: '',
    },
  };
};
