import * as React from 'react';
import './create-campaign-form.scss';
import { useState, useRef, useEffect } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { COMMON_STATUS, CampaignStatus } from '../../../../utils/utils';
import { createEmptyCampaign } from './campaignType';
import {
  validateGeoCoordinate,
  transformDonationList,
  convertDonationExampleToUIItems,
  createNewForDonations,
  isValidForm,
  parseStringToInt,
  isValidateSpecialCharacter,
} from '../../../../utils/logic';
import { DefaultExampleList, DefaultExampleListEmpty } from '../../../../types/commonType';
import * as _ from 'lodash';
import CreateDonationFormComponent from '../create-donation-form/create-donation-form';
import CertificateContainer from '../../../common/certification/certificate-container';
import { buildTooltipManageTemplate } from '../../../common/column-template-table/column-template';
import { env } from '../../../../configs';
import WIUploadMediaField from '../../../common/upload/wi-upload-media-field';
import { SelectButton } from 'primereact/selectbutton';
import { VideoOptionTypes, VideoOptions, defaultDataSource, defaultVideoUrl, docData, replaceVideoUrl } from '../../../common/useVideoCustom';
import { WiTextEditor, WiTextEditor1Line } from '../../../dynamic-form/commons';
import { WIVideo } from '../../../dynamic-form/commons/wi-video/wi-video';
import { LIST_TEMPLATE_PREVIEW } from '../../../../utils/utils';
import useAuth from '../../../../context/useAuth';
import { CURRENCY_CODES, PERMISSIONS_V2 } from '../../../../utils/constants';

const CreateCampaignFormComponent = (props: any) => {
  const { data, onSubmit, setRegionLogo } = props;

  const formikRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangingDonations, setIsChangingDonations] = useState(false);
  const inclusive1Banners = [
    'template_data_banner1',
    'template_data_banner2',
    'template_data_banner3',
    'template_data_banner4',
    'template_data_banner5',
    'template_data_banner6',
  ];
  const inclusive1ItemDeletes = [
    'template_data_banner1',
    'template_data_banner2',
    'template_data_banner3',
    'template_data_banner4',
    'template_data_banner5',
    'template_data_banner6',
    'template_data_qr_code',
    'template_data_wi_logo',
    'template_data_illustration_logo',
    'signature_name',
    'signature',
    'signature_name_second',
    'signature_second',
  ];
  const [donationExample, setDonationExample] = useState<any>({
    donations: [],
    donationsCAD: [],
    donationsCHF: [],
  });

  const { auth } = useAuth();
  const permissions = {
    canUpdateCampaign:
      auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_UPDATE) || auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_UPDATE_TENANT),
  };

  useEffect(() => {
    handleUpadateValidateSchema(data?.selectedCampaign?.template_id);
    const updateDonationExample = transformExampleList(
      data.selectedCampaign
        ? _.isEmpty(data.selectedCampaign.donation_example_list)
          ? DefaultExampleListEmpty
          : data.selectedCampaign.donation_example_list
        : DefaultExampleList,
    );
    setDonationExample(updateDonationExample);
    if (data.selectedCampaign) {
      let regionId = data.selectedCampaign.land?.region_id;
      data.lands = data.lands.filter((l: any) => l.region_id === regionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.selectedCampaign]);

  const transformExampleList = (donation_example_list: any) => {
    let eur = convertDonationExampleToUIItems(donation_example_list.eur, CURRENCY_CODES.EUR);
    let cad = convertDonationExampleToUIItems(donation_example_list.cad, CURRENCY_CODES.CAD);
    let chf = convertDonationExampleToUIItems(donation_example_list.chf, CURRENCY_CODES.CHF);

    return {
      donations: eur,
      donationsCAD: cad,
      donationsCHF: chf,
    };
  };

  const initialCampaignValues = (campaign: any) => {
    let initialCampaign = createEmptyCampaign();
    if (!campaign || !campaign.uuid) {
      initialCampaign.status = CampaignStatus.find(f => f.name.toLowerCase() === 'active')?.code ?? CampaignStatus[0]?.code;
      initialCampaign.land_id = !data.lands ? '' : (data.lands[0]?.uuid ?? '');
      initialCampaign.partner_id = !data.partners ? '' : (data.partners[0]?.uuid ?? '');
      return initialCampaign;
    }

    initialCampaign.land_id = campaign.land_id;
    initialCampaign.partner_id = campaign.partner_id;
    initialCampaign.partner_name = campaign.partner_name;
    initialCampaign.name = campaign.name;
    initialCampaign.additional_description = campaign.additional_description;
    initialCampaign.certificate_campaign_description = campaign.certificate_campaign_description;
    initialCampaign.certificate_reason_description = campaign.certificate_reason_description;
    initialCampaign.code = campaign.code;
    initialCampaign.headline_description = campaign.headline_description;
    initialCampaign.landingpage_url = campaign.landingpage_url;
    initialCampaign.status = getCampaignStatus(campaign.status);
    initialCampaign.campaign_logo = campaign.campaign_logo;
    initialCampaign.certificate_animal_image = campaign.certificate_animal_image;
    initialCampaign.certificate_tree_image = campaign.certificate_tree_image;
    initialCampaign.certificate_drone_image = campaign.certificate_drone_image;
    initialCampaign.certificate_qr_code = campaign.certificate_qr_code;
    initialCampaign.donation_example_list = campaign.donation_example_list;
    initialCampaign.email_template_id = campaign.email_template_id;
    initialCampaign.email_template_id_error_message = campaign.email_template_id_error_message;
    initialCampaign.email_header_picture = campaign.email_header_picture;
    initialCampaign.email_additional_picture = campaign.email_additional_picture;
    initialCampaign.is_default_video = campaign.is_default_video;
    initialCampaign.video_url = campaign.video_url ?? defaultVideoUrl;
    initialCampaign.video_type = campaign.video_type ?? 'yt';
    initialCampaign.message = campaign.message ?? { de: '', en: '' };
    initialCampaign.template_id = campaign.template_id ?? LIST_TEMPLATE_PREVIEW[0].uuid;
    initialCampaign.template_data = campaign.template_data ?? [''];
    initialCampaign.forest_type = onGetForestType(campaign);

    var templateData = campaign.template_data.find((item: { template_id: any }) => item.template_id === LIST_TEMPLATE_PREVIEW[1].uuid);
    const dynamicValues = templateData?.banners?.slice(0, 6).map((banner: any, index: any) => ({
      [`template_data_banner${index + 1}`]: banner || '',
    }));

    if (dynamicValues) {
      Object.assign(initialCampaign, ...dynamicValues);
    }

    initialCampaign.signature = campaign.template_data ? templateData?.signature : '';
    initialCampaign.signature_name = campaign.template_data ? templateData?.signature_name : { de: '', en: '' };
    initialCampaign.signature_second = campaign.template_data ? templateData?.signature_second : '';
    initialCampaign.signature_name_second = campaign.template_data ? templateData?.signature_name_second : { de: '', en: '' };
    initialCampaign.template_data_qr_code = campaign.template_data ? templateData?.qr_code : '';
    initialCampaign.template_data_wi_logo = campaign.template_data ? templateData?.wi_logo : '';
    initialCampaign.template_data_illustration_logo = campaign.template_data ? templateData?.illustration_logo : '';

    return initialCampaign;
  };

  const onGetForestType = (campaign: any) => {
    const landItem = data.lands?.find((d: any) => d.uuid === campaign.land_id);
    const regionItem = data.regions?.find((d: any) => d.uuid === landItem?.region_id);
    return regionItem?.forest_type;
  };

  const onDataDocTemplate = (values: any) => {
    var expectedDataDoc = docData;
    if (values.is_default_video === false) {
      var url = values.video_url?.de ? values.video_url?.de : values.video_url?.en;
      expectedDataDoc = replaceVideoUrl(url, values.video_type, values.is_default_video);
    } else {
      expectedDataDoc = replaceVideoUrl(defaultVideoUrl.de, defaultDataSource, values.is_default_video);
    }

    return expectedDataDoc;
  };

  const onPressActionCampaign = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    if (
      formikRef &&
      isValidForm(data.selectedCampaign && data.selectedCampaign.uuid, formikRef.current.dirty, formikRef.current.isValid, isChangingDonations)
    ) {
      //@ts-ignore: Object is possibly 'null'.
      const currentCampaignData = formikRef?.current.values;
      transformDonationList(currentCampaignData, donationExample);

      var template_data = formikRef?.current.values.template_data.length > 0 ? formikRef?.current.values.template_data : [{}];
      template_data = _.isArray(template_data) ? template_data : JSON.parse(template_data);
      const idExists = template_data.some((item: { template_id: any }) => item.template_id === currentCampaignData.template_id);
      var banners = [
        currentCampaignData.template_data_banner1,
        currentCampaignData.template_data_banner2,
        currentCampaignData.template_data_banner3,
        currentCampaignData.template_data_banner4,
        currentCampaignData.template_data_banner5,
        currentCampaignData.template_data_banner6,
      ];

      if (!idExists) {
        template_data.push({
          template_id: currentCampaignData.template_id,
          banners: banners,
          signature: currentCampaignData.signature,
          signature_name: currentCampaignData.signature_name,
          signature_name_second: currentCampaignData.signature_name_second,
          signature_second: currentCampaignData.signature_second,
          qr_code: currentCampaignData.template_data_qr_code,
          wi_logo: currentCampaignData.template_data_wi_logo,
          illustration_logo: currentCampaignData.template_data_illustration_logo,
        });

        currentCampaignData.template_data = JSON.stringify(template_data);
      } else {
        const updatedArray = template_data.map((item: { template_id: any }) =>
          item.template_id === currentCampaignData.template_id
            ? {
                ...item,
                banners: banners,
                signature_name: currentCampaignData.signature_name,
                signature: currentCampaignData.signature,
                signature_name_second: currentCampaignData.signature_name_second,
                signature_second: currentCampaignData.signature_second,
                qr_code: currentCampaignData.template_data_qr_code,
                wi_logo: currentCampaignData.template_data_wi_logo,
                illustration_logo: currentCampaignData.template_data_illustration_logo,
              }
            : item,
        );

        currentCampaignData.template_data = JSON.stringify(updatedArray);
      }

      currentCampaignData.template_id = currentCampaignData.template_id === LIST_TEMPLATE_PREVIEW[0].uuid ? null : currentCampaignData.template_id;

      inclusive1ItemDeletes.forEach(element => {
        delete currentCampaignData[element];
      });

      setIsLoading(true);
      await onSubmit(currentCampaignData);
      setIsLoading(false);
    }
  };

  const getCampaignStatus = (code: string) => {
    let item = CampaignStatus.find(f => f.code.toLowerCase() === code.toLowerCase());
    if (item) {
      return item.code;
    }

    return CampaignStatus[0].code;
  };

  const handleGetLandItem = (item: any, setField: any) => {
    setField('land_id', item.value, false);
    if (data.lands.length > 0 && !data?.selectedCampaign) {
      const landItem = data.lands.find((d: any) => d.uuid === item.value);
      const regionItem = data.regions?.find((d: any) => d.uuid === landItem?.region_id);
      setField('region_name', regionItem?.name, false);
      setField('certificate_drone_image', regionItem?.certificate_drone_image, false);
      setField('certificate_animal_image', regionItem?.certificate_animal_image, false);
      setField('certificate_tree_image', regionItem?.certificate_tree_image, false);
      setField('certificate_qr_code', regionItem?.certificate_qr_code, false);
      setField('certificate_campaign_description', regionItem?.certificate_description, false);
      setField('forest_type', regionItem?.forest_type, false);
      setField('email_template_id.de', 88, false);
      setField('email_template_id.en', 89, false);
      setField('email_header_picture', regionItem?.email_header_picture, false);
      setField('email_additional_picture', regionItem?.email_additional_picture, false);
      setRegionLogo(regionItem?.logo);
    }
  };

  const handleGetPartnerItem = (item: any, setField: any) => {
    setField('partner_id', item.value, false);

    if (data.lands.length > 0) {
      const partnerItem = data.partners.find((d: any) => d.uuid === item.value);
      setField('partner_name', partnerItem.name, false);
    }
  };

  const generateValidationSchemaObj = (): any => {
    return {
      landingpage_url: Yup.string()
        .url('LANDING PAGE URL MUST BE A VALID URL')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? null : currentValue;
        })
        .nullable(),
      code: Yup.string().required('Code is required'),
      name: Yup.object().shape({
        de: Yup.string().required('Campaign name DE is required'),
        en: Yup.string().required('Campaign name EN is required'),
      }),
      email_template_id: Yup.object().shape({
        de: Yup.number()
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? null : currentValue;
          })
          .nullable()
          .required('Email Template DE is required'),
        en: Yup.number()
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? null : currentValue;
          })
          .nullable()
          .required('Email Template EN is required'),
      }),
      certificate_reason_description: Yup.object().shape({
        de: Yup.string().required('Certificate DE is required'),
        en: Yup.string().required('Certificate EN is required'),
      }),
      headline_description: Yup.object().shape({
        de: Yup.string().required('Headline DE is required'),
        en: Yup.string().required('Headline EN is required'),
      }),
      status: Yup.string().required('Status is required'),
      land_id: Yup.string().required('Area is required'),
      email_header_picture: Yup.string().required('Email header picture is required'),
      email_additional_picture: Yup.string().required('Email additional picture is required'),
      is_default_video: Yup.boolean().required('Default video is required'),
      video_url: Yup.object().when('is_default_video', {
        is: false,
        then: Yup.object().shape({
          de: Yup.string().required('Video Url DE is required'),
          en: Yup.string().required('Video Url EN is required'),
        }),
      }),
    };
  };

  const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape(generateValidationSchemaObj()));

  const setDataIsChangingDonations = (data: any) => {
    setIsChangingDonations(data);
  };

  const setDataDonations = (data: any) => {
    donationExample.donations = _.cloneDeep(data);
    setIsChangingDonations(true);
  };

  const setDataDonationsCAD = (data: any) => {
    donationExample.donationsCAD = _.cloneDeep(data);
    setIsChangingDonations(true);
  };

  const setDataDonationsCHF = (data: any) => {
    donationExample.donationsCHF = _.cloneDeep(data);
    setIsChangingDonations(true);
  };

  const buildHeaderTemplate = () => {
    const type = 'Campaign';
    const title = `${type} code`;
    const code = data.selectedCampaign?.code || '';
    const tooltip = `Click to open campaign page with code ${code}`;
    const URL = `${env.DONATION_URL}?${type.toLowerCase()}=${code}`;
    return buildTooltipManageTemplate(title, code, tooltip, URL);
  };

  const handleUpadateValidateSchema = (item: any) => {
    let newValidationSchemaObj: any = generateValidationSchemaObj();
    if (!item || item === LIST_TEMPLATE_PREVIEW[0].uuid) {
      newValidationSchemaObj['certificate_campaign_description'] = Yup.object().shape({
        de: Yup.string().required('Certificate DE is required'),
        en: Yup.string().required('Certificate EN is required'),
      });
      newValidationSchemaObj['certificate_animal_image'] = Yup.string().required();
      newValidationSchemaObj['certificate_tree_image'] = Yup.string().required();
      newValidationSchemaObj['certificate_drone_image'] = Yup.string().required();
    } else {
      newValidationSchemaObj['template_data_banner1'] = Yup.string().required();
      newValidationSchemaObj['template_data_banner2'] = Yup.string().required();
      newValidationSchemaObj['template_data_banner3'] = Yup.string().required();
      newValidationSchemaObj['template_data_banner4'] = Yup.string().required();
      newValidationSchemaObj['template_data_banner5'] = Yup.string().required();
      newValidationSchemaObj['template_data_banner6'] = Yup.string().required();
      newValidationSchemaObj['signature'] = Yup.string().required();
      newValidationSchemaObj['signature_name'] = Yup.object().shape({
        de: Yup.string().required('Name 1 is required'),
      });
      newValidationSchemaObj['signature_second'] = Yup.string().required();
      newValidationSchemaObj['signature_name_second'] = Yup.object().shape({
        de: Yup.string().required('Name 2 is required'),
      });
    }

    setValidationSchema(Yup.object().shape(newValidationSchemaObj));
  };

  const customAreaValueTemplate = (option: any) => {
    return <div>{option}</div>;
  };

  const customAreaItemTemplate = (option: any) => {
    const region = data.regions?.find((r: any) => r.uuid === option.region_id);
    return (
      <div>
        <div>{option?.name?.de ?? option.name}</div>
        <div style={{ fontWeight: 500 }}> {region?.name?.de || region?.name}</div>
      </div>
    );
  };

  return (
    <div className="campaign-form">
      <div className="campaign-form-container">
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={initialCampaignValues(data.selectedCampaign)}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ values, errors, touched, dirty, isValid, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="sticky-header-form">
                  <div className="col-12 d-flex align-items-center justify-content-between btn-submit-container">
                    {buildHeaderTemplate()}
                    <Button
                      loading={isLoading}
                      id="btn-submit-coupons"
                      // disabled={ !isValidForm(data.selectedCampaign && data.selectedCampaign.uuid, dirty, isValid, isChangingDonations) }
                      type="submit"
                      hidden={!permissions.canUpdateCampaign}
                      label={data.selectedCampaign ? 'Update' : 'Create'}
                      className="p-button-rounded btn-submit"
                      icon="pi pi-chevron-right"
                      iconPos="right"
                      onClick={onPressActionCampaign}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-7">
                  <Accordion activeIndex={0}>
                    <AccordionTab header="Campaign Information">
                      <div className="row row-content">
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Campaign name DE
                              <span className="required-label"> *</span>
                            </label>
                            <input
                              className={`${touched.name?.de && errors.name?.de ? 'has-error' : ''}`}
                              type="text"
                              name="name.de"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name?.de}
                              maxLength={50}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${touched.name?.de && errors.name?.de ? 'error' : ''}`}>{touched.name?.de && errors.name?.de}</div>
                          </div>
                          <div className="element-form">
                            <label className="label">
                              Campaign name EN
                              <span className="required-label"> *</span>
                            </label>
                            <Field
                              className={`${touched.name?.en && errors.name?.en ? 'has-error' : ''}`}
                              type="text"
                              name="name.en"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name.en}
                              maxLength={50}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${touched.name?.en && errors.name?.en ? 'error' : ''}`}>{touched.name?.en && errors.name?.en}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">Campaign logo</label>
                            <WIUploadMediaField
                              type="file"
                              name="campaign_logo"
                              label="Campaign logo"
                              hideLabel={true}
                              required={true}
                              classImage={'image-cert'}
                              classField="campaign-logo logo-form"
                              disabled={!permissions.canUpdateCampaign}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Campaign name EN
                            <span className="required-label"> *</span>
                          </label>
                          <Field
                            className={`${
                              touched.name?.en && errors.name?.en
                                ? "has-error"
                                : ""
                            }`}
                            type="text"
                            name="name.en"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name.en}
                            maxLength={50}
                          />
                          <div
                            className={`${
                              touched.name?.en && errors.name?.en ? "error" : ""
                            }`}
                          >
                            {touched.name?.en && errors.name?.en}
                          </div>
                        </div>
                      </div> */}
                      </div>
                      <div className="row row-content">
                        {!data.selectedCampaign && (
                          <div className="col-md-6">
                            <div className="element-form">
                              <label className="label">
                                Identifier<span className="required-label"> *</span>
                              </label>
                              <input
                                className={`${touched.code && errors.code ? 'has-error' : ''}`}
                                type="text"
                                name="code"
                                onChange={(e: any) => {
                                  const valueText = e.target.value;
                                  if (isValidateSpecialCharacter(valueText)) {
                                    handleChange(e);
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.code}
                                readOnly={data.selectedCampaign ? true : false}
                                maxLength={36}
                                disabled={!permissions.canUpdateCampaign}
                              />
                              <div className={`${touched.code && errors.code ? 'error' : ''}`}>{touched.code && errors.code}</div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Status
                              <span className="required-label"> *</span>
                            </label>
                            <Dropdown
                              className={`${errors.status ? 'has-error' : ''}`}
                              name="status"
                              value={values.status}
                              options={CampaignStatus}
                              onChange={(item: any) => {
                                setFieldValue('status', item.value, false);
                                handleChange(item);
                              }}
                              optionLabel="name"
                              optionValue="code"
                              appendTo="self"
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${errors.status ? 'error' : ''}`}>{errors.status}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-content">
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Areas<span className="required-label"> *</span>
                            </label>
                            <Dropdown
                              className={`${errors.land_id ? 'has-error' : ''} wi-country-dropdown p-dropdown-custom-search`}
                              value={values.land_id}
                              name="land_id"
                              options={data.lands.filter((l: any) => l.status === COMMON_STATUS.ACTIVE) || []}
                              onChange={(item: any) => {
                                handleGetLandItem(item, setFieldValue);
                                handleChange(item);
                              }}
                              optionLabel="name.de"
                              valueTemplate={option => customAreaValueTemplate(option?.name?.de ?? option?.name)}
                              itemTemplate={option => customAreaItemTemplate(option)}
                              optionValue="uuid"
                              appendTo="self"
                              filter
                              filterBy="name.de,name,code"
                              disabled={!permissions.canUpdateCampaign}
                              filterPlaceholder={'Search'}
                            />
                            <div className={`${errors.land_id ? 'error' : ''}`}>{errors.land_id}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">Partners</label>
                            <Dropdown
                              className={`${errors.partner_id ? 'has-error' : ''} p-dropdown-custom-search`}
                              name="partner_id"
                              value={values.partner_id}
                              options={data.partners || []}
                              onChange={(item: any) => {
                                handleGetPartnerItem(item, setFieldValue);
                                handleChange(item);
                              }}
                              optionLabel="name"
                              optionValue="uuid"
                              appendTo="self"
                              filter
                              filterBy={'name'}
                              panelClassName="p-dropdown-campaign-panel"
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${errors.partner_id ? 'error' : ''}`}>{errors.partner_id}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-content">
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Headline Description DE
                              <span className="required-label"> *</span>
                            </label>
                            <input
                              className={`${touched.headline_description?.de && errors.headline_description?.de ? 'has-error' : ''}`}
                              type="text"
                              name="headline_description.de"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.headline_description.de}
                              maxLength={50}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${touched.headline_description?.de && errors.headline_description?.de ? 'error' : ''}`}>
                              {touched.headline_description?.de && errors.headline_description?.de}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Headline Description EN <span className="required-label"> *</span>
                            </label>
                            <input
                              className={`${touched.headline_description?.en && errors.headline_description?.en ? 'has-error' : ''}`}
                              type="text"
                              name="headline_description.en"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.headline_description.en}
                              maxLength={50}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${touched.headline_description?.en && errors.headline_description?.en ? 'error' : ''}`}>
                              {touched.headline_description?.en && errors.headline_description?.en}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-content">
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">Additional Description DE</label>
                            <input
                              className={`${errors.additional_description?.de ? 'has-error' : ''}`}
                              type="text"
                              name="additional_description.de"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.additional_description.de}
                              maxLength={300}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${errors.additional_description?.de ? 'error' : ''}`}>{errors.additional_description?.de}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">Additional Description EN</label>
                            <input
                              className={`${errors.additional_description?.en ? 'has-error' : ''}`}
                              type="text"
                              name="additional_description.en"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.additional_description.en}
                              maxLength={300}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${errors.additional_description?.en ? 'error' : ''}`}>{errors.additional_description?.en}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-content">
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Reason for certificate DE
                              <span className="required-label"> *</span>
                            </label>
                            <input
                              className={`${
                                touched.certificate_reason_description?.de && errors.certificate_reason_description?.de ? 'has-error' : ''
                              }`}
                              type="text"
                              name="certificate_reason_description.de"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.certificate_reason_description.de}
                              maxLength={80}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div
                              className={`${touched.certificate_reason_description?.de && errors.certificate_reason_description?.de ? 'error' : ''}`}
                            >
                              {touched.certificate_reason_description?.de && errors.certificate_reason_description?.de}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Reason for Certificate EN
                              <span className="required-label"> *</span>
                            </label>
                            <input
                              className={`${
                                touched.certificate_reason_description?.en && errors.certificate_reason_description?.en ? 'has-error' : ''
                              }`}
                              type="text"
                              name="certificate_reason_description.en"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.certificate_reason_description.en}
                              maxLength={80}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div
                              className={`${touched.certificate_reason_description?.en && errors.certificate_reason_description?.en ? 'error' : ''}`}
                            >
                              {touched.certificate_reason_description?.en && errors.certificate_reason_description?.en}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-content">
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">Landing Page URL</label>
                            <input
                              className={`${touched.landingpage_url && errors.landingpage_url ? 'has-error' : ''}`}
                              type="text"
                              name="landingpage_url"
                              onChange={(item: any) => {
                                setFieldValue('landingpage_url', item.value, false);
                                handleChange(item);
                              }}
                              onBlur={handleBlur}
                              value={values.landingpage_url || ''}
                              maxLength={255}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${touched.landingpage_url && errors.landingpage_url ? 'error' : ''}`}>
                              {touched.landingpage_url && errors.landingpage_url}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row row-content">
                      <div className="col-md-3">
                        <div className="element-form">
                          <WIUploadField
                            type="file"
                            name="campaign_logo"
                            label="Campaign logo"
                            required={true}
                            classImage={"image-cert"}
                            classField="campaign-logo"
                          />
                        </div>
                      </div>
                    </div> */}
                    </AccordionTab>
                    <AccordionTab header="Certificate Information">
                      {values.template_id !== LIST_TEMPLATE_PREVIEW[0].uuid ? (
                        <div className="row row-content">
                          {inclusive1Banners.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="element-form">
                                <WIUploadMediaField
                                  type="file"
                                  hideLabelFileName={true}
                                  classImage="image-cert"
                                  className="email_additional_picture"
                                  name={item}
                                  label={`${'Certificate banner ' + (index + 1)}`}
                                  required={true}
                                  disabled={!permissions.canUpdateCampaign}
                                />
                              </div>
                            </div>
                          ))}
                          <div className="col-md-6">
                            <div className="element-form">
                              <label className="label">
                                Name 1<span className="required-label"> *</span>
                              </label>
                              <input
                                className={`${touched.signature_name?.de && errors.signature_name?.de ? 'has-error' : ''}`}
                                name="signature_name.de"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.signature_name?.de}
                                disabled={!permissions.canUpdateCampaign}
                              />
                              <div className={`${touched.signature_name?.de && errors.signature_name?.de ? 'error' : ''}`}>
                                {touched.signature_name?.de && errors.signature_name?.de}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="element-form">
                              <label className="label">
                                Name 2<span className="required-label"> *</span>
                              </label>
                              <input
                                className={`${touched.signature_name_second?.de && errors.signature_name_second?.de ? 'has-error' : ''}`}
                                name="signature_name_second.de"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.signature_name_second?.de}
                                disabled={!permissions.canUpdateCampaign}
                              />
                              <div className={`${touched.signature_name_second?.de && errors.signature_name_second?.de ? 'error' : ''}`}>
                                {touched.signature_name_second?.de && errors.signature_name_second?.de}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row row-content">
                          <div className="col-md-12">
                            <div className="element-form">
                              <label className="label">
                                Certificate Description DE
                                <span className="required-label"> *</span>
                              </label>
                              <textarea
                                className={`${
                                  touched.certificate_campaign_description?.de && errors.certificate_campaign_description?.de ? 'has-error' : ''
                                }`}
                                name="certificate_campaign_description.de"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!permissions.canUpdateCampaign}
                                value={values.certificate_campaign_description?.de}
                              />
                              <div
                                className={`${
                                  touched.certificate_campaign_description?.de && errors.certificate_campaign_description?.de ? 'error' : ''
                                }`}
                              >
                                {touched.certificate_campaign_description?.de && errors.certificate_campaign_description?.de}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="element-form">
                              <label className="label">
                                Certificate Description EN
                                <span className="required-label"> *</span>
                              </label>
                              <textarea
                                className={`${
                                  touched.certificate_campaign_description?.en && errors.certificate_campaign_description?.en ? 'has-error' : ''
                                }`}
                                name="certificate_campaign_description.en"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.certificate_campaign_description?.en}
                                disabled={!permissions.canUpdateCampaign}
                              />
                              <div
                                className={`${
                                  touched.certificate_campaign_description?.en && errors.certificate_campaign_description?.en ? 'error' : ''
                                }`}
                              >
                                {touched.certificate_campaign_description?.en && errors.certificate_campaign_description?.en}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </AccordionTab>
                    <AccordionTab header="Email Setting">
                      <div className="row row-content">
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Email template DE
                              <span className="required-label"> *</span>
                            </label>
                            <InputNumber
                              className={`${touched.email_template_id?.de && errors.email_template_id?.de ? 'has-error' : ''}`}
                              name="email_template_id.de"
                              locale="de-DE"
                              onBlur={e => {
                                const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                                setFieldValue('email_template_id.de', value, false);
                                setTimeout(() => {
                                  handleBlur(e);
                                }, 200);
                              }}
                              format={false}
                              value={values.email_template_id?.de}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${touched.email_template_id?.de && errors.email_template_id?.de ? 'error' : ''}`}>
                              {touched.email_template_id?.de && errors.email_template_id?.de}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Email template EN
                              <span className="required-label"> *</span>
                            </label>
                            <InputNumber
                              className={`${touched.email_template_id?.en && errors.email_template_id?.en ? 'has-error' : ''}`}
                              name="email_template_id.en"
                              locale="de-DE"
                              onBlur={e => {
                                const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                                setFieldValue('email_template_id.en', value, false);
                                setTimeout(() => {
                                  handleBlur(e);
                                }, 200);
                              }}
                              format={false}
                              value={values.email_template_id?.en}
                              disabled={!permissions.canUpdateCampaign}
                            />
                            <div className={`${touched.email_template_id?.en && errors.email_template_id?.en ? 'error' : ''}`}>
                              {touched.email_template_id?.en && errors.email_template_id?.en}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="element-form">
                            <WIUploadMediaField
                              type="file"
                              hideLabelFileName={true}
                              classImage="image-cert"
                              className="email_header_picture"
                              name="email_header_picture"
                              label="Email header picture"
                              required={true}
                              disabled={!permissions.canUpdateCampaign}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="element-form">
                            <WIUploadMediaField
                              type="file"
                              hideLabelFileName={true}
                              classImage="image-cert"
                              className="email_additional_picture"
                              name="email_additional_picture"
                              label="Email additional picture"
                              required={true}
                              disabled={!permissions.canUpdateCampaign}
                            />
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                    <AccordionTab header="Donation Example">
                      <CreateDonationFormComponent
                        setDataIsChangingDonations={setDataIsChangingDonations}
                        originalDonations={createNewForDonations(data.selectedCampaign ?? [], CURRENCY_CODES.EUR)}
                        donations={[...donationExample.donations]}
                        setDataDonations={setDataDonations}
                        originalDonationsCAD={createNewForDonations(data.selectedCampaign ?? [], CURRENCY_CODES.CAD)}
                        donationsCAD={[...donationExample.donationsCAD]}
                        setDataDonationsCAD={setDataDonationsCAD}
                        originalDonationsCHF={createNewForDonations(data.selectedCampaign ?? [], CURRENCY_CODES.CHF)}
                        donationsCHF={[...donationExample.donationsCHF]}
                        setDataDonationsCHF={setDataDonationsCHF}
                      />
                    </AccordionTab>
                    <AccordionTab header="Thankyou Page Customization">
                      <div className="row row-content">
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">Video Mode</label>
                            <SelectButton
                              className="donation-checkbox wi-selectbutton"
                              id="is_default_video"
                              name="is_default_video"
                              options={VideoOptions}
                              onChange={(item: any) => {
                                if (item.value) {
                                  setFieldValue('is_default_video', item.value === 'Default', false);
                                  setFieldValue('video_type', 'yt', false);
                                  setFieldValue('video_url.de', '', false);
                                  setFieldValue('video_url.en', '', false);
                                  setFieldValue('video_headline.en', '', false);
                                  setFieldValue('video_headline.de', '', false);
                                }
                              }}
                              disabled={!permissions.canUpdateCampaign}
                              onBlur={handleBlur}
                              value={values.is_default_video ? 'Default' : 'Custom'}
                            />
                          </div>
                        </div>
                        <div className="col-md-6" />
                        <div className="col-md-12 video-container">
                          <div className="element-form">
                            <div className="aw_video_wrapper" style={{ height: 'auto', aspectRatio: '1/1' }}>
                              <iframe srcDoc={onDataDocTemplate(values)} style={{ width: '100%', height: '100%' }} scrolling="no"></iframe>
                            </div>
                          </div>
                        </div>
                        {!values.is_default_video ? (
                          <div
                            className="col-md-12"
                            style={{
                              visibility: values.is_default_video ? 'collapse' : 'visible',
                            }}
                          >
                            <div className="element-form">
                              <label className="label">Video Source</label>
                              <SelectButton
                                className="donation-checkbox wi-selectbutton"
                                id="video_type"
                                name="video_type"
                                options={VideoOptionTypes}
                                disabled={!permissions.canUpdateCampaign}
                                onChange={(item: any) => {
                                  if (item.value) {
                                    setFieldValue('video_type', item.value, false);
                                    setFieldValue('video_url.de', '', false);
                                    setFieldValue('video_url.en', '', false);
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.video_type}
                                optionValue="value"
                                optionLabel="label"
                              />
                            </div>
                            <div className="element-form">
                              {values.video_type != 'ml' ? (
                                <div
                                  className="col-md-12"
                                  style={{
                                    visibility: values.is_default_video ? 'collapse' : 'visible',
                                  }}
                                >
                                  <div className="col-md-12">
                                    <div className="element-form">
                                      <label className="label">
                                        Video URL DE
                                        <span className="required-label"> *</span>
                                      </label>
                                      <input
                                        className={`${touched.video_url?.de && errors.video_url?.de ? 'has-error' : ''}`}
                                        disabled={!permissions.canUpdateCampaign}
                                        type="text"
                                        name="video_url.de"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.video_url?.de}
                                      />
                                      <div className={`${touched.video_url?.de && errors.video_url?.de ? 'error' : ''}`}>
                                        {touched.video_url?.de && errors.video_url?.de}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="element-form">
                                      <label className="label">
                                        Video URL EN
                                        <span className="required-label"> *</span>
                                      </label>
                                      <input
                                        className={`${touched.video_url?.en && errors.video_url?.en ? 'has-error' : ''}`}
                                        type="text"
                                        name="video_url.en"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.video_url?.en}
                                        disabled={!permissions.canUpdateCampaign}
                                      />
                                      <div className={`${touched.video_url?.en && errors.video_url?.en ? 'error' : ''}`}>
                                        {touched.video_url?.en && errors.video_url?.en}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              {values.video_type == 'ml' ? (
                                <div
                                  className="col-md-12"
                                  style={{
                                    visibility: values.is_default_video ? 'collapse' : 'visible',
                                  }}
                                >
                                  <div className="col-md-12">
                                    <div className="element-form">
                                      <label className="label">
                                        Video DE
                                        <span className="required-label"> *</span>
                                      </label>
                                      <WIVideo name="video_url.de" schema={{}} disabled={!permissions.canUpdateCampaign} />
                                      <div className={`${touched.video_url?.de && errors.video_url?.de && !values.video_url?.de ? 'error' : ''}`}>
                                        {touched.video_url?.de && errors.video_url?.de && !values.video_url?.de ? errors.video_url?.de : ''}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="element-form">
                                      <label className="label">
                                        Video EN
                                        <span className="required-label"> *</span>
                                      </label>
                                      <WIVideo name="video_url.en" schema={{}} disabled={!permissions.canUpdateCampaign} />
                                      <div className={`${touched.video_url?.en && errors.video_url?.en && !values.video_url?.en ? 'error' : ''}`}>
                                        {touched.video_url?.en && errors.video_url?.en && !values.video_url?.en ? errors.video_url?.en : ''}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="col-md-12">
                          <div className="element-form">
                            <label className="label">Thank You Message DE</label>
                            <WiTextEditor name="message.de" disabled={!permissions.canUpdateCampaign} values={values} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="element-form">
                            <label className="label">Thank You Message EN</label>
                            <WiTextEditor name="message.en" disabled={!permissions.canUpdateCampaign} values={values} />
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
                <div className="col-12 col-lg-5">
                  <CertificateContainer
                    values={values}
                    errors={errors}
                    forestType={'forestType?.name'}
                    isCampaign={true}
                    region_logo={data?.regionLogo || ''}
                    visible={true}
                    handleChange={handleChange}
                    handleUpadateValidateSchema={handleUpadateValidateSchema}
                    fields={[
                      {
                        title: 'Certificate Campaign Description DE',
                        name: 'certificate_campaign_description.de',
                      },
                      {
                        title: 'Certificate Campaign Description EN',
                        name: 'certificate_campaign_description.en',
                      },
                    ]}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateCampaignFormComponent;
