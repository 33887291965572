import React, { useEffect } from 'react';
import './layout.scss';
import '../assets/fonts/fonts.css';
import { useLayoutV2 } from '../context/LayoutProvider';
import { ProgressSpinner } from 'primereact/progressspinner';
import WiWarning from '../components_v2/common/wi-warning/wi-warning';
import Menubar from './sidebar-menu/sidebar-menu';
import WiAccountUser from './wi-account/wi-account';
import WiLoading from '../components_v2/common/wi-loading/wi-loading';

const Layout = (props: any) => {
  const { progress, setProgress, isContentLoading, turnOffProgress, popupSaveChange, setPopupSaveChange } = useLayoutV2();
  const { className } = props;

  useEffect(() => {
    turnOffProgress();
  }, []);

  return (
    <div className={['main-container-v2', className || ''].filter(c => !!c).join(' ')}>
      <div className="menu">
        <Menubar />
      </div>
      <div className="content">
        <main className="d-flex flex-column w-100 h-100">
          <WiAccountUser title={props.title} />
          <div className="d-flex flex-column w-100 position-relative flex-1">
            {isContentLoading && (
              <div className="loading-container-v2 content-loading-container-v2">
                <ProgressSpinner className="progress-spinner-v2" />
              </div>
            )}
            {props.children}
          </div>
        </main>
      </div>
      {progress?.display && <WiLoading visible={progress?.loading} setProgress={setProgress} type={progress?.type} message={progress?.message} />}
      {popupSaveChange?.visible && (
        <WiWarning visible={popupSaveChange?.visible} popupSaveChange={popupSaveChange} setPopupSaveChange={setPopupSaveChange} />
      )}
    </div>
  );
};

export default Layout;
