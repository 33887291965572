import * as React from 'react';
import './wi-loading.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TYPE_LOADING } from '../../../utils/constants';
import { useEffect, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { ReactComponent as CloseSvg } from '../../../assets/images/icons/close.svg';
import { ReactComponent as CheckSvg } from '../../../assets/images/icons/check.svg';

const WiLoading = (props: any) => {
  const { visible, message, type, setProgress } = props;

  const [value, setValue] = useState(0);
  let timeoutId: any = null;
  let interval: any = null;

  useEffect(() => {
    if (type !== TYPE_LOADING.LOADING) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutId = setTimeout(() => {
        setProgress({
          display: false,
          loading: false,
          type: TYPE_LOADING.LOADING,
          message: '',
        });
      }, 3000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (type !== TYPE_LOADING.LOADING) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      interval = setInterval(() => {
        setValue(prevValue => {
          const newValue = prevValue + 30;
          if (newValue === 3000) {
            setValue(0);
            clearInterval(interval);
          }
          return newValue;
        });
      }, 100);
    } else {
      setValue(0);
      clearInterval(interval);
    }
    return () => {
      setValue(0);
      clearInterval(interval);
    };
  }, [type]);

  const onHideLoading = () => {
    if (type === TYPE_LOADING.LOADING) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (interval) {
      clearInterval(interval);
    }
    setProgress({
      display: false,
      loading: false,
      type: type,
      message: message,
    });
  };

  return (
    <>
      <div className={`wi-loading-backdrop ${!visible && 'wi-loading-backdrop-none'}`} onClick={() => onHideLoading()}>
        <div className="wi-loading-backdrop-container">
          <div style={{ width: '400px' }} className={`wi-loading-v2 ${visible ? 'wi-loading-show' : 'wi-loading-none'}`}>
            {
              <div className="loading-content">
                {type === TYPE_LOADING.LOADING ? (
                  <ProgressSpinner className="progress-spinner-v2" style={{ width: '24px', height: '24px', position: 'relative' }} strokeWidth="8" />
                ) : (
                  <>
                    <div className="progress-bar">
                      <ProgressBar style={{ height: '4px', width: '69px' }} value={value}></ProgressBar>
                    </div>
                    <div className="mt-16">{type === TYPE_LOADING.ERROR ? <CloseSvg></CloseSvg> : <CheckSvg></CheckSvg>}</div>
                  </>
                )}
                <p className="mb-0 message-loading mt-16">{message}</p>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default WiLoading;
