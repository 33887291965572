import * as React from 'react';
import './content-component.scss';

const ContentComponent = (props: any) => {
  const { className, children } = props;
  return (
    <div className={className}>
      <div className="content">{children}</div>
    </div>
  );
};

export default ContentComponent;
